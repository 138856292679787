<div class="flex flex-column pt-0 dropdown-info">
  @if(this.addedPassengerList.length > 0){
    <div class="flex flex-column pt-0 co-passengers-info">
    <div class="grid">
        <div class="col-12 booking-dropdown">
            <p-dropdown [options]="addedPassengerList" [(ngModel)]="selectAddedPassenger" placeholder="Select added passenger"
              [ngModelOptions]="{standalone : true}" optionLabel="name" [showClear]="false" (onChange)="onSelectAddedPassenger($event)">
              <ng-template pTemplate="selectedItem">
                <div class="flex align-items-center gap-2">
                  <div class="passenger-popup bold-name">
                    <span class="ellipsis font-14 mr-2">
                      {{selectAddedPassenger.pass_full_name}}
                    </span>
                    <span class="unique font-14 ellipsis mr-2">
                      | {{selectAddedPassenger.unique_id}} | {{selectAddedPassenger.passenger_type}}
                    </span>
                  </div>
                </div>
              </ng-template>
        
              <ng-template let-passenger pTemplate="item">
                <div class="flex align-items-center gap-2">
                  <div class="passenger-popup bold-name">
        
                    <img [src]="getPassengerImageUrl(passenger)" class="vertical-align-middle border-round-sm list-img mr-2" />
                    <span class="ellipsis font-14 mr-2">
                      {{passenger.pass_full_name}}
                    </span>
                    <span class="unique font-14 ellipsis mr-2">
                      ({{passenger.unique_id}}) ({{passenger.passenger_type}})
                    </span>
        
                    <i class="{{miIcons.OFF_OUTLINE_CLOSE}} wh-24 close-icon cursor-pointer"></i>
        
                  </div>
        
                </div>
              </ng-template>
            </p-dropdown>
        </div>
    </div>

    @if(selectAddedPassenger){
        <div class="flex flex-row align-items-center py-12px">
            <div class="w-6 passenger-type-info">   
              <img [src]="selectAddedPassenger?.passenger_profile_image_url?.length > 0 ? selectAddedPassenger?.passenger_profile_image_url[0] : defaultUserImage" class="vertical-align-middle border-round-sm passenger-icon list-img mr-2 image-details" />
              <span class="font-14 ellipsis mr-2 text-color">
                  {{selectAddedPassenger.pass_full_name}}
              </span>
            </div>
            <div class="w-6 passenger-type-info">
              <div class="flex flex-row flex-wrap">
                <div class="flex align-items-center justify-content-center icon-size active allowed border-round m-1 mr-3">
                  <i class="wh-24 inline-block"></i>
                  T
                </div>
              
                <!-- selectAddedPassenger[field.attributeCode] -->
                <div class="flex align-items-center justify-content-center icon-size allowed border-round m-1">
                  <i class="{{miIcons.BOOKING_CAR_OLD}} wh-21 inline-block"></i>
                </div>
                <div class="flex align-items-center justify-content-center icon-size allowed  border-round m-1">
                  <i class="{{miIcons.BOOKING_AIRPORT_OLD}} wh-21 inline-block"></i>
                </div>
                <div class="flex align-items-center justify-content-center icon-size not-allowed border-round m-1">
                  <i class="{{miIcons.BOOKING_SHUTTLE_OLD}} wh-21 inline-block"></i>
                </div>
                <div class="flex align-items-center justify-content-center icon-size allowed  border-round m-1">
                  <i class="{{miIcons.BOOKING_BUS}} wh-21 inline-block"></i>
                </div>
              </div>
            <div class="service-on-off">
              @if(selectAddedPassenger?.transport_services_allowed){
                <div class="on-service" translate>bookings.lbl_on</div>
              }
              @else {
                <div class="off-service" translate>bookings.lbl_off</div>
              }  
            </div>
            </div>
        </div>
      <!-- <hr class="hr-line"> -->
      <div class="flex flex-row align-items-center py-12px">
        <div class="w-6 flex align-items-center">
          <i class="mi-lg mi-passenger_type_photo margin-top details-icon inline-block icon-color mr-2"></i>
          <div>
            <label class="font-normal font-14 text-500 value-color mb-0 details-label" translate>dispatch.passenger_type</label>
          </div>
        </div>
        <div class="w-6 passenger-type-value"> {{ selectAddedPassenger?.passenger_type || '-' }} </div>
      </div>
      <hr class="hr-line">
      <div class="flex flex-row align-items-center py-12px">
        <div class="w-6 flex align-items-center">
          <i class="mi-lg mi-watchlist details-icon margin-top inline-block icon-color mr-2"></i>
          <div>
            <label class="font-normal font-14 text-500 value-color mb-0 details-label" translate>dispatch.watchlist</label>
          </div>
        </div>
        <div class="w-6 details-value"> {{ selectAddedPassenger?.watchlist || '-' }} </div>
      </div>
      <hr class="hr-line">
      <div class="flex flex-row align-items-center py-12px">
        <div class="w-6 flex align-items-center">
          <i class="mi-lg id_card details-icon margin-top inline-block icon-color mr-2"></i>
          <div>
            <label class="font-normal font-14 text-500 value-color mb-0 details-label" translate>dispatch.uniqueId</label>
          </div>
        </div>
        <div class="w-6 details-value"> {{ selectAddedPassenger?.unique_id || '-' }} </div>
      </div>
      <hr class="hr-line">
      <div class="flex flex-row align-items-center py-12px">
        <div class="w-6 flex align-items-center">
          <i class="mi-lg mi-phone details-icon margin-top inline-block icon-color mr-2"></i>
          <div>
            <label class="font-normal font-14 text-500 value-color mb-0 details-label" translate>dispatch.contact_number</label>
          </div>
        </div>
        <div class="w-6 details-value"> 
          <app-mi-mobile-value [isStaticEntity]="true" [countryCodeForMobileNumber]="countryCodeForMobileNumber" [mobileNumber]="selectAddedPassenger?.mobile_number"></app-mi-mobile-value>  
        </div>
      </div>

      <hr class="hr-line">
      <div class="flex flex-row align-items-center py-12px">
        <div class="w-6 flex align-items-center">
          <i class="mi-lg mi-mail details-icon margin-top inline-block icon-color mr-2"></i>
          <div>
            <label  class="font-normal font-14 text-500 value-color mb-0 details-label" translate>dispatch.email_address</label>
          </div>
        </div>
        <div [pTooltip]="selectAddedPassenger?.email"  tooltipPosition="bottom" class="w-6 ellipse details-value"> {{ selectAddedPassenger?.email || '-' }} </div>
      </div>

      <hr class="hr-line">
      <div class="flex flex-row align-items-center py-12px">
        <div class="w-6 flex align-items-center">
          <i class="mi-lg mi-chart-barcode details-icon margin-top inline-block icon-color mr-2"></i>
          <div>
            <label class="font-normal font-14 text-500 value-color mb-0 details-label" translate>dispatch.barcode</label>
          </div>
        </div>
        <div class="w-6 font-semibold details-value"> - </div>
      </div>

      <hr class="hr-line">
      <div class="flex flex-row align-items-center py-12px">
        <div class="w-6 flex align-items-center">
          <i class="mi-lg mi-gender details-icon margin-top inline-block icon-color mr-2"></i>
          <div>
            <label class="font-normal font-14 text-500 value-color mb-0 details-label " translate>dispatch.gender</label>
          </div>
        </div>
        <div class="w-6 details-value"> {{ selectAddedPassenger?.gender || '-' }} </div>
      </div>

      <hr class="hr-line">
      <div class="flex flex-row align-items-center py-12px">
        <div class="w-6 flex">
          <i class="mi-lg mi-trip-notification details-icon margin-top inline-block icon-color mr-2"></i>
          <div>
            <label class="font-normal font-14 text-500 value-color mb-0 details-label" translate>dispatch.opt_in_for_trip_notifications</label>
          </div>
        </div>
        <div class="w-6 details-value"> - </div>
      </div>

      @for (group of groups; track $index) {
        @if (group.code !== DETAILS_GROUP_CODE) {
          <div class="privileges-section">
            <label class="font-semibold text-lg " translate>{{cs.getLabelValue(moduleName+'.groups.'+group.code+'.label',attributeLabels,group.code)}}</label>      
          </div>
        }
        @for (field of group.fields; track $index) {
          @if (group.code !== DETAILS_GROUP_CODE && field.attributeCode !== 'passenger_id') {
            <div class="formgrid grid mr-2">
              <div class="flex col p-3 pl-1 text-500 ">
                <div>
                  @if (group.code === DETAILS_GROUP_CODE) {
                    <i class="{{getIcon(field.attributeId)}} margin-top details-icon lock-icon-top inline-block cursor-pointer icon-color mr-2"></i>
                  }
                  @else {
                    <i class="{{miIcons.BOOKING_LOCK}} details-icon margin-top lock-icon-top inline-block cursor-pointer icon-color mr-2"></i>
                  }
                </div>

                <div class="privileges-fields-label flex align-items-center">
                  @if(field.attributeCode === 'number_of_passengers_per_booking'){
                    <label class="font-normal font-14 text-500 value-color mb-0 details-label" translate>{{cs.getLabelValue(moduleName+'.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}} 
                    </label>
                    <span class="text-500 ml-2 hidden">
                      <app-mi-tooltip [messageData]="cs.getLabelValue( moduleName +'.fields.'+field.attributeCode+'.helpTips',attributeLabels,field.attributeCode)"></app-mi-tooltip>
                    </span>
                  } @else if(field.attributeCode === 'number_of_bookings_per_day'){
                    <label class="font-normal font-14 text-500 value-color mb-0 details-label" translate>{{cs.getLabelValue(moduleName+'.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}} 
                    </label>
                    <span class="text-500 ml-2 hidden">
                      <app-mi-tooltip [messageData]="cs.getLabelValue( moduleName +'.fields.'+field.attributeCode+'.helpTips',attributeLabels,field.attributeCode)"></app-mi-tooltip>
                    </span>
                  }  @else if(field.attributeCode === 'number_of_luggage_allowed'){
                    <label class="font-normal font-14 text-500 value-color mb-0 details-label" translate>{{cs.getLabelValue(moduleName+'.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}} 
                    </label>
                    <span class="text-500 ml-2 hidden">
                      <app-mi-tooltip [messageData]="cs.getLabelValue( moduleName +'.fields.'+field.attributeCode+'.helpTips',attributeLabels,field.attributeCode)"></app-mi-tooltip>
                    </span>
                  } @else if(field.attributeCode === 'number_of_wheelchair_per_booking'){
                    <label class="font-normal font-14 text-500 value-color mb-0 details-label" translate>{{cs.getLabelValue(moduleName+'.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}} 
                    </label>
                    <span class="text-500 ml-2 hidden">
                      <app-mi-tooltip [messageData]="cs.getLabelValue( moduleName +'.fields.'+field.attributeCode+'.helpTips',attributeLabels,field.attributeCode)"></app-mi-tooltip>
                    </span>
                  } @else if(field.attributeCode === 'number_of_child_seat_per_booking'){
                    <label class="font-normal font-14 text-500 value-color mb-0 details-label" translate>{{cs.getLabelValue(moduleName+'.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}} 
                    </label>
                    <span class="text-500 ml-2 hidden">
                      <app-mi-tooltip [messageData]="cs.getLabelValue( moduleName +'.fields.'+field.attributeCode+'.helpTips',attributeLabels,field.attributeCode)"></app-mi-tooltip>
                    </span>
                  } 
                  @else {
                    <label class="font-normal font-14 text-500 value-color mb-0 details-label" translate>{{cs.getLabelValue(moduleName+'.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}} 
                    </label>
                    <span class="text-500 ml-2 passenger-tooltip">
                      <app-mi-tooltip [messageData]="cs.getLabelValue( moduleName +'.fields.'+field.attributeCode+'.helpTips',attributeLabels,field.attributeCode)"></app-mi-tooltip>
                    </span>
                  }
                </div>
              </div>
                <div  [pTooltip]="selectAddedPassenger[field.attributeCode]"  tooltipPosition="bottom" class=" value col p-2 ellipse font-14 text-color privileges-value flex align-items-center">
                  {{selectAddedPassenger[field.attributeCode] || '-'}}
                </div>
            </div> 
            <hr class="hr-line-fields">
          }                
        }
      }
    }
</div>
  } @else {
    <div class="w-full pt-0 pr-2 pb-0 pl-3">
      <app-no-result-found></app-no-result-found>    
    </div> 
  }
