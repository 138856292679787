@if(countryCodeForMobileNumber) {
    <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png" [class]="'mr-2 flag flag-' + countryCodeForMobileNumber?.toLowerCase()" class="width-18"/>
}

@if (isStaticEntity) {
    <label class="detail-value">{{ formattedMobileNumber ? formattedMobileNumber : '--' }}</label>
} @else if(moduleName === 'booking_pass' ){
    <label class="detail-value font-semibold text-base text-black-alpha-90">{{ attributeValues ? attributeValues : '--' }}</label>
}
@else {
    <label class="detail-value">{{ formattedMobileNumber ? formattedMobileNumber : '--' }}</label>
}