import { NgStyle } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ImageModule } from 'primeng/image';
import { AppSettings } from '../../app.settings';

@Component({
  selector: 'app-mi-image-container',
  standalone: true,
  imports: [NgStyle, ImageModule],
  templateUrl: './mi-image-container.component.html',
  styleUrl: './mi-image-container.component.scss'
})
export class MiImageContainerComponent implements OnInit {
  @Input() miImage;
  // @Input() miHeight;
  @Input() miWidth;
  @Input() moduleName;
  defaultLoaderImg = AppSettings.DEFAULT_LOADER_IMAGE;
  entityTypes = AppSettings.ENTITY_TYPE;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    if (this.moduleName === AppSettings.ENTITY_TYPE.EVENTS) {
      this.defaultLoaderImg = AppSettings.DEFAULT_EVENT_IMAGE;
      this.cd.detectChanges();
    }
  }

}
