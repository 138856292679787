
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators, FormArray } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AppIcons } from 'app/modules/shared/app.icons';
import { AppSettings } from 'app/modules/shared/app.settings';
import { Language } from 'app/modules/shared/models/language';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { EntityService } from 'app/modules/shared/services/entity.service';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { ChipsModule } from 'primeng/chips';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CheckboxModule } from 'primeng/checkbox';
import { Country } from 'app/modules/shared/models/country';
import { MultiSelectModule } from 'primeng/multiselect';
import { SettingsService } from 'app/modules/settings/settings.service';
import { MenuModule } from 'primeng/menu';
import { NgClass } from '@angular/common';
import { Router } from '@angular/router';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { AccessProviderDirective } from 'app/modules/shared/directives/access-provider.directive';

interface AttributeDataType {
  tenantId?: string;
  createdBy?: string;
  updatedBy?: string;
  createdAt?: number;
  updatedAt?: number;
  attributeInputTypeId?: number;
  inputTypeCode?: string;
  inputName?: string;
  useInCustomField?: boolean;
  imperialUnit?: string | null;
  metricUnit?: string | null;
  countryId?: string;
}

@Component({
  selector: 'app-settings-custom-fields',
  standalone: true,
  imports: [TranslateModule, ButtonModule, ToastModule, InputTextModule, ChipsModule, InputTextareaModule, MenuModule, ReactiveFormsModule, FormsModule, TabViewModule, DropdownModule, CheckboxModule, MultiSelectModule, NgClass, AccessProviderDirective],
  templateUrl: './settings-custom-fields.component.html',
  styleUrl: './settings-custom-fields.component.scss'
})
export class SettingsCustomFieldsComponent implements OnInit {
  miIcons = AppIcons;
  customFieldForm: FormGroup;
  language: Language;
  country: Country;
  moduleList: any = AppSettings.MODULE_NAME;
  moduleNameList: { label: string, key: string }[] = [];
  moduleNameFilter;
  selectedModuleName: string;
  selectedModuleNameFilter: string;
  uniqueTabs: any[] = [];
  uniqueGroups: any[] = [];
  selectedTabGroups;
  dataTypes: AttributeDataType[] = [];
  validationList = [
    { label: this.cs.getLabel('settings.custom_fields.lbl_alphabets_allowed'), value: 'alphabetsAllowed' },
    { label: this.cs.getLabel('settings.custom_fields.lbl_numbers_allowed'), value: 'numbersAllowed' },
    { label: this.cs.getLabel('settings.custom_fields.lbl_special_characters_allowed'), value: 'specialCharactersAllowed' },
  ];
  showCustomLabel: boolean = false;
  isFormShow: boolean = false;
  fieldName: string;
  validationPattern: string;
  allowActions: string[] = [];
  inputTypeIdObj;
  contentArray: string[] = [];
  isShowCustomFieldList: boolean = false;
  isShowAddNewCustomField: boolean = false;
  isCustomFieldDetailedData: boolean = false;
  attributeCodes: string;
  selectedAttributeCodes: string;
  hoveredItem: string | null = null;
  rowItems: MenuItem[] | undefined;
  normalizedAttributeCode: string;
  customFieldDetailedData;
  deleteLabel: string = this.cs.getLabel('lbl_delete');
  editLabel: string = this.cs.getLabel('lbl_edit');
  deleteMsg: string = this.cs.getLabel('settings.custom_fields.lbl_delete_confirm_message');
  deleteHeaderMsg: string = this.cs.getLabel('settings.custom_fields.confirm_delete_header');
  attributeCodeData;
  setCurrentRow;
  entityCodeForCustomFieldDetails: string;
  tabCodeForCustomFieldDetails: string;
  sectionForCustomFieldDetails: string;
  allowActionsViewArray;
  exampleLabel;
  helpTipsLabel;
  patternForDetails;
  requiredYesNo;
  firstObject;
  matchedAttributeData;
  inputType;
  isChipsDisabled: boolean = false;
  isAllowActionAdvanceFilterDisabled: boolean = false;
  isAllowActionSearchDisabled: boolean = false;
  attributeId: string;
  updateForm: any;
  selectedTab;
  tabs;
  groupLabelsMap = new Map<string, string>();
  isEdit: boolean = false;
  showAsterisk: boolean = false;
  isChipsTouched: boolean = false;
  isRequiredFlag: boolean = false;
  isShowContentRequired: boolean = false;
  entityData = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT_FOR_CUSTOM_FIELDS,
    offset: 0,
    searchStr: '',
    defaultSortColumn: 'updatedAt',
    defaultSortType: 'desc'
  }
  displayedPatterns: string[] = [];
  patterns = {
    '^[a-zA-Z\\s]*$': ['alphabetsAllowed'],
    '^[0-9]+$': ['numbersAllowed'],
    '^[!@#$%^&*()_+\\-=\[\\]{}|;:\'",.<>?/~"\\]*$': ['specialCharactersAllowed'],
    '^[a-zA-Z0-9\\s]*$': ['alphabetsAllowed', 'numbersAllowed'],
    '^[a-zA-Z!@#$%^&*()_+\\-=\'";:,.?/\\\\ ]*$': ['alphabetsAllowed', 'specialCharactersAllowed'],
    '^[0-9!@#$%^&*()_+\\-=\'";:,.?/\\\\ ]*$': ['numbersAllowed', 'specialCharactersAllowed'],
    '^[a-zA-Z0-9!@#$%^&*()_+\\-=\[\]{}|;:",.<>?/~"\\\\ ]*$': ['alphabetsAllowed', 'numbersAllowed', 'specialCharactersAllowed'],
  };
  validationPatterns = {
    [AppSettings.CUSTOM_FIELD_VALIDATION_VALUES.NUMBERS]: this.cs.getLabel('lbl_numbers'),
    [AppSettings.CUSTOM_FIELD_VALIDATION_VALUES.ALPHABETS]: this.cs.getLabel('lbl_alphabets'),
    [AppSettings.CUSTOM_FIELD_VALIDATION_VALUES.SPECIAL_CHARACTERS]: this.cs.getLabel('lbl_special_characters'),
    [AppSettings.CUSTOM_FIELD_VALIDATION_VALUES.ALPHA_NUMERIC]: this.cs.getLabel('lbl_numbers_alphabets'),
    [AppSettings.CUSTOM_FIELD_VALIDATION_VALUES.ALPHA_SPECIAL]: this.cs.getLabel('lbl_alphabets_special_characters'),
    [AppSettings.CUSTOM_FIELD_VALIDATION_VALUES.NUMBER_SPECIAL]: this.cs.getLabel('lbl_number_special_characters'),
    [AppSettings.CUSTOM_FIELD_VALIDATION_VALUES.ALPHA_NUMBER_SPECIAL]: this.cs.getLabel('lbl_number_alphabet_special_characters')
  };

  constructor(private cs: CommonBindingDataService, private formBuilder: FormBuilder, private router: Router,
    private settingsService: SettingsService, private entityService: EntityService, private cdr: ChangeDetectorRef,
    private messageService: MessageService, private confirmationService: ConfirmationService, private configService: ConfigService) {
  }

  ngOnInit() {
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.formValidation();
    this.setRowItems();
    this.getModuleNames();
    this.getAttributeDataTypes();
    this.onOtherOptionChanges();
    this.subscribeToAllowActionChanges();
  }

  formValidation() {
    this.customFieldForm = this.formBuilder.group({
      moduleName: ['', [Validators.required]],
      tab: ['', [Validators.required]],
      sections: ['', [Validators.required]],
      dataType: ['', [Validators.required]],
      content: this.formBuilder.array([]),
      otherOption: [false],
      allowActionSearch: [false],
      allowActionAdvanceFilter: [false],
      allowActionManageColumn: [false],
      informationText: ['', [Validators.required]],
      note: ['', [Validators.required]],
      validation: [''],
      requiredField: [false],
    });
    this.syncCheckboxState();
  }

  public setRowItems() {
    this.rowItems = [
      {
        label: this.editLabel,
        icon: AppIcons.EDIT_2 + " wh-16",
        iconClass: 'bulk-update-icon',
        command: () => {
          this.editCustomField();
        }
      },
      {
        label: this.deleteLabel,
        icon: AppIcons.BASIC_DELETE + " wh-16",
        iconClass: "bulk-update-icon",
        command: () => {
          this.delete();
        },
      },
    ];
  }

  delete() {
    const attributeId = this.matchedAttributeData ? this.matchedAttributeData[0].attributeId : this.firstObject.attributeId;
    const attributeCode = this.matchedAttributeData ? this.matchedAttributeData[0].attributeCode : this.firstObject.attributeCode;
    this.confirmationService.confirm({
      header: this.deleteHeaderMsg,
      message: this.deleteMsg,
      rejectButtonStyleClass: 'bg-white text-color',
      acceptButtonStyleClass: 'bg-red-500',
      accept: () => {
        this.settingsService.deleteCustomFieldById(this.selectedModuleNameFilter, attributeId, attributeCode).subscribe((res: any) => {
          this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel(res.message) });
        })
        if(this.selectedModuleNameFilter) {
          const event = {
            value: this.selectedModuleNameFilter
          };
          this.getSelectedModuleNameFilter(event);
        }
        this.onCancel();
      },
      reject: () => {
      }
    });
  }

  editCustomField() {
    this.isCustomFieldDetailedData = false;
    this.isShowAddNewCustomField = false;
    this.isFormShow = true;
    this.attributeId = this.setCurrentRow.attributeId;
    this.fieldName = this.setCurrentRow.attributeCode; 
    this.customFieldForm.controls.dataType.disable();
    this.customFieldForm.controls.moduleName.disable();
    this.isChipsDisabled = !(this.setCurrentRow?.inputType?.inputName === 'Check Box' || this.setCurrentRow?.inputType?.inputName === 'Dropdown');
    this.customFieldForm.controls['otherOption'].enable(this.otherOptionDisable());
    this.isAllowActionAdvanceFilterDisabled = AppSettings.CUSTOM_FIELD_DATA_TYPES_ADVANCE_FILTER.includes(this.setCurrentRow?.inputType?.inputName);
    this.isAllowActionSearchDisabled = AppSettings.CUSTOM_FIELD_DATA_TYPES_SEARCH_FILTER.includes(this.setCurrentRow?.inputType?.inputName);
    const isOtherOptionDisable: boolean = AppSettings.CUSTOM_FIELD_CONTENT_OTHER_OPTION.includes(this.setCurrentRow?.inputType?.inputName);
    if(this.isAllowActionAdvanceFilterDisabled) {
      this.customFieldForm.controls.allowActionAdvanceFilter.disable();
      this.customFieldForm.get('allowActionAdvanceFilter')?.setValue(false);

    }
    if(this.isAllowActionSearchDisabled) {
      this.customFieldForm.controls.allowActionSearch.disable();
      this.customFieldForm.get('allowActionSearch')?.setValue(false);
    }
    if(isOtherOptionDisable){
      this.customFieldForm.controls.otherOption.disable();
    }
    this.getTabsAndSections(this.setCurrentRow?.entityCode);
  }

  setFormValuesInEdit() {
    const validations = JSON.parse(this.setCurrentRow?.validations || '{}');
    let selectedValidationValues = '';

    if (validations.pattern && this.patterns.hasOwnProperty(validations.pattern)) {
      selectedValidationValues = this.patterns[validations.pattern];
      this.validationPattern = validations.pattern;
    }
    const presetValues = this.setCurrentRow?.attributePresetValues || [];
    this.contentArray = presetValues.map(item => item.labelValue);
    const otherOption = presetValues.some(item => item.labelKey === 'Other');
    const selectedDataType = this.dataTypes?.find(item => item.inputName === this.setCurrentRow?.inputType?.inputName);
    const views = this.setCurrentRow?.views || [];
    const viewCodes = views.map(view => view.viewCode);
    const transformedTab = this.transformAttributeCode(this.setCurrentRow?.tabCode);
    const transformedGroup = this.transformAttributeCode(this.setCurrentRow?.groupCode);
    this.uniqueGroups = this.selectedTabGroups[transformedTab.toLowerCase()];
    this.customFieldForm.patchValue({
      moduleName: this.setCurrentRow?.entityCode,
      tab: this.setCurrentRow?.tabCode,
      sections: transformedGroup,
      dataType: selectedDataType,
      content: this.contentArray,
      otherOption: otherOption,
      allowActionSearch: viewCodes.includes(AppSettings.CUSTOM_FIELD_ALLOW_ACTION.DEFAULT_FILTER_VIEW),
      allowActionAdvanceFilter: viewCodes.includes(AppSettings.CUSTOM_FIELD_ALLOW_ACTION.ADVANCED_FILTER_VIEW),
      allowActionManageColumn: viewCodes.includes(AppSettings.CUSTOM_FIELD_ALLOW_ACTION.DEFAULT_TABLE_VIEW),
      informationText: this.setCurrentRow?.attributeLabels.find(item => item.labelKey === this.setCurrentRow.entityCode + ".fields." + this.setCurrentRow.attributeCode + ".helpTips")?.labelValue || '',
      note: this.setCurrentRow?.attributeLabels.find(item => item.labelKey === this.setCurrentRow.entityCode + ".fields." + this.setCurrentRow.attributeCode + ".example")?.labelValue || '',
      validation: selectedValidationValues,
      requiredField: validations?.required || false
    });
  }
  
  otherOptionDisable() {
    return this.setCurrentRow?.inputType?.inputName === 'Check Box' || this.setCurrentRow?.inputType?.inputName === 'Dropdown'
      ? { onlySelf: true, emitEvent: false }
      : undefined
  }

  updateCustomFields() {
    const transformedFieldName = this.transformAttributeCode(this.fieldName);
    const dataType = this.setCurrentRow?.inputType;
    const event = {
      value: {
        inputTypeCode: dataType.inputTypeCode,
        inputName: dataType.inputName,
        attributeInputTypeId: dataType.attributeInputTypeId
      }
    };
    this.onChangeDataType(event);
    const attributeCode = this.fieldName;
    const transformedAttributeCode = this.cs.transformString(attributeCode);
    const transformedTab = this.cs.transformString(this.customFieldForm.value.tab);
    const transformedSection = this.cs.transformString(this.customFieldForm.value.sections);
    const validations = {
      required: this.customFieldForm.value.requiredField,
      ...(this.validationPattern ? { pattern: this.validationPattern } : {})
    };
    const reqBody = {
      forTenantCode: this.configService.getForTenantCode(),
      inputTypeId: dataType,
      dataType: this.inputTypeIdObj,
      attributeCode: transformedAttributeCode,
      presetValue: {},
      validations: validations,
      encrypted: true,
      multiValue: this.inputType === 'checkBox' ? true : false,
      editable: true,
      customField: true,
      allowActions: this.allowActions,
      entityViewAttribute: {
        tabCode: transformedTab,
        groupCode: transformedSection
      },
      fieldName: transformedFieldName,
      informationText: this.customFieldForm.value.informationText,
      note: this.customFieldForm.value.note,
      content: this.customFieldForm.value.content,
      other: this.customFieldForm.value.otherOption
    }
    this.settingsService.updateCustomFields(reqBody, this.setCurrentRow?.entityCode, this.attributeId).subscribe((res: any) => {
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel(res.message) });
      const entityCodeNew = this.setCurrentRow?.entityCode;
      if(entityCodeNew) {
        const event = {
          value: entityCodeNew
        };
        this.getSelectedModuleNameFilter(event);
      }
      this.onCancel();
    },
    error => {
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel('settings.lbl_please_fill_the_form') });
    });
  }

  selectCustomList(item) {
    this.isShowAddNewCustomField = true;
    this.isFormShow = false;
    this.selectedAttributeCodes = item;
    if (item) {
      this.normalizedAttributeCode = this.cs.transformString(item);
      this.matchedAttributeData = this.attributeCodeData.filter(item => item.attributeCode.toLowerCase() === this.normalizedAttributeCode);
      this.getCustomFieldDetailsByAttributeId(this.selectedModuleNameFilter, this.matchedAttributeData);
    }
  }

  onMouseEnter(item: string): void {
    this.hoveredItem = item;
  }

  onMouseLeave(): void {
    this.hoveredItem = null;
  }

  onActionButtonClick(event: Event, menu: any) {
    event.stopPropagation();
    menu.toggle(event);
  }

  addCustomLabel() {
    this.fieldName = '';
    this.isEdit = false;
    this.showCustomLabel = true;
    this.isShowCustomFieldList = false
    this.isShowAddNewCustomField = false;
    this.isCustomFieldDetailedData = false;
  }

  onAddCustomLabel() {
    if (this.fieldName) {
      this.isFormShow = true;
      this.customFieldForm.controls['dataType'].enable();
      this.customFieldForm.controls['moduleName'].enable();
      this.customFieldForm.reset();
    }
    this.resetContentForNewRecord();
  }

  onCancelCustomLabel() {
    this.fieldName = '';
    this.showCustomLabel = false;
    this.isFormShow = false;
    this.isShowCustomFieldList = true;
    this.isShowAddNewCustomField = true;
    if(this.selectedModuleNameFilter) {
      const event = {
        value: this.selectedModuleNameFilter
      };
      this.getSelectedModuleNameFilter(event);
    }
  }

  get content(): FormArray {
    return this.customFieldForm.get('content') as FormArray;
  }

  onOtherOptionChange(): void {
    const otherOptionChecked = this.customFieldForm.get('otherOption')?.value;
    if (otherOptionChecked) {
      if (!this.contentArray.includes('Other')) {
        this.contentArray.push('Other');
        this.updateContentArray();
      }
    } else {
      const index = this.contentArray.indexOf('Other');
      if (index >= 0) {
        this.contentArray.splice(index, 1);
        this.updateContentArray();
      }
    }
  }

  onChipsFocus() {
    this.isChipsTouched = true;
  }

  onChipsBlur() {
    const contentControl = this.customFieldForm.controls['content'];
    if (this.isChipsTouched && this.contentArray?.length === 0 && this.customFieldForm.get('dataType')?.value !== 'Check Box' && this.customFieldForm.get('dataType')?.value !== 'Dropdown') {
      contentControl.markAsTouched();
      this.isShowContentRequired = true;
    }
    else {
      this.isShowContentRequired = false;
    }
  }

  resetContentForNewRecord() {
    this.contentArray = [];
    const contentControl = this.customFieldForm.get('content');
    if (contentControl) {
      contentControl.setValue([]);
      contentControl.updateValueAndValidity();
    }
    this.customFieldForm.controls['otherOption'].setValue(false);
    this.updateContentArray();
  }

  updateContentArray(): void {
    const formArray = this.content;
    formArray.clear();
    this.contentArray.forEach(value => {
      formArray.push(this.formBuilder.control(value));
    });
    this.syncCheckboxState();
    this.cdr.detectChanges();
  }

  onOtherOptionChanges(): void {
    this.customFieldForm.get('content')?.valueChanges.subscribe(() => {
      this.syncCheckboxState();
    });
  }

  syncCheckboxState() {
    const otherOptionChecked = this.contentArray.includes('Other');
    this.customFieldForm.get('otherOption')?.setValue(otherOptionChecked, { emitEvent: false });
  }

  subscribeToAllowActionChanges() {
    this.customFieldForm.get('allowActionSearch')?.valueChanges.subscribe((value: boolean) => {
      this.updateAllowActions(AppSettings.CUSTOM_FIELD_ALLOW_ACTION.DEFAULT_FILTER_VIEW, value);
    });

    this.customFieldForm.get('allowActionAdvanceFilter')?.valueChanges.subscribe((value: boolean) => {
      this.updateAllowActions(AppSettings.CUSTOM_FIELD_ALLOW_ACTION.ADVANCED_FILTER_VIEW, value);
    });

    this.customFieldForm.get('allowActionManageColumn')?.valueChanges.subscribe((value: boolean) => {
      this.updateAllowActions(AppSettings.CUSTOM_FIELD_ALLOW_ACTION.DEFAULT_TABLE_VIEW, value);
    });
  }

  updateAllowActions(action: string, isChecked: boolean) {
    if (isChecked) {
      if (!this.allowActions.includes(action)) {
        this.allowActions.push(action);
      }
    } else {
      const index = this.allowActions.indexOf(action);
      if (index > -1) {
        this.allowActions.splice(index, 1);
      }
    }
  }

  onChangeValidation(event) {
    const selectedValues = event.value;
    this.validationPattern = '';

    if (selectedValues.includes(AppSettings.CUSTOM_FIELD_VALIDATION[0].value) && selectedValues.includes(AppSettings.CUSTOM_FIELD_VALIDATION[1].value) && selectedValues.includes(AppSettings.CUSTOM_FIELD_VALIDATION[2].value)) {
      this.validationPattern = AppSettings.CUSTOM_FIELD_VALIDATION_VALUES.ALPHA_NUMBER_SPECIAL;
    } else if (selectedValues.includes(AppSettings.CUSTOM_FIELD_VALIDATION[0].value) && selectedValues.includes(AppSettings.CUSTOM_FIELD_VALIDATION[1].value)) {
      this.validationPattern = AppSettings.CUSTOM_FIELD_VALIDATION_VALUES.ALPHA_NUMERIC;
    } else if (selectedValues.includes(AppSettings.CUSTOM_FIELD_VALIDATION[0].value) && selectedValues.includes(AppSettings.CUSTOM_FIELD_VALIDATION[2].value)) {
      this.validationPattern = AppSettings.CUSTOM_FIELD_VALIDATION_VALUES.ALPHA_SPECIAL;
    } else if (selectedValues.includes(AppSettings.CUSTOM_FIELD_VALIDATION[2].value) && selectedValues.includes(AppSettings.CUSTOM_FIELD_VALIDATION[1].value)) {
      this.validationPattern = AppSettings.CUSTOM_FIELD_VALIDATION_VALUES.NUMBER_SPECIAL;
    } else if (selectedValues.includes(AppSettings.CUSTOM_FIELD_VALIDATION[0].value)) {
      this.validationPattern = AppSettings.CUSTOM_FIELD_VALIDATION_VALUES.ALPHABETS;
    } else if (selectedValues.includes(AppSettings.CUSTOM_FIELD_VALIDATION[1].value)) {
      this.validationPattern = AppSettings.CUSTOM_FIELD_VALIDATION_VALUES.NUMBERS;
    } else if (selectedValues.includes(AppSettings.CUSTOM_FIELD_VALIDATION[2].value)) {
      this.validationPattern = AppSettings.CUSTOM_FIELD_VALIDATION_VALUES.SPECIAL_CHARACTERS;
    }
  }

  getModuleNames() {
    const requestBody = {
      limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT_FOR_CUSTOM_FIELDS,
      offset: 0
    };
    this.entityService.getEntityConfiguration(requestBody, this.configService.getForTenantCode()).subscribe({
      next: (res: any) => {
        this.moduleNameList = [];
        res.data.forEach(element => {
          const module = this.moduleList.find(module => module.key === element.entityCode);
          if (module) {
            module.value = element.entityConfigId;
            module.showInAccess = element.showInAccess
            this.moduleNameList.push({
              label: module.label,
              key: module.key
            });
          }
        });
        this.moduleNameList = [...this.moduleNameList];
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
      }
    })
  }

  getSelectedModuleNameFilter(event) {
    this.selectedModuleNameFilter = event.value;
    if(!this.selectedModuleNameFilter) {
      return;
    }
    this.settingsService.getCustomFieldListByEntityCode(this.selectedModuleNameFilter, this.entityData).subscribe((res: any) => {
      if (res.count > 0) {
        this.isShowCustomFieldList = true;
        this.isShowAddNewCustomField = true;
      } else {
        this.isShowCustomFieldList = false;
        this.isShowAddNewCustomField = false;
      }
      this.attributeCodeData = res.data.map(item => item);
      const transformedAttributeCodes = res.data.map(item => this.transformAttributeCode(item.attributeCode));
      this.attributeCodes = transformedAttributeCodes;
      this.selectedAttributeCodes = this.attributeCodes?.[0];
      this.firstObject = this.attributeCodeData[0];
      if (res.data.length > 0) {
        this.getCustomFieldDetailsByAttributeId(this.selectedModuleNameFilter, res.data);
      } else {
        this.isCustomFieldDetailedData = false;
        this.isFormShow = false;
      }
    })
  }

  getCustomFieldDetailsByAttributeId(selectedModuleNameFilter, data) {
    this.settingsService.getCustomFieldDetailsByAttributeId(selectedModuleNameFilter, data[0].attributeId, data[0].attributeCode).subscribe((res: any) => {
      if (res) {
        this.isFormShow = false;
        this.isEdit = true;
        this.setViewLabel(res.views);
        this.setCurrentRow = res;
        const attributeCodes = data.map(item => item.attributeCode);
        this.customFieldDetailedData = res;
        this.entityCodeForCustomFieldDetails = res.entityCode;
        this.tabCodeForCustomFieldDetails = res.tabCode;
        this.sectionForCustomFieldDetails = res.groupCode;
        this.isCustomFieldDetailedData = true;
        this.entityCodeForCustomFieldDetails = this.transformAttributeCode(this.entityCodeForCustomFieldDetails);
        this.tabCodeForCustomFieldDetails = this.transformAttributeCode(this.tabCodeForCustomFieldDetails);
        this.sectionForCustomFieldDetails = this.transformAttributeCode(this.sectionForCustomFieldDetails);
        this.exampleLabel = res.attributeLabels.find(item => item.labelKey === res.entityCode + ".fields." + attributeCodes[0] + ".example")?.labelValue || '';
        this.helpTipsLabel = res.attributeLabels.find(item => item.labelKey === res.entityCode + ".fields." + attributeCodes[0] + ".helpTips")?.labelValue || '';
        const validationObj = JSON.parse(res.validations);
        this.patternForDetails = validationObj.pattern || '';
        this.setDisplayedPatterns(this.patternForDetails);
        this.requiredYesNo = validationObj.required ? this.cs.getLabel('lbl_yes') : this.cs.getLabel('lbl_no');
        this.cdr.detectChanges();
      }
    })
  }

  setDisplayedPatterns(patternForDetails) {
    this.displayedPatterns = [];
    for (const [pattern, label] of Object.entries(this.validationPatterns)) {
      if (patternForDetails === pattern) {
        this.displayedPatterns.push(label);
        break;
      }
    }
  }

  setViewLabel(views) {
    const labels: string[] = [];
    if (views.some(view => view.viewCode === AppSettings.CUSTOM_FIELD_ALLOW_ACTION.DEFAULT_FILTER_VIEW)) {
      labels.push(this.cs.getLabel('settings.custom_fields.lbl_search'));
    }
    if (views.some(view => view.viewCode === AppSettings.CUSTOM_FIELD_ALLOW_ACTION.ADVANCED_FILTER_VIEW)) {
      labels.push(this.cs.getLabel('settings.custom_fields.lbl_advance_filters'));
    }
    if (views.some(view => view.viewCode === AppSettings.CUSTOM_FIELD_ALLOW_ACTION.DEFAULT_TABLE_VIEW)) {
      labels.push(this.cs.getLabel('settings.custom_fields.lbl_manage_columns'));
    }
    this.allowActionsViewArray = labels.join(' | ');
  }

  transformAttributeCode(attributeCode: string): string {
    const replaced = attributeCode.replace(/_/g, ' ');
    const capitalized = replaced.replace(/\b\w/g, char => char.toUpperCase());
    return capitalized;
  }

  getSelectedModuleName(event) {
    this.uniqueTabs = [];
    this.uniqueGroups = [];
    this.selectedModuleName = event.value;
    this.getTabsAndSections(this.selectedModuleName);
  }

  getTabsAndSections(selectedModuleName) {
    const CUSTOM_FIELD_EXCLUDE_TABS = ['documents', 'features'];
    const excludedSections = ["driving_record", "stay_home_address", "driver_stay_home_address", "home_stay_location_restrictions"];
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.entityService.getAttributeDefinition(selectedModuleName, AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe(res => {
      this.uniqueTabs = [];
      this.uniqueGroups = [];
      this.tabs = res.tabs;
      const attributeLabels = this.cs.getAttributeLabels(res);
      const filteredTabs = this.tabs.filter(tab => !CUSTOM_FIELD_EXCLUDE_TABS.includes(tab.tabCode));
      for (const tab of filteredTabs) {
        this.uniqueTabs.push({
          labelKey:this.cs.getLabelValue(selectedModuleName+'.tabs.'+tab.tabCode+'.label',attributeLabels, tab.tabCode),
          labelValue:tab.tabCode
        });
        const tempArray= [];
        for (const group of tab.groups) {
          if (!excludedSections.includes(group.code)) {
            tempArray.push(this.cs.getLabelValue(selectedModuleName + '.groups.' + group.code + '.label', attributeLabels, group.code));
          }
        }
        this.selectedTabGroups = {
          ...this.selectedTabGroups,
          [tab.tabCode]:tempArray
        };
      }
      if(this.isEdit) {
        this.setFormValuesInEdit();
      }
      this.cdr.detectChanges();
    });
  }

  onChangeTab(event: any) {
    const selectedTabCode = event.value;
    this.uniqueGroups = this.selectedTabGroups[selectedTabCode.toLowerCase()];
    const selectedTab = this.tabs.find(tab => tab.tabCode === selectedTabCode.toLowerCase());
    if (selectedTab && selectedTab.tabIndex === 0) {
      this.customFieldForm.controls.requiredField.enable();
      this.isRequiredFlag = false;
    } else {
      this.customFieldForm.controls.requiredField.setValue(false);
      this.customFieldForm.controls.requiredField.disable();
      this.isRequiredFlag = true;
    }
  }

  getAttributeDataTypes(): void {
    this.settingsService.getAttributeDataTypes().subscribe(res => {
      const data = res as AttributeDataType[];
      this.dataTypes = data
        .filter(item => item.useInCustomField)
        .map(item => ({
          inputName: item.inputName,
          attributeInputTypeId: item.attributeInputTypeId,
          inputTypeCode: item.inputTypeCode,
        }));
    });
  }

  onCancel() {
    this.fieldName = '';
    this.customFieldForm.reset();
    this.isShowCustomFieldList = true;
    this.isShowAddNewCustomField = true;
    this.isCustomFieldDetailedData = false;
    this.isFormShow = false;
    this.isChipsDisabled = false;
    if(this.isEdit) {
      const entityCodeNew = this.setCurrentRow?.entityCode;
      if(entityCodeNew) {
        const event = {
          value: entityCodeNew
        };
        this.getSelectedModuleNameFilter(event);
      }
      this.isEdit = false;
    }
    this.router.navigate(['app/settings/custom-fields']);
  }

  onChangeDataType(event: any): void {
    this.inputType = event.value.inputTypeCode;
    if (AppSettings.CUSTOM_FIELD_INPUT_TYPE_MAPPING[this.inputType]) {
      this.inputTypeIdObj = AppSettings.CUSTOM_FIELD_INPUT_TYPE_MAPPING[this.inputType];
    } else {
      this.inputTypeIdObj = '';
    }
    this.isChipsDisabled = !(event.value.inputName === 'Check Box' || event.value.inputName === 'Dropdown');
    const dataTypeValue = event.value.inputName;
    this.isAllowActionAdvanceFilterDisabled = AppSettings.CUSTOM_FIELD_DATA_TYPES_ADVANCE_FILTER.includes(dataTypeValue);
    this.isAllowActionSearchDisabled = AppSettings.CUSTOM_FIELD_DATA_TYPES_SEARCH_FILTER.includes(dataTypeValue);
    if (this.isAllowActionSearchDisabled) {
      this.customFieldForm.get('allowActionSearch')?.setValue(false);
    }
    if (this.isAllowActionAdvanceFilterDisabled) {
      this.customFieldForm.get('allowActionAdvanceFilter')?.setValue(false);
    }
    this.validationList = this.validationList.map(option => ({
      ...option,
      disabled: this.disableValidation(dataTypeValue, option)
    }));
    if (dataTypeValue === 'Check Box' || dataTypeValue === 'Dropdown') {
      this.addContentRequiredValidation();
      this.showAsterisk = true;
    } else {
      this.removeContentRequiredValidation();
      this.showAsterisk = false;
    }
  }

  disableValidation(dataTypeValue, option) {
    return (dataTypeValue === 'Integer' && (option.value === 'alphabetsAllowed' || option.value === 'specialCharactersAllowed')) ? true : false
  }

  addContentRequiredValidation() {
    const contentControl = this.customFieldForm.get('content');
    if (contentControl && !contentControl.hasValidator(Validators.required)) {
      contentControl.setValidators(Validators.required);
    }
    contentControl?.updateValueAndValidity();
  }
  
  removeContentRequiredValidation() {
    const contentControl = this.customFieldForm.get('content');
    if (contentControl && contentControl.hasValidator(Validators.required)) {
      contentControl.setValidators(null);
    }
    contentControl?.updateValueAndValidity();
    this.contentArray = [];
    this.customFieldForm.controls['otherOption'].setValue(false);
    this.isShowContentRequired = false;
    this.updateContentArray();
  }

  onAddCustomFields() {
    const attributeCode = this.fieldName;
    const transformedAttributeCode = this.cs.transformString(attributeCode);
    const transformedTab = this.cs.transformString(this.customFieldForm.value.tab);
    const transformedSection = this.cs.transformString(this.customFieldForm.value.sections);
    if (this.customFieldForm.valid) {
      const validations = {
        required: this.customFieldForm.value.requiredField,
        ...(this.validationPattern ? { pattern: this.validationPattern } : {})
      };
      const reqBody = {
        forTenantCode: this.configService.getForTenantCode(),
        inputTypeId: this.customFieldForm.value.dataType,
        dataType: this.inputTypeIdObj,
        attributeCode: transformedAttributeCode,
        presetValue: {},
        validations: validations,
        encrypted: true,
        multiValue: this.inputType === 'checkBox' ? true : false,
        editable: true,
        customField: true,
        allowActions: this.allowActions,
        entityViewAttribute: {
          tabCode: transformedTab,
          groupCode: transformedSection
        },
        fieldName: this.fieldName,
        informationText: this.customFieldForm.value.informationText,
        note: this.customFieldForm.value.note,
        content: this.customFieldForm.value.content,
        other: this.customFieldForm.value.otherOption
      }
      this.saveCustomFields(reqBody, this.customFieldForm.value.moduleName);
    } else {
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel('settings.lbl_please_fill_the_form') });
    }
  }

  saveCustomFields(reqBody, entityCode) {
    this.settingsService.saveCustomFields(reqBody, entityCode).subscribe((res: any) => {
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel(res.message) });
      const entityCodeNew = entityCode;
      if(entityCodeNew) {
        const event = {
          value: entityCodeNew
        };
        this.getSelectedModuleNameFilter(event);
      }
      this.onCancel();
    })
  }

}
