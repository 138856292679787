import { Component, Input } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TrimSpacesDirective } from 'app/modules/shared/directives/trim-spaces.directive';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { InputTextModule } from 'primeng/inputtext';
import { KeyFilterModule } from 'primeng/keyfilter';

@Component({
  selector: 'app-mi-seed-number',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, InputTextModule, KeyFilterModule, TrimSpacesDirective],
  templateUrl: './mi-seed-number.component.html',
  styleUrl: './mi-seed-number.component.scss'
})
export class MiSeedNumberComponent {
  @Input() field: any;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  @Input() pKeyFilter: string = null;
  formName: FormGroup;
  placeholder: any;

  constructor(private formGroupDirective: FormGroupDirective,
    public cs: CommonBindingDataService) {
    this.formName = this.formGroupDirective.control;
  }

  ngOnInit() {
    this.cs.seededPlayerSelected.subscribe((result: boolean) => {
      this.field.validation.required = result;
    })
    this.placeholder = this.cs.getLabel('passengers.enter_seed_number');
  }
}
