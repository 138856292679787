
<!-- calendar code start -->
<div class="flex">
    <div class="driver-list-column pr-0">
        @for(group of groupList; track group; let j=$index) {
            @if (j === 0) {
                <div class="first-column first-row first-cell pl-3 pt-2 pb-2 flex align-items-center">
                    <div class="inline-flex">
                        <p-checkbox (onChange)="onSelectAllChange($event)" binary="true" class="first-column-header"></p-checkbox>
                        <p-dropdown [options]="listType" [(ngModel)]="selectedListType" (onChange)="onListTypeChange($event)" optionLabel="name" optionValue="value">
                            <ng-template  pTemplate="dropdownicon">
                                <i class="mi-lg caret-down-2"></i>
                            </ng-template>
                        </p-dropdown>
                        <div class="filter-az">
                            <span translate>shift.az</span>
                            <i class="mi-lg mi-filter_2"></i>
                        </div>
                    </div>
                </div>
            }

            <div class="col-12 flex td">
                <div class="column-cells pl-3 flex align-items-center">
                    <div class="inline-flex">
                        <div class="inline-flex">
                            <p-checkbox [id]="j" [value]="group.isSelected"  binary="true" name="group" ></p-checkbox>
                            <img class="driver-img ml-2" src="../../../../../assets/images/mi-theme/light/basic/Rectangle.png">
                        </div>
                       <div class="block w-13rem ml-2 flex align-items-center">
                            <div class="flex justify-content-between flex-wrap">
                                <div class="flex align-items-center justify-content-center ">
                                    <span class=" ellipse"  (mouseover)="op.toggle($event); hoveredVehicle = group" (mouseout)="op.toggle($event)"  >{{group.driverName}}</span>
                                </div>
                            </div>
                       </div>
                    </div>

                </div>
            </div>
        }
    </div>

    <div class=" toolbar-scroll pl-0">
        <!-- day row -->
        <div >
            <ul>
                @for (day of weekDaysList; track day; let i = $index) {
                    <li class="first-row">
                        <div class="day inline-block pt-2 pb-2">
                            <div class="flex align-items-center justify-content-center flex-wrap">
                                <div class="day-name mr-2"> {{ day.dayName}} </div>
                                <div class="day-number ml2"> {{ day.day}} </div>
                            </div>

                        </div>
                    </li>
                }

            </ul>
        </div>

        <!-- shift cells-->
        @for(group of groupList; track group; let m=$index) {
                <ul>
                    @for (day of weekDaysList; track day; let n = $index) {
                        <li>
                                <div class="shift-cells flex align-items-center flex-column">
                                    @for (shift of group.shift; track shift; let k = $index) {
                                        @if (shift.startDate === day.date) {
                                            @if (shift.isLeave) {
                                                <div class="block shift-block">
                                                    <div class="day-off ml-2 align-content-center">
                                                        <div class="ml-2 uppercase" translate>shift.dayOff </div>
                                                        <div class="ml-2 font-semibold"> {{shift.leaveReason}}</div>
                                                    </div>
                                                </div>
                                            } @else if (shift.isShiftPublish) {
                                                <div class="block shift-block">
                                                    @if (shift.endTime) {
                                                        <div class="shift-time ml-2 align-content-center pointer" [style.width]="shift.dateDifference > 1 ? shift.dateDifference * 200 + 'px' : '84%'" (click)="shiftOverlay.toggle($event); hoveredPublishedUnPublishedShift = shift; publishedUnpublishedShiftGroup = group">
                                                            <div class="ml-2 uppercase"> {{ shift.shiftName }}</div>
                                                            <div class="ml-2 font-semibold"> {{shift.startTime  | date: 'h:mma'}} -  {{shift.endTime  | date: 'h:mma'}}</div>
                                                        </div>
                                                    } @else {
                                                        <div class="shift-time ml-2 align-content-center pointer" (click)="shiftOverlay.toggle($event); hoveredPublishedUnPublishedShift = shift; publishedUnpublishedShiftGroup = group" [class]="shift.dateDifference > 1 ? 'line-img' : ''" [style.width]="shift.dateDifference > 1 ? shift.dateDifference * 200 + 'px' : '84%'" >
                                                            <div class="ml-2 uppercase"> {{ shift.shiftName }}</div>
                                                            <div class="ml-2 font-semibold"> <span>{{shift.startDate  | date: 'd MMM'}} -  {{shift.endDate  | date: 'd MMM'}}</span> <span class="dot ml-2"></span> <span class="ml-2">{{shift.startTime  | date: 'h:mma'}} -  <span translate>shift.noEndTime</span></span> </div>
                                                        </div>
                                                    }
                                                </div>
                                            } @else {
                                                <div class="block shift-block" (click)="shiftOverlay.toggle($event); hoveredPublishedUnPublishedShift = shift; publishedUnpublishedShiftGroup = group">
                                                    <div class="unpublish-shift ml-2 flex align-items-center pointer">
                                                        <div class="ml-2 uppercase"> {{ shift.shiftName }}</div>
                                                        <div class="ml-2 font-semibold"> {{shift.startTime  | date: 'h:mma'}} -  {{shift.endTime  | date: 'h:mma'}}</div>
                                                    </div>
                                                </div>
                                            }
                                        }
                                    }

                                </div>
                        </li>
                    }
                </ul>
        }
    </div>
</div>
<!-- calendar code end -->


<!-- Vehicle overlay panels -->
<p-overlayPanel #op>
    <ng-template pTemplate="content">
        <div class="grid">
            <div class="col-2">
                <img class="driver-overlay-img" src="../../../../../assets/images/mi-theme/light/basic/Rectangle.png">
            </div>
            <div class="col-10 pl-0 flex align-items-center">
                <div class="flex">
                    <label class="driver-overlay-name mr-2">{{hoveredVehicle.driverName}}</label> <label class="mr-2">(Vehicle)</label>
                    <span class="group">
                        <i class="mi-lg mi-driver"></i>
                        <span class="ml-1">
                            {{hoveredVehicle.driverGroup}}
                        </span>
                    </span>
                </div>
            </div>
        </div>
        <div class="grid">
            <div class="col-5 label-text" translate>shift.status</div>
            <div class="col-7 label-value capitalize"><span [class]="hoveredVehicle.status"></span> {{hoveredVehicle.status}}</div>
        </div>
        <hr class="hr">
        <div class="grid">
            <div class="col-5 label-text" translate>shift.lbl_shift_name</div>
            <div class="col-7 label-value">Gold AM</div>
        </div>
        <hr class="hr">
        <div class="grid">
            <div class="col-5 label-text" translate>shift.lbl_type</div>
            <div class="col-7 label-value">Car</div>
        </div>
        <hr class="hr">
        <div class="grid">
            <div class="col-5 label-text" translate>shift.lbl_year_make_model</div>
            <div class="col-7 label-value">2018 Toyoto prius</div>
        </div>
        <hr class="hr">
        <div class="grid">
            <div class="col-5 label-text" translate>shift.group</div>
            <div class="col-7">
                <label class="label-value">Management</label><br>
                <label>USA/Midwest region/chicago</label>
            </div>
        </div>
        <hr class="hr">
        <div class="grid">
            <div class="col-5 label-text" translate>shift.vin_sin</div>
            <div class="col-7 label-value">2018 Toyoto prius</div>
        </div>
        <hr class="hr">
    </ng-template>
</p-overlayPanel>


<!-- unpublish, publish shift overlay panel -->
<p-overlayPanel #shiftOverlay>
    <ng-template pTemplate="content">
        <div class="grid">
            <div class="col-2">
                <img class="driver-overlay-img" src="../../../../../assets/images/mi-theme/light/basic/Rectangle.png">
            </div>
            <div class="col-10 pl-0 flex align-items-center">
                <div class="flex">
                    <label class="driver-overlay-name mr-2">{{publishedUnpublishedShiftGroup.driverName}}</label> <label class="mr-2">(Vehicle)</label>
                    <span class="group">
                        <i class="mi-lg mi-scheduledbreak"></i>
                        <span class="ml-1"> {{publishedUnpublishedShiftGroup.driverGroup}}</span>
                    </span>
                </div>
            </div>

        </div>
        @if (hoveredPublishedUnPublishedShift.endTime) {
            <div class="grid">
                <div class="col-6 label-text">
                    <i class="mi-lg calendar_calendar"></i>
                    <span class="ml-1">  {{hoveredPublishedUnPublishedShift.startDate  | date: 'dd MMM'}} </span>
                </div>
                <div class="col-6 label-value">
                    <i class="mi-lg mi-endofshift"></i>
                    <span class="ml-1">  {{hoveredPublishedUnPublishedShift.startTime  | date: 'h:mma'}} -  {{hoveredPublishedUnPublishedShift.endTime  | date: 'h:mma'}}</span>

                </div>
            </div>
        } @else {
            <div class="grid">
                <div class="col-6 label-text">
                    <i class="mi-lg calendar_calendar"></i>
                    <span class="ml-1">  {{hoveredPublishedUnPublishedShift.startDate  | date: 'd MMM'}} -  {{hoveredPublishedUnPublishedShift.endDate  | date: 'd MMM'}} </span>
                </div>
                <div class="col-6 label-value">
                    <i class="mi-lg mi-endofshift"></i>
                    <span class="ml-1">  {{hoveredPublishedUnPublishedShift.startTime  | date: 'h:mma'}} -  <span translate>shift.noEndTime</span></span>
                </div>
            </div>
        }

        <hr class="hr">
        <div class="grid">
            <div class="col-6" translate>
                <i class="mi-lg mi-breaks"></i>
                <label class="label-text ml-2" translate>shift.breaks</label>
            </div>
            <div class="col-6 label-value">
                <div>
                    <i class="mi-lg mi-endofshift"></i>
                    <span class="ml-1">  {{hoveredPublishedUnPublishedShift.startTime  | date: 'h:mma'}} -  {{hoveredPublishedUnPublishedShift.endTime  | date: 'h:mma'}}</span>
                </div>
                <div>
                    <i class="mi-lg mi-endofshift"></i>
                    <span class="ml-1">  {{hoveredPublishedUnPublishedShift.startTime  | date: 'h:mma'}} -  {{hoveredPublishedUnPublishedShift.endTime  | date: 'h:mma'}}</span>
                </div>
            </div>
        </div>
    </ng-template>
</p-overlayPanel>
