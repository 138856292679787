<div class="flex filter-btn flex-column dispatch" [ngClass]="{ 'bg-detail': hideSearchFilters }">
  <div class="flex flex-row align-items-center justify-content-between " [ngClass]="{'pb-0': hideSearchFilters, 'pb-3': !hideSearchFilters}">
    <div class="font-18 font-semibold default-text-black-color" [ngClass]="{'pt-3': hideSearchFilters, 'pl-3': hideSearchFilters}">{{headerLabel}}</div>
    @if(!hideSearchFilters){
      <div class="font-14 font-normal default-print-label-color">{{lastSyncTime}} <i class="mi-lg mi-refresh wh-16 inline-block vertical-align-text-bottom cursor-pointer" (click)="refreshTime()"></i>
      </div>
    }
  </div>
  @if(!hideSearchFilters){<div class="flex flex-row justify-content-between">
    <div class="header-dialog"  >
      <span class="p-input-icon-left">
        <i class="icon-top mi-lg search cursor-pointer"
         [ngClass]="{ 'pi-search': !searchValue, 'pi-times': searchValue }"
         (click)="clearSearch()"></i>
        <input class="input-width"  type="text" pInputText [placeholder]="searchPlaceholder"
         [(ngModel)]="searchValue" (keyup)="onSearch($event)"/>
      </span>
    </div> 
    <p-button  [styleClass]="selectedFilterCount ? 'with-filter' : 'no-filter'"  icon="mi-lg mi-filter_2 inline-block wh-24 vertical-align-text-bottom" styleClass=" bg-f5f5f5 border-none" style="width:10%"
    (click)="showFilterDialog()" />
    @if (selectedFilterCount) {
      <p-badge [value]="selectedFilterCount" severity="success" />
    }
    @if(filterList) {
      <app-filter-box [filterList]="filterList" [show]="show" [position]="position" [moduleName]="moduleName"
          [selectedFilterCount]="selectedFilterCount" [filterAttributeLabels]="filterAttributeLabels" class="filter-box-div"
         (onValueChange)="onFilterValueChange(true,$event)" (onToggleOverLay)="show = false" isFilterBox="true"></app-filter-box>
    }
  </div>}
  @if(hideSearchFilters){
    <app-driver-list-card [forDetail]="forDetail" [bookingId]="bookingId" [driverDetail]="driverDetail.driver" (closeBtnClicked)="onCloseDriverDetailView($event)" [fromDialogHeader]="true" class="bg-detail"></app-driver-list-card>
  }
</div>
