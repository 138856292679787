import { Component, OnInit } from "@angular/core";
import { DashboardNavbarComponent } from "../dashboard-navbar/dashboard-navbar.component";
import { TranslateModule } from "@ngx-translate/core";
import { FilterSectionComponent } from "./filter-section/filter-section.component";
import { AccordionModule } from "primeng/accordion";
import { NgClass } from "@angular/common";
import data from "../../../../../../src/assets/json/dashboard/data.json";
import { AppSettings } from "app/modules/shared/app.settings";
import { ListResponse, Values } from "app/modules/vehicles/models/listResponse.models";
import { EntityService } from "app/modules/shared/services/entity.service";
import { EntityList } from "app/modules/vehicles/models/entity.models";
import { Country } from "app/modules/shared/models/country";
import { ConfigService } from "app/modules/shared/services/config.service";
import { AccessProviderDirective } from "app/modules/shared/directives/access-provider.directive";
import { CommonBindingDataService } from "app/modules/shared/services/common-binding-data.service";
import { forkJoin } from "rxjs";
import * as _ from "lodash";
import { Router } from "@angular/router";
import { BookingService } from "app/modules/bookings/services/booking.service";
import { BookingStatus } from "app/modules/bookings/models/booking.models";

@Component({
  selector: "app-activity-feed",
  templateUrl: "./activity-feed.component.html",
  styleUrls: ["./activity-feed.component.scss"],
  standalone: true,
  imports: [
    DashboardNavbarComponent,
    TranslateModule,
    FilterSectionComponent,
    AccordionModule,
    NgClass,
    AccessProviderDirective
  ],
})
export class ActivityFeedComponent implements OnInit {
  nodes!: any[];
  listColumns: Values;
  pagination: any;
  totalRecords: number = 0;
  tableData: any = [];
  accordionTabs: any[] = [];
  userIds: any;
  usersData: any;
  country: Country;
  BOOKING_STATUS_LABEL = AppSettings.BOOKING.STATUS_BY_ID;
  mappedResponse: any[] = [];
  filterList;
  filterAttributeLabels = {};
  isLoading = true;
  data: any;
  attributeLabels = {};
  filterAvailableColumns = [];
  allColumns: any;
  bookingStatus: BookingStatus[];
  attributeIdForTimeRange;
  entityData: EntityList = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted: AppSettings.DELETED_TYPE.BOTH,
    forTenantCode: this.configService.getForTenantCode(),
    actionStatus: '',
    advanceFilters: [],
  };

  constructor(private dashboardService: EntityService, private configService: ConfigService, 
    private cs: CommonBindingDataService, private router: Router,  private bookingService: BookingService,) { }

  ngOnInit() {
    this.nodes = data.nodes;
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.searchEntity();
    this.getData();
  }

  goToBookingDetails(event) {
    this.router.navigate(['app/bookings/' + event]);
  }

  searchEntity() {
    this.accordionTabs = [];
    const uniqueUserIds = new Set();
    this.entityData.countryCode = this.country[0].countryCode;
    this.dashboardService.searchEntity(AppSettings.ENTITY_CODE.BOOKING_ACTIVITY, this.entityData).subscribe((res: ListResponse) => {
      this.mapBookingStatuses(res);
      res.data.forEach((activity: any) => {
        uniqueUserIds.add(activity.values.updated_by);
      });
      this.userIds = Array.from(uniqueUserIds);
      if(this.userIds.length > 0) {
        this.getUserDetailsById(this.userIds);
      }
    });
  }

  getUserDetailsById(userIds) {
    this.dashboardService.getUserDetailsById(userIds).subscribe(res => {
      this.usersData = res;
      this.accordionTabs = this.mapBookingActivitiesToUsers(this.usersData, this.mappedResponse);
    });
  }

  mapBookingStatuses(apiResponse: any): void {
    this.mappedResponse = apiResponse.data.map(activity => {
      const bookingStatus = activity.values.booking_status;
      return {
        ...activity,
        values: {
          ...activity.values,
          booking_status_label: this.BOOKING_STATUS_LABEL[bookingStatus] || 'Unknown Status'
        }
      };
    });
  }

  mapBookingActivitiesToUsers(users, bookingActivities): any[] {
    return bookingActivities.map(activity => {
      const userId = activity.values.updated_by;
      const user = users.find(u => u.userId === userId);

      if (user) {
        const fullName = `${user.firstName} ${user.lastName}`;
        const roleName = user.rolelist[0]?.roleName;
        return {
          key: activity.id,
          label: activity.values.booking_status_label,
          labelClass: this.cs.getLabelClass(activity.values.booking_status),
          icon: this.cs.getIconClass(activity.values.booking_status),
          adminName: fullName,
          roleName: roleName,
          timeAgo: this.cs.formatTimeAgo(activity.values.time_and_date),
          data: {
            bookingId: activity.values.booking_entity_id,
            owner_passenger_name: activity.values.owner_passenger_name,
            owner_pass_type: activity.relatedData[0]?.values?.owner_pass_type ? activity.relatedData[0]?.values?.owner_pass_type : 'Player',
            number_of_passenger: activity.relatedData[0]?.values?.number_of_passenger || 0,
            booking_pickup_location_address : activity.relatedData[0]?.values?.booking_pickup_location_address,
            booking_dropoff_location_address  : activity.relatedData[0]?.values?.booking_dropoff_location_address,
          },
        };
      }
      return null;
    }).filter(result => result !== null);
  }


  onSearchValueChanges(event) {
    this.entityData.searchStr = event;
    this.searchEntity();
  }

  getData() {
    return forkJoin({
      tableViewData: this.getTableView(),
      filterViewDate: this.getFilterView(),
      setBookingStatus: this.setBookingStatus()
    });
  }

  getTableView() {
    this.dashboardService
      .getAttributeDefinition( AppSettings.ENTITY_CODE.BOOKING_ACTIVITY,AppSettings.VIEW_CODE.DEFAULT_TABLE_VIEW)
      .subscribe((res) => {
        if (res) {
          this.setTableView(res);
        }
      });
  }

  setTableView(res) { 
    this.isLoading = false;
    this.data = res;
    this.attributeIdForTimeRange = this.getAttributeId('time_and_date', this.data);
    this.attributeLabels = this.cs.getAttributeLabels(this.data);
    this.allColumns = this.cs.getColumns(this.data);
    this.searchEntity();

    this.allColumns?.forEach((key, index) => {
      this.allColumns[index] = key;
      this.allColumns[index].field = key.attributeCode;
      this.allColumns[index].header = this.cs.getLabelValue(
        "booking_activity" + ".fields." + key.attributeCode + ".label",
        this.attributeLabels,
        key.attributeCode
      );
    });
    this.filterAvailableColumns = _.clone(this.allColumns);
  }

  getAttributeId(attributeCode: string, locationAttributeData): number | undefined {
    for (const tab of locationAttributeData.tabs) {
      for (const group of tab.groups) {
        const attribute = group.fields.find(field => field.attributeCode === attributeCode);
        if (attribute) {
          return attribute.attributeId;
        }
      }
    }
    return undefined;
  }

  getFilterView() {
    this.dashboardService
      .getAttributeDefinition(
        AppSettings.ENTITY_CODE.BOOKING_ACTIVITY,        
        AppSettings.VIEW_CODE.ADVANCED_FILTER_VIEW
      )
      .subscribe((filterResponse) => {
        if (filterResponse) {
          this.setFilterView(filterResponse);
        }
      });
  }

  setFilterView(filterResponse) { 
    this.filterAttributeLabels = filterResponse;
    this.cs.getAttributeLabels(filterResponse);
    this.filterList = this.cs.getColumns(filterResponse);
    this.filterList = _.sortBy(this.filterList, "attributeIndex");
  }

  setBookingStatus() {
    this.bookingService.getBookingStatus().subscribe((res: BookingStatus[]) => {
      this.bookingStatus = res;
      setTimeout(() => {
        this.setStatusFilter();
      }, 500);
    });
  }

  setStatusFilter() {
    const activityFeedFilter = this.filterList?.find(filter => filter.attributeCode === "booking_status");
    if (activityFeedFilter) {
      activityFeedFilter.presetValues = this.bookingStatus.map(booking => ({
        labelKey: AppSettings.BOOKING.STATUS[booking.bookingCode],
        labelValue: booking.bookingStatusId,
      }));
    }
  }

  onFilterValueChange(event) {
    const formData = event;
    this.entityData.filters = [];
    for (const [key, value] of Object.entries(formData)) {
        if (key !== 'time_and_date') {
            const attributeValue = value;
            if (attributeValue) {
                this.entityData.filters.push({
                    attributeCode: key,
                    attributeValue,
                });
            }
        }
    }
    this.entityData.filters = _.filter(
        _.uniq(this.entityData.filters, function(item) {
            return item.attributeValue.length > 0 ? item.attributeCode : undefined;
        }),
        function(element) {
            return element.attributeValue.length > 0;
        }
    );
    this.entityData.advanceFilters = [];
    if (formData.time_and_date && Array.isArray(formData.time_and_date)) {
        const timestamps = formData.time_and_date.map(date => new Date(date).getTime());
        const fromValue = Math.min(...timestamps);
        const toValue = Math.max(...timestamps);
        this.entityData.advanceFilters.push({
            attributeId: this.attributeIdForTimeRange,
            fromValue: fromValue,
            toValue: toValue,
            comparisonOperator: "BETWEEN",
        });
    }
    this.entityData.offset = event.first;
    this.entityData.filters = this.cs.mapAttributeIds(this.entityData.filters, this.filterList, 'filter');
    this.searchEntity();
  }

}
