import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MiFieldsComponent } from '../mi-fields/mi-fields.component';
import { MiErrorComponent } from '../mi-error/mi-error.component';
import { MiTooltipComponent } from '../mi-fields/mi-tooltip/mi-tooltip.component';
import { CommonBindingDataService } from '../../services/common-binding-data.service';


@Component({
  selector: 'app-seeded-player-field',
  standalone: true,
  imports: [
    CommonModule,
    MiFieldsComponent,
    MiErrorComponent,
    MiTooltipComponent
  ],
  templateUrl: './seeded-player-field.component.html'
})
export class SeededPlayerFieldComponent {
  @Input() field: any;
  @Input() attributeLabels: any;
  @Input() moduleName: string;
  @Input() dateFormat: string;
  @Input() multiSelectDisplay: any;

  constructor(public cs: CommonBindingDataService) {}
} 