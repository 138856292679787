export interface ZoneItem {
  tenantId: string;
  createdBy: string;
  updatedBy: string;
  createdAt: number;
  updatedAt: number;
  zoneId: string;
  zoneName: string;
  aliasName: string;
  description: string;
  geofenceCategory: string;
  latLog: string;
  geoFence: string;
  radius: number;
  tag: string;
  active: boolean;
  message?: string;
  zoneType: string;
  latitude: string;
  longitude: string;
  zoneColor: string;
}

export interface ZoneListResponse {
  data: ZoneItem[];
  count: number;
  pagination: ZoneItemPagination;
}

export interface ZoneItemPagination {
  limit: number;
  offset: number;
  searchStr: string;
  defaultSortColumn: string;
  defaultSortType: string;
}

export interface Zone {
  zoneType: string;
  geoFence?: string;
  zoneColor: string;
  zoneName: string;
  latitude?: number;
  longitude?: number;
  radius?: number;
}

export interface Polygon {
  coords: { lat: number; lng: number }[];
  strokeColor: string;
  fillColor: string;
  label: string;
  strokeOpacity: number;
  strokeWeight: number;
  fillOpacity: number;
}

export interface Circle {
  center: { lat: number; lng: number };
  radius: number;
  strokeColor: string;
  fillColor: string;
  label: string;
  strokeOpacity: number;
  strokeWeight: number;
  fillOpacity: number;
}

export interface ZonesData {
  polygons: Polygon[]
}

