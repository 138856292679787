

  @if (miImage) {
    <div class="overlay-image">
      <p-image 
      [src]="miImage" 
      [previewImageSrc]="imgFileUrl"
      [alt]="'miImage'" 
      [width] = "miWidth"
      class="shared-img vertical-align-middle border-round-sm event-img mr-2"
      [preview]="true" />
    </div>


  } @else {
    <div class="no-image inline-flex mr-2">
      @if (moduleName === entityTypes.EVENTS) {
        <i class="mi-lg calendar_event"></i>
      } @else {
        <img [src]="defaultLoaderImg" class="vertical-align-middle border-round-sm mr-2" />
      }
    </div>
  }
  <!-- [height] = "miHeight" -->
