<p-breadcrumb [model]="routePath"></p-breadcrumb>
<p-tabView [(activeIndex)]="activeIndex" (onChange)="tabViewChange($event)">
  <p-tabPanel>
    <ng-template pTemplate="header">
      <i class="pi pi-align-left mr-2"></i>
      <span translate>lbl_all</span>&nbsp;({{totalRecords}})
    </ng-template>
    <ng-template pTemplate="body">
        <app-mi-table
          [collectionOfColumns]="allColumns"
          [attributeLabels]="filterAttributeLabels"
          [data]="tableData"
          [isLoading]="isLoading"
          (onPageChange)="onPageChange($event)"
          (itemPerPageChange)="itemPerPageChange($event)"
          [totalRecords]="totalRecords"
          [filterList]="filterList"
          (onValueChange)="onFilterValueChange($event)"
          [filterFields]="filterFieldsArray"
          [label]="btnLabel"
          [attributeData]="attributeData"
          (onSearchValueChange)="onSearchValueChanges($event)"
          (onUpdate)="onUpdateQueue($event)"
          (setCurrentRowData)="setCurrentRowData($event)"
          (queueLocationFromMiTable)="getQueueLocationFromMiTable($event)"
          [rowItems]="rowItems"
          [isEntity]="isEntity"
          [uniqueId]="uniqueId"
          [staticEntity]="'add_queue'"
          [visible]="visible">
        </app-mi-table>
    </ng-template>
  </p-tabPanel>
</p-tabView>
<p-toast></p-toast>
