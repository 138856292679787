export interface EntityList {
  limit?: number;
  offset?: number;
  searchStr?: string;
  eventCode?: string;
  defaultSortColumn?: string;
  defaultSortType?: string;
  filters?: Filter[];
  forTenantCode: string;
  countryCode?: string;
  deleted?: string;
  actionStatus?: string;
  status?: string;
  userName?: string;
  tagIds?: string[];
  tableViewCode?: string;
  advanceFilters?: AdvanceFilter[];
  languageCode?: string;
  viewCode?: string;
}

export interface Filter {
  attributeCode?: any,
  attributeId?: any;
  attributeValue: AttributeValue;
}

export interface AdvanceFilter {
  attributeId?: any;
  fromValue?: any,
  toValue?: any,
  comparisonOperator: string;
}

export interface AttributeValue {
}


export interface EntityCount {
  forTenantCode: string,
  entityCode?: string
}

export interface EntityCountsResponse {
  tenantId: string;
  entityCode: string;
  counts: Counts;
}

export interface Counts {
    ALL: number;
    DELETED: number;
    [key: string]: number;
}


export interface ActionStatusUpdate {
  forTenantCode: string;
  entityCode: string;
  actionStatus: string;

}