<p-breadcrumb [model]="routePath"></p-breadcrumb>
<div appAccessProvider [accessCode]="{ menuCode: 'MANAGE_LOCATIONS', pageCode: 'DETAILS', subPageCode: 'VIEW' }">
<div class="grid mt-1 mr-0 details-div">
    <div class="col-9">
        @if (showStatus) {
            <app-overview-status [dataForStatus]="dataForStatus" [locationCategory]="locationCategory"></app-overview-status>
        }
    </div>
    <div class="col-3 text-right flex align-items-center justify-content-end">
        <div>
            <p-button appAccessProvider [accessCode]="{ menuCode: 'MANAGE_LOCATIONS', pageCode: 'DETAILS', subPageCode: 'EDIT' }"
                styleClass="p-button-secondary" class="border-400 menu-btn p-2 p-button-secondary" (click)="menu.toggle($event)" icon="{{miIcons.MORE_HORIZONTAL}} block wh-24"></p-button>
                
            <button appAccessProvider [accessCode]="{ menuCode: 'EVENTS', pageCode: 'ALL_EVENTS', subPageCode: 'EDIT/UPDATE' }" (click)="edit()" pButton class=" border-400 bg-white text-color mr-1 pl-2 pr-2 pt-1 pb-1 mr-2 hover:surface-100 edit-btn" [label]="editBtnLabel" icon="{{miIcons.EDIT_PENCIL}} block wh-16">  
            </button>
        </div>
    </div>
</div>

<div>
    @if (locationAttributeData) {
        <app-overview-details
            [attributeValues]="attributeValues"
            [attributeLabels]="attributeLabels"
            [locationAttributeData]="locationAttributeData"
            [locationDetails]="locationDetails"
        ></app-overview-details>
    }
</div>

<div class="header-module-menu">
    <p-menu  #menu  [model]="moduleHeaderSecondaryBtnMenuItems" [popup]="true"></p-menu>
</div>
</div>