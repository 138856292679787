import { Component, OnInit } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { MenuItem, MessageService } from "primeng/api";
import { BreadcrumbModule } from "primeng/breadcrumb";
import { TabViewModule } from "primeng/tabview";
import { ManageZonesService } from "../../services/manage-zones.service";
import { CommonBindingDataService } from "../../../shared/services/common-binding-data.service";
import { Router } from "@angular/router";
import { ModuleHeaderComponent } from "../../../shared/components/module-header/module-header.component";
import { FilterSectionComponent } from "../../../dashboard/components/activity-feed/filter-section/filter-section.component";
import { CommonModule, DatePipe, NgClass } from "@angular/common";
import { Polygon, Zone, ZoneItem, ZoneItemPagination, ZonesData } from "../../models/zone.models";
import { ZoneMapComponent } from "../zone-map/zone-map.component";
import { FormsModule } from "@angular/forms";
import { InputTextModule } from "primeng/inputtext";
import data from "../../../../../../src/assets/json/dashboard/data.json";
import { AppSettings } from "../../../shared/app.settings";
import * as _ from 'lodash';
import { AppIcons } from "app/modules/shared/app.icons";
import { NoResultFoundComponent } from "app/modules/shared/components/no-result-found/no-result-found.component";
import { AccessProviderDirective } from "app/modules/shared/directives/access-provider.directive";

@Component({
  selector: "app-manage-zones",
  templateUrl: "./manage-zones.component.html",
  styleUrls: ["./manage-zones.component.scss"],
  standalone: true,
  imports: [
    BreadcrumbModule,
    TabViewModule,
    TranslateModule,
    ModuleHeaderComponent,
    TranslateModule,
    FilterSectionComponent,
    NgClass,
    CommonModule,
    ZoneMapComponent,
    FormsModule,
    InputTextModule,
    NoResultFoundComponent,
    AccessProviderDirective
  ],
  providers: [DatePipe],
})
export class ManageZonesComponent implements OnInit {
  routePath: MenuItem[] = [];
  activeIndex: number = 0;
  paginator: ZoneItemPagination = {
    limit: 20,
    offset: 0,
    searchStr: "",
    defaultSortColumn: "updatedAt",
    defaultSortType: "desc",
  };
  searchValue: string = "";
  zoneListData=[];
  btnLabel: string;
  zonesData: any;
  hovered: boolean = false;
  centerCordinateList: any;
  miIcons = AppIcons;
  isBtnDisabled: boolean = false;

  constructor(
    private manageZonesService: ManageZonesService,
    private cs: CommonBindingDataService,
    private router: Router,
    private datePipe: DatePipe,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.setRoutePath();
    this.getZonesList();
    this.setHeadersAndLabels();
  }

  onSearch(): void {
    this.paginator.searchStr = this.searchValue;
    this.getZonesList();
  }

  getZonesList(paginator: ZoneItemPagination = this.paginator) {
    this.manageZonesService.getZonesList(paginator).subscribe((zoneData) => {
      this.zoneListData = [];
      if (zoneData.data.length > 0) {
        this.zoneListData.push(zoneData.data[0]);
      }
      this.isBtnDisabled = zoneData.data.length >= 1;
      const zonesData: ZonesData = { polygons: [] };

      const tempzonesData = _.cloneDeep(zoneData);

      this.centerCordinateList = _.map(tempzonesData.data, ({ latitude, longitude }) => {
        if (latitude != null && longitude != null && latitude !== '' && longitude !== '' && latitude !== 0 && longitude !== 0) {
          return { lat: latitude, lng: longitude };
        }
        return null;
      }).filter(coordinate => coordinate !== null);

      const geoFenceCenterCordsList = _.flatMap(tempzonesData.data, (zoneData) => {
        if (zoneData.geoFence) {
          const coordinatePairs = zoneData.geoFence.split(',');

          return _.map(coordinatePairs, pair => {
            const [lng, lat] = pair.trim().split(' ').map(Number);
            return { lat, lng };
          });
        }
        return [];
      });

      this.centerCordinateList = [...this.centerCordinateList, ...geoFenceCenterCordsList]

      const commonZoneProps = (zone: Zone) => ({
        strokeColor: zone.zoneColor,
        fillColor: zone.zoneColor,
        label: zone.zoneName,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillOpacity: 0.35
      });

      const transformGeoZone = (zone: Zone): Polygon => {
        const coords = zone.geoFence?.split(",").map(coord => {
          const [lng, lat] = coord.split(" ").map(parseFloat);
          return { lat, lng };
        });

        return {
          coords,
          ...commonZoneProps(zone)
        };
      };

      zonesData.polygons = zoneData.data.map(transformGeoZone);

      this.zonesData = zonesData;
    }, (error) => {
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
    });
  }

  setHeadersAndLabels() {
    this.isBtnDisabled = this.zoneListData  && this.zoneListData.length > 1;
    this.btnLabel = this.cs.getLabel("manage_zones.add_zone_lbl");
  }

  clearSearch() {
    this.searchValue = "";
    this.paginator.searchStr = "";
    this.getZonesList();
  }

  navigateToZoneDetail(zoneId: string) {
    this.router.navigate(["app/manage-zones/", zoneId]);
  }

  onAddZone() {
    this.router.navigate(["app/manage-zones/add"]);
  }
  onEditZone(zoneId: string) {
    this.router.navigate(["app/manage-zones/edit", zoneId]);
  }

  setRoutePath() {
    this.routePath = [
      { label: this.cs.getLabel("manage_zones.manage_zone"), routerLink: "" },
    ];
  }

  formatUpdatedAt(epoch: number): string {
    const date = new Date(epoch);
    return this.datePipe.transform(date, AppSettings.DATE_FORMATTER_MONTH_TIME);
  }
}
