<div class="relative">
  <div class="grid absolute bottom-13px w-full">
    <div class="col-7"></div>
    <div class="col-2 horizontal">
      <label class="flex font-semibold">{{cs.getLabelValue(moduleName
        +'.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}
        @if (field.validation?.required) {
          <span class="required">*</span>
        }
        @if (cs.getLabelValue(moduleName+'.fields.'+field.attributeCode+'.helpTips',attributeLabels,0)) {
          <app-mi-tooltip [messageData]="cs.getLabelValue(moduleName
            +'.fields.'+field.attributeCode+'.helpTips',attributeLabels,field.attributeCode)">
          </app-mi-tooltip>
        }
      </label>
    </div>
    <div class="col-3">
      <app-mi-fields 
        [field]="field" 
        [attributeLabels]="attributeLabels" 
        [dateFormat]="dateFormat"
        [moduleName]="moduleName" 
        [multiSelectDisplay]="multiSelectDisplay">
      </app-mi-fields>

      <app-mi-error 
        [field]="field" 
        [attributeLabels]="attributeLabels"
        [moduleName]="moduleName">
      </app-mi-error>
    </div>
  </div>
</div> 