import { Component, Input } from '@angular/core';
import { AppSettings } from 'app/modules/shared/app.settings';
import { Country } from 'app/modules/shared/models/country';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { EntityService } from 'app/modules/shared/services/entity.service';

interface UserDetail {
  firstName: string;
  lastName: string;
  email: string;
}

@Component({
  selector: 'app-input-id',
  standalone: true,
  imports: [],
  templateUrl: './input-id.component.html',
  styleUrl: './input-id.component.scss'
})
export class InputIdComponent {

  @Input() userId: any;
  @Input() field: any;
  userDetails: UserDetail;
  country: Country;
  userProfileAttributeData: any;
  img: any = "../../../../../../assets/images/default_profile.png";
  entityData: any = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted: AppSettings.DELETED_TYPE.BOTH,
    forTenantCode: this.configService.getLoggedInTenantCode(),
  };

  constructor(
    private configService: ConfigService,
    private cs: CommonBindingDataService,
    private entityService: EntityService) { }

  ngOnInit() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    const profileData = JSON.parse(localStorage.getItem(AppSettings.PROFILE_DATA));
    this.userId = profileData.userId;
    if (this.userId) {
      this.getUserDetails(this.userId);
    }
    this.getAttributes();
  }

  getAttributes() {
    this.entityService.getAttributeDefinition(AppSettings.ENTITY_CODE.USER_DETAIL, AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe(res => {
      if (res) {
        this.userProfileAttributeData = this.cs.getOrganizedAttribute(res);
        let attributeIdForUserProfile = this.getAttributeId('user_id');
        this.searchEntity(attributeIdForUserProfile);
      }
    });
  }

  getAttributeId(attributeCode) {
    const fields = this.userProfileAttributeData.tabs[0].groups[0].fields;
    const field = fields.find(field => field.attributeCode === attributeCode);
    return field ? field.attributeId : null;
  }

  getUserDetails(userId) {
    this.entityService.getUserDetails(userId, this.configService.getForTenantCode()).subscribe((result: UserDetail) => {
      this.userDetails = result;
    })
  }

  searchEntity(attributeIdForUserProfile) {
    this.entityData.countryCode = this.country[0].countryCode;
    this.entityData.filters.push({
      attributeId: attributeIdForUserProfile,
      attributeValue: this.userId
    });
    this.entityService.searchEntity(AppSettings.ENTITY_CODE.USER_DETAIL, this.entityData).subscribe((res: any) => {
      let data = this.cs.getTableData(res);
      this.img = this.getUserProfileImage(data);
    })
  }

  getUserProfileImage(data) {
    if (data.length > 0) {
      return (data[0].user_profile_image_url && data[0].user_profile_image_url.length > 0) ? data[0].user_profile_image_url[0] : null;
    }
    return null;
  }

  getImageByFileId(fileId) {
    this.entityService.getFile(fileId, AppSettings.DOCUMENTS_TYPE.PROFILE).subscribe(result => {
      const file = new File([result], 'User Profile');
      const reader = new FileReader();
      reader.readAsDataURL(result);
      const that = this;
      reader.onloadend = function() {
        const base64data = reader.result;
        that.img = base64data;
      }
    })
  }

}
