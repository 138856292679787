import { Directive, ElementRef, Input, OnChanges } from '@angular/core';
import { PermissionAccessService } from '../services/permission-access.service';

@Directive({
  selector: '[appAccessProvider]',
  standalone: true,
})
export class AccessProviderDirective implements OnChanges {
  @Input() accessCode: { menuCode: string; subMenuCode: string, pageCode: string; subPageCode: string };
  private innerElement: HTMLElement;

  constructor(refElement: ElementRef, private permissionAccessService: PermissionAccessService) {
    this.innerElement = refElement.nativeElement;
  }

  ngOnChanges() {
    const {menuCode, subMenuCode, pageCode, subPageCode} = this.accessCode;
    const hasAccess = this.permissionAccessService.hasAccess(menuCode, subMenuCode, pageCode, subPageCode);
    this.innerElement.style.display = hasAccess ? '' : 'none';
  }
}
