import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, FormArray, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SettingsService } from 'app/modules/settings/settings.service';
import { AppIcons } from 'app/modules/shared/app.icons';
import { AppSettings } from 'app/modules/shared/app.settings';
import { Countries } from 'app/modules/shared/countries';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { Dropdown, DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { TabViewModule } from 'primeng/tabview';
import { StaticContentReqDto, StaticContentResponse, RoleData, RequestBody } from '../../models/content.models';
import { NgClass } from '@angular/common';
import { AccessProviderDirective } from 'app/modules/shared/directives/access-provider.directive';

@Component({
  selector: 'app-contact-support',
  standalone: true,
  imports: [TranslateModule, BreadcrumbModule, ButtonModule, TabViewModule, FormsModule, ReactiveFormsModule, InputTextModule, InputSwitchModule, DropdownModule, NgClass, AccessProviderDirective],
  templateUrl: './contact-support.component.html',
  styleUrls: ['./contact-support.component.scss', '../../../shared/components/mi-fields/mobile-input/flags.scss']
})
export class ContactSupportComponent {
  @ViewChild('dropdown') dropdown!: Dropdown;
  btnLabel: string;
  routePath: MenuItem[] = [];
  supportForm: FormGroup;
  addBtnLabel: string;
  maxRestriction = 9;
  miIcons = AppIcons;
  countries: any[] | undefined;
  selectedCountry: any;
  filterIcon = 'pi pi-search';
  hasChanges = false;
  originalFormData: any;

  constructor(private cs: CommonBindingDataService,
    private fb: FormBuilder,
    private settingsService: SettingsService,
    private configService: ConfigService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService) {

  }


  ngOnInit(): void {
    this.setLabels();
    this.setRoutePath();
    this.setCountries();
    this.initForm();
    this.getContactSupportData();
  }

  private setLabels() {
    this.addBtnLabel = this.cs.getLabel('label_save');
  }

  private initForm() {
    this.supportForm = this.fb.group({
      supports: this.fb.array([this.createSupport()])
    });

    this.hasChanges = false;
    this.supportForm.valueChanges.subscribe(() => {
      if (this.originalFormData) {
        this.hasChanges = !this.isFormEqual(this.supportForm.value, this.originalFormData);
      }
    });
  }

  private isFormEqual(formData: any, originalData: any): boolean {
    return JSON.stringify(formData) === JSON.stringify(originalData);
  }

  private setRoutePath() {
    this.routePath = [
      { label: this.cs.getLabel('content.lbl_contact_support'), routerLink: 'content/terms-and-conditions' },
    ];
  }

  isFieldInvalid(fieldName: string, index: number): boolean {
    const control = this.supports.at(index).get(fieldName);
    return control?.invalid && (control?.touched || control?.dirty);
  }

  createSupport(): FormGroup {
    return this.fb.group({
      supportName: ['', [Validators.required, Validators.pattern(AppSettings.NAME_PATTERN)]],
      supportNumber: ['', [Validators.required, Validators.pattern(AppSettings.PHONE_PATTERN), Validators.maxLength(AppSettings.MAX_MOBILE_LENGTH), Validators.minLength(AppSettings.MIN_MOBILE_LENGTH)]],
      driver: [false],
      passenger: [false],
      countryCode: [this.selectedCountry]
    });
  }

  get supports(): FormArray {
    return this.supportForm.get('supports') as FormArray;
  }

  addSupport(): void {
    this.supports.push(this.createSupport());
  }

  removeSupport(index: number): void {
    if (this.supports.length > 1) {
      this.showConsentPopup(index);
    }
  }

  showConsentPopup(index) {
    const confirmHeader = this.cs.getLabel('content.delete_contact_header');
    const confirmHeaderMsg = this.cs.getLabel('content.delete_contact_msg');
    this.confirmationService.confirm({
      header: confirmHeader,
      message: confirmHeaderMsg,
      acceptIcon: 'none',
      rejectIcon: 'none',
      acceptLabel: this.cs.getLabel('content.lbl_delete'),
      rejectLabel: this.cs.getLabel('content.lbl_cancel'),
      rejectButtonStyleClass: 'bg-white text-color',
      acceptButtonStyleClass: 'bg-red-500',
      accept: ($event) => {
        this.supports.removeAt(index);
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Successful', detail: this.cs.getLabel('content.contact_delete_message') });
      },
      reject: () => {
      }
    });
  }

  onCancel() {
    if (this.hasChanges) {
      this.showCancelConfirmationPopup();
    } else {
      this.resetForm();
    }
  }

  private showCancelConfirmationPopup() {
    const confirmHeader = this.cs.getLabel('content.lbl_confirmation');
    const confirmMessage = this.cs.getLabel('content.confirm_discard_msg');
    this.confirmationService.confirm({
      header: confirmHeader,
      message: confirmMessage,
      acceptIcon: 'none',
      rejectIcon: 'none',
      acceptLabel: this.cs.getLabel('content.lbl_discard'),
      rejectLabel: this.cs.getLabel('content.lbl_no'),
      accept: () => {
        this.resetForm();
      },
      reject: () => {
        
      }
    });
  }

  private resetForm() {
    this.supportForm.setValue(this.originalFormData);
    this.hasChanges = false;
  }

  setCountries() {
    this.countries = Countries.COUNTRY_CODE;
    this.selectedCountry = this.countries[AppSettings.DEFAULT_COUNTRY_CODE];
  }

  selectCountry(event: any, index: number) {
    const selectedCountry = event.value;
    this.supports.at(index).get('countryCode')?.setValue(selectedCountry);
  }

  onFilter(event) {
    if (event.filter) {
      this.filterIcon = 'pi pi-times'
    } else {
      this.filterIcon = 'pi pi-search';
    }
  }

  onFilterIconClick() {
    if (this.filterIcon.includes('times')) {
      this.dropdown.filterValue = null;
      this.filterIcon = 'pi pi-search';
    }
  }

  isFormValid(): boolean {
    return this.supports.controls.every(control => {
      const supportName = control.get('supportName');
      const supportNumber = control.get('supportNumber');
      const driver = control.get('driver');
      const passenger = control.get('passenger');
      return supportName?.valid && supportNumber?.valid && (driver?.value === true || passenger?.value === true);
    });
  }

  onAdd() {
    if (this.supportForm.valid) {
      const requestBody: RequestBody = this.transformedPayload();
      this.settingsService.updateStaticContentInfo(requestBody).subscribe(res => {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Successful', detail: this.cs.getLabel('settings.contact_support_added_msg') });
      }, (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: error.errors.general[0].message });
      });
    } else {
      this.supportForm.markAllAsTouched();
    }
  }

  private transformedPayload(): RequestBody {
    const supportsArray = this.supports.controls;
    const staticContentReqDto: StaticContentReqDto[] = [];

    supportsArray.forEach((control, index) => {
      const formValue = control.value;
      const countryCode = formValue.countryCode ? formValue.countryCode.value : '';

      if (formValue.driver) {
        staticContentReqDto.push({
          contentType: `CONTACT_SUPPORT_NAME_${this.padIndex(index + 1)}`,
          roleCode: 'DRIVER',
          content: formValue.supportName
        });

        staticContentReqDto.push({
          contentType: `CONTACT_SUPPORT_NUMBER_${this.padIndex(index + 1)}`,
          roleCode: 'DRIVER',
          content: `${countryCode} ${formValue.supportNumber}`.trim()
        });

      }

      if (formValue.passenger) {
        staticContentReqDto.push({
          contentType: `CONTACT_SUPPORT_NAME_${this.padIndex(index + 1)}`,
          roleCode: 'PASSENGER',
          content: formValue.supportName
        });

        staticContentReqDto.push({
          contentType: `CONTACT_SUPPORT_NUMBER_${this.padIndex(index +1)}`,
          roleCode: 'PASSENGER',
          content: `${countryCode} ${formValue.supportNumber}`.trim()
        });

      }
    });

    return {
      forTenantCode: this.configService.getForTenantCode(),
      contentType: 'CONTACT_SUPPORT',
      staticContentReqDto
    };
  }

  private padIndex(index: number): string {
    return index.toString().padStart(2, '0');
  }

  getContactSupportData() {
    const contentType = AppSettings.STATIC_CONTENT.CONTACT_SUPPORT;
    this.settingsService.getStaticContentData(contentType).subscribe((res: StaticContentResponse[]) => {
      if (res && res.length > 0) {
        const formArray = this.transformApiResponse(res);
        this.supportForm.setControl('supports', formArray);
        this.originalFormData = this.supportForm.value;
        this.addBtnLabel = this.cs.getLabel('content.lbl_update');
      }
    });
  }

  private transformApiResponse(response: StaticContentResponse[]): FormArray {
    const roleDataMap = new Map<number, RoleData>();
    const countryCodeMap = new Map<number, string>();
  
    const reqDtoData = this.extractStaticContentReqDto(response);
    this.processContentItems(reqDtoData, roleDataMap, countryCodeMap);
  
    const supportsArray = this.createSupportsFormGroup(roleDataMap, countryCodeMap);
  
    this.setInitialCountryCode(countryCodeMap);
  
    return this.fb.array(supportsArray);
  }
  
  private extractStaticContentReqDto(response: StaticContentResponse[]): StaticContentReqDto[] {
    return response.reduce((acc, item) => {
      return acc.concat(item.staticContentReqDto);
    }, [] as StaticContentReqDto[]);
  }
  
  private processContentItems(
    reqDtoData: StaticContentReqDto[],
    roleDataMap: Map<number, RoleData>,
    countryCodeMap: Map<number, string>
  ): void {
    reqDtoData.forEach(item => {
      const { contentType, roleCode, content } = item;
      const match = contentType.match(/_(\d{2})$/);
  
      if (match) {
        const index = parseInt(match[1], 10);
  
        if (!roleDataMap.has(index)) {
          roleDataMap.set(index, {});
        }
        const roleData = roleDataMap.get(index)!;
  
        if (roleCode === 'DRIVER') {
          this.processDriverRole(contentType, content, roleData, countryCodeMap, index);
        } else if (roleCode === 'PASSENGER') {
          this.processPassengerRole(contentType, content, roleData, countryCodeMap, index);
        }
      }
    });
  }
  
  private processDriverRole(
    contentType: string,
    content: string,
    roleData: RoleData,
    countryCodeMap: Map<number, string>,
    index: number
  ): void {
    if (contentType.includes('NAME')) {
      roleData.driverName = content;
    } else if (contentType.includes('NUMBER')) {
      const countryCodeMatch = content.match(/^\+(\d+)/);
      if (countryCodeMatch) {
        countryCodeMap.set(index, `+${countryCodeMatch[1]}`);
        roleData.driverNumber = content.replace(/^\+\d+\s*/, '');
      } else {
        roleData.driverNumber = content;
      }
    }
  }
  
  private processPassengerRole(
    contentType: string,
    content: string,
    roleData: RoleData,
    countryCodeMap: Map<number, string>,
    index: number
  ): void {
    if (contentType.includes('NAME')) {
      roleData.passengerName = content;
    } else if (contentType.includes('NUMBER')) {
      const countryCodeMatch = content.match(/^\+(\d+)/);
      if (countryCodeMatch) {
        countryCodeMap.set(index, `+${countryCodeMatch[1]}`);
        roleData.passengerNumber = content.replace(/^\+\d+\s*/, '');
      } else {
        roleData.passengerNumber = content;
      }
    }
  }
  
  private createSupportsFormGroup(
    roleDataMap: Map<number, RoleData>,
    countryCodeMap: Map<number, string>
  ): FormGroup[] {
    const supportsArray: FormGroup[] = [];
    roleDataMap.forEach((data, index) => {
        const countryCode = countryCodeMap.get(index) || '';
        const selectedCountry = this.countries.find(country => country.value === countryCode) || null;
       
        supportsArray.push(this.fb.group({
            supportName: [data.driverName || data.passengerName || '', [Validators.required, Validators.pattern(AppSettings.NAME_PATTERN)]],
            supportNumber: [data.driverNumber || data.passengerNumber || '',
                [
                  Validators.required,
                  Validators.pattern(AppSettings.PHONE_PATTERN),
                  Validators.maxLength(AppSettings.MAX_MOBILE_LENGTH),
                  Validators.minLength(AppSettings.MIN_MOBILE_LENGTH)
                ]
            ],
            driver: [!!data.driverName],
            passenger: [!!data.passengerName],
            countryCode: [selectedCountry]
        }));
    });
 
    return supportsArray;
  }
  
  private setInitialCountryCode(countryCodeMap: Map<number, string>): void {
    const firstCountryCode = [...countryCodeMap.values()][0] || '';
    this.selectedCountry = this.countries.find(country => country.value === firstCountryCode) || this.countries[AppSettings.DEFAULT_COUNTRY_CODE];
  }  

}
