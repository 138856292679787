<div appAccessProvider [accessCode]="{ menuCode: 'ADMINISTRATION', pageCode: 'CONFIGURATION_SETTING', subPageCode: 'EDIT' }">
    <div class="flex justify-content-end flex-wrap btn-with-links">
        <button pButton class="mr-2 bg-white default-text-light-black-color cursor-pointer border-button" [label]="'cancel' | translate" (click)="onCancel()"></button>
        <p-button  class="flex align-items-center justify-content-center cursor-pointer" (click)="onNextBtnClick()" [label]="saveConfigurationSettingsBtnLabel"></p-button>
    </div>
    
    <p-tabView>
        <p-tabPanel>
            <ng-template pTemplate="header">
                <div class="tab-icons">
                  <i class="mi-lg details mr-2"></i>
                  <span translate>settings.lbl_configuration_settings</span>
                </div>
              </ng-template>
              <div class="config-setting">
                @if (showForm) {
                  @for(tab of configurationSettingsAttributeData?.tabs; track tab?.tabCode) {
                    <app-mi-form
                        [attributeLabels]="attributeLabelsForConfigurationSettings"
                        [moduleName]="configurationSettingsModuleName"
                        [attributeValues]="attributeValues"
                        [groups]="tab.groups"
                        [isMultipleFilesUpload]="false"
                        [previousBtnText]="previousBtnLabel"
                        [submitBtnText]="saveConfigurationSettingsBtnLabel"
                        (saveData)="saveConfigurationSettings($event)"
                        (previousClick)="onCancel()"
                        [saveBtnId]="'config'"
                    >
                    </app-mi-form>
                }
                }
              </div>
    
    </p-tabPanel>
    </p-tabView>
    </div>
    