<div class="pb-1 pr-3 pl-1 flex">
    <div class="flex-1 flex align-items-center">
        <label class="truncate-text"> {{'placeholder_search_by' |translate}}
            {{cs.getLabelValue(moduleName+'.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}
        </label>&nbsp;
    </div>
</div>

<div class="flex align-items-center p-input-icon-left input-container w-full -ml-3">
    @if(searchQuery){
        <span><i class="pi pi-times cursor-pointer text-500 flex align-items-start text-sm wh-8 pl-1" (click)="onSearchClear()"></i></span>
    }
    @if(!searchQuery){
        <span><i class="pi pi-search text-500 flex align-items-start text-sm wh-8 pl-1"></i></span>
    }
    <p-autoComplete
        [(ngModel)]="selectedItems"
        [suggestions]="items"
        (completeMethod)="search($event)"
        (onSelect)="onSelectItem($event)"
        [placeholder]="'placeholder_search' |translate"
        [multiple]="true"
        class="mi-auto-complete w-full">

        <ng-template let-item pTemplate="item">
            <div class="flex align-items-center gap-2">
                <div class="passenger-popup bold-name">

                    @if(item.passenger_profile_image){
                    <img [src]="item.passenger_profile_image || defaultLoaderImg"
                        class="vertical-align-middle border-round-sm list-img mr-1" />

                    }@else{
                    <i class="mi-lg mi-default-user wh-20-m-5 vertical-align-middle border-round-sm"></i>
                    }
                    <div class="ellipsis mr-2 font-semibold">
                        {{item.value}}       {{item.group_name}}    {{item.location_short_code}}  {{item.name_code}}  {{item.first_name}} {{item.last_name}} <span class="font-normal text-sm">{{item.mobile_number}} {{item.reg_number}} {{item.location_display_name_for_passenger}} 
                        </span>
                    </div>
                    @if(item.email || item.location_display_name_for_booker ) {
                        <div class="unique ellipsis mr-2">
                            {{item.email}} {{item.location_display_name_for_booker}}
                        </div>
                    }
                </div>

            </div>
        </ng-template>
    </p-autoComplete>
</div>

@if(visibleItems.length > 0){
    <div class="selected-container mt-3">
      <div class="font-semibold mb-2"> {{'lbl_selected'| translate}} {{cs.getLabelValue(moduleName+'.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}} </div> 
        @for (item of visibleItems; track $index) {
    <div class="grid p-1 border-radius hover:surface-200 border-bottom-1 border-200">
        <div class="form-field col-10 md:col-10 lg:col-10 field mb-0 flex justify-content-start align-items-center">
            <div class="passenger-popup bold-name">
                <div class="ellipsis mr-2 font-semibold">
                    {{item.value}}  {{item.group_name}} {{item.location_short_code}}  {{item.name_code}}  {{item.first_name}} {{item.last_name}} <span class="font-normal text-sm">{{item.mobile_number}} {{item.reg_number}} {{item.location_display_name_for_passenger}}
                         </span>
                </div>
                @if(item.email || item.location_display_name_for_booker ) {
                    <div class="unique ellipsis mr-2">
                        {{item.email}} {{item.location_display_name_for_booker}}
                    </div>
                }
            </div>
        </div>
        <div class="col-2 md:col-2 lg:col-2  flex justify-content-end">
            <p-button icon="pi pi-trash" [outlined]="true" (click)="removeSelected(i)" />
        </div>
    </div>
    }
    </div>
}