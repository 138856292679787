<p-breadcrumb [model]="routePath"></p-breadcrumb>
<div appAccessProvider [accessCode]="{ menuCode: 'DRIVERS', subMenuCode: 'ALL_DRIVERS', pageCode: 'ALL', subPageCode: 'VIEW' }">
<div class="grid mt-1 details-div border-bottom-1 border-200 mr-0">
    <div class="col-9">
        @if (showStatus) {
            <status [dataForStatus]="dataForStatus" [entityForStatus]="entityForStatus" [userId]="userId" [imgFileUrl]="imgFileUrl"></status>
        }
    </div>
    <div class="col-3 pr-3 text-right flex align-items-center justify-content-end">
        <div appAccessProvider [accessCode]="{ menuCode: 'DRIVERS', subMenuCode: 'ALL_DRIVERS', pageCode: 'ALL', subPageCode: 'DELETE' }">
            @if (!isDeleted()) {
                <button icon="mi-lg basic_tray" (click)="deleteDriver()" pButton class="border-400 bg-white text-color mr-1 pl-2 pr-2 pt-1 pb-1 mr-2 hover:surface-100 delete-btn"[label]="deleteBtnLabel"></button>
            }
        </div>
    </div>
</div>


<p-tabView [(activeIndex)]="activeIndex" (activeIndexChange)="activeIndexChange($event)" class="driver-detail-tab" [scrollable]="true">
        <p-tabPanel>
            <ng-template pTemplate="header">
                <i class="mi-lg overview p-98px"></i>
                <label class="no-wrap-text" class="capitalize" translate>driver.lbl_overview</label>
            </ng-template>
            @if (driverAttributeData && driverDetails) {
                <app-driver-overview-details 
                    [attributeValues]="attributeValues"
                    [attributeLabels]="attributeLabels"
                    [driverAttributeData]="driverAttributeData"
                    [driverDetails]="driverDetails"
                    [driverGroupList]="driverGroupList"
                    [isDeleted]="isDeleted()">
                </app-driver-overview-details>
            }
        </p-tabPanel>
    <p-tabPanel>
        <ng-template pTemplate="header">
            <i class="mi-lg specs"></i>
            <label  class="no-wrap-text" translate>driver.lbl_assignment_history</label>
        </ng-template>
        <ng-template pTemplate="body">
            <app-assignment-history [tableData]="tableData" [driverDetails]="driverDetails" [isDeleted]="isDeleted()"></app-assignment-history>
            @if(tableData?.length === 0) {
                <app-no-result-found></app-no-result-found> 
            }
        </ng-template>

    </p-tabPanel>

    <p-tabPanel>
        <ng-template pTemplate="header">
            <i class="mi-lg journey-history"></i>
            <label class="no-wrap-text" translate>driver.lbl_journey_history</label>
        </ng-template>
        <ng-template pTemplate="body">
            <app-journey-history [tableData]="tableData" [driverDetails]="driverDetails"></app-journey-history>
            @if(tableData?.length === 0) {
                <app-no-result-found></app-no-result-found> 
            }
        </ng-template>
        
    </p-tabPanel>

    <p-tabPanel>
        <ng-template pTemplate="header">
            <i class="mi-lg documents"></i>
            <label class="no-wrap-text" translate>driver.lbl_documents</label>
        </ng-template>
        <ng-template pTemplate="body">
            <app-document-list
                [attributeValues]="attributeValues"
                [attributeLabels]="attributeLabels"
                [driverDetails]="driverDetails"
                [entityCode]="entityCode"
                [isDeleted]="isDeleted()"
                [label]="addBtnLabel"
                (onBtnClick)="onAddDriverDocument()">
            </app-document-list>
        </ng-template>

    </p-tabPanel>
</p-tabView>
</div>
