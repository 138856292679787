import { CommonModule, JsonPipe, NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CheckboxModule } from 'primeng/checkbox';
import { DividerModule } from 'primeng/divider';
import { InputTextModule } from 'primeng/inputtext';
import { AutoCompleteCompleteEvent, AutoCompleteModule } from 'primeng/autocomplete';
import { AppSettings } from 'app/modules/shared/app.settings';
import { EntityService } from 'app/modules/shared/services/entity.service';
import { ConfigService } from 'app/modules/shared/services/config.service';
import type { Country } from 'app/modules/shared/models/country';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-entity-select',
  standalone: true,
  imports: [AutoCompleteModule, FormsModule, ReactiveFormsModule, TranslateModule, InputTextModule, JsonPipe, CheckboxModule, DividerModule, NgClass, CommonModule],
  templateUrl: './entity-select.component.html',
  styleUrl: './entity-select.component.scss'
})
export class EntitySelectComponent {
  checked = [];
  @Input() attributeValues;
  @Input() field: any;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  @Output() onSearchValueChange: EventEmitter<string> = new EventEmitter();
  formName: FormGroup;
  searchValue: string = '';
  selectedControl: string[] = [];
  inputPlaceholder: string;
  filteredValues: any[] = [];
  suggestions: any[] | undefined;
  selectedItems: any = [];
  entityIds = [];
  items: any[] | undefined;
  country: Country;
  private isFirstTime: boolean = true;
  private subscription: Subscription;
  private clearSubscription: Subscription;
  moduleNameForConcerns;

  constructor(private formGroupDirective: FormGroupDirective,
    public cs: CommonBindingDataService,
    private entityService: EntityService,
    private configService: ConfigService) {
    this.formName = this.formGroupDirective.control;
  }


  ngOnInit(): void {
    this.clearSubscription = this.cs.clearAutoComplete$.subscribe(() => {
      this.clearCompleteChange();
    });
    if(this.moduleName === AppSettings.ENTITY_CODE.INCIDENTS_AND_COMPLAINT) {
      // const reportedBy = this.formName.controls['reported_by_name'].value;
      // this.formName.controls['reported_by'] = reportedBy;
      this.subscription = this.cs.data$.subscribe(data => {
        if (data) {
          this.moduleNameForConcerns = data.toLowerCase();
          if (this.isFirstTime) {
            this.isFirstTime = false;
          } else {
            this.clearAutoComplete();
          }
        }
      });
    }
    const relatedData = this.entityService.getRelateData;
    const data = relatedData?.[this.field.attributeCode];
    if (data) {
      this.selectedItems.push(...data);
      data.forEach(item => {
        this.entityIds.push(item.entityId)
      });
      this.formName.controls[this.field.attributeCode].setValue(this.entityIds);
      this.formName.controls[this.field.attributeCode].updateValueAndValidity();
    }
  }

  clearAutoComplete() {
    this.selectedItems = [];
    this.formName.controls['incident_complaint_date'].setValue(null);
    this.formName.controls['journey_id'].setValue(null);
    this.formName.controls[this.field?.isOneToMultiple ? this.field?.attributeCodeOneToMultiple : this.field.attributeCode].setValue('');
    this.formName.get(this.field?.isOneToMultiple ? this.field?.attributeCodeOneToMultiple : this.field.attributeCode).updateValueAndValidity();
  }

  clearCompleteChange(){
    this.selectedItems = [];
    this.items = [];
    this.formName.controls[this.field?.isOneToMultiple ? this.field?.attributeCodeOneToMultiple : this.field.attributeCode].setValue('');
    this.formName.get(this.field?.isOneToMultiple ? this.field?.attributeCodeOneToMultiple : this.field.attributeCode).updateValueAndValidity();
  }

  search(event: AutoCompleteCompleteEvent) {
    const query = event.query;
    if (!query || query.trim().length === 0) {
      return;
    }
    const entityActions = ['active_vehicle_id', 'default_vehicle_id', 'driver', 'passenger']

    let viewCode = this.field.attributeCode.toUpperCase() + '_' + 'AUTOCOMPLETE_VIEW'
    if (entityActions.includes(this.field.attributeCode)) {
      viewCode = 'AUTOCOMPLETE_VIEW';
    }

    const activeAttributeCodes = ['driver'];
    let actionStatus = '';

    if (activeAttributeCodes.includes(this.field.attributeCode)) {
      actionStatus = 'Active'
    }

    if(this.moduleNameForConcerns) {
      this.moduleName = this.moduleNameForConcerns;
      viewCode = 'AUTOCOMPLETE_VIEW';
    }

    if (query.length > 0) {
      this.formName.controls[this.field.attributeCode].markAsTouched();
      this.formName.controls[this.field.attributeCode].markAsDirty();
    }
    this.fetchFromAPI(viewCode, query, actionStatus);
  }

  onSelectItem(event) {
    this.entityIds.push(event.value.id)
    this.formName.controls[this.field.attributeCode].setValue(this.entityIds);
    this.formName.controls[this.field.attributeCode].updateValueAndValidity();
  }


  fetchFromAPI(viewCode: string, search: string, actionStatus: string) {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    const requestBody = {
      viewCode: viewCode,
      countryCode: this.country[0].countryCode,
      forTenantCode: this.configService.getForTenantCode(),
      searchText: search,
      actionStatus: actionStatus,
      deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED
    }

    const attributeCodes = ['active_vehicle_id', 'default_vehicle_id'];
    if (this.field.attributeCode === 'checkin_location_id') {
      this.moduleName = AppSettings.ENTITY_CODE.LOCATION
    }
    if (this.field.attributeCode === 'checkin_location_id') {
      this.moduleName = AppSettings.ENTITY_CODE.LOCATION
    }
    if (attributeCodes.includes(this.field.attributeCode)) {
      this.moduleName = AppSettings.ENTITY_CODE.VEHICLE
    }
    if (this.field.attributeCode === 'driver') {
      this.moduleName = AppSettings.ENTITY_CODE.DRIVER
    }

    this.entityService.autoComplete(AppSettings.ENTITY_CODE[this.moduleName.toUpperCase()], requestBody).subscribe(res => {
      this.items = this.cs.getTableData(res) || [];
      this.setFullNameForEachEntity();
    });
  }

  setFullNameForEachEntity() {
    this.items.forEach(element => {
      element.fullName = element.first_name + ' ' + element.last_name;
      if(element.id === this.formName.controls['reported_by'].value) {
        this.selectedItems = element.fullName;
      }
    });
  }

  handleUnselect(event: any) {
    const unselectedItem = event.value;
    this.selectedItems = this.selectedItems.filter(item => item !== unselectedItem);
    this.entityIds = this.entityIds.filter(item => item !== unselectedItem.entityId);
    this.formName.controls[this.field.attributeCode].setValue(this.entityIds);
    this.formName.controls[this.field.attributeCode].updateValueAndValidity();
  }

  onSelectSingleItem(event) {
    this.entityIds = event.value.id;
    this.formName.controls[this.field.attributeCode].setValue(this.entityIds);
    this.formName.controls[this.field.attributeCode].updateValueAndValidity();
  }
  
  handleSingleUnselect() {
    this.entityIds = [];
    this.formName.controls[this.field.attributeCode].setValue(null);
    this.formName.controls[this.field.attributeCode].updateValueAndValidity();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.clearSubscription) {
      this.clearSubscription.unsubscribe();
    }
  }

}

