import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { AppSettings } from '../../../app.settings';
import { CommonBindingDataService } from '../../../services/common-binding-data.service';
import { MetaDataService } from 'app/modules/shared/services/meta-data.service';

@Component({
  selector: 'app-date-time-selector-custom',
  standalone: true,
  imports: [DropdownModule, CalendarModule, ReactiveFormsModule, FormsModule, OverlayPanelModule, DialogModule, TranslateModule],
  templateUrl: './date-time-selector-custom.component.html',
  styleUrl: './date-time-selector-custom.component.scss',
  providers: [DatePipe, FormsModule]
})
export class DateTimeSelectorCustomComponent implements OnInit {
  displayCalendar: boolean = false;
  @Input() field: any;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  formName: FormGroup;
  placeholder: string;
  dropdownList: any = [];
  selectedDate: Date;
  showCalendar: boolean = false;  
  hourFormat = this.metaDataService.timeFormat;

  constructor(private formGroupDirective: FormGroupDirective, private metaDataService: MetaDataService,
    public cs: CommonBindingDataService, private datePipe: DatePipe) {
    this.formName = this.formGroupDirective.control;
  }

  ngOnInit() {
    const currentDateInTimezone = this.metaDataService.getCurrentTimeInTimezone();
    this.formName.controls[this.field?.isOneToMultiple ? this.field?.attributeCodeOneToMultiple : this.field.attributeCode].setValue(currentDateInTimezone);
    this.selectedDate = new Date(this.formName.controls[this.field?.isOneToMultiple ? this.field?.attributeCodeOneToMultiple : this.field.attributeCode].value);
    this.placeholder = this.cs.getLabelValue(this.moduleName + '.fields.' + this.field.attributeCode + '.placeholder', this.attributeLabels, null);
    this.dropdownList = [{ label: AppSettings.BROADCAST_GROUP.SELECT_TIME_OPTIONS.SEND_NOW, value: Date.now() },
    { label: AppSettings.BROADCAST_GROUP.SELECT_TIME_OPTIONS.PICK_DATE_AND_TIME, value: this.selectedDate?.getTime() }];
  }

  onDropdownChange(event) {
    if (event.originalEvent.target.innerText === AppSettings.BROADCAST_GROUP.SELECT_TIME_OPTIONS.PICK_DATE_AND_TIME) {
      this.showCalendar = true;
      this.showCalendarDialog();
    } else {
      this.showCalendar = false;
    }
  }

  showCalendarDialog() {
    this.displayCalendar = true;
  }

  onDateSelect(event) {
    this.dropdownList[1].value = event?.getTime();
    this.selectedDate = event?.getTime();
    this.formName.controls[this.field?.isOneToMultiple ? this.field?.attributeCodeOneToMultiple : this.field.attributeCode].patchValue(event?.getTime());
    this.displayCalendar = false;
  }

}
