import { Component, Input, ViewChild } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AppSettings } from 'app/modules/shared/app.settings';
import type { Country } from 'app/modules/shared/models/country';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { EntityService } from 'app/modules/shared/services/entity.service';
import type { ListResponse } from 'app/modules/vehicles/models/listResponse.models';
import type { EntityList } from 'app/modules/vehicles/models/entity.models';
import * as _ from 'lodash';
import { AutoCompleteCompleteEvent, AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { MultiSelect, MultiSelectModule } from 'primeng/multiselect';
import { CommonBindingDataService } from '../../../services/common-binding-data.service';
import { MetaDataService } from 'app/modules/shared/services/meta-data.service';

@Component({
  selector: 'app-mi-quick-filter-multi-select',
  standalone: true,
  imports: [AutoCompleteModule, FormsModule, ReactiveFormsModule, MultiSelectModule, TranslateModule, CalendarModule],
  templateUrl: './mi-quick-filter-multi-select.component.html',
  styleUrl: './mi-quick-filter-multi-select.component.scss'
})
export class MiQuickFilterMultiSelectComponent {
  @ViewChild('multiSelect') multiSelect!: MultiSelect;
  @Input() field: any;
  formName: FormGroup;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  @Input() label: string;
  selectedValue!: any[];
  selectAll: boolean = false;
  isFilter: boolean = true;
  placeholder: any;
  filterIcon = 'pi pi-search';
  filterText;
  country: Country;
  items: any[] | undefined;

  entityData: EntityList = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT_FOR_SETTINGS,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
    forTenantCode: this.confService.getLoggedInTenantCode(),
    actionStatus: ''
  };
  constructor(private formGroupDirective: FormGroupDirective,
    public cs: CommonBindingDataService,
    private configService: ConfigService,
    private entityService: EntityService,
    private metaDataService: MetaDataService,
    private confService: ConfigService) {
    this.formName = this.formGroupDirective.control;
  }

  ngOnInit(): void {
    if (this.field?.attributeCode === 'driver_group_id') {
      this.searchEntity();
    }

    if (this.field.attributeCode === 'DriverType' && this.moduleName === 'shift_list') {
      this.label = this.cs.getLabel('shift.driverType');
    }

    this.placeholder = this.cs.getLabelValue(this.moduleName + '.fields.' + this.field.attributeCode + '.placeholder', this.attributeLabels, this.field.attributeCode);
  }

  searchEntity() {

    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.entityData.countryCode = this.country[0].countryCode;
    this.entityService.searchEntity(AppSettings.ENTITY_CODE.DRIVER_GROUP, this.entityData).subscribe((res: ListResponse) => {

      this.items = this.cs.getTableData(res);
      this.field.presetValues = _.map(this.items, (item) => ({
        labelKey: item.group_name,
        labelValue: item.id
      }));
    })
  }

  changedValue(event) {
    const { value } = event;
    this.formName.controls[this.field.attributeCode].setValue(value);
    this.formName.controls[this.field.attributeCode].updateValueAndValidity();
    // if (value) this.selectAll = value.length === this.field.presetValues.length;
  }


  onSelectAllChange(event) {
    this.selectedValue = event.checked ? [...this.field.presetValues.map(value => value.labelValue)] : [];
    this.formName.controls[this.field.attributeCode].setValue(this.selectedValue);
    this.selectAll = event.checked;
  }

  onFilter(event) {
    if (event.filter) {
      this.filterIcon = 'pi pi-times'
    } else {
      this.filterIcon = 'pi pi-search';
    }
  }

  onFilterIconClick() {
    if (this.filterIcon.includes('times')) {
      this.multiSelect.filterValue = null;
      this.filterIcon = 'pi pi-search';
    }
  }

  clearSearchField(multiSelect: any) {
    multiSelect.filterValue = null;
  }

  search(event: AutoCompleteCompleteEvent) {
    const viewCode = this.field.attributeCode.toUpperCase() + '_' + 'AUTOCOMPLETE_VIEW'
    this.fetchFromAPI(viewCode, event.query);
  }

  fetchFromAPI(viewCode: string, search: string) {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    const requestBody = {
      viewCode: viewCode,
      countryCode: this.country[0].countryCode,
      forTenantCode: this.configService.getForTenantCode(),
      searchText: search,
      actionStatus: "",
      deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED
    }

    if (this.field.attributeCode === 'driver_group_id') {
      this.moduleName = AppSettings.ENTITY_CODE.DRIVER_GROUP
    }
    this.entityService.autoComplete(AppSettings.ENTITY_CODE[this.moduleName.toUpperCase()], requestBody).subscribe(res => {
      this.items = this.cs.getTableData(res) || [];
    });
  }
}
