import { ChangeDetectorRef, Component } from '@angular/core';
import { FormGroup, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AppSettings } from 'app/modules/shared/app.settings';
import { Country } from 'app/modules/shared/models/country';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { AddEditBreakComponent } from 'app/modules/shift-management/components/add-edit-break/add-edit-break.component';
import { EntityService } from 'app/modules/shared/services/entity.service';
import { MenuItem, MessageService } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ScrollerModule } from 'primeng/scroller';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TabViewModule } from 'primeng/tabview';
import { ShiftService } from 'app/modules/shift-management/services/shift.service';
import { DatePipe } from '@angular/common';
import dayjs from 'dayjs';
import { ShiftTemplateService } from 'app/modules/shift-template/services/shift-template.service';
import { MetaDataService } from 'app/modules/shared/services/meta-data.service';

interface Shift {
  name: string;
  code: string;
}

@Component({
  selector: 'app-assign-driver-to-shift',
  standalone: true,
  imports: [
    ButtonModule, TranslateModule, TabViewModule, BreadcrumbModule, DropdownModule, CalendarModule, SelectButtonModule,
    RadioButtonModule, AddEditBreakComponent, ScrollPanelModule, ScrollerModule, InputTextModule, CheckboxModule,
    FormsModule, ReactiveFormsModule, RadioButtonModule, DatePipe
  ],
  templateUrl: './assign-driver-to-shift.component.html',
  styleUrl: './assign-driver-to-shift.component.scss',
  providers: [DatePipe]
})
export class AssignDriverToShiftComponent {
  routePath: MenuItem[] = [];
  individualDriverList: any = [];
  driverGroupList: any = [];

  shifts: Shift[] | undefined;
  dateFormat = this.metaDataService.lowerDateFormat;
  hourFormat = this.metaDataService.timeFormat;

  selectedShift: Shift | undefined;
  dayOptions = AppSettings.DAYS_COLLECTION;
  shiftType: any = [];
  selectedShiftType: any = AppSettings.SHIFT_TYPE.SHIFT_TEMPLATE;
  selectedAssignmentType: any;
  assignmentType: any = [];
  placeholder: any;
  DRIVER_ASSIGNMENT_TYPE = AppSettings.DRIVER_ASSIGNMENT_TYPE;
  addShiftForm: FormGroup;
  activeIndex = 0;
  SHIFT_TYPE = AppSettings.SHIFT_TYPE;
  selectedDrivers: any[] = [];
  selectedGroups: any[] = [];
  startDate: Date;
  endDate: Date;
  startTime: Date;
  endTime: Date;
  selectShiftPlaceholder: any;
  disableShiftTimeFields: boolean = false;
  shiftTemplateBreakList = [];
  showBreak: boolean = true;
  country: Country;
  shiftId: any;
  shiftDetails: any;
  DRIVER_TYPE = AppSettings.DRIVER_TYPE;
  isEdit: boolean = true;
  shiftTemplates: any[] | undefined;
  entityData: any = {
    limit: 2000,
    offset: 0,
    searchStr: "",
    defaultSortColumn: 'updatedAt',
    defaultSortType: 'desc',
    forTenantCode: '',
  };

  driverEntityData: any = {
    limit: 3000,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted: 'ONLY_NON_DELETED',
    forTenantCode: '',
    actionStatus: ''
  };

  entityDataForDriverGroup: any = {
    limit: 2000,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted: 'ONLY_NON_DELETED',
    forTenantCode: '',
    actionStatus: ''
  };

  constructor(private entityService: EntityService,
    public cs: CommonBindingDataService,
    private router: Router,
    private formBuilder: FormBuilder,
    private configService: ConfigService,
    private cd: ChangeDetectorRef,
    private shiftService: ShiftService,
    private shiftTemplateService: ShiftTemplateService,
    private messageService: MessageService,
    private metaDataService: MetaDataService,
    private route: ActivatedRoute) { }


  ngOnInit() {
    this.shiftId = this.route.snapshot.paramMap.get('id');
    if (this.shiftId) {
      this.getShiftDetails();
    }
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.entityData.forTenantCode = this.driverEntityData.forTenantCode = this.entityDataForDriverGroup.forTenantCode = this.configService.getForTenantCode();
    this.driverEntityData.countryCode = this.entityDataForDriverGroup.countryCode = this.country[0].countryCode;
    this.initializeForm();
    this.initializeVariables();
    this.setRoutePath();
    this.getShiftTemplateList();
    this.getIndividualDriverList();
    this.getDriverGroupList();
  }

  getShiftTemplateList() {
    this.shiftTemplateService.searchShiftTemplateList(this.entityData).subscribe((res: any) => {
      this.shiftTemplates = res.data;
    });
  }

  getShiftDetails() {
    this.shiftService.getShiftDetails(this.shiftId).subscribe((result: any) => {
      this.shiftDetails = result;
      this.selectedShiftType = result.shiftTemplateId ? this.SHIFT_TYPE.SHIFT_TEMPLATE : this.SHIFT_TYPE.MANUALLY;
    })
  }

  initializeVariables() {
    this.selectedAssignmentType = this.DRIVER_ASSIGNMENT_TYPE.INDIVIDUAL_DRIVER;
    this.shiftType = AppSettings.SHIFT_TYPE_OPTIONS;
    this.assignmentType = AppSettings.ASSIGNMENT_TYPE_OPTIONS;
    this.placeholder = this.cs.getLabel('shift.search_driver');
    this.selectShiftPlaceholder = this.cs.getLabel('shift.lbl_select_shift');
  }

  initializeForm() {
    this.addShiftForm = this.formBuilder.group({
      assignmentType: [],
      drivers: [],
      groups: [],
    });
  }


  setRoutePath() {
    this.routePath = [
      {
        label: this.cs.getLabel('shift.assign_drivers_to_shift'),
        routerLink: '/app/manage-shift',
        icon: 'pi pi-arrow-left',
        iconStyle: { 'font-weight': 'bold', 'margin-right': '10px' }
      }
    ];
  }

  getIndividualDriverList() {
    this.entityService
      .searchEntity(AppSettings.ENTITY_CODE.DRIVER, this.driverEntityData)
      .subscribe((res: any) => {
        this.individualDriverList = res.data;
      });
  }


  getDriverGroupList() {
    this.entityService
      .searchEntity(AppSettings.ENTITY_CODE.DRIVER_GROUP, this.entityDataForDriverGroup)
      .subscribe((res: any) => {
        this.driverGroupList = res.data;
      });
  }

  onShiftChange(event) {
    this.addShiftForm.controls['startTime'].setValue(new Date(event.value.startTime));
    this.addShiftForm.controls['endTime'].setValue(new Date(event.value.endTime));
    this.disableShiftTimeFields = true;
    this.shiftTemplateBreakList = event.value.shiftTemplateBreakList;
    this.shiftTemplateBreakList.forEach(element => {
      element.reason = 'Rest Break';
    });
    this.showBreak = false;
    this.cd.detectChanges();
    this.showBreak = true;
  }

  shiftTypeChange(event) {
    this.selectedShiftType = event.key;
    this.disableShiftTimeFields = this.selectedShiftType === this.SHIFT_TYPE.SHIFT_TEMPLATE;
    this.addShiftForm.controls['name'].setValue(null);
    this.shiftTemplateBreakList = [];
    this.showBreak = false;
    this.cd.detectChanges();
    this.showBreak = true;
  }

  assignmentTypeChange(event) {
    this.selectedAssignmentType = event.key
  }

  edit() {
    this.router.navigate([`/app/manage-shift/edit-shift/${this.shiftId}`]);
  }

  onIndividualCheckboxChange(event, driver, options) {
    if (event.checked) {
      this.selectedDrivers.push(driver.id);
    } else {
      const index = this.selectedDrivers.findIndex(ele => ele === driver.id);
      if (index >= 0) {
        this.selectedDrivers.splice(index, 1);
      }
    }
  }

  onGroupCheckboxChange(event, driverGroup) {
    if (event.checked) {
      this.selectedGroups.push(driverGroup.id);
    } else {
      const index = this.selectedGroups.findIndex(ele => ele === driverGroup.id);
      if (index >= 0) {
        this.selectedGroups.splice(index, 1);
      }
    }
  }

  onSaveShift() {
    if (!this.isFormValid()) return;
    const requestBody = this.buildRequestBody();
    this.editShift(requestBody);

  }

  isFormValid() {
    if (!this.addShiftForm.valid) {
      this.addShiftForm.markAllAsTouched();
      return false;
    }
    return true;
  }

  private buildRequestBody() {
    const { shiftDetails, selectedShiftType, configService, country } = this;
    const baseRequestBody = {
      tenantCode: configService.getForTenantCode(),
      countryCode: country[0].countryCode,
      startDateStr: shiftDetails.startDateStr,
      endDateStr: shiftDetails.endDateStr,
      breakList: this.getBreakList(),
      weekdaysList: shiftDetails.weekdaysList,
      assignedEntity: shiftDetails.assignedEntity,
      shiftId: this.shiftId,
    };

    if (selectedShiftType === AppSettings.SHIFT_TYPE.SHIFT_TEMPLATE) {
      return {
        ...baseRequestBody,
        shiftTemplateId: shiftDetails.shiftTemplateId,
        shiftDriverList: this.getShiftDriverList(),
        shiftGroupList: this.getShiftGroupList(),
      };
    } else {
      return {
        ...baseRequestBody,
        startTimeStr: shiftDetails.startTimeStr,
        endTimeStr: shiftDetails.endTimeStr,
        shiftName: shiftDetails.shiftName,
        shiftDriverList: this.selectedDrivers,
        shiftGroupList: this.selectedGroups,
      };
    }
  }

  private getBreakList() {
    if (this.addShiftForm.controls['assignmentType'].value === AppSettings.SHIFT_TYPE.SHIFT_TEMPLATE) {
      return this.shiftDetails.shiftBreakList.map(ele => {
        return {
          reason: ele?.reason,
          shiftTemplateBreakId: ele.shiftTemplateBreakId
        }
      })
    } else {
      return this.shiftDetails.shiftBreakList.map(ele => {
        return {
          startTimeStr: ele.breakStartTime,
          endTimeStr: ele.breakEndTime,
          reason: ele?.reason,
          shiftBreakId: ele.shiftBreakId
        }
      })
    }
  }

  private getShiftDriverList() {
    return this.addShiftForm.value.assignmentType === this.DRIVER_ASSIGNMENT_TYPE.INDIVIDUAL_DRIVER
      ? this.selectedDrivers
      : [];
  }

  private getShiftGroupList() {
    return this.addShiftForm.value.assignmentType === this.DRIVER_ASSIGNMENT_TYPE.DRIVER_GROUP
      ? this.selectedGroups
      : [];
  }

  editShift(requestBody) {
    this.shiftService.updateShift(requestBody, this.shiftId).subscribe({
      next: (result) => {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Successful', detail: this.cs.getLabel('shift.shift_updated_successfully'), });
        this.router.navigate(['app/manage-shift']);
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
      }
    });
  }

  onCancel() {
    this.router.navigate(['/app/manage-shift']);
  }
}
