import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GroupColorComponent } from 'app/modules/manage-groups/components/group-color/group-color.component';
import { ManageZonesService } from 'app/modules/manage-zones/services/manage-zones.service';
import { EntityService } from 'app/modules/shared/services/entity.service';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelect, MultiSelectModule } from 'primeng/multiselect';
import { distinctUntilChanged, filter, takeWhile } from 'rxjs/operators';
import { AppSettings } from '../../../app.settings';
import { CommonBindingDataService } from '../../../services/common-binding-data.service';
import { MessageService } from '../../../services/message.service';
import { ListResponse } from 'app/modules/vehicles/models/listResponse.models';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { EventService } from 'app/modules/events/services/event.service';
import { SettingsService } from 'app/modules/settings/settings.service';
import { Country } from 'app/modules/shared/models/country';

@Component({
  selector: 'app-mi-select',
  templateUrl: './mi-select.component.html',
  styleUrls: ['./mi-select.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, DropdownModule, MultiSelectModule, GroupColorComponent, CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MiSelectComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild('miSelect') miSelect!: MultiSelect;
  @Input() field: any;
  formName: FormGroup;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  @Input() multiSelectDisplay: string = 'comma';
  alive = true;
  placeholder: any;
  filterIcon = 'pi pi-search';
  entityData: any = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT_FOR_SETTINGS,
    offset: 0,
    searchStr: "",
    defaultSortColumn: 'updatedAt',
    defaultSortType: 'desc',
    forTenantCode: this.configService.getForTenantCode(),
    attributeCode: AppSettings.ATTRIBUTE_CODE_FOR_SETTINGS.COLOR
  };
  country: Country;
  locationAttributeData;
  attributeIdForLocation;
  data;
  pickupPresetValues;
  dropPresetValues;
  pickLocationValues: any[] = [];
  dropLocationValues;
  bodyTypePayload: any = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT_FOR_SETTINGS,
    offset: 0,
    searchStr: "",
    defaultSortColumn: 'updatedAt',
    defaultSortType: 'desc',
    forTenantCode: this.configService.getForTenantCode(),
    attributeCode: 'body_type'
  };

  constructor(
    private messageService: MessageService,
    private formGroupDirective: FormGroupDirective,
    public cs: CommonBindingDataService,
    private entityService: EntityService,
    private eventService: EventService,
    private settingsService: SettingsService,
    private manageZonesService: ManageZonesService, private configService: ConfigService, private cd: ChangeDetectorRef) {
    this.formName = this.formGroupDirective.control;
  }

  ngOnInit() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.placeholder = this.cs.getLabelValue(this.moduleName + '.fields.' + this.field.attributeCode + '.placeholder', this.attributeLabels, null);
    const PASS_PRIVILEGE_CONF = AppSettings.ENTITY_CODE.PASS_PRIVILEGE_CONF;
    const PASSENGER = AppSettings.ENTITY_CODE.PASSENGER;
    const DISPATCH_ZONE = 'dispatch_zone';
    const REGION_GEOFENCE = 'region_geofence';
    const GROUP_COLOR = 'group_color';
    const VEHICLE_BODY_TYPE_PREFERENCE = 'vehicle_body_type_preference';
    const TRANSPORTATION_TYPE = 'transportation_type';
    const VEHICLE_TYPE = 'vehicle_type';
    const VEHICLE_BODY_TYPE = 'vehicle_body_type';

    switch (this.field.attributeCode) {
      case DISPATCH_ZONE:
      case REGION_GEOFENCE:
        this.getZoneList();
        break;
      case GROUP_COLOR:
        this.getColorList();
        break;
      case VEHICLE_BODY_TYPE_PREFERENCE:
        this.getBodyTypes();
        break;
      case TRANSPORTATION_TYPE:
        if (this.moduleName === PASS_PRIVILEGE_CONF || this.moduleName === PASSENGER) {
          this.getTransportationTypes();
        }
        break;
      case VEHICLE_TYPE:
        if (this.moduleName === PASS_PRIVILEGE_CONF || this.moduleName === PASSENGER) {
          this.getVehicleTypes();
        }
        break;
      case VEHICLE_BODY_TYPE:
        if (this.moduleName === PASS_PRIVILEGE_CONF || this.moduleName === PASSENGER) {
          this.getVehicleBodyTypes();
        }
        break;
      default:
      break;
    }
  }


  ngAfterViewInit(): void {
    this.listenForLinkData();
  }

  ngOnDestroy() {
    this.alive = false;
  }

  listenForLinkData() {
    if (!this.field?.link) {
      return;
    }
    this.messageService.message$.pipe(
      filter(v => v.link === this.field.link),
      takeWhile(() => this.alive)
    ).subscribe((v) => {
      this.field.options = v.data
    })
  }

  changedValue(value: string) {
    if (!this.field.provideData) {
      return;
    }
    this.messageService.messageSubject.next({
      link: this.field.attributeCode,
      data: this.field.provideData.filter(v => v.sourceValue === value)
    })
  }

  changedSelectedValue(value) {
    this.cs.sendDataToEntitySelect(value);
  }

  changedpMultiValue(value) {
    if (this.field.attributeCode === AppSettings.PASSENGER_PRIVILEGE_SETTINGS_SHOW_LIST_OF_PREDEFINED_LOCATIONS_ATTRIBUTE_CODE) {
      this.formName.controls[this.field?.isOneToMultiple ? this.field?.attributeCodeOneToMultiple : AppSettings.PASSENGER_PRIVILEGE_SETTINGS_SELECTED_LOCATIONS_ATTRIBUTE_CODE].setValue(value.join(', '));
    }
  }

  onFilter(event) {
    if (event.filter) {
      this.filterIcon = 'pi pi-times'
    } else {
      this.filterIcon = 'pi pi-search';
    }
  }

  onFilterIconClick() {
    if (this.filterIcon.includes('times')) {
      this.miSelect.filterValue = null;
      this.filterIcon = 'pi pi-search';
    }
  }

  getZoneList() {
    const ZoneItemPagination = {
      limit: 100,
      offset: 0,
      searchStr: "",
      defaultSortColumn: "updatedAt",
      defaultSortType: "desc",
    };
    this.manageZonesService.getZonesList(ZoneItemPagination).subscribe((zoneData) => {
      const zonesName = zoneData.data.map(res => ({
        labelKey: `${res.zoneName} (${res.aliasName})`,
        labelValue: `${res.zoneName} (${res.aliasName})`
      }));
      this.field.presetValues = zonesName;
    });
  }

  getColorList() {
    this.entityService.searchAttributeSettings(AppSettings.ENTITY_TYPE.DRIVER_GROUP, this.entityData).subscribe((res: ListResponse) => {
      this.field.presetValues = this.cs.getTableDataForSettings(res);
    })
  }

  getBodyTypes() {
    this.entityService.searchAttributeSettings(AppSettings.ENTITY_TYPE.VEHICLE, this.bodyTypePayload).subscribe((res: ListResponse) => {
      this.field.presetValues = this.cs.getTableDataForSettings(res);
      this.cd.detectChanges();
    })
  }

  getTransportationTypes() {
    const eventId = this.eventService.getSelectedEventId();
    if (!eventId) {
      console.log('eventId not found');
      return;
    }

    this.eventService.getTransportationTypes(eventId).pipe(distinctUntilChanged()).subscribe(result => {
      if (!Array.isArray(result)) return;
      this.field.presetValues = result.filter(item => item.transportationType !== AppSettings.TRANSPORTATION_MODE.VEHICLE_TRANSFER).map((item) => ({
        labelValue: item.transportationType,
      }));
    });
  }

  getVehicleTypes() {
    const entityData: any = {
      limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT_FOR_SETTINGS,
      offset: 0,
      searchStr: "",
      defaultSortColumn: 'updatedAt',
      defaultSortType: 'desc',
      forTenantCode: this.configService.getForTenantCode(),
      attributeCode: AppSettings.ATTRIBUTE_CODE_FOR_SETTINGS.VEHICLE_TYPE
    };
    this.settingsService.searchAttributeSettings(AppSettings.ENTITY_TYPE.VEHICLE, entityData).subscribe((res: ListResponse) => {
      this.field.presetValues = this.cs.getTableDataForSettings(res);
    })
  }

  changedMultiValue() {
  }

  getVehicleBodyTypes() {
    const entityData: any = {
      limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT_FOR_SETTINGS,
      offset: 0,
      searchStr: "",
      defaultSortColumn: 'updatedAt',
      defaultSortType: 'desc',
      forTenantCode: this.configService.getForTenantCode(),
      attributeCode: AppSettings.ATTRIBUTE_CODE_FOR_SETTINGS.BODY_TYPE
    };
    this.settingsService.searchAttributeSettings(AppSettings.ENTITY_TYPE.VEHICLE, entityData).subscribe((res: ListResponse) => {
      this.field.presetValues = this.cs.getTableDataForSettings(res);
    })
  }

}

