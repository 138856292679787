<p-breadcrumb [model]="routePath"></p-breadcrumb>
<div appAccessProvider [accessCode]="{ menuCode: 'ORGANIZATION', pageCode: 'ALL', subPageCode: 'VIEW' }">
    <div class="grid mt-1 details-div">
        <div class="col-9 md:col-9 details-header-spacing">
            @if (showStatus) {
                <status [dataForStatus]="dataForStatus" [entityForStatus]="entityForStatus" [imgFileId]="imgFileId" [imgFileUrl]="imgFileUrl"></status>
            }
        </div>
        <div appAccessProvider [accessCode]="{ menuCode: 'ORGANIZATION', pageCode: 'ALL', subPageCode: 'EDIT' }" class="details-header-space text-right flex align-items-center justify-content-end">
                <button (click)="edit(0, 'org-details')" pButton class="edit-btn border-400 bg-white text-color mr-1 pl-2 pr-2 pt-1 pb-1 mr-4 hover:surface-100" [label]="'organization.lbl_edit' | translate">
                    <i class="mi-lg mr-2 edit-pen edit_pencil"></i>
                </button>
        </div>
    </div>

    <p-tabView [(activeIndex)]="activeIndex">
        <p-tabPanel>
            <ng-template pTemplate="header">
                <div class="tab-icons pointer">
                <i class="mi-lg details mr-2"></i>
                <span translate>organization.overview</span>
                </div>
            </ng-template>
            <div class="grid mt-2 ml-3 mr-2 overview-details">
                <div class="col-6 md:col-6 lg:col-6 pl-0">
                    <div class="card p-fluid">
                        <div class="flex justify-content-between flex-wrap">
                            <div class="flex justify-content-center mb-3">
                                <label class="card-heading uppercase" translate>organization.lbl_company_details</label>
                            </div>
                            <div appAccessProvider [accessCode]="{ menuCode: 'ORGANIZATION', pageCode: 'ALL', subPageCode: 'EDIT' }">
                                <div (click)="edit(0, 'org-details')" class="edit flex justify-content-center mb-3">
                                    <label class="card-action" translate>organization.lbl_edit</label>
                                    <i class="mi-lg edit_pencil"></i>
                                </div>
                            </div>
                        </div>

                        <div class="grid">
                            <div class="col-6 pt-1">
                                <label class="detail-heading" translate>Status</label>
                            </div>
                            <div class="col-6 pb-1">
                                <span [class]="tenantDetails?.active ? 'active' : 'inactive'" ></span>
                                <label class="detail-value">{{ tenantDetails?.active ? ('lbl_active' | translate) : ('lbl_inactive' | translate) }}</label>
                            </div>
                        </div>

                        <hr class="hr-line">
                    
                        <div class="grid">
                            <div class="col-6 pt-1">
                                <label class="detail-heading" translate>organization.lbl_company_name</label>
                            </div>
                            <div class="col-6 pb-1">
                                <label class="detail-value">{{tenantDetails?.tenantName}}</label>
                            </div>
                        </div>
                        <hr class="hr-line">
                        <div class="grid">
                            <div class="col-6 pt-1">
                                <label class="detail-heading" translate>organization.lbl_code</label>
                            </div>
                            <div class="col-6 pb-1">
                                <label class="detail-value">{{tenantDetails?.tenantCode}}</label>
                            </div>
                        </div>
                        <hr class="hr-line">

                        <div class="grid">
                            <div class="col-6 pt-1">
                                <label class="detail-heading" translate>organization.lbl_email_address</label>
                            </div>
                            <div class="col-6 pb-1">
                                <a [href]="'mailto:' + tenantDetails?.email" class="email-link"> {{tenantDetails?.email ? tenantDetails?.email : '--'}}</a>
                            </div>
                        </div>
                        <hr class="hr-line">
                        <div class="grid">
                            <div class="col-6 pt-1">
                                <label class="detail-heading" translate>organization.lbl_contact_number</label>
                            </div>
                            @if (tenantContactNumber) {
                                <div class="col-6 pb-1">
                                    <app-mi-mobile-value [countryCodeForMobileNumber]="countryCodeForMobileNumber" [mobileNumber]="tenantContactNumber"></app-mi-mobile-value>
                                </div>
                            }
                        </div>

                        <hr class="hr-line">
                        <div class="grid">
                            <div class="col-6 pt-1">
                                <label class="detail-heading"
                                    translate>organization.lbl_logo</label>
                            </div>
                            <div class="col-6 pb-1 organization-logo">
                                @if (logoImage) {
                                <app-mi-image-container [miImage]="logoImage" miWidth="100" class="border-none barcode-image-preview"></app-mi-image-container>
                                } @else {
                                <span>{{ 'lbl_empty' | translate }}</span>
                                }

                            </div>
                        </div>
                    </div>
                    <div class="card p-fluid">
                        <div class="flex justify-content-between flex-wrap">
                            <div class="flex justify-content-center mb-3">
                                <label class="card-heading uppercase" translate>organization.lbl_details_settings</label>
                            </div>
                            <div (click)="edit(0, 'settings')" class="edit flex justify-content-center mb-3">
                                <label class="card-action" translate>organization.lbl_edit</label>
                                <i class="mi-lg edit_pencil"></i>
                            </div>  
                        </div>
                        
                        <div class="grid">
                            <div class="col-6 pt-1">
                                <label class="detail-heading" translate>organization.lbl_shuttle_bookings</label>
                            </div>
                            <div class="col-6 pb-1">
                                <label class="detail-value">{{tenantDetails?.shuttleBookings ? 'Yes' : 'No' }}</label>
                            </div>
                        </div>
                        <hr class="hr-line">
                        <div class="grid">
                            <div class="col-6 pt-1">
                                <label class="detail-heading" translate>organization.lbl_vehicle_auto_dispatch</label>
                            </div>
                            <div class="col-6 pb-1">
                                <label class="detail-value">{{tenantDetails?.vehicleAutoDispatch ? 'Yes' : 'No' }}</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-6 md:col-6 lg:col-6">
                    <div class="card p-fluid">
                        <div class="flex justify-content-between flex-wrap">
                            <div class="flex justify-content-center mb-3">
                                <label class="card-heading uppercase" translate>organization.user_details</label>
                            </div>
                            <div appAccessProvider [accessCode]="{ menuCode: 'ORGANIZATION', pageCode: 'ALL', subPageCode: 'EDIT' }">
                                <div (click)="edit(0, 'admin-details')" class="edit flex justify-content-center mb-3">
                                    <label class="card-action" translate>organization.lbl_edit</label>
                                    <i class="mi-lg edit_pencil"></i>
                                </div>
                            </div>
                        </div>

                        <div class="grid">
                            <div class="col-6 pt-1">
                                <label class="detail-heading" translate>organization.lbl_name</label>
                            </div>
                            <div class="col-6 pb-1">
                                <label class="detail-value">{{userDetails?.firstName ?  userDetails?.firstName + ' ' + userDetails?.lastName : '--'}}</label>
                            </div>
                        </div>
                        <hr class="hr-line">

                        <div class="grid">
                            <div class="col-6 pt-1">
                                <label class="detail-heading" translate>organization.lbl_email_address</label>
                            </div>
                            <div class="col-6 pb-1">
                                <a [href]="'mailto:' + userDetails?.email" class="email-link"> {{userDetails?.email ? userDetails?.email : '--'}}</a>
                            </div>
                        </div>
                        <hr class="hr-line">

                        <div class="grid">
                            <div class="col-6 pt-1">
                                <label class="detail-heading" translate>organization.lbl_contact_number</label>
                            </div>
                            @if (adminContactNumber) {
                                <div class="col-6 pb-1">
                                    <app-mi-mobile-value  [countryCodeForMobileNumber]="countryCodeForUserMobileNumber" [mobileNumber]="adminContactNumber"></app-mi-mobile-value>
                                </div>
                            }
                        </div>
                        <hr class="hr-line">

                        <div class="grid">
                            <div class="col-6 pt-1">
                                <label class="detail-heading" translate>organization.lbl_code</label>
                            </div>
                            <div class="col-6 pb-1">
                                <label class="detail-value">{{tenantDetails?.tenantCode}}</label>
                            </div>
                        </div>
                        <hr class="hr-line">

                        <div class="grid">
                            <div class="col-6 pt-1">
                                <label class="detail-heading" translate>organization.username</label>
                            </div>
                            <div class="col-6 pb-1">
                                <label class="detail-value">{{userDetails?.username ? userDetails?.username : '--'}}</label>
                            </div>
                        </div>
                        <hr class="hr-line">

                        
                    
                        <div class="grid">
                            <div class="col-6 pt-1">
                                <label class="detail-heading"
                                    translate>organization.password</label>
                            </div>
                            <div class="col-6 pb-1">
                                <button pButton [label]="buttonLabel" class="reset-password" (click)="btnClick()"></button>
                            </div>
                        </div>

                    </div>
                    <div class="card p-fluid">
                        <div class="flex justify-content-between flex-wrap">
                            <div class="flex justify-content-center mb-3">
                                <label class="card-heading uppercase" translate>organization.lbl_unique_identifications</label>
                            </div>
                            <div (click)="edit(0, 'unique-identity')" class="edit flex justify-content-center mb-3">
                                <label class="card-action" translate>organization.lbl_edit</label>
                                <i class="mi-lg edit_pencil"></i>
                            </div>  
                        </div>
                        <div class="grid">
                            <div class="col-6 pt-1">
                                <label class="detail-heading" translate>organization.lbl_unique_identifiers</label>
                            </div>
                            <div class="col-6 pb-1">
                                 @if(this.tenantDetails?.uniqueIdentificationDisplay){
                                    @for(tab of parsedUniqueIdentificationDisplay; track tab; let i = $index ){
                                        <label class="detail-value unique-value">{{ tab || '--'}}</label>
                                     }
                                 }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </p-tabPanel>
        <p-tabPanel>
            <ng-template pTemplate="header">
                <div class="tab-icons">
                <i class="mi-lg privilege_settings mr-2"></i>
                <span translate>organization.accessToModules</span>
                </div>
            </ng-template>
            <div class="grid mt-2 ml-3 mr-2 overview-details">
                <div class="col-6 pl-0">
                    <div class="card p-fluid">
                        <div class="flex justify-content-between flex-wrap">
                            <div class="flex justify-content-center mb-3">
                                <label class="card-heading uppercase" translate>organization.accessToModules</label>
                            </div>
                            @if (showEditButtonForAccessToModule) {
                                <div appAccessProvider [accessCode]="{ menuCode: 'ORGANIZATION', pageCode: 'ALL', subPageCode: 'EDIT' }">
                                    <div (click)="edit(1, 'access-modules')" class="edit flex justify-content-center mb-3">
                                        <label class="card-action" translate>organization.lbl_edit</label>
                                        <i class="mi-lg edit_pencil"></i>
                                    </div>
                                </div>
                            }

                        </div>
                        @for (module of moduleList; track module;let i = $index) {
                            <div class="grid">
                                <div class="col-6 pt-1">
                                    <label class="detail-heading" translate> {{ module.moduleName}}</label>
                                </div>
                                <div class="col-6 pb-1">
                                    <label class="detail-value">{{module?.isAccessible ? ('lbl_yes' | translate) : ('lbl_no' | translate)}}</label>
                                </div>
                            </div>
                            <hr class="hr-line">
                        }
                    </div>
                </div>

            </div>
        </p-tabPanel>
    </p-tabView>
</div>

<p-dialog [header]="header" [(visible)]="showPopup" [style]="{width:'40vw','max-width': '500px'}">
    <form [formGroup]="resetPasswordGroup">
        <div class="pr-3">
            <div class="pt-3">
                <label translate>organization.enter_new_password_for_organization</label>
            </div>
            <div class="pt-3">
                <label class="label" translate>organization.enter_password</label>   <span class="required ml-1">*</span>
            </div>
            <div class="pt-2 pb-3 justify-content-start">
                <p-password class="w-full"  formControlName="password" [toggleMask]="true" />
                @if (resetPasswordGroup.controls.password.errors && (resetPasswordGroup.controls.password.dirty || resetPasswordGroup.controls.password.touched)) {
                    <div class="form-errors">
                    <div [hidden]="!resetPasswordGroup.controls.password.errors.required" class="text-err" translate>
                        <i class="error_outline"></i>
                        organization.password_is_required
                    </div>
                    <div [hidden]="!resetPasswordGroup.controls.password.errors.pattern" class="text-err" translate>
                        <i class="error_outline"></i>
                        <label translate>organization.password_must_contain</label>
                        <label translate>organization.at_least_one_uppercase_letter</label>
                        <label translate>organization.at_least_one_lowercase_letter</label>
                        <label translate>organization.at_least_one_number</label>
                        <label translate>organization.at_least_one_special_character</label>
                        <label translate>organization.minimum_eight_character</label>
                    </div>
                </div>
                }
            </div>
        </div>
    </form>
    <div class="flex pr-3 pb-3 justify-content-end dialog-footer">
        <button pButton class="bg-white default-text-light-black-color cursor-pointer cancel-btn"
            [label]="'cancel' | translate" (click)="showPopup = false"></button>
        <button pButton class="border-round-sm reset-btn" type="submit" [label]="'lbl_reset' | translate"  (click)="resetPassword()"></button>
    </div>
</p-dialog>