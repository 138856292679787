<p-breadcrumb [model]="routePath"></p-breadcrumb>
<div appAccessProvider [accessCode]="{ menuCode: 'CONTENT_MANAGEMENT', subMenuCode: 'FAQS' }">
  <div class="flex justify-content-end flex-wrap btn-with-links">
    <button pButton class="mr-2 bg-white default-text-light-black-color border-button" [label]="'cancel' | translate"
      (click)="onCancel()"></button>
    <p-button class="flex align-items-center justify-content-center event-box" [label]="addBtnLabel"
      (click)="onAdd()"></p-button>
  </div>


  <div class="main-settings">
    <p-tabView [(activeIndex)]="activeTabIndex" (onChange)="onTabChange($event)">
      @for (tab of tabs; track tab; let i = $index) {
        <p-tabPanel>
          <ng-template pTemplate="header">
            <i class="mi-lg {{ tab.icon }}"></i>
            <span translate>{{ tab.label }}</span>
          </ng-template>
          <form [formGroup]="faqForm">
            <div class="card background-not">
              <div class="grid">
                <div class="font-semibold p-2">
                  <label class="flex font-18 uppercase" translate>content.lbl_add_new_faq
                  </label>
                </div>
                <div class="form-field  col-12 md:col-12 lg:col-12 field">
                  <label class="flex" for="question" translate>
                    content.lbl_add_question  <span class="required ml-1">*</span>
                  </label>
    
                  <div class="form-control">
                    <input id="question" formControlName="question"  [placeholder]="'content.placeholder_faq_question' | translate" appTrimSpaces  pInputText  class="p-component p-element w-full"/>
                  </div>
                    @if (faqForm.controls.question.errors && (faqForm.controls.question.dirty || faqForm.controls.question.touched)) {
                      <div class="form-errors mt-1 required-field">
                          <div [hidden]="!faqForm.controls.question.errors.required" translate>
                            content.faq_question_req_err
                          </div>
                      </div>
                    }
                </div>
                <div class="form-field  col-12 md:col-12 lg:col-12 field">
                  <label class="flex" for="answer" translate> content.lbl_add_answer  <span class="required ml-1">*</span></label>
    
                  <div class="form-control">
                    <textarea id="answer" formControlName="answer" [placeholder]="'content.placeholder_faq_answer' | translate" pInputTextarea rows="5" class="p-component p-element w-full"></textarea>
                  </div>
                  @if (faqForm.controls.answer.errors && (faqForm.controls.answer.dirty || faqForm.controls.answer.touched)) {
                    <div class="form-errors mt-1 required-field">
                        <div [hidden]="!faqForm.controls.answer.errors.required" translate>
                          content.faq_answer_req_err
                        </div>
                    </div>
                  }
                </div>
              </div>
            </div>
    
            <div class="flex justify-content-end mr-17 save-action-div">
              <button pButton class="mr-2  bg-color-gray default-text-light-black-color border-button"
                [label]="'cancel' | translate" (click)="onCancel()"></button>
              <p-button class=" flex align-items-center justify-content-center" [label]="addBtnLabel"
                (click)="addFaq()" type="submit"></p-button>
            </div>
    
            <div class="card background-not">
              <div class="grid">
    
                <div class="font-semibold px-2 py-3">
                  <label class="flex font-18 uppercase" translate>content.lbl_frequently_asked
                  </label>
                </div>
    
              
                  <p-accordion class="w-full">
                    @for (faq of faqs; track faq; let i = $index) {
                    <p-accordionTab expandIcon="pi pi-chevron-down" collapseIcon="pi pi-chevron-up" iconPos="right">
                      <ng-template pTemplate="header">
                        <div class="flex  justify-content-start align-items-center w-full">
                        <span class="p-ml-2 font-16 font-semibold ">{{ faq.question }}</span>
                        </div>
                        <div class="flex  justify-content-end align-items-center mr-4">
                          <button pButton icon="pi pi-pencil" class="p-button-text p-button-sm p-1 px-2 mr-1"
                            (click)="editFaq(i)" ></button>
                          <button pButton icon="pi pi-trash" class="p-button-text p-button-sm p-1 px-2"
                            (click)="deleteFaq(i); $event.stopPropagation()"></button>
                          
                        </div>
                      </ng-template>
    
                      <div class="p-fluid">
                        <div class="w-full">
                          <p>{{ faq.answer }}</p>
                        </div>
    
                      </div>
                    </p-accordionTab>
    
                    }
                  </p-accordion>
              </div>
            </div>
    
          </form>
    
        </p-tabPanel>
      }
    </p-tabView>
  </div>
</div>