import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NoResultFoundComponent } from 'app/modules/shared/components/no-result-found/no-result-found.component';
import type { Country } from 'app/modules/shared/models/country';
import type { Language } from 'app/modules/shared/models/language';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { EntityService } from 'app/modules/shared/services/entity.service';
import dayjs from 'dayjs';
import * as _ from 'lodash';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { AppIcons } from '../../../shared/app.icons';
import { AppSettings } from '../../../shared/app.settings';
import { ShiftService } from '../../services/shift.service';
import { MetaDataService } from 'app/modules/shared/services/meta-data.service';
import { addTimeToCurrentDate } from 'app/modules/shared/utility-functions/date';

@Component({
  selector: 'app-group-day-list',
  standalone: true,
  imports: [TranslateModule, DropdownModule, CheckboxModule, OverlayPanelModule, DatePipe, ButtonModule,
    FormsModule, ReactiveFormsModule, NoResultFoundComponent],
  templateUrl: './group-day-list.component.html',
  styleUrl: './group-day-list.component.scss'
})
export class GroupDayListComponent {
  @Input() listType: any = [];
  @Input() selectedListType: any = AppSettings.LIST_TYPE_OPTIONS[1].value;
  @Input() selectedDayTimeStamp: any = new Date().getTime();
  @Input() selectedDayDate: any = dayjs();
  @Input() searchValue: any = '';

  @Output() onNextPreviousClick: EventEmitter<any> = new EventEmitter();
  @Output() onListTypeChangeEvent: EventEmitter<any> = new EventEmitter();
  @Output() shiftPublishedUnpublishedList: EventEmitter<any> = new EventEmitter();

  hourFormat = this.metaDataService.timeFormat;
  publishedShiftList: any = [];
  unpublishedShiftList: any = [];
  isClearSelected: boolean = true;
  listTypeCopy: any = [];
  hourList: any = [];
  groupList: any = [];
  selectAll: boolean = true;
  miIconCaretDown = AppIcons.CARET_DOWN;
  dayNumber: number = 0;
  hoveredGroup: any;
  hoveredPublishedUnPublishedShift: any;
  publishedUnpublishedShiftGroup: any;
  currentDate: any = new Date();
  isAscendingData: boolean = true;
  country: Country;
  language: Language;
  defaultLoaderImg = AppSettings.DEFAULT_LOADER_IMAGE;
  currentTimeMarkerInitialHeight = 1241;

  driverDataPayloadData: any = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: '',
    // defaultSortColumn: "updatedAt",
    defaultSortType: "asc",
    startDateTime: 0,
    endDateTime: 0,
    startDateTimeStr: '',
    endDateTimeStr: ''
  };

  private maxDataReached = {
    entityDataForDriver: false,
  }

  constructor(
    public datePipe: DatePipe,
    private shiftService: ShiftService,
    private entityService: EntityService,
    private datepipe: DatePipe,
    private configService: ConfigService,
    private metaDataService: MetaDataService,
    private cd: ChangeDetectorRef) { }

  ngOnInit() {
    this.setHours();
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.listTypeCopy = _.cloneDeep(this.listType);
    setTimeout(() => {
      const cb = () => {
        this.driverDataPayloadData.offset = this.driverDataPayloadData.offset + (this.driverDataPayloadData.offset === 0 ? this.driverDataPayloadData.limit : AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT);
        if (!this.maxDataReached.entityDataForDriver) {
          this.getGroupList();
        }
      };
      this.applyInfiniteScroll(cb)
    }, 30);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.driverDataPayloadData.offset = 0;
    this.groupList = [];
    if (changes.searchValue) {
      this.searchValue = changes.searchValue.currentValue;
      this.driverDataPayloadData.searchStr = this.searchValue;
      if (this.driverDataPayloadData.searchStr.length >= 3) {
        this.getGroupList();
      } else if (this.driverDataPayloadData.searchStr.length === 0) {
        this.maxDataReached.entityDataForDriver = false;
        this.getGroupList();
      }
    } else if (changes.selectedDayTimeStamp) {
      this.getGroupList();
    }
  }

  applyInfiniteScroll(onEndPageCallback) {
    const contentEl = document.querySelector('#shift-cells') as HTMLElement;
    const handleInfiniteScroll = () => {
      const heightDiff = contentEl.scrollHeight - contentEl.offsetHeight;
      const endOfPage = Math.round(contentEl.scrollTop) === heightDiff || Math.round(contentEl.scrollTop) === (heightDiff + 1);
      if (endOfPage) {
        onEndPageCallback()
      }
    };
    contentEl.addEventListener("scroll", handleInfiniteScroll)
  }

  setHours() {
    this.hourList = this.hourFormat === AppSettings.HOUR_FORMAT ? AppSettings.DAY_HOURS : AppSettings.DAY_HOURS_24_FORMAT;
    this.onNextPreviousClick.emit(dayjs());
  }

  onListTypeChange(event) {
    this.onListTypeChangeEvent.emit(event.value);
  }

  sortDrivers() {
    this.isAscendingData = !this.isAscendingData;
    this.driverDataPayloadData.defaultSortType = this.isAscendingData ? 'desc' : 'asc';
    this.groupList = [];
    this.getGroupList();
  }

  calculateTotalWorkingHoursInWeekForDriver(driver: any) {
    driver.workingHours = 0
    driver.shift.forEach((shift) => {
      if (!shift.isLeave) {
        driver.workingHours = driver.workingHours + shift.shiftDuration;
      }
    });
  }

  setShiftDetails(shift: any) {
    const startTimeArray = shift.shiftDetailsDto.startTimeStr.split(AppSettings.STRING_SPLIT_BY_SPACE);
    const hourMinutes = startTimeArray[0].split(AppSettings.STRING_SPLIT_BY_COLON);
    shift.shiftDetailsDto.startTimeHour =this.hourFormat === AppSettings.HOUR_FORMAT ? `${Number(hourMinutes[0])}${startTimeArray[1]}` : startTimeArray[1] === 'PM' ? Number(hourMinutes[0]) + 12 : Number(hourMinutes[0]);
    const startDate = new Date(`${shift.shiftDetailsDto.startDateStr}`);
    const endDate = new Date(`${shift.shiftDetailsDto.startDateStr}`);
    shift.shiftDetailsDto.startTime = Number(hourMinutes[1]);
    if (shift.shiftCalendarList && shift.shiftCalendarList.length > 0) {
      const shiftCalendar = shift.shiftCalendarList.find(ele => ele.shiftId === shift.shiftDetailsDto.shiftId);
      shift.shiftDetailsDto.isShiftPublish = shiftCalendar.shiftStatus === AppSettings.SHIFT_STATUS.PUBLISH;
    }

    if (startTimeArray[1] === 'PM') {
      startDate.setHours(Number(hourMinutes[0]) + 12);
    } else {
      startDate.setHours(Number(hourMinutes[0]));
    }
    startDate.setMinutes(Number(hourMinutes[1]));

    const endTimeArray = shift.shiftDetailsDto.endTimeStr.split(AppSettings.STRING_SPLIT_BY_SPACE);
    const hourMinutesEndTime = endTimeArray[0].split(AppSettings.STRING_SPLIT_BY_COLON);
    if (endTimeArray[1] === 'PM') {
      endDate.setHours(Number(hourMinutesEndTime[0]) + 12);
    } else {
      endDate.setHours(Number(hourMinutesEndTime[0]));
    }
    endDate.setMinutes(Number(hourMinutesEndTime[1]));
    shift.shiftDetailsDto.timeDifferenceInMinutes = (dayjs(endDate.getTime()).diff(dayjs(startDate.getTime()), 'minute'));
    this.cd.detectChanges();
  }

  setShiftData(shift: any, group: any) {
    this.setShiftDetails(shift);
    if (shift.shiftCalendarList && shift.shiftCalendarList.length > 0) {
      shift.shiftCalendarList.forEach(shiftCalendar => {
        shiftCalendar = { ...shiftCalendar, ...shift.shiftDetailsDto };
        shiftCalendar.startDateStr = shiftCalendar.startDateTime;
        shiftCalendar.endDateStr = shiftCalendar.endDateTime;

        if (shiftCalendar.startTimeStr) {
          shiftCalendar.startTimeStr = addTimeToCurrentDate(shiftCalendar.startTimeStr);
        }
        if (shiftCalendar.startTimeStr) {
          shiftCalendar.endTimeStr = addTimeToCurrentDate(shiftCalendar.endTimeStr);
        }
        if (shiftCalendar.shiftBreakList && shiftCalendar.shiftBreakList.length > 0) {
          shiftCalendar.shiftBreakList.forEach(element => {
            if (element.breakStartTime) {
              element.breakStartTime = addTimeToCurrentDate(element.breakStartTime);
            }
            if (element.breakEndTime) {
              element.breakEndTime = addTimeToCurrentDate(element.breakEndTime);
            }
          });
        }

        group.shift.push(shiftCalendar);
        this.cd.detectChanges();
      });
    }
  }

  getShift(shiftList: any, group: any) {
    group.shift = [];
    if (shiftList && shiftList.length > 0) {
      shiftList.forEach(shift => {
        this.setShiftData(shift, group);
      });
    }
  }

  setGroupDetails(group: any, groupDetailsList: any[]) {
    const groupDetails = groupDetailsList.find(ele => ele.id === group.driverGroupEntityId);
    group.color = groupDetails.values?.group_color;
    group.driverGroup = groupDetails.values?.group_name;
    group.groupMembers = groupDetails.values?.assigned_drivers.length;
    group.status = groupDetails.values?.group_status
  }

  setGroupData(groupData: any, groupDetailsList: any[]) {
    groupData.data.forEach((group) => {
      this.setGroupDetails(group, groupDetailsList);
      this.getShift(group.shiftList, group);
      this.calculateTotalWorkingHoursInWeekForDriver(group);
    });
  }


  getDriverDetails(driverIds, groupData) {
    const requestBody = {
      forTenantCode: this.configService.getForTenantCode(),
      countryCode: this.country[0].countryCode,
      viewCode: AppSettings.VIEW_CODE.DETAIL_VIEW,
      entityIds: driverIds,
      limit: this.driverDataPayloadData.limit,
      offset: 0
    }
    this.entityService.getEntityDetailsByEntityIds(requestBody, AppSettings.ENTITY_CODE.DRIVER_GROUP).subscribe((result: any) => {
      this.setGroupData(groupData, result.data);
      this.cd.detectChanges();
    });
  }

  getGroupList() {
    this.driverDataPayloadData.startDateTimeStr = dayjs(this.selectedDayTimeStamp).startOf('day').format(AppSettings.DD_MM_YYYY);
    this.driverDataPayloadData.endDateTimeStr = dayjs(this.selectedDayTimeStamp).endOf('day').format(AppSettings.DD_MM_YYYY);
    this.shiftService.getDriverList(this.driverDataPayloadData, 'groups').subscribe((result: any) => {
      this.listTypeCopy[1].name = `${this.listType[1].name} (${result.count})`;
      this.groupList.push(...result.data);
      const currentTime = document.querySelector('.current-time') as HTMLElement;
      if (currentTime) {
        currentTime.style.height = `${this.driverDataPayloadData.offset ? this.driverDataPayloadData.offset * this.currentTimeMarkerInitialHeight : this.currentTimeMarkerInitialHeight}px`;
      }
      const groupIds = result.data.map(ele => {
        return ele.driverGroupEntityId
      })

      this.getDriverDetails(groupIds, result)

      if (result?.data.length === 0) {
        this.maxDataReached.entityDataForDriver = true;
      }

      if (!this.maxDataReached.entityDataForDriver) {
        this.maxDataReached.entityDataForDriver = this.groupList.length >= result.count;
      }
    });
  }

  getPublishedUnpublishedShiftList() {
    const requestBody = {
      forTenantCode: this.configService.getForTenantCode(),
      languageCode: this.language[0].langCode,
      startDateTimeStr: dayjs(this.selectedDayTimeStamp).startOf('day').format(AppSettings.DD_MM_YYYY),
      endDateTimeStr: dayjs(this.selectedDayTimeStamp).endOf('day').format(AppSettings.DD_MM_YYYY)
    }
    this.shiftService.getPublishUnPublishListInDateRange(requestBody).subscribe({
      next: (result: any) => {
        this.publishedShiftList = result.filter(ele => ele.shiftStatus.toLowerCase() === AppSettings.SHIFT_STATUS.PUBLISH.toLowerCase());
        this.unpublishedShiftList = result.filter(ele => ele.shiftStatus.toLowerCase() === AppSettings.SHIFT_STATUS.UNPUBLISH.toLowerCase());
        this.shiftPublishedUnpublishedList.emit({ publishedShiftList: this.publishedShiftList, unpublishedShiftList: this.unpublishedShiftList });
      },
      error: (error) => {

      }
    })
  }
}
