import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { EntityService } from '../../../shared/services/entity.service';
import { AppSettings } from '../../../shared/app.settings';
import * as _ from 'lodash';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { MiErrorComponent } from '../../../shared/components/mi-error/mi-error.component';
import { MiFieldsComponent } from '../../../shared/components/mi-fields/mi-fields.component';
import { MiTooltipComponent } from '../../../shared/components/mi-fields/mi-tooltip/mi-tooltip.component';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Country } from 'app/modules/shared/models/country';

@Component({
  selector: 'app-driver-driving-record',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, MiFieldsComponent, MiErrorComponent, ButtonModule, MiTooltipComponent, TranslateModule],
  templateUrl: './driver-driving-record.component.html',
  styleUrl: './driver-driving-record.component.scss'
})
export class DriverDrivingRecordComponent {
  @Output() onRemoveDriverDrivingRecord: EventEmitter<any> = new EventEmitter();
  @Input() group: any;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  @Input() miFormGroup: FormGroup;
  @Input() attributeValues;
  @Input() driverDrivingRecordIds;
  @Input() edit: boolean = false;
  slots: any = [];
  groupCopy: any = [];
  driverDrivingRecordCount = 0;
  removeSlotHeader: string = this.cs.getLabel('lbl_remove_slot_header');
  removeSlotMessage: string = this.cs.getLabel('lbl_remove_slot_message');
  country: Country;

  constructor(
    public cs: CommonBindingDataService,
    private cd: ChangeDetectorRef,
    private driverService: EntityService,
    private confirmationService: ConfirmationService,
    private configService: ConfigService,
    private messageService: MessageService,
  ) {
  }

  ngOnInit(): void {
    this.moduleName = AppSettings.ENTITY_CODE.DRIVER_DRIVING_RECORD;
    this.groupCopy = _.cloneDeep(this.group.fields);
  
    if (this.driverDrivingRecordIds.length > 0) {
      this.setFieldsForEdit();
    } else {
      this.setFields();
    }
  }

  setFieldsForEdit() {
    for (let j = 0; j < this.driverDrivingRecordIds.length; j++) {
      this.addSlot(j);
    }
  }

  setFields() {
    const group = _.cloneDeep(this.group)
    for (const field of group.fields) {
      const validators = this.addValidator(field.validation);
      field.attributeCodeOneToMultiple = `${this.driverDrivingRecordCount}_${field.attributeCode}`;
      field.isOneToMultiple = true;
      field.fieldName = field.attributeCode;
      const value = this.attributeValues ? this.attributeValues[field?.attributeCodeOneToMultiple] : '';
      this.miFormGroup.registerControl(field.attributeCodeOneToMultiple, new FormControl(value, validators));
    }
    this.slots.push(group.fields);
  }

  private addValidator(rules) {
    if (!rules) {
      return [];
    }
    const validators = [];
    (Object.keys(rules).map((rule) => {
      if (rule === "required" && rules.required == true) {
        validators.push(Validators.required);
      }

      if (rule === "min" && rules.min) {
        if (rules[rule] !== null) {
          validators.push(Validators.min(rules[rule] ? rules[rule] : 10))
        }
      }

      if (rule === "max" && rules.max) {
        if (rules[rule] !== null) {
          validators.push(Validators.max(rules[rule] ? rules[rule] : 50));
        }
      }

      if (rule === "pattern" && rules.pattern) {
        if (rules[rule] !== null) {
          const validatorRule = new RegExp([rules[rule]].join(''));
          validators.push(Validators.pattern(rules[rule] ? validatorRule : ''));
        }

      }
      if (rule === "unique" && rules.unique == true) {
        validators.push(Validators.pattern(''));

      }
    }));
    return validators;
  }

  addSlot(prefix) {
    const newSlot = _.cloneDeep(this.groupCopy);
    for (const field of newSlot) {
      const validators = this.addValidator(field.validation);
      field.attributeCodeOneToMultiple = `${prefix}_${field.attributeCode}`;
      field.isOneToMultiple = true;
      field.fieldName = field.attributeCode;
      const value = this.attributeValues ? this.attributeValues[field?.attributeCodeOneToMultiple] : '';
      this.miFormGroup.registerControl(field?.attributeCodeOneToMultiple, new FormControl(value, validators));
    }
    this.slots.push(newSlot);
  }

  addMoreSlots() {
    this.driverDrivingRecordCount = this.slots.length + 1;
    this.addSlot(this.driverDrivingRecordCount);
  }

  removeSlot(index, slot) {
    if (this.driverDrivingRecordIds[index]) {
      this.deleteSlotFromDb(index, this.driverDrivingRecordIds[index], slot);
    } else {
      this.confirmationService.confirm({
        header: this.removeSlotHeader,
        message: this.removeSlotMessage,
        acceptIcon: null,
        rejectIcon: null,
        acceptLabel: this.cs.getLabel('driver.remove'),
        rejectLabel: this.cs.getLabel('driver.cancel'),
        rejectButtonStyleClass: 'bg-white text-color',
        acceptButtonStyleClass: 'bg-red-500',
        accept: () => {
          this.slots.splice(index, 1);
          this.cs.removeDocumentByIndex(this.miFormGroup.value, index);
        },
        reject: () => {
        }
      });
    }
  }

  deleteSlotFromDb(index, id, slot) {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    const requestBody = {
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: AppSettings.ENTITY_CODE.DRIVER_DRIVING_RECORD,
      entityIds: [id],
      countryCode: this.country[0].countryCode
    };
    this.confirmationService.confirm({
      header: this.removeSlotHeader,
      message: this.removeSlotMessage,
      acceptIcon: null,
      rejectIcon: null,
      acceptLabel: this.cs.getLabel('driver.remove'),
      rejectLabel: this.cs.getLabel('driver.cancel'),
      rejectButtonStyleClass: 'bg-white text-color',
      acceptButtonStyleClass: 'bg-red-500',
      accept: () => {
        this.driverService.deleteEntityActionStatus(requestBody).subscribe((res: any) => {
          this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel(res.message) });
          this.slots.splice(index, 1);
          this.driverDrivingRecordIds.splice(index, 1);
          slot.forEach(element => {
            this.miFormGroup.removeControl(element.attributeCodeOneToMultiple);
          });
          const removeDriverDrivingRecord = [requestBody.entityIds, index]
          this.onRemoveDriverDrivingRecord.emit(removeDriverDrivingRecord);
          this.cd.detectChanges();
        })
      },
      reject: () => {
      }
    });
  }
}
