@if(hideService.getVisibilityState(cardId) | async){
<div class="card p-0 height-225px shadow-card">
  <div class="flex justify-content-between align-items-center header-div">
    <h5 class="font-16 mb-0 default-text-black-color font-bold height-17px">
    </h5>
    <!-- <app-hide-button [cardId]="cardId"></app-hide-button> -->
  </div>

  <div>
    <p-table [scrollable]="true" scrollHeight="calc(50vh - 45px)" [value]="parkingQueueData">
      <ng-template pTemplate="header">
        <tr>
          <th class="font-16 font-bold default-text-black-color" translate>
            dashboard.parking_and_queues
          </th>
          <th class="font-14 font-semibold" translate>dashboard.bookings</th>
          <th class="font-14 font-semibold" translate>dashboard.vehicles</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-product>
        <tr>
          <td [ngClass]="product.labelClass">{{ product.label }}</td>
          <td [ngClass]="product.availableClass">{{ product.available }}</td>
          <td [ngClass]="product.occupiedClass">{{ product.occupied }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
}
