<div class="grid mt-2 ml-3 speccifications">
    <div class="col-6 pl-0">

            <div class="card p-fluid">
                <div class="flex justify-content-between flex-wrap">
                    <div class="flex justify-content-center mb-3">
                        <label class="card-heading uppercase" translate>vehicle.lbl_dimensions</label>
                    </div>
                    <div appAccessProvider [accessCode]="{ menuCode: 'VEHICLES', pageCode: 'ALL', subPageCode: 'EDIT' }">
                        <div class="edit justify-content-center mb-3" [ngClass]="{'hidden': isDeleted}" (click)="redirectToEdit()">
                            <label class="card-action" translate>vehicle.lbl_edit</label>
                            <i class="mi-lg edit_pencil"></i>
                        </div>
                    </div>
                </div>
                @for (field of dimensions.fields; track field; let i = $index) {
                    @if (i !== 0) {
                        <hr class="hr-line">
                    }
                <div class="grid">
                    <div class="col-4 pt-1">
                        <label class="detail-heading" translate>{{cs.getLabelValue('vehicle.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                    </div>
                    <div class="col-8 pb-1">
                        <label class="detail-value">{{ attributeValues[field.attributeCode] ? attributeValues[field.attributeCode] : '--' }}
                            @if(field.imperialUnit && attributeValues[field.attributeCode]){
                                {{cs.getUnit(field)}}
                            }
                        </label>
                    </div>
                </div>
            }
            </div>

            <div class="card p-fluid">
                <div class="flex justify-content-between flex-wrap">
                    <div class="flex justify-content-center mb-3">
                        <label class="card-heading uppercase" translate>vehicle.lbl_weight</label>
                    </div>
                    <div appAccessProvider [accessCode]="{ menuCode: 'VEHICLES', pageCode: 'ALL', subPageCode: 'EDIT' }">
                        <div class=" justify-content-center mb-3"  [ngClass]="{'hidden': isDeleted}" (click)="redirectToEdit()">
                            <label class="card-action" translate>vehicle.lbl_edit</label>
                            <i class="mi-lg edit_pencil"></i>
                        </div>
                    </div>
                </div>
                @for (field of weight.fields; track field; let i = $index) {
                    @if (i !== 0) {
                        <hr class="hr-line">
                    }
                <div class="grid">
                    <div class="col-4 pt-1">
                        <label class="detail-heading" translate>{{cs.getLabelValue('vehicle.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                    </div>
                    <div class="col-8 pb-1">
                        <label class="detail-value">{{ attributeValues[field.attributeCode] ? attributeValues[field.attributeCode] : '--' }} 
                            @if(field.imperialUnit && attributeValues[field.attributeCode]){
                                {{cs.getUnit(field)}}
                            }
                        </label>
                    </div>
                </div>
            }
            </div>

            <div class="card p-fluid">
                <div class="flex justify-content-between flex-wrap">
                    <div class="flex justify-content-center mb-3">
                        <label class="card-heading uppercase" translate>vehicle.lbl_performance</label>
                    </div>
                    <div appAccessProvider [accessCode]="{ menuCode: 'VEHICLES', pageCode: 'ALL', subPageCode: 'EDIT' }">
                        <div class="justify-content-center mb-3"  [ngClass]="{'hidden': isDeleted}" (click)="redirectToEdit()">
                            <label class="card-action" translate>vehicle.lbl_edit</label>
                            <i class="mi-lg edit_pencil"></i>
                        </div>
                    </div>
                </div>
                @for (field of performance.fields; track field; let i = $index) {
                    @if (i !== 0) {
                        <hr class="hr-line">
                    }
                <div class="grid">
                    <div class="col-4 pt-1">
                        <label class="detail-heading" translate>{{cs.getLabelValue('vehicle.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                    </div>
                    <div class="col-8 pb-1">
                        <label class="detail-value">{{ attributeValues[field.attributeCode] ? attributeValues[field.attributeCode] : '--' }} 
                            @if(field.imperialUnit && attributeValues[field.attributeCode]){
                                {{cs.getUnit(field)}}
                            }
                        </label>
                    </div>
                </div>
            }
            </div>

            <div class="card p-fluid">
                <div class="flex justify-content-between flex-wrap">
                    <div class="flex justify-content-center mb-3">
                        <label class="card-heading uppercase" translate>vehicle.lbl_fuel_economy</label>
                    </div>
                    <div appAccessProvider [accessCode]="{ menuCode: 'VEHICLES', pageCode: 'ALL', subPageCode: 'EDIT' }">
                        <div class="justify-content-center mb-3" [ngClass]="{'hidden': isDeleted}" (click)="redirectToEdit()">
                            <label class="card-action" translate>vehicle.lbl_edit</label>
                            <i class="mi-lg edit_pencil"></i>
                        </div>
                    </div>
                </div>
                @for (field of fuelEconomy.fields; track field; let i = $index) {
                    @if (i !== 0) {
                        <hr class="hr-line">
                    }
                <div class="grid">
                    <div class="col-4 pt-1">
                        <label class="detail-heading" translate>{{cs.getLabelValue('vehicle.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                    </div>
                    <div class="col-8 pb-1">
                        <label class="detail-value">{{ attributeValues[field.attributeCode] ? attributeValues[field.attributeCode] : '--' }} 
                            @if(field.imperialUnit && attributeValues[field.attributeCode]){
                                {{cs.getUnit(field)}}
                            }
                        </label>
                    </div>
                </div>
            }
            </div>

            <div class="card p-fluid">
                <div class="flex justify-content-between flex-wrap">
                    <div class="flex justify-content-center mb-3">
                        <label class="card-heading uppercase" translate>vehicle.lbl_fuel</label>
                    </div>
                    <div appAccessProvider [accessCode]="{ menuCode: 'VEHICLES', pageCode: 'ALL', subPageCode: 'EDIT' }">
                        <div class="justify-content-center mb-3" [ngClass]="{'hidden': isDeleted}" (click)="redirectToEdit()">
                            <label class="card-action" translate>vehicle.lbl_edit</label>
                            <i class="mi-lg edit_pencil"></i>
                        </div>
                    </div>
                </div>
                @for (field of fuel.fields; track field; let i = $index) {
                    @if (i !== 0) {
                        <hr class="hr-line">
                    }
                <div class="grid">
                    <div class="col-4 pt-1">
                        <label class="detail-heading" translate>{{cs.getLabelValue('vehicle.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                    </div>
                    <div class="col-8 pb-1">
                        <label class="detail-value">{{ attributeValues[field.attributeCode] ? attributeValues[field.attributeCode] : '--' }} 
                            @if(field.imperialUnit && attributeValues[field.attributeCode]){
                                {{cs.getUnit(field)}}
                            }
                        </label>
                    </div>
                </div>
            }
            </div>

            <div class="card p-fluid">
                <div class="flex justify-content-between flex-wrap">
                    <div class="flex justify-content-center mb-3">
                        <label class="card-heading uppercase" translate>vehicle.lbl_oil</label>
                    </div>
                    <div appAccessProvider [accessCode]="{ menuCode: 'VEHICLES', pageCode: 'ALL', subPageCode: 'EDIT' }">
                        <div class="justify-content-center mb-3" [ngClass]="{'hidden': isDeleted}" (click)="redirectToEdit()">
                            <label class="card-action" translate>vehicle.lbl_edit</label>
                            <i class="mi-lg edit_pencil"></i>
                        </div>
                    </div>
                </div>
                @for (field of oil.fields; track field; let i = $index) {
                    @if (i !== 0) {
                        <hr class="hr-line">
                    }
                <div class="grid">
                    <div class="col-4 pt-1">
                        <label class="detail-heading" translate>{{cs.getLabelValue('vehicle.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                    </div>
                    <div class="col-8 pb-1">
                        <label class="detail-value">{{ attributeValues[field.attributeCode] ? attributeValues[field.attributeCode] : '--' }} 
                            @if(field.imperialUnit && attributeValues[field.attributeCode]){
                                {{cs.getUnit(field)}}
                            }
                        </label>
                    </div>
                </div>
            }
            </div>
    </div>
    <div class="col-6">
        <div class="card p-fluid">
            <div class="flex justify-content-between flex-wrap">
                <div class="flex justify-content-center mb-3">
                    <label class="card-heading uppercase" translate>vehicle.lbl_engine</label>
                </div>
                <div appAccessProvider [accessCode]="{ menuCode: 'VEHICLES', pageCode: 'ALL', subPageCode: 'EDIT' }">
                    <div class="justify-content-center mb-3"  [ngClass]="{'hidden': isDeleted}" (click)="redirectToEdit()">
                        <label class="card-action" translate>vehicle.lbl_edit</label>
                        <i class="mi-lg edit_pencil"></i>
                    </div>
                </div>
            </div>
            @for (field of engine.fields; track field; let i = $index) {
                @if (i !== 0) {
                    <hr class="hr-line">
                }
            <div class="grid">
                <div class="col-4 pt-1">
                    <label class="detail-heading" translate>{{cs.getLabelValue('vehicle.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                </div>
                <div class="col-8 pb-1">
                    <label class="detail-value">{{ attributeValues[field.attributeCode] ? attributeValues[field.attributeCode] : '--' }}  @if(field.imperialUnit){
                        {{cs.getUnit(field)}}
                    }</label>
                </div>
            </div>
        }
        </div>

        <div class="card p-fluid">
            <div class="flex justify-content-between flex-wrap">
                <div class="flex justify-content-center mb-3">
                    <label class="card-heading uppercase" translate>vehicle.lbl_transmission</label>
                </div>
                <div appAccessProvider [accessCode]="{ menuCode: 'VEHICLES', pageCode: 'ALL', subPageCode: 'EDIT' }">
                    <div class="justify-content-center mb-3"  [ngClass]="{'hidden': isDeleted}" (click)="redirectToEdit()">
                        <label class="card-action" translate>vehicle.lbl_edit</label>
                        <i class="mi-lg edit_pencil"></i>
                    </div>
                </div>
            </div>
            @for (field of transmission.fields; track field; let i = $index) {
                @if (i !== 0) {
                    <hr class="hr-line">
                }
            <div class="grid">
                <div class="col-4 pt-1">
                    <label class="detail-heading" translate>{{cs.getLabelValue('vehicle.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                </div>
                <div class="col-8 pb-1">
                    <label class="detail-value">{{ attributeValues[field.attributeCode] ? attributeValues[field.attributeCode] : '--' }}  
                        @if(field.imperialUnit && attributeValues[field.attributeCode]){
                            {{cs.getUnit(field)}}   
                        }
                    </label>
                </div>
            </div>
        }
        </div>

        <div class="card p-fluid">
            <div class="flex justify-content-between flex-wrap">
                <div class="flex justify-content-center mb-3">
                    <label class="card-heading uppercase" translate>vehicle.lbl_wheels_and_tiers</label>
                </div>
                <div appAccessProvider [accessCode]="{ menuCode: 'VEHICLES', pageCode: 'ALL', subPageCode: 'EDIT' }">
                    <div class=" justify-content-center mb-3"  [ngClass]="{'hidden': isDeleted}" (click)="redirectToEdit()">
                        <label class="card-action" translate>vehicle.lbl_edit</label>
                        <i class="mi-lg edit_pencil"></i>
                    </div>
                </div>
            </div>
            @for (field of wheelsAndTyres.fields; track field; let i = $index) {
                @if (i !== 0) {
                    <hr class="hr-line">
                }
            <div class="grid">
                <div class="col-4 pt-1">
                    <label class="detail-heading" translate>{{cs.getLabelValue('vehicle.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                </div>
                <div class="col-8 pb-1">
                    <label class="detail-value">{{ attributeValues[field.attributeCode] ? attributeValues[field.attributeCode] : '--' }}
                        @if(field.imperialUnit && attributeValues[field.attributeCode]){
                            {{cs.getUnit(field)}}
                        }
                    </label>
                </div>
            </div>
        }
        </div>