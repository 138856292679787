<p-breadcrumb [model]="routePath"></p-breadcrumb>
<div appAccessProvider [accessCode]="{ menuCode: 'USER_MANAGEMENT', subMenuCode: 'MANAGE_USERS', pageCode: 'DETAILS', subPageCode: 'ADD' }">
<app-module-header [label]="btnLabel" (onBtnClick)="onAddUser()" [moduleHeaderSecondaryBtnMenuItems]="moduleHeaderSecondaryBtnMenuItems" [showLastSync]="false"></app-module-header>
</div>
<div appAccessProvider [accessCode]="{ menuCode: 'USER_MANAGEMENT', subMenuCode: 'MANAGE_USERS', pageCode: 'DETAILS', subPageCode: 'LIST' }">
<p-tabView [(activeIndex)]="activeIndex">
  <p-tabPanel>
    <ng-template pTemplate="header">
      <i class="{{miIcons.DASHBOARD_ACTIVITY_FEED}} inline-block wh-14 mr-2"></i>
      <span translate>lbl_all</span>&nbsp;<span class="iconNumber text-color-secondary">({{totalRecords}})</span>
    </ng-template>
    <ng-template pTemplate="body">
      @if(listColumns) {
        <app-mi-table
          [collectionOfColumns]="allColumns"
          [attributeLabels]="filterAttributeLabels"
          [data]="tableData"
          [isLoading]="isLoading"
          (onPageChange)="onPageChange($event)"
          (itemPerPageChange)="itemPerPageChange($event)"
          [message]="deleteRoleMsg"
          [header]="deleteRoleHeaderMsg"
          (onDelete)="onIncidentComplaintDelete($event)"
          (onBulkDataDeleteEvent)="onBulkDataDeleteEvent($event)"
          (onRowSelection)="onRowSelect($event)"
          [filterList]="filterList"
          (onValueChange)="onFilterValueChange($event)"
          [filterFields]="filterFieldsArray"
          [label]="btnLabel"
          (onBtnClick)="onAddUser()"
          [attributeData]="attributeData"
          [rowItems]="rowItems"
          (onUpdate)="onUpdateUser($event)"
          [bulkSelectionsItems] = "bulkSelectionsItems"
          [bulkUpdateLabel]="bulkUpdateLabel"
          [bulkUpdateHeader]="bulkUpdateHeader"
          [entityNameToDisplay]="entityNameToDisplay"
          [btnUpdate]="btnUpdate"
          [bulkDeleteLabel]="bulkDeleteLabel"
          (setCurrentRowData)="setCurrentRowData($event)"
          [showBulkUpdateDialog]="showBulkUpdateDialog"
          [reviewHeader]="reviewHeader"
          (onSearchValueChange)="onSearchValueChanges($event)"
          [totalRecords]="totalRecords"
          [visible]="visible"
          (onToggleOverLay)="showBulkUpdateDialog = false"
          (onChangeStatus)="onChangeStatus($event)"
          [isEntity]="isEntity"
          [uniqueId]="uniqueId"
          (onStaticBulkDataUpdateEvent)="onStaticBulkDataUpdate($event)"
          >
        </app-mi-table>
      }
    </ng-template>
  </p-tabPanel>
</p-tabView>
</div>
