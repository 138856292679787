<form [formGroup]="formName">
    <!-- <p-chips  [formControlName]="field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode"  [max]="field?.validation?.max"></p-chips> -->

</form>
<p-autoComplete 
    [(ngModel)]="selectedItems" 
    [suggestions]="suggestions" 
    (completeMethod)="search($event)" 
    [multiple]="true" 
    (onKeyUp)="handleKeyUp($event)"
    (onSelect)="onSelectItem($event)"
    (onUnselect)="onUnselect($event)"
    [placeholder]="moduleName === 'vehicle' ? ('lbl_enter_labels' | translate) : ''">
</p-autoComplete>


