import * as dayjs from 'dayjs';

export function getTimeDiffInHours(startTime, endTime) {
  const diffInMilliseconds = endTime.getTime() - startTime.getTime();
  let hours = (((diffInMilliseconds / 1000) / 60) / 60);
  const minutes = (((diffInMilliseconds / 1000) / 60) % 60);
  hours = hours > 0 ? Math.trunc(hours) : 0;
  return `${hours} Hours ${minutes} Minutes`;
}

export function convertSeconds(seconds) {
  const hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return {
    hour: hours,
    minutes: minutes,
    seconds: remainingSeconds
  };
}

export function addTimeToCurrentDate(time) {
  console.log(time);
  const timeArray = time.split(' ');
  const hourMinuteArray = timeArray[0].split(':');
  let hour = hourMinuteArray[0];
  const minute = hourMinuteArray[1];
  if (timeArray[1] === 'PM') {
    hour = (Number(hour) + 12).toString();
  } else if (timeArray[1] === 'AM') {
    if (Number(hour) === 12) {
      hour = 0;
    }
  }
  const dateWithTime: any = dayjs().startOf('day').add(hour, 'hour').add(minute, 'minute');
  return dateWithTime.$d;
}