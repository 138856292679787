<div class="sidebar-height">
<div class="company-logo">
  <div class="shrink-width-logo">
    <i class="{{miIcons.CLOSED_LOGO}} inline-block cursor-pointer sidebar-closed-logo flex-shrink-0"></i>
  </div>
  <i class="{{miIcons.OPEN_FLEET_NXT}} inline-block cursor-pointer sidebar-logo flex-shrink-0"></i>
</div>
<div class="sidebar-dropdown">
  <div class="event-dropdown">
    <form [formGroup]="eventDropdownForm">
      <p-dropdown [options]="listColumns" optionLabel="label" optionValue="eventShortcode" formControlName="eventDropdown"
      [placeholder]="'events.lbl_select_an_event'|translate" (onChange)="onEventChangeChange($event)" (mouseleave)="onDropdownMouseLeave()">
        <ng-template let-option pTemplate="selectedItem">
          <!-- @if (selectedEvent) { -->
            <div class="flex align-items-center">
              @if (option.eventLogoImgUrl) {
                <img [src]="option?.eventLogoImgUrl  || defaultEventImageUrl" class="option-icon mr-2">
              } @else {
                <div class="inline-flex">
                  <i class="mi-lg calendar_event"></i>
                </div>
              }
              <span class="sidebar-event">{{getTruncatedEventName(option.eventName)}}</span>
            </div>
          <!-- } -->
        </ng-template>
    
        <ng-template let-option pTemplate="item">
          <div class="flex align-items-center">
            @if (option.eventLogoImgUrl) {
              <img [src]="option?.eventLogoImgUrl  || defaultEventImageUrl" class="option-icon mr-2">
            } @else {
              <div class="inline-flex">
                <i class="mi-lg calendar_event"></i>
              </div>
            }
            <span class="sidebar-event">{{ option.eventName }}</span>
          </div>
        </ng-template>
      </p-dropdown>
    </form>

  </div>
  <div class="event-image">
    @if (selectedEventData?.eventLogoImgUrl) {
      <img [src]="selectedEventData?.eventLogoImgUrl || defaultEventImageUrl" class="event-img ml-2 mr-2">
    } @else {
      <i class="mi-lg calendar_event"></i>
    }
    
  </div>
</div>
<div class="sidebar-body hide-scrollbar">
  <p-panelMenu class="sidebar-panel-menu " [model]="items" [multiple]="true"></p-panelMenu>
</div>
</div>