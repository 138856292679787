import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { AppIcons } from '../../shared/app.icons';
import { AppSettings } from '../../shared/app.settings';
import { StatusComponent } from '../../shared/components/status/status.component';
import { Country } from '../../shared/models/country';
import { Language } from '../../shared/models/language';
import { Status } from '../../shared/models/status';
import { CommonBindingDataService } from '../../shared/services/common-binding-data.service';
import { ConfigService } from '../../shared/services/config.service';
import { EntityService } from '../../shared/services/entity.service';
import { EventService } from '../services/event.service';
import { Countries } from 'app/modules/shared/countries';
import { TabViewModule } from 'primeng/tabview';
import { AccessProviderDirective } from 'app/modules/shared/directives/access-provider.directive';
import { MiTooltipComponent } from 'app/modules/shared/components/mi-fields/mi-tooltip/mi-tooltip.component';
import { addTimeToCurrentDate } from 'app/modules/shared/utility-functions/date';
import { MetaDataService } from 'app/modules/shared/services/meta-data.service';
import { MiImageContainerComponent } from 'app/modules/shared/ui-sharable/mi-image-container/mi-image-container.component';
import { BookingService } from 'app/modules/bookings/services/booking.service';
import { AttributeData } from 'app/modules/vehicles/models/attribute.models';
import { EntityResponse } from 'app/modules/vehicles/models/entityResponse.models';
import { BookingStatus } from 'app/modules/shared/models/booking-status';
import { FindByViewResponse } from 'app/modules/shared/models/find-by-view-response';
import { GoogleMapsService } from '../../shared/services/google-map.services';

@Component({
  selector: 'app-event-details',
  standalone: true,
  imports: [TranslateModule, DatePipe, BreadcrumbModule, TabViewModule,
    StatusComponent, ButtonModule, MenuModule, AccessProviderDirective, MiTooltipComponent,
    MiImageContainerComponent],
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss', '../../shared/components/mi-fields/mobile-input/flags.scss'],
  providers: [DatePipe]
})
export class EventDetailsComponent implements OnInit {
  routePath: MenuItem[] = [];
  eventId: string;
  dataForStatus: Array<Status> = [];
  country: Country;
  language: Language;
  activeIndex: any = 0;
  imgFileId: any;
  imgFileUrl: any;
  showStatus: boolean = false;
  eventDetails: any;
  entityForStatus: any;
  eventImage: any;
  editBtnLabel: any;
  countryCodeList = Countries.COUNTRY_CODE;
  countryCodeForTransportSupervisorNumber: any;
  emergencyNumber: any;
  miIcons = AppIcons;
  moduleHeaderSecondaryBtnMenuItems: MenuItem[] = [];
  languageCode: string;
  eventState: string;
  defaultLoaderImg = AppSettings.DEFAULT_LOADER_IMAGE;
  center: google.maps.LatLngLiteral;
  map: google.maps.Map;
  address: any;
  attributeLabelsForTimeSettings = {};
  attributeLabelsForConfigSettings = {};
  timeSettingsFields: any[] = [];
  configSettingsFields: any[] = [];
  timeSettingsEntityId: any;
  configSettingsEntityId: any;
  attributeValues = [];
  configSettingAttributeValues = [];
  eventCode: any;
  timeSettingsModuleName: string = AppSettings.ENTITY_TYPE.TIME_SETTINGS;
  configSettingsModuleName: string = AppSettings.ENTITY_CODE.CONFIG_SETTING;
  unit: any;
  bookingStatuses: any[] = [];
  appsetting = AppSettings.ATTRIBUTE_CODE_FOR_CONFIG_SETTINGS;

  constructor(private route: ActivatedRoute,
    private router: Router,
    public cs: CommonBindingDataService,
    private datePipe: DatePipe,
    private entityService: EntityService,
    private configService: ConfigService,
    private confirmationService: ConfirmationService,
    private cd: ChangeDetectorRef,
    private eventService: EventService,
    private metaDataService: MetaDataService,
    private bookingService: BookingService,
    private messageService: MessageService,
    private googleMapsService: GoogleMapsService) { }

  ngOnInit() {
    this.eventId = this.route.snapshot.paramMap.get('id');
    this.getAttributesForTimeSetting();
    this.getAttributesForConfigSetting();
    this.getEventDetails();
    this.setCountryAndLanguage();
    this.editBtnLabel = this.cs.getLabel('lbl_edit');
    this.setRoutePath()
  }

  ngAfterViewInit() {
    this.initMap();
  }

  getAttributesForTimeSetting() {
    this.entityService.getAttributeDefinition(AppSettings.ENTITY_TYPE.TIME_SETTINGS, AppSettings.VIEW_CODE.ADD_EDIT_VIEW)
    .subscribe((res: AttributeData) => {
      if (res) {
        this.attributeLabelsForTimeSettings = this.cs.getAttributeLabels(res);
        console.log(this.attributeLabelsForTimeSettings);
        const timeSettingsAttributeData = this.cs.getOrganizedAttribute(res);
        const timeSettingsTab = timeSettingsAttributeData ? timeSettingsAttributeData.tabs.find(ele => ele.tabCode === 'time_settings') : [];
        const groups = timeSettingsTab.groups.length > 0 ? timeSettingsTab.groups.find(ele => ele.code === 'time_settings') : [];
        this.timeSettingsFields = groups.fields.length > 0 ? groups.fields : [];
        this.cd.detectChanges();
      }
    });
  }

  getAttributesForConfigSetting() {
    this.entityService.getAttributeDefinition(AppSettings.ENTITY_CODE.CONFIG_SETTING, AppSettings.VIEW_CODE.EVENT_ADD_EDIT_VIEW)
    .subscribe((res: AttributeData) => {
      if (res) {
        this.attributeLabelsForConfigSettings = this.cs.getAttributeLabels(res);
        console.log(this.attributeLabelsForConfigSettings);
        const configSettingsAttributeData = this.cs.getOrganizedAttribute(res);
        const configSettingsTab = configSettingsAttributeData ? configSettingsAttributeData.tabs.find(ele => ele.tabCode === 'configuration_setting') : [];
        const groups = configSettingsTab.groups.length > 0 ? configSettingsTab.groups.find(ele => ele.code === 'configuration_setting') : [];
        this.configSettingsFields = groups.fields.length > 0 ? groups.fields : [];
      }
    });
  }

  setRoutePath() {
    this.routePath = [
      {
        label: "Events",
        routerLink: '/app/events/list',
        icon: 'pi pi-arrow-left',
        iconStyle: { 'font-weight': 'bold', 'margin-right': '10px' }
      },
      {
        label: "Details",
        styleClass: 'breadcrumb-child forward-slash breadcrumb-text',
        style: { 'display': 'flex', 'top': '2px', 'position': 'relative' }
      },
    ];
  }

  setCountryAndLanguage() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    const tempUnit = this.metaDataService.systemOfMeasurement;
    this.unit = tempUnit === 'Imperial' ? 'miles' : 'km';
  }

  async setMarker(markerlocation): Promise<void> {
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;
    const marker = new AdvancedMarkerElement({
      map: this.map,
      position: markerlocation,
    });
    this.map.setCenter(markerlocation);
    this.map.setZoom(17);
  }

  async initMap(): Promise<void> {
    const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
    this.map = new Map(document.getElementById('map') as HTMLElement, {
      center: { lat: 18.50932924799519, lng: 74.32022387996037 },
      zoom: 8,
      mapTypeControl: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapId: this.googleMapsService.mapId
    });
  }

  setStateForEvent() {
    const currentTime = new Date().getTime();
    if (this.eventDetails.eventStartDateTime && this.eventDetails.eventEndDateTime) {
      this.eventState = this.determineEventState(this.eventDetails.eventStartDateTime, this.eventDetails.eventEndDateTime, currentTime);
    }
  }

  private determineEventState(eventStartDate, eventEndDate, currentTime) {
    if (eventStartDate < currentTime && eventEndDate > currentTime) {
      return AppSettings.EVENT_STATE.ONGOING;
    } else if (eventStartDate > currentTime) {
      return AppSettings.EVENT_STATE.UPCOMING;
    } else {
      return AppSettings.EVENT_STATE.COMPLETED;
    }
  }

  setModuleHeaderSecondaryBtnMenuItems() {
    this.moduleHeaderSecondaryBtnMenuItems = [
      {
        label: this.eventDetails.eventStatusStr.toLowerCase() === AppSettings.EVENT_STATUS.ACTIVE.toLowerCase() ? this.cs.getLabel('events.lbl_make_event_inactive') : this.cs.getLabel('events.lbl_make_event_active'),
        icon: AppIcons.OVERDUE + ` wh-16 ${this.eventDetails.eventStatusStr === AppSettings.EVENT_STATUS.ACTIVE ? 'bg-red-500' : 'bg-teal-300'}`,
        command: () => {
          this.showConsentPopup();
        }
      }
    ];
  }

  showConsentPopup() {
    const statusChangeConfirmMsg = this.eventDetails.eventStatusStr === AppSettings.EVENT_STATUS.ACTIVE ? this.cs.getLabel('events.lbl_make_event_inactive_msg') : this.cs.getLabel('events.lbl_make_event_active_msg')
    this.confirmationService.confirm({
      header: this.eventDetails.eventStatusStr === AppSettings.EVENT_STATUS.ACTIVE ? this.cs.getLabel('events.lbl_make_event_inactive') : this.cs.getLabel('events.lbl_make_event_active'),
      message: statusChangeConfirmMsg,
      acceptIcon: 'none',
      rejectIcon: 'none',
      acceptLabel: this.cs.getLabel('events.lbl_save'),
      rejectLabel: this.cs.getLabel('events.lbl_cancel'),
      rejectButtonStyleClass: 'bg-white text-color',
      acceptButtonStyleClass: 'bg-red-500',
      accept: ($event) => {
        this.updateEventStatus();
      },
      reject: () => {
      }
    });
  }

  updateEventStatus() {
    const data = {
      active: this.eventDetails.eventStatusStr === AppSettings.EVENT_STATUS.ACTIVE ? false : true,
      forTenantCode: this.configService.getForTenantCode()
    }
    this.eventService.updateEventStatus(this.eventId, data).subscribe({
      next: () => {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('events.lbl_event_status_updated_successfully') });
        setTimeout(() => {
          this.getEventDetails();
        }, 300);
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
      }
    });
  }

  getEventDetails() {
    this.eventService.getEvent(this.eventId).subscribe({
      next: (result) => {
        this.eventDetails = result;
        if (this.unit === 'miles') {
          this.eventDetails.serviceAreaRadius = this.cs.convertMetersToMiles(this.eventDetails.serviceAreaRadius).toFixed(2);
          this.eventDetails.broadcastRadius = this.cs.convertMetersToMiles(this.eventDetails.broadcastRadius).toFixed(2);
        }
        else {
          this.eventDetails.serviceAreaRadius = this.cs.convertMetersToKm(this.eventDetails.serviceAreaRadius).toFixed(2);
          this.eventDetails.broadcastRadius = this.cs.convertMetersToKm(this.eventDetails.broadcastRadius).toFixed(2);
        }

        this.eventDetails.transportationType = JSON.parse(this.eventDetails.transportationType).join(' | ');
        this.eventCode = this.eventDetails.eventShortcode;
        const currentTime = new Date().getTime();
        const markerlocation = { lat: this.eventDetails.latitude, lng: this.eventDetails.longitude };
        this.setMarker(markerlocation);
        this.imgFileId = this.eventDetails.eventLogoImgId;
        this.imgFileUrl = this.eventImage = this.eventDetails.eventLogoImgUrl;
        this.parseDates();
        this.setStatusData();
        this.showStatus = true;
        this.setModuleHeaderSecondaryBtnMenuItems();
        this.getTimeSettings();
        this.getConfigSettings();
      },
      error: (error) => {

      }
    })
  }

  getTimeSettings() {
    this.eventService.getEventSettingsDetailsByView(AppSettings.ENTITY_TYPE.TIME_SETTINGS, this.eventCode, {}).subscribe({
      next: (response: FindByViewResponse) => {
        this.timeSettingsEntityId = response.entityId;
        this.getEntityDetails();
      },
      error: (error) => {
      }
    })
  }

  getEntityDetails() {
    this.eventService.getEventSettingsEntity(this.timeSettingsEntityId, AppSettings.ENTITY_TYPE.TIME_SETTINGS, AppSettings.VIEW_CODE.ADD_EDIT_VIEW, this.eventCode).subscribe({
      next: (response: EntityResponse) => {
        this.attributeValues = this.cs.getOrgAttributeValues(response);
        this.setHourMinuteSeconds();
        this.cd.detectChanges();
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
      }
    })
  }



  getConfigSettings() {
    this.eventService.getEventSettingsDetailsByView(AppSettings.ENTITY_CODE.CONFIG_SETTING, this.eventCode, {}).subscribe({
      next: (response: FindByViewResponse) => {
        this.configSettingsEntityId = response.entityId;
        this.getConfigSettingEntityDetails();
      },
      error: (error) => {
      }
    })
  }

  getConfigSettingEntityDetails() {
    this.eventService.getEventSettingsEntity(this.configSettingsEntityId, AppSettings.ENTITY_CODE.CONFIG_SETTING, AppSettings.VIEW_CODE.EVENT_ADD_EDIT_VIEW, this.eventCode).subscribe({
      next: (response: EntityResponse) => {
        this.configSettingAttributeValues = this.cs.getOrgAttributeValues(response);
        this.getBookingStatuses();
        this.cd.detectChanges();
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
      }
    })
  }

  getBookingStatuses() {
    this.bookingService.getBookingStatuses().subscribe({
      next: (result: BookingStatus[]) => {
        this.bookingStatuses = result;
        for (const [key, value] of Object.entries(this.configSettingAttributeValues)) {
          if (
            key === AppSettings.ATTRIBUTE_CODE_FOR_CONFIG_SETTINGS.PASSENGER_CANCEL_BOOKING_ALLOWED_STATUS ||
            key === AppSettings.ATTRIBUTE_CODE_FOR_CONFIG_SETTINGS.PASSENGER_EDIT_BOOKING_ALLOWED_STATUS) {
              let configStatusArray = value;
              this.configSettingAttributeValues[key] = [];
              if (configStatusArray && configStatusArray.length > 0) {
                configStatusArray.forEach(statusId => {
                  const statusObject: any = this.bookingStatuses.find(ele => ele.bookingStatusId === statusId);
                  if (statusObject) {
                    const statusString = JSON.parse(statusObject.bookingStatusDisplayStr);
                    if (statusString && statusString.length > 0) {
                      this.configSettingAttributeValues[key].push(statusString[0].label);
                    }
                  }
                });
              }

          }

        }
      },
      error: (error) => { }
    });
  }

  setHourMinuteSeconds() {
    for (let [key, value] of Object.entries(this.attributeValues)) {
      if (value) {
        const hourMinutesSeconds: any = addTimeToCurrentDate(value);
        const hour = hourMinutesSeconds.hour;
        const minutes = hourMinutesSeconds.minutes;
        const seconds = hourMinutesSeconds.seconds;
        this.attributeValues[key] = `${hour > 0 ? hour : '00'}h - ${minutes > 0 ? minutes : '00'}m - ${seconds > 0 ? seconds : `00`}s`
      }
    }
  }

  parseDates() {
    let emptyLabel = this.cs.getLabel('lbl_empty');
    this.eventDetails.eventStartDateTime = this.eventDetails.eventStartDateTime ? this.transformDate(this.eventDetails.eventStartDateTime) : emptyLabel;
    this.eventDetails.eventEndDateTime = this.eventDetails.eventEndDateTime ? this.transformDate(this.eventDetails.eventEndDateTime) : emptyLabel;
    this.eventDetails.startBookingDatePassengerApp = this.eventDetails.startBookingDatePassengerApp ? this.transformDate(this.eventDetails.startBookingDatePassengerApp) : emptyLabel;
    this.eventDetails.stopBookingDatePassengerApp = this.eventDetails.stopBookingDatePassengerApp ? this.transformDate(this.eventDetails.stopBookingDatePassengerApp) : emptyLabel;
  }

  transformDate(date) {
    return this.datePipe.transform(date, this.metaDataService.upperDateFormat)
  }

  setStatusData() {
    this.entityForStatus = {
      entityName: this.eventDetails.eventName
    };

    this.dataForStatus = [
      { label: 'Short Code', text: this.eventDetails.eventShortcode }
    ];
  }

  edit(index, view) {
    this.router.navigate(['app/events/edit/', this.eventId, index, view]);
  }

}
