<p-breadcrumb [model]="routePath"></p-breadcrumb>
<app-module-header [label]="btnLabel" (onBtnClick)="onAddIncidentComplaint()" [moduleHeaderSecondaryBtnMenuItems]="moduleHeaderSecondaryBtnMenuItems"></app-module-header>

<p-tabView [(activeIndex)]="activeIndex" (onChange)="tabViewChange($event)">
  <p-tabPanel>
    <ng-template pTemplate="header">
      <i class="{{miIcons.DASHBOARD_ACTIVITY_FEED}} inline-block wh-14 mr-2"></i>
      <span translate>lbl_all</span>&nbsp;<span class="iconNumber text-color-secondary">({{concernCount}})</span>
    </ng-template>
    <ng-template pTemplate="body">
      @if (data) {
        <app-mi-table
        [collectionOfColumns]="allColumns"
        [attributeLabels]="filterAttributeLabels"
        [data]="tableData"
        entityName="incidents_and_complaint"
        [isLoading]="isLoading"
        (onPageChange)="onPageChange($event)"
        (itemPerPageChange)="itemPerPageChange($event)"
        [totalRecords]="totalRecords"
        [message]="deleteVehicleMsg"
        [header]="deleteIncidentsHeaderMsg"
        (onDelete)="onIncidentComplaintDelete($event)"
        (onBulkDataDeleteEvent)="onBulkDataDeleteEvent($event)"
        (onRowSelection)="onRowSelect($event)"
        [filterList]="filterList"
        (onValueChange)="onFilterValueChange($event)"
        [filterFields]="filterFieldsArray"
        [label]="btnLabel"
        (onBtnClick)="onAddIncidentComplaint()"
        [bulkUpdateLabel]="bulkUpdateLabel"
        [bulkUpdateHeader]="bulkUpdateHeader"
        [bulkDeleteLabel]="bulkDeleteLabel"
        [attributeData]="attributeData"
        (onBulkDataUpdateEvent)="onBulkDataUpdateEvent($event)"
        (onSearchValueChange)="onSearchValueChanges($event)"
        (onUpdate)="onUpdateIncidentComplaint($event)"
        [rowItems]="rowItems"
        [bulkSelectionsItems] = "bulkSelectionsItems"
        (setCurrentRowData)="setCurrentRowData($event)"
        [visiblePrintLabel]="visiblePrintLabel"
        [showBulkUpdateDialog]="showBulkUpdateDialog"
        [visible]="visible"
        (onToggleOverLay)="showBulkUpdateDialog = false; visiblePrintLabel = false"
        (onChangeStatus)="onStatusChange($event)"
        [reviewHeader]="reviewHeader"
        [btnUpdate]="btnUpdate"
        [entityNameToDisplay]="entityNameToDisplay"
        >
        </app-mi-table>
      }
    </ng-template>
  </p-tabPanel>

</p-tabView>

<input hidden type="file" id="upload" (change)="onChange($event)" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" #uploader>
