import { DatePipe } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { BookingService } from "app/modules/bookings/services/booking.service";
import { AppSettings } from "app/modules/shared/app.settings";
import { NoResultFoundComponent } from "app/modules/shared/components/no-result-found/no-result-found.component";
import { Country } from "app/modules/shared/models/country";
import { Language } from "app/modules/shared/models/language";
import { ConfigService } from "app/modules/shared/services/config.service";
import { EntityService } from "app/modules/shared/services/entity.service";
import { EntityList } from "app/modules/vehicles/models/entity.models";
import { ListResponse } from "app/modules/vehicles/models/listResponse.models";
import { forkJoin } from "rxjs";

@Component({
  selector: "app-activity-tab",
  templateUrl: "./activity-tab.component.html",
  styleUrls: ["./activity-tab.component.scss"],
  standalone: true,
  imports: [TranslateModule, DatePipe, NoResultFoundComponent],
})
export class ActivityTabComponent implements OnInit {
  @Input() bookingDetailItem: any;
  data: any;
  country: Country;
  userIds: any;
  activityList: any[] = [];
  usersData: any;
  BOOKING_STATUS_LABEL = AppSettings.BOOKING.STATUS_BY_ID;
  attributeIdForBookingId: any;
  language: Language;
  bookingStatuses: any = [];
  showActivity: boolean = false;
  isActivityDataAvailable: boolean = true;
  entityData: EntityList = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted: AppSettings.DELETED_TYPE.BOTH,
    forTenantCode: this.configService.getForTenantCode(),
    actionStatus: '',
    advanceFilters: [],
  };
  constructor(private configService: ConfigService, 
    private entityService: EntityService,
    private bookingService: BookingService
  ) { }

  ngOnInit(): void {
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.getData();
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
  }

  getData() {
    forkJoin({
      bookingStatusList: this.bookingService.getBookingStatuses(),
      attributeForBooking: this.entityService.getAttributeDefinition(AppSettings.ENTITY_CODE.BOOKING_ACTIVITY, AppSettings.VIEW_CODE.ADVANCED_FILTER_VIEW)
    }).subscribe(resultData => {
      this.setBookingStatusData(resultData);
      this.setAttributeIdAndValueForBookingId(resultData.attributeForBooking)
    })
  }

  setAttributeIdAndValueForBookingId(attributeForBooking) {
    const tabs = attributeForBooking?.tabs.find(ele => ele.tabCode === 'default');
    const groups = tabs ? tabs?.groups.find(ele => ele.code === 'default') : null;
    const bookingIdField = groups ? groups.fields.find(ele => ele.attributeCode === AppSettings.BOOKING_ATTRIBUTE_CODE.BOOKING_ENTITY_ID) : null;
    if(bookingIdField) {
      this.attributeIdForBookingId = bookingIdField.attributeId;
      this.entityData.filters.push({
        attributeId: this.attributeIdForBookingId,
        attributeValue: this.bookingDetailItem.id
      })
      this.searchEntity();
    }
  }

  setBookingStatusData(resultData) {
    this.bookingStatuses = resultData.bookingStatusList;
    this.bookingStatuses.forEach(booking => {
      booking.bookingStatusDisplayStr = JSON.parse(booking.bookingStatusDisplayStr);
      booking.bookingStatusDisplayStr = booking.bookingStatusDisplayStr.filter(status => status.langId === this.language[0].languageId);
    });
  }

  searchEntity() {
    const uniqueUserIds = new Set();
    this.entityData.countryCode = this.country[0].countryCode;
    this.entityService.searchEntity(AppSettings.ENTITY_CODE.BOOKING_ACTIVITY, this.entityData).subscribe((res: ListResponse) => {
      if (res.count === 0) {
        this.isActivityDataAvailable = false;
      }
      this.mapBookingStatuses(res);
      res.data.forEach((activity: any) => {
        uniqueUserIds.add(activity.values.updated_by);
      });
      this.userIds = Array.from(uniqueUserIds);
      if (this.userIds.length > 0) {
        this.getUserDetailsById();
      }
    });
  }

  mapBookingStatuses(apiResponse: any): void {
    this.activityList = apiResponse.data.map(activity => {
      const bookingStatus = activity.values.booking_status;
      const bookingStatusObj = this.bookingStatuses.find(ele => ele.bookingStatusId === bookingStatus); 
      return {
        ...activity,
        values: {
          ...activity.values,
          booking_status_label: (bookingStatusObj && bookingStatusObj?.bookingStatusDisplayStr.length > 0) ? bookingStatusObj?.bookingStatusDisplayStr[0].label : 'Unknown Status',
          bookingIcon: bookingStatusObj?.bookingIcon,
          booking: activity.relatedData.find(ele => ele.entityCode === AppSettings.ENTITY_CODE.BOOKING)?.values
        }
      };
    });
  }

  getUserDetailsById() {
    this.entityService.getUserDetailsById(this.userIds).subscribe(res => {
      this.usersData = res;
      this.mapUserWithActivity();
    });
  }

  mapUserWithActivity() {
    if (this.activityList && this.activityList.length > 0) {
      this.activityList.forEach((element, index) => {
        if (element && element?.values) {
          const user = this.usersData.find(ele => ele.userId === element.values.updated_by);
          const role = user.rolelist[0].roleName;
          element.values.author = user ? `${user?.firstName} ${user?.lastName}` : '-';
          element.values.authorRole = role ? role : '-'
        }
        if (index === this.activityList.length-1) {
          this.showActivity = true;
        }
      });
    }
  }
}
