<div class="grid col-12 top-4 relative">
    <div class="col-12">
        <div class="flex default-text-black-color font-bold" translate>lbl_selected_driver_header
        </div>
    </div>
    <div class="col-6">
      <p-panel>
        <ng-template pTemplate="header">
          <div class="flex justify-content-between align-items-center w-full">
            <div class="flex default-text-black-color"><span class="font-bold">{{'lbl_all_drivers' | translate}}</span>
              <div>&nbsp;({{driverListCount}})</div>
            </div>
            <div class="flex default-text-black-color ml-auto">
              <p-multiSelect #multiSelect [placeholder]="'lbl_select_group' | translate"
                [(ngModel)]="selectedGroup"
                [options]="groupList"
                optionLabel="labelValue"
                [showToggleAll]="true"
                optionValue="id"
                [filter]="true"
                (onChange)="onChangeGroupList($event)"
                (onPanelHide)="clearSearchField(multiSelect)"
                (onFilter)="onFilter($event)">

                <ng-template pTemplate="filtericon">
                    <i [class]="filterIcon" (click)="onFilterIconClick()"></i>
                </ng-template>
              </p-multiSelect>
            </div>
          </div>
        </ng-template>
        <div>
          <span class="p-input-icon-left w-full">
              <i class="{{miIcons.SEARCH}} inline-block wh-12 left-22 relative"></i>
              <input type="text" pInputText (input)="onSearch($event, allDriverData)" [placeholder]="'lbl_search' | translate" />
          </span>
        </div>
        <div class="all-drivers-container">  
          <div  class="all-drivers-list">
            @for (col of allDriverData; track col.header) {
              @if(col.first_name && col.last_name) {
                <div class="all-drivers-box">
                  <div class="driver-checkbox">
                    <p-checkbox [label]="col.first_name + ' ' + col.last_name + (col.group_name ? ' (' + col.group_name + ')' : '')" [(ngModel)]="col.selected" [binary]="true" (onChange)="pushToSelectedColumns(col)"></p-checkbox>
                    <div class="relative left-23">
                      <img class="profile-img" [src]="col.icon" />
                      <span class="font-12 profile-label">{{ col.unique_id }}</span>
                    </div>
                  </div>
                </div>
              }
            }
          </div>
        </div>
      </p-panel>
    </div>

    <div class="col-6">
      <p-panel>
        <ng-template pTemplate="header">
          <div class="flex justify-content-between w-full">
            <div class="flex default-text-black-color"><span class="font-bold">{{'lbl_selected_drivers' | translate}}</span>
              <div>&nbsp;({{selectedColumns?.length}})</div>
            </div>
          </div>
        </ng-template>
        <div class="all-drivers-container mt-0">
          <div class="selected-driver-list">

            @for (column of selectedColumns; track column.first_name; let i = $index ) {
            <div >
              <div class="all-drivers-box align-items-center grid">
                <div class="flex align-items-center justify-content-between w-full">
                  <div class="col-1">
                    <img class="selected-profile-img" [src]="column.icon" />
                  </div>
                  <div class="col-7 pr-0">
                    <div class="selected-drivers">{{ column.first_name }} {{ column.last_name }}</div>
                    <div class="font-12 selected-drivers">{{ column.unique_id }}</div>
                  </div>
                  <div class="col-4 flex justify-content-end pl-0">
                    <div class="pi pi-times-circle cursor-pointer pl-2" (click)="removeFromSelectedColumn(i,column)"></div>
                  </div>
                </div>
              </div>
            </div>
            }
          </div>
        </div>
      </p-panel>
    </div>
  </div>