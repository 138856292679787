import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { MiErrorComponent } from '../../../shared/components/mi-error/mi-error.component';
import { MiFieldsComponent } from '../../../shared/components/mi-fields/mi-fields.component';
import { MiTooltipComponent } from '../../../shared/components/mi-fields/mi-tooltip/mi-tooltip.component';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import * as _ from 'lodash';
import { AppSettings } from '../../../shared/app.settings';
import { EntityService } from '../../../shared/services/entity.service';
import { ConfigService } from '../../../shared/services/config.service';

@Component({
  selector: 'booking-time-restriction',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, MiFieldsComponent, MiErrorComponent, ButtonModule, MiTooltipComponent, TranslateModule],
  templateUrl: './booking-time-restriction.component.html',
  styleUrl: './booking-time-restriction.component.scss'
})
export class BookingTimeRestrictionComponent implements OnInit {
  @Input() group: any;
  @Input() isTwoColStructure: any;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  @Input() miFormGroup: FormGroup;
  @Input() attributeValues;
  @Input() bookingTimeRestrictionIds: any = [];
  @Input() edit: boolean = false;
  slots: any = [];
  groupCopy: any = [];
  bookingTypeRestrictionCount = 1000;

  constructor(
    public cs: CommonBindingDataService,
    private cd: ChangeDetectorRef,
    private passengerService: EntityService,
    private configService: ConfigService
  ) {
  }

  ngOnInit(): void {
    this.moduleName = AppSettings.ENTITY_CODE.PASSENGER_BOOKING_TIME_RESTRICTION;
    this.groupCopy = _.cloneDeep(this.group.fields);
    this.setFields();
    if (this.edit) {
      this.setFieldsForEdit();
    }
  }

  setFieldsForEdit() {
    for (let j = 1; j < this.bookingTimeRestrictionIds.length; j++) {
      this.addMoreSlots();
    }
  }

  setFields() {
    for (const field of this.group.fields) {
      const validators = this.addValidator(field.validation);
      field.attributeCodeOneToMultiple = `${this.bookingTypeRestrictionCount}_${field.attributeCode}`;
      field.isOneToMultiple = true;
      field.fieldName = field.attributeCode;
      const value = this.attributeValues ? this.attributeValues[field?.attributeCodeOneToMultiple] : '';
      this.miFormGroup.registerControl(field.attributeCodeOneToMultiple, new FormControl(value, validators));
    }
    this.slots.push(this.group.fields);
  }

  private addValidator(rules) {
    if (!rules) {
      return [];
    }
    const validators = [];
    (Object.keys(rules).map((rule) => {
      if (rule === "required" && rules.required == true) {
        validators.push(Validators.required);
      }

      if (rule === "min" && rules.min) {
        if (rules[rule] !== null) {
          validators.push(Validators.min(rules[rule] ? rules[rule] : 10))
        }
      }

      if (rule === "max" && rules.max) {
        if (rules[rule] !== null) {
          validators.push(Validators.max(rules[rule] ? rules[rule] : 50));
        }
      }

      if (rule === "pattern" && rules.pattern) {
        if (rules[rule] !== null) {
          const validatorRule = new RegExp([rules[rule]].join(''));
          validators.push(Validators.pattern(rules[rule] ? validatorRule : ''));
        }

      }
      if (rule === "unique" && rules.unique == true) {
        validators.push(Validators.pattern(''));

      }
    }));

    return validators;
  }

  addMoreSlots() {
    this.bookingTypeRestrictionCount += 1;
    const newSlot = _.cloneDeep(this.groupCopy);
    for (const field of newSlot) {
      const validators = this.addValidator(field.validation);
      field.attributeCodeOneToMultiple = `${this.bookingTypeRestrictionCount}_${field.attributeCode}`;
      field.isOneToMultiple = true;
      field.fieldName = field.attributeCode;
      const value = this.attributeValues ? this.attributeValues[field?.attributeCodeOneToMultiple] : '';
      this.miFormGroup.registerControl(field?.attributeCodeOneToMultiple, new FormControl(value, validators));
    }
    this.slots.push(newSlot);
    this.cd.detectChanges();
  }

  removeSlot(index, slot) {
    if (this.bookingTimeRestrictionIds[index]) {
      this.deleteSlotFromDb(index, this.bookingTimeRestrictionIds[index], slot);
    } else {
      this.slots.splice(index, 1);
      this.cd.detectChanges();
    }
  }

  deleteSlotFromDb(index, id, slot) {
    const requestBody = {
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: AppSettings.ENTITY_CODE.PASSENGER_BOOKING_TIME_RESTRICTION,
      entityIds: [id]
    };
    this.passengerService.deleteEntityActionStatus(requestBody).subscribe(response => {
      this.slots.splice(index, 1);
      this.bookingTimeRestrictionIds.splice(index, 1);
      slot.forEach(element => {
        this.miFormGroup.removeControl(element.attributeCode);
      });
      this.cd.detectChanges();
    })
  }
}
