import { AsyncPipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ConfigService } from 'app/modules/shared/services/config.service';
import * as _ from 'lodash';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { forkJoin } from 'rxjs';
import { AppSettings } from '../../../shared/app.settings';
import { MITableComponent } from '../../../shared/components/mi-table/mi-table.component';
import { Country } from '../../../shared/models/country';
import { Language } from '../../../shared/models/language';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { EntityService } from '../../../shared/services/entity.service';
import { AttributeData, entityResponse } from '../../../vehicles/models/attribute.models';
import { EntityCount, EntityCountsResponse, EntityList } from '../../../vehicles/models/entity.models';
import { ListResponse, Values } from '../../../vehicles/models/listResponse.models';

@Component({
  selector: 'app-add-queue',
  standalone: true,
  imports: [BreadcrumbModule, TabViewModule, TranslateModule, MITableComponent, AsyncPipe, ToastModule],
  templateUrl: './add-queue.component.html',
  styleUrl: './add-queue.component.scss'
})
export class AddQueueComponent implements OnInit {
  @ViewChild(MITableComponent) tableRef: MITableComponent;
  @Output() setCountAll: EventEmitter<any> = new EventEmitter<any>();
  activeIndex: number = 0;
  isLoading = true;
  filterAvailableColumns = [];
  @Input() moduleName: string;
  filterList: {};
  btnLabel: string;
  attributeLabels = {};
  filterAttributeLabels = {};
  listColumns: Values;
  allColumns: any;
  pagination: any;
  tableData: any = [];
  country: Country;
  language: Language;
  // entityCount: EntityCountsResponse;
  entityData: EntityList = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
    forTenantCode: this.configService.getForTenantCode(),
    actionStatus: ''
  };
  routePath: MenuItem[] = [];
  filterFieldsArray: any[];
  attributeData: any;
  data: AttributeData;
  rowItems: MenuItem[] | undefined;
  currentRow: any;
  visible: boolean = false;
  totalRecords: number = 0;
  attributeIdOfGroupStatus: string;
  isEntity: boolean = true;
  uniqueId: string = 'add_queue';
  queueData: any;
  queueId: string;
  queueLocationValue: string;
  queueAttributeData: any = {};
  queueTabFields: any = {};
  queueLocationAttributeId: string = 'location_entity_id';
  addedOnAttributeId: string = 'added_on';
  addedOnCurrentTime: number;
  queueAttributes: any[];
  relationshipDataArray: any[];
  relationshipData: any[];
  locationFilters;
  parkingLocationList;
  parkingLocations;
  checkInLocationAttributeId;
  categoryAttributeIdInLocationFilter;
  entityDataForParkingLocation = {
    limit: 2000,
    offset: 0,
    searchStr: '',
    filters: [],
    forTenantCode: this.configService.getForTenantCode(),
    deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
    countryCode: ''
  }

  constructor(private queueManagementService: EntityService,
    private cs: CommonBindingDataService, private confirmationService: ConfirmationService, private cd: ChangeDetectorRef,
    private router: Router, private messageService: MessageService, private actRoute: ActivatedRoute,
    private configService: ConfigService) { }

  ngOnInit() {
    this.setBreadcrumb();
    this.initializeColumn();
    this.setLabels();
    this.getData();
    this.getAttributes();
    this.createAttributes();
    this.getFilterViewOfDriver();
    this.getFilterViewForLocation();
  }

  private setBreadcrumb() {
    this.routePath = [{
      label: this.cs.getLabel('queue.lbl_add_queue'),
      routerLink: '../list',
      icon: 'pi pi-arrow-left',
      iconStyle: { 'font-weight': 'bold', 'margin-right': '10px' }
    }];
  }


  private initializeColumn() {
    this.allColumns = [
      {
        "attributeCode": "first_name",
        "attributeIndex": 0,
        "field": "first_name",
        "header": this.cs.getLabel('queue.columns.driver_name')
      },
      {
        "attributeCode": "duty_status",
        "attributeIndex": 1,
        "field": "duty_status",
        "header": this.cs.getLabel('queue.columns.duty_status')
      },
      {
        "attributeCode": "active_vehicle_id",
        "attributeIndex": 2,
        "field": "active_vehicle_id",
        "header": this.cs.getLabel('queue.columns.vehicle_assigned')
      },
      {
        "attributeCode": "driver_group_id",
        "attributeIndex": 3,
        "field": "driver_group_id",
        "header": this.cs.getLabel('queue.columns.driver_group')
      },
      {
        "attributeCode": "unique_id",
        "attributeIndex": 4,
        "field": "unique_id",
        "header": this.cs.getLabel('queue.columns.unique_id')
      },
      {
        "attributeCode": "parking",
        "attributeIndex": 5,
        "field": "parking",
        "header": this.cs.getLabel('queue.columns.parking')
      },
      {
        "attributeCode": "add_queue_location",
        "attributeIndex": 6,
        "field": "add_queue_location",
        "header": this.cs.getLabel('queue.columns.queue_location')
      },
      {
        "attributeCode": "queue_action",
        "attributeIndex": 7,
        "field": "queue_action",
        "header": this.cs.getLabel('queue.columns.action')
      }
    ];
  }

  public setLabels() {
    const now = new Date();
    this.addedOnCurrentTime = now.getTime();
    this.btnLabel = this.cs.getLabel('queue.lbl_add_queue');
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
  }

  getTableView() {
    this.queueManagementService.getAttributeDefinition(AppSettings.ENTITY_CODE.QUEUE, AppSettings.VIEW_CODE.DEFAULT_TABLE_VIEW).subscribe((res) => {
      if (res) {
        this.isLoading = false;
        this.searchEntity();
        this.filterAvailableColumns = _.clone(this.allColumns);
        this.isEntity = false;
      }
    });
  }

  getFilterViewOfDriver() {
    this.queueManagementService.getAttributeDefinition(AppSettings.ENTITY_CODE.DRIVER, AppSettings.VIEW_CODE.ADVANCED_FILTER_VIEW).subscribe(filterResponse => {
      if (filterResponse) {
       console.log(filterResponse);
       const fields = filterResponse?.tabs[0]?.groups[0]?.fields;
       const categoryField = fields ? fields.find(ele => ele.attributeCode === AppSettings.LOCATION_FILTER_ATTRIBUTES.CHECKIN_LOCATION_ID) : [];
       this.checkInLocationAttributeId = categoryField ? categoryField.attributeId : null;
       console.log(this.checkInLocationAttributeId)
      }
    })
  }

  getFilterViewForLocation() {
    this.queueManagementService.getAttributeDefinition(AppSettings.ENTITY_CODE.LOCATION, AppSettings.VIEW_CODE.DEFAULT_TABLE_VIEW).subscribe(filterResponse => {
      if (filterResponse) {
       this.locationFilters = filterResponse;
       console.log(this.locationFilters);
       const fields = this.locationFilters?.tabs[0]?.groups[0]?.fields;
       const categoryField = fields ? fields.find(ele => ele.attributeCode === AppSettings.LOCATION_FILTER_ATTRIBUTES.LOCATION_CATEGORY) : [];
       this.categoryAttributeIdInLocationFilter = categoryField ? categoryField.attributeId : null;
       console.log(this.categoryAttributeIdInLocationFilter)
       if(this.categoryAttributeIdInLocationFilter) {
         this.searchQueueLocation();
       }
      }
    })
  }

  searchQueueLocation(){
    this.entityDataForParkingLocation.countryCode = this.country[0].countryCode;
    this.entityDataForParkingLocation.filters = [{
      attributeId: this.categoryAttributeIdInLocationFilter,
      attributeValue: [AppSettings.ATTRIBUTE_VALUES_FOR_LOCATION_CATEGORY.PARKING]
    }];

    this.queueManagementService.searchEntity(AppSettings.ENTITY_CODE.LOCATION, this.entityDataForParkingLocation).subscribe((result: any) => {
      this.parkingLocations = result.data;
      this.filterQueueLocation();
    })
  }

  filterQueueLocation() {
    this.parkingLocationList = [];
    this.parkingLocations.forEach(element => {
      this.parkingLocationList.push({
        labelKey: element.values.location_display_name_for_booker,
        labelValue: element.id
      });
      this.getFilterView();
    });
  }

  getFilterView() {
    this.queueManagementService
      .getAttributeDefinition(
        AppSettings.ENTITY_CODE.QUEUE,
        AppSettings.VIEW_CODE.ADVANCED_FILTER_VIEW
      )
      .subscribe((res) => {
        if (res) {
          this.filterList = [
            {
              "attributeCode": "Parking Location",
              "attributeId": this.checkInLocationAttributeId,
              "attributeIndex": 0,
              "presetValues": this.parkingLocationList,
            },
            // {
            //   "attributeCode": "Include Non Check-in Drivers ",
            //   "attributeIndex": 3,
            //   "presetValues": [
            //     {
            //       "labelKey": "Yes",
            //       "labelValue": "Yes",
            //     },
            //     {
            //       "labelKey": "No",
            //       "labelValue": "No",
            //     }
            //   ],
            // }
          ];
        }
      });
  }

  getData() {
    return forkJoin({
      tableViewData: this.getTableView(),
    })
  }

  searchEntity() {
    this.entityData.countryCode = this.country[0].countryCode;
    this.queueManagementService.searchEntity(AppSettings.ENTITY_CODE.DRIVER, this.entityData).subscribe((res: ListResponse) => {
      this.listColumns = res?.data[0]?.values;
      this.pagination = res?.pagination;
      this.totalRecords = res?.count;
      this.tableData = [];
      this.tableData = res.data?.map((data: any) => {
        const relatedData = data?.relatedData || [];
        const groupedData = _.groupBy(relatedData, 'entityCode');

        const relatedVehicle = (groupedData['vehicle'] || []).map(related => ({
          id: related.id,
          ...related.values
        }));

        const relatedDriverGroup = (groupedData['driver_group'] || []).map(related => ({
          id: related.id,
          ...related.values
        }));

        const relatedLocation = (groupedData['location'] || []).map(related => ({
          id: related.id,
          ...related.values
        }));

        if (data?.values?.driver_profile_image_url && (data?.values?.driver_profile_image_url[0])) {
          data.values.profileImage = data?.values?.driver_profile_image_url[0];
        }
  

        return {
          id: data.id,
          ...data.values,
          vehicle: relatedVehicle,
          driverGroup: relatedDriverGroup,
          location: relatedLocation
        };
      }) || [];
    })
  }

  getQueueLocationFromMiTable(event) {
    this.queueLocationValue = event;
  }

  onPageChange(event) {
    this.entityData.offset = event?.first;
    this.entityData.limit = event?.rows;
    this.searchEntity();
  }

  itemPerPageChange(event) {
    this.entityData.limit = event;
    this.searchEntity();
  }

  getAttributes() {
    const entityType = AppSettings.ENTITY_TYPE.QUEUE;
    this.queueManagementService.getAttributeDefinition(entityType, AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe(res => {
      if (res) {
        this.data = res;
        this.attributeLabels = this.cs.getAttributeLabels(this.data);
        this.queueAttributeData = this.cs.getOrganizedAttribute(this.data);
        this.queueTabFields = this.queueAttributeData.tabs[0].groups[0].fields;
        this.relationshipDataArray = this.queueAttributeData.relations;
        const filteredData = this.relationshipDataArray.filter(relationship =>
          relationship.otherEntityCode === "location" || relationship.otherEntityCode === "driver"
        );
        this.relationshipData = filteredData.map(relationship => ({
          entityRelationshipConfigId: relationship.entityRelationshipConfigId,
          otherEntityId: ''
        }));
      }
    });
  }

  createAttributes() {
    this.queueAttributes = [
      {
        attributeCode: 'driver_entity_id',
        rowAttributeProperty: 'id'
      },
      {
        attributeCode: 'location_entity_id',
        rowAttributeProperty: 'location_entity_id'
      },
    ];
  }

  onFilterValueChange(event) {
    console.log(event);
    const queueData = event;
    this.entityData.filters = [];
    for (const [key, value] of Object.entries(queueData)) {
      const attributeValue = value;
      if (attributeValue) {
        this.entityData.filters.push({
          attributeId: this.checkInLocationAttributeId,
          attributeValue
        });
      }
    }
    this.entityData.filters = _.filter(_.uniq(this.entityData.filters, function(item, key, a) {
      if (item.attributeValue.length > 0) {
        return item.attributeCode;

      }
    }), function(element) {
      if (element.attributeValue.length > 0) {
        return true;
      }
      return false;
    });
    this.entityData.offset = event.first;
    this.entityData.filters = this.cs.mapAttributeIds(this.entityData.filters, this.filterList, 'filter');
    this.searchEntity();
  }

  onSearchValueChanges(event) {
    this.entityData.searchStr = event;
    this.searchEntity();
  }

  setCurrentRowData(event) {
    const queueFormData = event;
    this.queueData = {
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: this.data?.entityCode,
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      data: [],
    };

    for (const [key, value] of Object.entries(queueFormData)) {
      const attributeValue = <any>value;
      if (attributeValue) {
        const obj = typeof attributeValue;
        if (obj == "object") {
          if (attributeValue?.length > 0) {
            this.queueData?.data?.push({
              attributeCode: this.queueAttributes?.find(ele => ele?.rowAttributeProperty === key)?.attributeCode,
              attributeValue: typeof attributeValue === 'string' ? attributeValue : attributeValue[0]?.id,
            });
          }
        } else {
          this.queueData?.data?.push({
            attributeCode: this.queueAttributes?.find(ele => ele?.rowAttributeProperty === key)?.attributeCode,
            attributeValue: typeof attributeValue === 'string' ? attributeValue : attributeValue[0]?.id,
          });
        }
      }
    }
    this.queueData?.data?.push({
      attributeCode: this.queueLocationAttributeId,
      attributeValue: this.queueLocationValue,
    });

    const driverEntityId = this.queueData?.data?.find(item => item.attributeCode === "driver_entity_id")?.attributeValue;
    const queueLocation = this.queueData?.data?.find(item => item.attributeCode === "queue_location")?.attributeValue;
    if (driverEntityId && this.relationshipData[0]) {
        this.relationshipData[0].otherEntityId = driverEntityId;
    }
    if (queueLocation && this.relationshipData[1]) {
        this.relationshipData[1].otherEntityId = queueLocation;
    }

    this.queueData.relationshipData = this.relationshipData;
    const entitiesData = {
      countryCode: this.country[0].countryCode,
      tenantCode: this.configService.getForTenantCode(),
      entityCode: this.data.entityCode
    }

    this.queueData.data = this.queueData.data.filter(ele => ele.attributeCode);
    if (this.queueId) {
      this.saveQueueData(this.queueData)
    } else {
      this.createEntityAndUpdateAttributeData(entitiesData);
    }
  }

  createEntityAndUpdateAttributeData(entitiesData) {
    this.queueManagementService.createEntities(entitiesData.entityCode, entitiesData).subscribe((res: entityResponse) => {
      this.queueId = res.entityId;
      this.saveQueueData(this.queueData);
    });
  }

  saveQueueData(queueData?) {
    queueData.data = this.cs.mapAttributeIds(queueData.data, this.queueAttributeData.tabs);
    this.queueManagementService.saveAttributeData(queueData.entityCode, this.queueId, queueData).subscribe(res => {
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Successful', detail: this.cs.getLabel('queue.queue_added_successfully'), });
      this.router.navigate(['app/queue/list']);
    });
  }

  tabViewChange(event) {
    switch (event.index) {
      case 0:
        this.entityData.actionStatus = '';
        this.entityData.deleted = AppSettings.DELETED_TYPE.ONLY_NON_DELETED;
        this.entityData.filters = [];
        this.searchEntity();
        break;
      default:
        break;
    }
  }

}
