import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MiFieldsComponent } from '../mi-fields/mi-fields.component';
import { MiErrorComponent } from '../mi-error/mi-error.component';
import { CommonBindingDataService } from '../../services/common-binding-data.service';
import { MiTooltipComponent } from '../mi-fields/mi-tooltip/mi-tooltip.component';

@Component({
  selector: 'app-passenger-type-field',
  standalone: true,
  imports: [
    CommonModule,
    MiFieldsComponent,
    MiErrorComponent,
    MiTooltipComponent
  ],
  templateUrl: './passenger-type-field.component.html'
})
export class PassengerTypeFieldComponent {
  @Input() field: any;
  @Input() attributeLabels: any;
  @Input() moduleName: string;
  @Input() dateFormat: string;
  @Input() multiSelectDisplay: string;

  constructor(public cs: CommonBindingDataService) {}
} 