import { JsonPipe, NgClass } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NoResultFoundComponent } from 'app/modules/shared/components/no-result-found/no-result-found.component';
import * as _ from 'lodash';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AppIcons } from '../../../../shared/app.icons';
import { AppSettings } from '../../../../shared/app.settings';
import { QuickFilterFormComponent } from '../../../../shared/components/quick-filter-form/quick-filter-form.component';
import { Country } from '../../../../shared/models/country';
import { Language } from '../../../../shared/models/language';
import { CommonBindingDataService } from '../../../../shared/services/common-binding-data.service';
import { ConfigService } from '../../../../shared/services/config.service';
import { EntityService } from '../../../../shared/services/entity.service';
import type { EntityList } from '../../../../vehicles/models/entity.models';
import type { ListResponse, Values } from '../../../../vehicles/models/listResponse.models';
import { BookingService } from 'app/modules/bookings/services/booking.service';
import {  SidebarModule } from 'primeng/sidebar';
@Component({
  selector: 'app-assign-vehicle-from-bookings',
  standalone: true,
  imports: [NgClass, JsonPipe, ProgressSpinnerModule, DialogModule, TranslateModule, QuickFilterFormComponent, InputTextModule, RadioButtonModule, ButtonModule, ReactiveFormsModule, FormsModule, NoResultFoundComponent,SidebarModule],
  templateUrl: './assign-vehicle-from-bookings.component.html',
  styleUrl: './assign-vehicle-from-bookings.component.scss'
})
export class AssignVehicleFromBookingsComponent implements OnInit {
  miIcons = AppIcons;
  @Input() visibleAssignVehicle;
  @Input() vehicleBodyType: string;
  @Output() onToggleOverLay: EventEmitter<boolean> = new EventEmitter();
  @Output() onSelectVehicle: EventEmitter<boolean> = new EventEmitter();
  private searchSubject: Subject<string> = new Subject();
  attributeLabels: any;
  smartSearchTimer: any;
  moduleName: string;
  selectedFilterCount: number;
  driverData: any;
  advanceFilterValue: any[] = [];
  quickFilterValue: any[] = [];
  userId: any = '';
  imgFileId: any;
  selectedModify: {};
  vehicleList: any = [];
  vehicleListTemp: any = [];
  country: Country;
  language: Language;
  listColumns: Values;
  entityData: EntityList = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
    forTenantCode: '',
    actionStatus: AppSettings.VEHICLE_TAB_LIST.ASSIGNED
  };
  hideTable: boolean = false;

  bodyTypeAttribute: any;
  vehicleStatus: any;
  vehicleGroup: any;
  filterList: any = [];
  searchValue: string;
  loading: boolean = true;

  constructor(private vehicleService: EntityService, private cs: CommonBindingDataService, private messageService: MessageService,
    private configService: ConfigService, private bookingService: BookingService) { }

  ngOnInit() {
    this.bookingService.visibleAssignVehicle$.subscribe((visible) => {
      this.visibleAssignVehicle = visible;
    });
    this.moduleName = AppSettings.ENTITY_CODE.VEHICLE;
    this.entityData.forTenantCode = this.configService.getForTenantCode()
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.getFilterView();
  }

  getFilterView() {
    this.vehicleService.getAttributeDefinition(AppSettings.ENTITY_CODE.VEHICLE, AppSettings.VIEW_CODE.ADVANCED_FILTER_VIEW).subscribe(filterResponse => {
      if (filterResponse) {
        this.setVehicleFilterView(filterResponse);
      }
    });
  }

  setVehicleFilterView(filterResponse) {
    const filterList = this.cs.getColumns(filterResponse);
    this.attributeLabels = this.cs.getAttributeLabels(filterResponse);
    this.vehicleBodyType = _.find(filterList, { attributeCode: 'body_type' });
    this.filterList = [this.vehicleBodyType];
    this.searchEntity();
  }



  onHide() {
    this.visibleAssignVehicle = false;
    this.onToggleOverLay.emit(this.visibleAssignVehicle);
    this.bookingService.setVisibleAssignVehicle(false);
  }

  searchEntity() {
    const country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    if (!country || !country[0]?.countryId) {
      console.error('Country information not found in local storage.');
      return;
    }
    this.vehicleList = [];
    this.entityData.countryCode = country[0].countryCode;
    this.loading = true;
    this.vehicleService.searchEntity(AppSettings.ENTITY_CODE.VEHICLE, this.entityData, true).subscribe((res: ListResponse) => {
      this.listColumns = res?.data[0]?.values;
      if (res?.data[0]?.values) {
        for (const data of res.data) {
          this.vehicleList.push({
            id: data.id,
            ...data.values
          });
        }
      } else {
        this.vehicleList = [];
      }
      this.loading = false;
    })
  }

  onFilterValueChange(filterFlag, event) {
    filterFlag ? (this.advanceFilterValue = event) : (this.quickFilterValue = event);

    const combineFilter = { ...this.advanceFilterValue, ...this.quickFilterValue }
    this.selectedFilterCount = 0;

    for (const [key, value] of Object.entries(event)) {
      const attributeValue = value;
      if (attributeValue) {
        this.selectedFilterCount++;
      }

    }
    this.onFilterValue(combineFilter);
  }

  onFilterValue(combineFilter) {
    const vData = combineFilter;
    this.entityData.filters = [];
    for (const [key, value] of Object.entries(vData)) {
      const attributeValue = value;
      if (attributeValue) {
        this.entityData.filters.push({
          attributeCode: key,
          attributeValue
        });
      }
    }
    this.entityData.filters = _.filter(_.uniq(this.entityData.filters, function(item, key, a) {
      if (item.attributeValue.length > 0) {
        return item.attributeCode;

      }
    }), function(element) {
      if (element.attributeValue.length > 0) {
        return true;
      }
      return false;
    });
    this.entityData.offset = 0;
    this.entityData.filters = this.cs.mapAttributeIds(this.entityData.filters, this.filterList, 'filter');
    this.searchEntity();
  }

  assignVehicleData(data) {
    this.onSelectVehicle.emit(data);
    this.visibleAssignVehicle = false;
  }


  clearSearch() {
    this.searchValue = "";
    this.entityData.searchStr = '';
    this.searchEntity();
  }

  onSearch(event) {
    const value = event.target.value;
    if (event.target.value.length >= 3) {
      this.entityData.searchStr = value;
      this.searchEntity();
    } else if (event.target.value.length === 0) {
      this.entityData.searchStr = value
      this.searchEntity();
    }
  }

}

