import { Injectable } from "@angular/core";
import { ConfigService } from "app/modules/shared/services/config.service";
import { RestApiService } from "app/modules/shared/services/rest-api.service";
import { BehaviorSubject, Observable } from "rxjs";
import { AppSettings } from "../app.settings";
import { EventService } from "app/modules/events/services/event.service";
import dayjs from "dayjs";
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import localeData from 'dayjs/plugin/localeData';
import { StorageService } from "./storage.service";
import * as _ from 'lodash' 

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(localeData);

@Injectable({
  providedIn: 'root'
})
export class MetaDataService {
  public countrySubject = new BehaviorSubject<any>(null);
  public languageSubject = new BehaviorSubject<any>(null);
  public menusSubject = new BehaviorSubject<any>(null);
  public eventResponseSubject = new BehaviorSubject<any>(null);
  public generalSettingsSubject = new BehaviorSubject<any>(null);
  public selectedTimezoneSubject = new BehaviorSubject<any>(null);
  country$ = this.countrySubject.asObservable();
  language$ = this.languageSubject.asObservable();
  menus$ = this.menusSubject.asObservable();
  eventResponse$ = this.eventResponseSubject.asObservable();
  generalSettings$ = this.generalSettingsSubject.asObservable();
  selectedTimezone$ = this.selectedTimezoneSubject.asObservable();
  public mobileNumber: string = '';
  public currency: string = '';
  public timeZone: string = '';
  public timeFormat: string = '';
  public timeHourFormat: string = '';
  public dateTimeFormat: string = '';
  public dateTimeFormatPipe: string = '';
  public timeDateFormat: string = '';
  public systemOfMeasurement: string = '';
  // public distanceMeasurement: string = '';
  // public fuelVolume: string = '';
  eventId: any;
  public lowerDateFormat: string = '';
  public upperDateFormat: string = '';
  public upperDateTimeFormat: string = '';
  public upperDateTimeFormatPipe: string = '';
  public upperTimeDateFormat: string = '';
  public selectedTimeZone: string = '';
  public minDateForBooking;
  public maxDateForBooking;
  public latitude: number | null = null;
  public longitude: number | null = null;

  constructor(private restApiService: RestApiService, private confService: ConfigService,
    private eventService: EventService,
    private storageService: StorageService) { }

  updateMetaData(country: any, language: any, menus: any, eventResponse: any, generalSettings: any) {
    this.updateSubjects(country, language, menus, eventResponse, generalSettings);
    this.initializeEventData(eventResponse);
    this.initializeTimezone();
  }

  private updateSubjects(country: any, language: any, menus: any, eventResponse: any, generalSettings: any) {
    this.countrySubject.next(country);
    this.languageSubject.next(language);
    this.menusSubject.next(menus);
    this.eventResponseSubject.next(eventResponse);
    this.generalSettingsSubject.next(generalSettings);
  }

  private initializeEventData(eventResponse: any) {
    const eventCode = JSON.parse(localStorage.getItem('eventCodeData'));
    const event = _.find(eventResponse, { eventShortcode: eventCode }) || eventResponse[0];
    this.eventId = event?.eventId;

    this.eventService.getEvent(this.eventId).subscribe((res: any) => {
      const tenantId = res?.tenantId;
      this.storageService.setItem(AppSettings.TENANT_ID, tenantId);
      this.updateEventFormats(res);
      this.updateEventDates(res);
      this.initializeGeneralSettings();
    });
  }

  private updateEventFormats(eventData: any) {
    if (eventData.shortDateFormat) {
      this.lowerDateFormat = this.formatDateString(eventData.shortDateFormat);
      this.upperDateFormat = this.reverseFormatDateString(eventData.shortDateFormat);
    }
    if (eventData.timeFormat) {
      this.timeFormat = this.getTimeFormatFromEvent(eventData.timeFormat);
    }
    if (eventData.latitude && eventData.longitude) {
      this.latitude = eventData.latitude;
      this.longitude = eventData.longitude;
    }
    this.setDateTimeFormats();
  }

  private updateEventDates(eventData: any) {
    this.minDateForBooking = eventData.eventStartDateTime ? new Date(eventData.eventStartDateTime) : new Date();
    this.maxDateForBooking = eventData.eventEndDateTime ? new Date(eventData.eventEndDateTime) : new Date();
  }

  private initializeGeneralSettings() {
    this.generalSettings$.subscribe((settings) => {
      if (settings) {
        this.setBasicSettings(settings);
        this.setTimeFormat(settings);
        this.setDateTimeFormats();
      }
    });
  }

  private initializeTimezone() {
    this.selectedTimezone$.subscribe((res) => {
      if (!res) {
        return;
      }
      this.selectedTimeZone = res;
    });
  }

  private setBasicSettings(settings: any) {
    this.mobileNumber = this.getMobileNumber(settings);
    this.currency = this.getCurrency(settings);
    this.timeZone = this.getTimeZone(settings);
    this.systemOfMeasurement = this.getSystemOfMeasurement(settings);
    const shortDateFormat = settings.short_date_format || AppSettings.dd_MMM_yyyy_FORMAT;
    if (!this.lowerDateFormat) {
      this.lowerDateFormat = this.formatDateString(shortDateFormat);
      this.upperDateFormat = this.reverseFormatDateString(shortDateFormat);
    }
    this.lowerDateFormat = this.lowerDateFormat || shortDateFormat;
    this.upperDateFormat = this.upperDateFormat || this.reverseFormatDateString(this.lowerDateFormat);
  }
  

  private setTimeFormat(settings: any) {
    if (!this.timeFormat) {
      this.timeFormat = this.getTimeFormat(settings);
    }
    this.timeHourFormat = this.getTimeHourFormat(this.timeFormat);
  }

  private getTimeFormatFromEvent(timeFormat: string): string {
    if (timeFormat === '24- hours format') {
      return '24';
    } else if (timeFormat === '12- hours format') {
      return '12';
    }
    return '12';
  }

  private setDateTimeFormats() {
    this.dateTimeFormat = this.getDateTimeFormat(this.lowerDateFormat, this.timeHourFormat);
    this.dateTimeFormatPipe = this.getDateTimeFormatPipe(this.lowerDateFormat, this.timeHourFormat);
    this.timeDateFormat = this.getTimeDateFormat(this.timeHourFormat, this.lowerDateFormat);
    this.upperDateTimeFormat = this.getDateTimeFormat(this.upperDateFormat, this.timeHourFormat);
    this.upperDateTimeFormatPipe = this.getDateTimeFormatPipe(this.upperDateFormat, this.timeHourFormat);
    this.upperTimeDateFormat = this.getTimeDateFormat(this.timeHourFormat, this.upperDateFormat);
  }

  private getMobileNumber(settings: any): string {
    return settings.mobile_number || AppSettings.DEFAULT_MOBILE_NUMBER_FORMAT;
  }

  private getCurrency(settings: any): string {
    return settings.currency || '';
  }

  private getTimeZone(settings: any): string {
    return settings.time_zone || '';
  }

  private getSystemOfMeasurement(settings: any): string {
    return settings.system_of_measurement || AppSettings.SET_UNIT;
  }

  private getTimeFormat(settings: any): string {
    const timeFormat = settings.time_format || AppSettings.HOUR_FORMAT;
    return timeFormat === '24- hours format' ? '24' : '12';
  }

  private getTimeHourFormat(timeFormat: string): string {
    return timeFormat === '24' 
      ? AppSettings.HOUR_MINUTE 
      : AppSettings.HOUR_MINUTE_FORMAT;
  }

  private getDateTimeFormat(dateFormat: string, timeHourFormat: string): string {
    return `${dateFormat} ${timeHourFormat}`;
  }

  private getDateTimeFormatPipe(dateFormat: string, timeHourFormat: string): string {
    return `${dateFormat} | ${timeHourFormat}`;
  }

  private getTimeDateFormat(timeHourFormat: string, dateFormat: string): string {
    return `${timeHourFormat} ${dateFormat}`;
  }

  metaDataMultipleApi(data): Observable<any> {
    return this.restApiService.post('METADATA MULTIPLE API CALL', `${this.confService.getLoggedInTenantCode()}/api/v1/multi-api-call`, data, '');
  }


  appConfig(entityCode,entityData): Observable<any> {
    if (this.confService.getLoggedInTenantCode()) {
      const url = `${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${entityCode}/entities/search`;
      return this.restApiService.post('SEARCH ENTITY', url, entityData, 'page-center');
    }
  }
  

  getDate(date) {
    return dayjs(date).format(this.lowerDateFormat);
  }

  getUpperDate(date) {
    return dayjs(date).format(this.upperDateFormat);
  }

  formatDateString(dateFormat: string): string {
    dateFormat = dateFormat.replace(/MM/g, 'mm');
    dateFormat = dateFormat.replace(/yyyy/g, 'yy');
    return dateFormat;
  }

  reverseFormatDateString(dateFormat: string): string {
    dateFormat = dateFormat.replace(/mm/g, 'MM');
    if (!dateFormat.includes('yyyy')) {
      dateFormat = dateFormat.replace(/yy/g, 'yyyy');
    }
    return dateFormat;
  }

  getSystemTimezone() {
    const systemTimezone = dayjs.tz.guess();
    return systemTimezone;
  }

  getSelectedTimezone(): string {
    return this.selectedTimeZone || this.timeZone || this.getSystemTimezone();
  }

  getCurrentTimeInTimezone() {
    const now = dayjs().tz(this.getSelectedTimezone());
    return new Date(now.format('YYYY-MM-DD H:mm'));
  }

  getCurrentDateInTimezone() {
    const now = dayjs().tz(this.getSelectedTimezone());
    return new Date(now.format('YYYY-MM-DD'));
  }

  formatDateInTimezone(date: Date): string { 
    const selectedTimezone = this.getSelectedTimezone(); 
    return dayjs.utc(date).tz(selectedTimezone).format('YYYY-MM-DD'); 
  }

  // formatDateToTimezone(date: Date | string, format: string = 'YYYY-MM-DD HH:mm:ss'): string {
  //   return dayjs(date).tz(this.getSelectedTimezone()).format(format);
  // }
}
