<p-breadcrumb [model]="routePath"></p-breadcrumb>
<div appAccessProvider [accessCode]="{ menuCode: 'CONTENT_MANAGEMENT', subMenuCode: 'CONTACT_SUPPORT', pageCode: 'DETAILS' }">
    <div class="flex justify-content-end flex-wrap btn-with-links">
        <button pButton class="mr-2 bg-white default-text-light-black-color border-button" [label]="'cancel' | translate"
            (click)="onCancel()"></button>
        <p-button class="flex align-items-center justify-content-center event-box" [label]="addBtnLabel"
            (click)="onAdd()" [disabled]="!isFormValid()"></p-button>
    </div>


    <div class="main-settings">
        <p-tabView>
            <p-tabPanel>
                <ng-template pTemplate="header">
                    <i class="mi-lg {{miIcons.DETAILS}}"></i>
                    <span translate>content.lbl_contact_support</span>
                </ng-template>
                <form [formGroup]="supportForm">
                    <div class="card background-not">
                        <div class="grid">
                            <div class="form-field col-12 md:col-12 lg:col-12 field">
                                <label class="flex mb-3 uppercase" translate>content.lbl_contact_support
                                    <span class="required"></span>
                                </label>

                                <hr class="hr-line">

                                <div formArrayName="supports">
                                    @for (support of supports.controls; track support; let i = $index) {
                                    <div [formGroupName]="i" class="p-mb-3">
                                        <div class="grid">
                                            <div class="form-field col-3 md:col-3 lg:col-3 field">
                                                <label class="flex" for="supportName{{i}}">
                                                    {{'content.lbl_support_name' | translate}} {{i + 1}}
                                                </label>

                                                <div class="form-control">
                                                    <input id="supportName{{i}}" type="text" pInputText
                                                        formControlName="supportName"
                                                        class="p-component p-element w-full" [placeholder]="'Enter support name '+ (i + 1)"/>
                                                </div>
                                                @if (isFieldInvalid('supportName', i)) {
                                                    <div class="form-errors mt-1 required-field">
                                                        @if (supports.controls[i].get('supportName')?.errors?.['required']) {
                                                            <div>Support name {{ i + 1 }} is required.</div>
                                                        }
                                                        @if (supports.controls[i].get('supportName')?.errors?.['pattern']) {
                                                            <div>Support name {{ i + 1 }} should only contain alphabets.</div>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                            <div class="form-field col-3 md:col-3 lg:col-3 field">
                                                <label class="flex" for="supportNumber{{i}}">
                                                    {{'content.lbl_support_number' | translate}} {{i + 1}}
                                                </label>
                                                <div class="form-control">
                                                    <div class="flex mobile-dropdown">
                                                        <p-dropdown #dropdown (onChange)="selectCountry($event, i)" [options]="countries" [formControlName]="'countryCode'" [filter]="true" filterBy="value" (onFilter)="onFilter($event)" [ngModelOptions]="{standalone : true}" optionLabel="name" [showClear]="false">
                                                            <ng-template pTemplate="selectedItem">
                                                                @if(supports.controls[i].get('countryCode')?.value) {
                                                                    <div class="flex align-items-center gap-2">
                                                                        <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png" [class]="'flag flag-' + supports.controls[i].get('countryCode')?.value?.code?.toLowerCase()" class="width-18"/>
                                                                        <div>{{ supports.controls[i].get('countryCode')?.value?.value }}</div>
                                                                    </div>
                                                                }
                                                            </ng-template>
                                                            <ng-template let-country pTemplate="item">
                                                                <div class="flex align-items-center gap-2">
                                                                    <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png" [class]="'flag flag-' + country.code.toLowerCase()" class="width-18"/>
                                                                    <div>{{ country.value }}</div>
                                                                </div>
                                                            </ng-template>
                                                            <ng-template pTemplate="filtericon">
                                                                <i [class]="filterIcon" (click)="onFilterIconClick()"></i>
                                                            </ng-template>
                                                        </p-dropdown>
                                                        <input pInputText type="text" id="supportNumber{{i}}" class="p-component p-element w-full input-mobile" (input)="selectCountry($event)" formControlName="supportNumber" pKeyFilter="int"
                                                            [placeholder]="'Enter support number '+ (i + 1)" />
                                                    </div>
                                                </div>
                                                @if (isFieldInvalid('supportNumber', i)) {
                                                    <div class="form-errors mt-1 required-field">
                                                        @if (supports.controls[i].get('supportNumber')?.errors?.['required']) {
                                                            <div>Support number {{ i + 1 }} is required.</div>
                                                        }
                                                        @if (supports.controls[i].get('supportNumber')?.errors?.['maxlength']) {
                                                            <div translate>lbl_mobile_number_max_length</div>
                                                        }
                                                        @if (supports.controls[i].get('supportNumber')?.errors?.['minlength']) {
                                                            <div translate>lbl_mobile_number_max_length</div>
                                                        }
                                                    </div>
                                                }
                                            </div>   
                                            <div class="col-6 md:col-6 lg:col-6 flex justify-content-start field flex"
                                                [ngClass]="{
                                                    'align-items-center': isFieldInvalid('supportName', i) || isFieldInvalid('supportNumber', i),
                                                    'align-items-end': !(isFieldInvalid('supportName', i) || isFieldInvalid('supportNumber', i))
                                                }">
                                                <div class="form-field flex align-items-end mr-4">
                                                    <label class="flex mr-3 mb-2" for="driver{{ i }}"> {{ 'content.lbl_driver' | translate }} </label>
                                                    <p-inputSwitch id="driver{{ i }}" formControlName="driver"></p-inputSwitch>
                                                </div>
                                                <div class="form-field flex align-items-end">
                                                    <label class="flex mr-3 mb-2" for="passenger{{ i }}"> {{ 'content.lbl_passenger' | translate }}</label>
                                                    <p-inputSwitch id="passenger{{ i }}" formControlName="passenger"></p-inputSwitch>
                                                </div>
                                                @if (supports.length > 1) {
                                                    <p-button icon="pi pi-trash" [outlined]="true"  (click)="removeSupport(i)"/>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    }
                                </div>

                                <div class="p-mt-3">
                                    <p-button label="Add More" icon="pi pi-plus-circle" [text]="true" (click)="addSupport()" [disabled]="(supports.length >  maxRestriction)"/>
                                </div>

                            </div>
                        </div>
                    </div>
                </form>

                <div class="flex justify-content-end mr-17 save-action-div">
                    <button pButton class="mr-2 mb-2 bg-color-gray default-text-light-black-color border-button"
                        [label]="'cancel' | translate" (click)="onCancel()"></button>
                    <p-button class="mb-2 flex align-items-center justify-content-center" [label]="addBtnLabel"
                        (click)="onAdd()" [disabled]="!isFormValid()"></p-button>
                </div>
            </p-tabPanel>
        </p-tabView>
    </div>
</div>