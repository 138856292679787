<div class="w-full grid pt-2 relative">
    @for (slot of slots; track slot;let s = $index) {
        <p-accordion class="w-full mb-3 documents-div" [activeIndex]="activeAccordionIndex === s ? 0 : -1">
            @for (accordion of accordions; track accordion;let i = $index) {
                <p-accordionTab [header]="getHeader(s)" class="w-full mb-2 block">
                    <ng-template pTemplate="header">
                        <span class="flex align-items-center justify-content-end gap-2 w-full">
                            @if (slots.length > 1) {
                                <div class="col-1 remove flex justify-content-end align-items-center mr-5">
                                    <label class="pointer flex align-items-center flex-row-reverse text-color-secondary border-1 border-500 border-round-lg p-1" (click)="removeSlot(s, slot)" translate>lbl_remove
                                        <i class="mi-lg basic-delete mr-1"></i>
                                    </label>
                                </div>
                            }
                            @else {
                                <div class="empty-div"></div>
                            }
                        </span>
                    </ng-template>
                    <div class="w-full formgrid grid pt-2">
                    @for (field of slot; track field;let j = $index) {
                        <div class="field col-12 pl-0 md:col-12 lg:col-{{field.uiColumns}}">
                            @if(field.inputCode !== 'spacer' && field.attributeCode !== 'vehicle_entity_id'){
                                <div class="pt-2">
                                    <div class="col-12 pl-0 pb-3">
                                        <label class="flex font-semibold">{{cs.getLabelValue('vehicle_document.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}
                                            @if (field.validation?.required) {
                                                <span class="required">*</span>
                                            }
                                            @if (attributeLabels['vehicle_document.fields.'+field.attributeCode+'.helpTips']) {
                                                <app-mi-tooltip [messageData]="cs.getLabelValue('vehicle_document.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)">
                                                </app-mi-tooltip>
                                            }
                                        </label>
                                    </div>
                                    <app-mi-fields [field]="field" [attributeLabels]="attributeLabels"
                                    [moduleName]="moduleName"></app-mi-fields>
            
                                    <app-mi-error [field]="field" [attributeLabels]="attributeLabels"
                                    [moduleName]="moduleName"></app-mi-error>
                                </div>
                            }
                        </div>
                    }
                    </div>
                </p-accordionTab>
            }    
        </p-accordion>
    }
    @if(slots.length < 5) {
        <div class="add-more px-3 py-2 mt-2 absolute top-100 bg-white border-1 border-200 border-round-lg border-color-secondary" (click)="addMoreSlots()">
            <i class="mi-lg mi-add"></i>
            <label class="pointer text-base" translate>lbl_add_more</label>
        </div>
    }
</div>