<div class="grid">
  <p-dialog class="filter-header " (onHide)="onHide()" [(visible)]="show" [draggable]="false" [position]="position" [style]="{ 'min-width': '50vw', height: '70vh', margin: '0px 0px 0px 0px', padding:0, overflow: 'auto'}" [breakpoints]="{'1200px': '535px', '640px': '50vw'}" >
    <p-header class="flex justify-content-between align-item-center">
        <div class="justify-content-start align-content-start">
          <i class="{{miIcons.TEXT_ALIGN}} inline-block wh-15 mr-10 mt-1"></i>
          <span translate>filters_lbl</span>
        </div>
        <div>
          <button type="button" class="p-dialog-header-close absolute p-link p-ripple" (click)="show = false">
            <span class="font-semibold text-lg" translate>close_filter</span>
          </button>
        </div>
    </p-header>

        <div class="float-l overflow-y-auto filters-tab">
          <div class="filter-list overflow-y-scroll">
            <div>
              @for(item of filterList; track item; let i =$index;) {
                <div class="flex flex-wrap p-3 align-items-center gap-3 filter-item cursor-pointer relative" [ngClass]="{'font-bold': appliedFilterList[item.attributeCode]?.length>0,'active': i===0}" (click)="toggleClass($event,'active');selectionChange(item)">
                      {{cs.getLabelValue(moduleName
                      +'.fields.'+item.attributeCode+'.label',filterAttributeLabels,item.attributeCode)}}

                          @if( this.appliedFilterList[item.attributeCode]){
                           <div class="absolute  right-20"> {{getCount(this.appliedFilterList[item.attributeCode])}}</div>
                          }
                </div>
              }
            </div>
          </div>
        </div>


        <div class="inline-block h-full overflow-hidden overflow-y-scroll filters-val">
          @if (showFilter) {
              <app-advance-filter-form [selected]="selectedFilter" [fields]="filterList" [moduleName]="moduleName"
              [attributeLabels]="filterAttributeLabels" [attributeValues]="attributeValues" (onValueChange)="onFilterValueChange($event)" [isFilterBox]="isFilterBox"></app-advance-filter-form>
          }
        </div>
        <p-divider class="col-12">
        </p-divider>

    <ng-template pTemplate="footer">
        <button class="ml-3 pr-3 pl-3 bg-white border-button text-color-secondary"
        (click)="onClearAll()" [label]="'lbl_clear_all' | translate" pButton></button>
        <button pButton class="ml-3 pr-3 pl-3" [label]="'lbl_apply' | translate" (click)="onApply()" ></button>
    </ng-template>
  </p-dialog>
</div>