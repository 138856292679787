import { JsonPipe } from '@angular/common';
import { ChangeDetectorRef, Component, ViewChild, inject } from '@angular/core';
import { ActivatedRoute, Router, RouterLink, RouterLinkActive, RouterModule, RouterOutlet } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { UIElementService } from 'app/modules/shared/services/ui-element.service';
import * as _ from 'lodash';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { SkeletonModule } from 'primeng/skeleton';
import { TabViewModule } from 'primeng/tabview';
import { Observable, forkJoin, from, of } from 'rxjs';
import { concatMap, switchMap, tap } from 'rxjs/operators';
import { AppSettings } from '../../../shared/app.settings';
import { MiFormComponent } from '../../../shared/components/mi-form/mi-form.component';
import { Country } from '../../../shared/models/country';
import { Language } from '../../../shared/models/language';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { ConfigService } from '../../../shared/services/config.service';
import { EntityService } from '../../../shared/services/entity.service';
import type { entityResponse } from '../../../vehicles/models/attribute.models';
import type { GetAttributes } from '../../models/booking.models';
import { BookingService } from '../../services/booking.service';
import { AssignVehicleFromBookingsComponent } from '../ui-components/assign-vehicle-from-bookings/assign-vehicle-from-bookings.component';
import { BookingFormComponent } from '../ui-components/booking-form/booking-form.component';
import { BookingPassengerAddressComponent } from '../ui-components/booking-passenger-address/booking-passenger-address.component';
import { TransportationTypeComponent } from '../ui-components/transportation-type/transportation-type.component';
import { SelectPassengersComponent } from './select-passengers/select-passengers.component';

interface BookingStop {
  select_location_geolocation: string;
  select_location_address: string;
}

interface BookingData {
  [key: string]: any; // Adjust this to your specific structure if needed
}

export enum AssignmentType {
  NORMAL = 'NORMAL',
  LUGGAGE = 'LUGGAGE',
  ADDITIONAL = 'ADDITIONAL'
}

interface LocationChangeConfirmationResult {
  confirmed: boolean;
  updatedBookingData: any;
}

@Component({
  selector: 'app-add-booking',
  standalone: true,
  imports: [RouterOutlet, RouterLink, RouterLinkActive, RouterModule, BreadcrumbModule, TabViewModule, DialogModule, MiFormComponent,
    ButtonModule, TranslateModule, SelectPassengersComponent, BookingFormComponent, AssignVehicleFromBookingsComponent, JsonPipe,
    BookingPassengerAddressComponent, TransportationTypeComponent, SkeletonModule],
  templateUrl: './add-booking.component.html',
  styleUrl: './add-booking.component.scss'
})
export class AddBookingComponent {
  @ViewChild(BookingFormComponent) miFormComponent: BookingFormComponent;
  activeIndex: number = 0;
  data: GetAttributes;
  bookingAttributeData: any;
  routePath: MenuItem[] = [];
  attributeLabels: any = {};
  moduleName: string;
  country: Country;
  language: Language;
  relation: string = 'oneToOne'
  cols: any = [];
  bookingAttributeLength;
  bookingId: string;
  bookingDocumentId;
  attributeValues = [];
  nextBtnLabel;
  previousBtnLabel;
  entitiesData;
  bookingBookingPass: GetAttributes;
  bookingPass: GetAttributes;
  prefixForOneToManyRelatedFields: any = '_';
  stopLocationCode = 'booking_stops_location_geolocation';
  passengersAttributeCode = 'booking_passenger_section';
  selectVehicleCode = 'selectVehicleCode';
  assignVehicleControlCode = 'vehicle_assignment';
  assignedVehicleId: string;
  stopGeoLocations = [];
  stopAddresses = []
  bookingData: any;
  petBookingData: any;
  selectedPassengers: any[] = [];
  addedPassengerList: any = [];
  passengerView: any;
  isEdit = false;
  bookingBtnLabel: string;
  isEditView: boolean = false;
  passengerAddressVisible: boolean = false;
  passengerId: any;
  bookingPetInfo;
  petArray;
  filteredLabels: any;
  requestedPassengers: any[] = [];

  additionalRequirementData;
  passengerVehicleId = null;
  additionalVehicleIds: any[] = [];
  luggageVehicleIds: any[] = [];
  assignMentType = AssignmentType;
  luggageBookingIds: string[] = [];
  passengerBookingIds: string[] = [];
  mainBookingId: string;
  transportationTypeView: string;
  autoToManual: boolean = false;
  displayAssignPopup: boolean = false;
  isAssignNowClicked: boolean = false;
  petFields: any[] = [];
  petEntityIds: string[] = [];
  ownerPassDetails;
  coPassengerDetails;
  requestedByDetail;
  petInfoDetails;
  stopLocations;
  editRelationBookingData;
  editRelationBookingPassData;
  relatedDataOfLuggage;
  relatedDataOfPassenger;
  relatedDataOfBookingFor;
  relatedDataOfRequestedBy;
  editBookingFor;
  editRequestedBy;
  bookingStatus: string = '';
  activeTransportationType;
  isSpecialRequest;
  isRequestSentFlag;
  tripNotificationOption: string[] = [];
  tripNotificationValue;

  viewReady: boolean = false; // Flag to indicate when the view is ready

  private entityService = inject(EntityService);
  private bookingService = inject(BookingService);
  private cs = inject(CommonBindingDataService);
  private configService = inject(ConfigService);
  private messageService = inject(MessageService);
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private confService = inject(ConfigService);
  public uiService = inject(UIElementService);
  private cd = inject(ChangeDetectorRef);
  private confirmationService = inject(ConfirmationService);

  ngOnInit() {
    this.setLabels();

    this.setRoutePath();
    this.bookingService.selectedPassenger$.subscribe(passengers => {
      if (passengers.length > 0) {
        console.log('passengers', passengers);
        this.passengerId = passengers[0].id;
        this.selectedPassengers = passengers;
        this.addedPassengerList = passengers;
      } else {
        this.passengerId = null;
      }
    });

    this.bookingService.requestedPassenger$.subscribe(requestedPassengers => {
      this.requestedPassengers = requestedPassengers;
    });
  }

  transportationType(event) {
    console.log('booking: ttrasnportation type', event);
    if (this.transportationTypeView !== event) {
      this.transportationTypeView = event;
      this.attributeValues = [];
      this.bookingAttributeData = null;

      this.getAttributes(event);
    }
  }

  handleTransportationTypeList(items: MenuItem[]) {
    const transportationTypes = items;
    if (transportationTypes.length > 0) {
      this.activeTransportationType = transportationTypes[0].label;
    }
  }

  setEditFlow() {
    this.bookingBtnLabel = this.cs.getLabel('bookings.update_book_request');
    this.isEdit = true;
    this.entityService.getEntity(this.bookingId, AppSettings.ENTITY_CODE.BOOKING, AppSettings.BOOKING_VIEWS.CAR_JOURNEY).subscribe((res: any) => {
      if (res) {

        const isSpecialRequestFlag = res.attributeCodeValueDtoList.find(attr => attr.attributeCode === 'is_special_request')?.attributeValue;
        this.isSpecialRequest = isSpecialRequestFlag;

        const requestSentFlag = res.attributeCodeValueDtoList.find(attr => attr.attributeCode === 'request_sent')?.attributeValue;
        this.isRequestSentFlag = requestSentFlag;
        const tripNotificationValues = res.attributeCodeValueDtoList.find(attr => attr.attributeCode === 'owner_trip_notification')?.attributeValue;
        this.tripNotificationValue = tripNotificationValues || [];

        res.attributeCodeValueDtoList.forEach(value => {
          if (value?.attributeCode === 'booking_status') {
            this.bookingStatus = value.attributeValue;
          }
        });
        const data = res;

        const relatedData = _.groupBy(res['relatedData'], 'entityCode');
        this.addedPassengerList = this.formattedArray(relatedData?.booking_pass || []);
        const ownerPassId = this.extractOwnerPassId(res);
        const requestPassId = this.extractRequestPassId(res);
        res.attributeCodeValueDtoList.forEach(value => {
          if (value?.attributeCode === 'booking_for') {
            this.editBookingFor = value.attributeValue;
          }
        });
        res.attributeCodeValueDtoList.forEach(value => {
          if (value?.attributeCode === 'requested_by') {
            this.editRequestedBy = value.attributeValue;
          }
        });
        const petInfoId = this.extractPetInfoId(res);

        let ownerPassDetails = null;
        let requestPassDetails = null;
        let coPassengerDetails = [];
        let petInfoDetails = [];

        res.relatedData.forEach(e => {
          if (e.entityCode === "booking_pass") {
            if (e.entityId === ownerPassId) {
              ownerPassDetails = this.formatPassengerDetails(e);
            } else {
              coPassengerDetails.push(this.formatPassengerDetails(e));
            }
          }
          if (e.entityCode === 'passenger') {
            if (e.entityId === requestPassId){
              requestPassDetails = this.formatPassengerDetails(e);
            }
          }
          if (e.entityCode === 'booking_pet_info') {
            if (petInfoId.includes(e.entityId)) {
              petInfoDetails.push(this.formatPetDetails(e));
            }
          }
        });

        this.ownerPassDetails = ownerPassDetails;
        this.requestedByDetail = requestPassDetails;
        this.coPassengerDetails = coPassengerDetails;
        this.petInfoDetails = petInfoDetails;
        this.attributeValues = this.cs.getOrgAttributeValues(data);
        console.log(this.attributeValues);

        const dateAttributes = [
          AppSettings.DATE_ATTRIBUTE_IDS.PICKUP_DATE,
          AppSettings.DATE_ATTRIBUTE_IDS.PICKUP_TIME,
        ];

        dateAttributes.forEach(attr => {
          this.attributeValues[attr] = this.attributeValues[attr] ? new Date(this.attributeValues[attr]) : null;
        });

        const stopLocations = [];

        stopLocations.push({
          address: this.attributeValues['booking_pickup_location_address'],
          geoLocation: this.attributeValues['booking_pickup_location_geolocation'],
          locationId: this.attributeValues['booking_pickup_location_id'],
          locationCategory: this.attributeValues['booking_pickup_location_category']
        });

        const stopAddresses = this.attributeValues['booking_stops_location_address'] || [];
        const stopGeoLocations = this.attributeValues['booking_stops_location_geolocation'] || [];
        const stopLocationsId = this.attributeValues['booking_stops_location_ids'] || [];

        if (stopAddresses.length > 0 || stopGeoLocations.length > 0 || stopLocationsId.length > 0) {
          for (let i = 0; i < stopAddresses.length; i++) {
            const address = stopAddresses[i];
            const geoLocation = stopGeoLocations[i];
            const locationId = stopLocationsId[i]

            if (address || geoLocation || locationId) {
              stopLocations.push({
                address,
                geoLocation,
                locationId
              });
            }
          }
        }

        stopLocations.push({
          address: this.attributeValues['booking_dropoff_location_address'],
          geoLocation: this.attributeValues['booking_dropoff_location_geolocation'],
          locationId: this.attributeValues['booking_dropoff_location_id'],
          locationCategory: this.attributeValues['booking_dropoff_location_category']
        });

        console.log('generated Stop Locations:', stopLocations);
        this.stopLocations = stopLocations;
        this.viewReady = true;
      }
    });
  }

  private extractOwnerPassId(res: any): string {
    if (!res?.attributeCodeValueDtoList) return null;

    let ownerPassId = null;
    res.attributeCodeValueDtoList.forEach(value => {
      if (value?.attributeCode === 'owner_pass_id') {
        ownerPassId = value.attributeValue;
      }
    });
    return ownerPassId;
  }

  private extractRequestPassId(res: any): string {
    if (!res?.attributeCodeValueDtoList) return null;

    let requestPassId = null;
    res.attributeCodeValueDtoList.forEach(value => {
      if (value?.attributeCode === 'requested_by') {
        requestPassId = value.attributeValue;
      }
    });
    return requestPassId;
  }

  private extractPetInfoId(res: any): string[] {
    if (!res?.attributeCodeValueDtoList) return [];

    let petInfoIds: string[] = [];
    res.attributeCodeValueDtoList.forEach(value => {
      if (value?.attributeCode === 'pet_accommodation') {
        if (Array.isArray(value.attributeValue)) {
          petInfoIds = value.attributeValue;
        }
      }
    });
    return petInfoIds;
  }

  private formatPassengerDetails(passengerData: any): any {
    if (!passengerData?.attributeCodeValueDtoList) return {};

    const passengerDetails = {};
    passengerData.attributeCodeValueDtoList.forEach(attr => {
      if (attr?.attributeCode) {
        passengerDetails[attr.attributeCode] = attr.attributeValue;
      }
    });
    passengerDetails['id'] = passengerData?.entityId;
    return passengerDetails;
  }

  private formatPetDetails(petData: any): any {
    const petDetails = {};
    petData.attributeCodeValueDtoList.forEach(attr => {
      petDetails[attr.attributeCode] = attr.attributeValue;
    });
    return petDetails;
  }


  formattedArray(originalArray) {
    if (!Array.isArray(originalArray)) return [];

    return originalArray.map(item => {
      if (!item) return null;

      const newObj = { entityId: item.entityId };
      if (Array.isArray(item.attributeCodeValueDtoList)) {
        item.attributeCodeValueDtoList.forEach(attr => {
          if (attr?.attributeCode) {
            newObj[attr.attributeCode] = attr.attributeValue;
          }
        });
      }
      return newObj;
    }).filter(Boolean); // Remove null entries
  }

  setLabels() {
    this.nextBtnLabel = this.cs.getLabel('bookings.lbl_book_request');
    this.bookingBtnLabel = this.cs.getLabel('bookings.book_request');
    this.previousBtnLabel = this.cs.getLabel('lbl_previous');
    this.bookingId = this.route.snapshot.paramMap.get('id');
    console.log('bookingId:', this.bookingId);
    this.moduleName = AppSettings.ENTITY_CODE.BOOKING;
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    const href = this.router.url;
    this.isEditView = href.includes('edit');
  }

  onNextBtnClick() {
    const btn = document.getElementById('saveBookingId');
    btn.click();
  }

  getAttributes(event?) {
    const passengerAttributes$ = this.entityService.getAttributeDefinition(
      AppSettings.ENTITY_CODE.BOOKING_PASS,
      AppSettings.VIEW_CODE.DETAIL_VIEW
    );

    const transportationView = this.getTransportationView(event);
    const bookingAttributes$ = this.entityService.getAttributeDefinition(
      AppSettings.ENTITY_CODE.BOOKING,
      transportationView
    );

    forkJoin({
      passenger: passengerAttributes$,
      booking: bookingAttributes$
    }).subscribe({
      next: (result) => {
        console.log("in view api calls", result)
        if (result.passenger) {
          this.bookingBookingPass = null;
          this.bookingBookingPass = _.cloneDeep(result.passenger);
        }

        if (result.booking) {
          this.setBookingView(_.cloneDeep(result.booking));
        }
        if (this.bookingId) {
          this.setEditFlow();
        } else {
          this.viewReady = true;
        }
      },
      error: (error) => {
        console.error('Error fetching attributes:', error);
        this.messageService.add({
          key: 'tst',
          severity: 'error',
          summary: 'Error',
          detail: this.cs.getLabel('error.loading_attributes')
        });
      }
    });
  }

  getTransportationView(type) {
    switch (type) {
      case 'Airport':
        return AppSettings.BOOKING_VIEWS.AIRPORT;
      case 'Car Journey':
        return AppSettings.BOOKING_VIEWS.CAR_JOURNEY;
      case 'Vehicle Transfer':
        return AppSettings.BOOKING_VIEWS.VEHICLE_TRANSFER;
      default:
        return AppSettings.BOOKING_VIEWS.CAR_JOURNEY;
    }
  }

  setPassengerView(passengerAttributeView) {
    this.passengerView = {
      relations: _.find(passengerAttributeView.relations, { otherEntityCode: 'passenger', relation: "oneToOne" })
    }
  }

  setBookingView(res) {
    this.data = null;
    this.data = res;
    this.attributeLabels = this.cs.getAttributeLabels(this.data);
    this.filteredLabels = Object.keys(this.attributeLabels)
      .filter(key => key.startsWith('booking_pass') && key.endsWith('label') && key !== 'booking_pass.passenger_id.label')
      .reduce((result, key) => {
        result[key] = this.attributeLabels[key];
        return result;
      }, {});

    this.bookingAttributeData = this.cs.getOrganizedAttribute(this.data);
    this.bookingAttributeData.tabs[0].groups = this.bookingAttributeData.tabs[0].groups.filter(group => group.code !== 'admin_note');
    this.bookingAttributeLength = this.bookingAttributeData.tabs.length;
    this.editRelationBookingData = _.find(this.data.relations, { otherEntityCode: 'booking', relation: "oneToMany" });
    this.editRelationBookingPassData = _.find(this.data.relations, { otherEntityCode: 'booking_pass', relation: "oneToMany" });

    this.bookingPetInfo = _.find(this.data.relatedAttributes, { entityCode: 'booking_pet_info' });
    this.bookingPetInfo.relations = _.find(this.data.relations, { otherEntityCode: 'booking_pet_info', relation: "oneToMany" });



    const luggageAttributeId = _.get(_.find(this.data?.tabs?.[0]?.groups?.reduce((acc, group) =>
      acc.concat(group.fields), []), { attributeCode: 'luggage_booking_ids' }), 'attributeId');

    const passanegerAttributeId = _.get(_.find(this.data?.tabs?.[0]?.groups?.reduce((acc, group) =>
      acc.concat(group.fields), []), { attributeCode: 'passenger_booking_ids' }), 'attributeId');


    const bookingForAttributeId = _.get(_.find(this.data?.tabs?.[0]?.groups?.reduce((acc, group) =>
      acc.concat(group.fields), []), { attributeCode: 'booking_for' }), 'attributeId');

    const requestedByAttributeId = _.get(_.find(this.data?.tabs?.[0]?.groups?.reduce((acc, group) =>
      acc.concat(group.fields), []), { attributeCode: 'requested_by' }), 'attributeId');

    this.relatedDataOfBookingFor = _.find(this.data.relations, { otherEntityCode: 'passenger', relation: "oneToOne", ownerAttributeId: bookingForAttributeId });
    this.relatedDataOfRequestedBy = _.find(this.data.relations, { otherEntityCode: 'passenger', relation: "oneToOne", ownerAttributeId: requestedByAttributeId });
    this.relatedDataOfLuggage = _.find(this.data.relations, { otherEntityCode: 'booking', relation: "oneToMany", ownerAttributeId: luggageAttributeId });
    this.relatedDataOfPassenger = _.find(this.data.relations, { otherEntityCode: 'booking', relation: "oneToMany", ownerAttributeId: passanegerAttributeId });


    console.log(luggageAttributeId, passanegerAttributeId, 'relatedDataOfLuggage:', this.relatedDataOfLuggage, 'relatedDataOfPassenger:', this.relatedDataOfPassenger);
    this.updateValidations();
    if (this.bookingPetInfo && this.bookingPetInfo.tabs) {
      const petTab = this.bookingPetInfo.tabs.find(tab => tab.tabCode === 'default');
      if (petTab && petTab.groups[0]) {
        const petGroup = petTab.groups[0];
        this.petFields = petGroup.fields.map((field, index) => ({
          ...field,
          attributeCode: field.attributeCode,
        }));


      }
    }
  }

  updateValidation(data, fieldNames) {
    return _.find(data, (item) =>
      _.find(item.groups, (group) =>
        _.find(group.fields, (field) => {
          if (field.attributeCode === fieldNames) {
            field.validation.required = false;
            return true;
          }
        })
      )
    );
  }


  setRoutePath() {
    this.routePath = [
      {
        label: this.cs.getLabel('bookings.header'),
        routerLink: this.bookingId ? '../../current-bookings' : '../current-bookings',
        icon: 'pi pi-arrow-left',
        iconStyle: { 'font-weight': 'bold', 'margin-right': '10px' }
      },
      {
        label: this.bookingId ? this.cs.getLabel('bookings.edit') : this.cs.getLabel('bookings.details'),
        routerLink: '../current-bookings',
        styleClass: 'breadcrumb-child forward-slash breadcrumb-text',
        style: { 'display': 'flex', 'top': '2px', 'position': 'relative' }
      }
    ];
  }


  extractLocationData(data: BookingData, field: 'geolocation' | 'address'): string[] {
    const results: string[] = [];
    for (const key in data) {
      if (data.hasOwnProperty(key) && key.startsWith('100')) {
        const stop = data[key] as BookingStop;
        if (field === 'geolocation' && stop.select_location_geolocation) {
          results.push(stop.select_location_geolocation);
        } else if (field === 'address' && stop.select_location_address) {
          results.push(stop.select_location_address);
        }
      }
    }

    return results.slice(1, results.length - 1);
  }

  fetchAdditionalRequirementData() {
    this.additionalRequirementData.forEach(vehicle => {
      switch (vehicle.type) {
        case 'passenger':
          if (vehicle.index === 0) {
            this.passengerVehicleId = vehicle.id;
          }
          break;
        case 'additional':
          this.additionalVehicleIds.push(vehicle.id);
          break;
        case 'luggage':
          this.luggageVehicleIds.push(vehicle.id);
          break;
      }
    });
  }

  onSwitchChanged(event: boolean) {
    this.autoToManual = event;
  }

  onAssignNow() {
    this.isAssignNowClicked = true;
    this.displayAssignPopup = false;
  }

  onLater() {
    this.displayAssignPopup = false;
    this.resetBookingState();
    this.cd.detectChanges();
    this.transportationType(this.activeTransportationType);
  }

  selectedTripNotification(selectedValues: string[]) {
    this.tripNotificationOption = selectedValues;
  }

  private resetBookingState() {
    // Reset view states
    this.viewReady = false;
    this.isEdit = false;
    this.isEditView = false;
    this.autoToManual = false;
    this.isAssignNowClicked = false;

    // Reset booking related data
    this.bookingId = '';
    this.mainBookingId = '';
    this.bookingStatus = '';
    this.transportationTypeView = null;

    // Reset form data
    this.bookingBookingPass = null;
    this.bookingPass = null;
    this.attributeValues = [];
    this.bookingAttributeData = null;
    this.data = null;

    // Reset passenger related data
    this.selectedPassengers = [];
    this.addedPassengerList = [];
    this.requestedPassengers = [];
    this.passengerId = null;
    this.passengerView = null;

    // Reset vehicle related data
    this.passengerVehicleId = null;
    this.additionalVehicleIds = [];
    this.luggageVehicleIds = [];
    this.luggageBookingIds = [];
    this.passengerBookingIds = [];

    // Reset pet related data
    this.petArray = null;
    this.petEntityIds = [];
    this.petFields = [];
    this.bookingPetInfo = null;

    // Reset location data
    this.stopGeoLocations = [];
    this.stopAddresses = [];
    this.stopLocations = null;

    // Reset edit flow related data
    this.ownerPassDetails = null;
    this.coPassengerDetails = null;
    this.petInfoDetails = null;
    this.editBookingFor = null;
    this.editRequestedBy = null;
    this.editRelationBookingData = null;
    this.editRelationBookingPassData = null;
    this.relatedDataOfLuggage = null;
    this.relatedDataOfPassenger = null;
    this.relatedDataOfBookingFor = null;
    this.relatedDataOfRequestedBy = null;

    // Reset form component if it exists
    if (this.miFormComponent) {
      this.miFormComponent.miFormGroup.reset();
    }

    // Clear passengers from service
    this.bookingService.clearPassengers();

    // Reset booking button label
    this.bookingBtnLabel = this.cs.getLabel('bookings.book_request');
  }

  onSaveBooking(event) {
    this.saveBooking(event);
    if (this.autoToManual) {
      this.displayAssignPopup = true;
    }
  }

  saveBooking(event) {
    console.log(event);
    const bookingData = _.cloneDeep(event.formData);
    if (this.validatePassengers(bookingData)) {
      return;
    }

    delete bookingData?.owner_pass_id;
    delete bookingData?.booking_status;

    this.initializeBookingData();

    this.extractPetData(bookingData);
    bookingData.transportation_type = this.transportationTypeView;
    bookingData.owner_trip_notification = this.tripNotificationOption;
    this.setBookingForData(bookingData);
    this.setRequestedPassengerData(bookingData);

    let keysWithSubstring = _.keys(bookingData).filter(key => key.includes('booking_stops_location_geolocation'));

    if (keysWithSubstring && keysWithSubstring.length > 0) {
      if (!this.isEdit) {
        keysWithSubstring.shift();
      }
      keysWithSubstring.forEach((key, index) => {
        if (index === 0) {
          this.setGeolocationData(bookingData, 'booking_pickup_location', key);
        } else if (index === keysWithSubstring.length - 1) {
          this.setGeolocationData(bookingData, 'booking_dropoff_location', key);
        } else {
          const locationData = bookingData[key];
          if (locationData) {
            if (bookingData[`booking_stops_location_ids`] && bookingData[`booking_stops_location_ids`].length > 0) {
              bookingData[`booking_stops_location_ids`].push(locationData.location_id);
            } else {
              bookingData[`booking_stops_location_ids`] = [];
              bookingData[`booking_stops_location_ids`].push(locationData.location_id);
            }
          }
        }
      });
    }

    bookingData.booking_stops_location_geolocation = this.extractLocationData(bookingData, 'geolocation');
    bookingData.booking_stops_location_address = this.extractLocationData(bookingData, 'address');

    console.log('bookingData:', bookingData);

    if (this.isSpecialRequest && !this.isRequestSentFlag) {
      this.checkLocationChanges(bookingData).subscribe(result => {
          if (result.confirmed) {
              this.saveConfirmedBooking(result.updatedBookingData);
          } else {
              console.log('Location changes were not applied.');
          }
      });
    } else {
        this.saveConfirmedBooking(bookingData);
    }
  }

  checkLocationChanges(newBookingData: any): Observable<LocationChangeConfirmationResult> {
    const oldPickupLocation = this.attributeValues['booking_pickup_location_address'];
    const oldDropoffLocation = this.attributeValues['booking_dropoff_location_address'];
    const oldStopLocations = this.attributeValues['booking_stops_location_address'] || [];
    const oldPickupTime = this.attributeValues['pickup_time'];

    const newPickupLocation = newBookingData['booking_pickup_location_address'];
    const newDropoffLocation = newBookingData['booking_dropoff_location_address'];
    const newStopLocations = newBookingData['booking_stops_location_address'] || [];
    const newPickupTime = newBookingData['pickup_time'];

    const locationsChanged = newPickupLocation !== oldPickupLocation || newDropoffLocation !== oldDropoffLocation || !_.isEqual(newStopLocations, oldStopLocations);
    const pickupTimeChanged = newPickupTime !== oldPickupTime;

    const changesDetected = locationsChanged || pickupTimeChanged;

    if (changesDetected) {
      return new Observable<LocationChangeConfirmationResult>(observer => {
        this.confirmationService.confirm({
          header: this.cs.getLabel('bookings.lbl_confirmation'),
          message: this.cs.getLabel('bookings.confirmation_msg'),
          acceptIcon: 'none',
          rejectIcon: 'none',
          acceptLabel: 'Yes',
          rejectLabel: 'No',
          accept: () => {
            newBookingData.is_applicable_for_associated_bookings = true;

            observer.next({ confirmed: true, updatedBookingData: newBookingData });
            observer.complete();
          },
          reject: () => {
            observer.next({ confirmed: false, updatedBookingData: null });
            observer.complete();
          }
        });
      });
    }

    return new Observable<LocationChangeConfirmationResult>(observer => observer.next({ confirmed: true, updatedBookingData: newBookingData }));
  }

  saveConfirmedBooking(bookingData) {
    this.additionalRequirementData = bookingData.vehicleAssignmentData;

    this.entitiesData = {
        'countryCode': this.country[0].countryCode,
        'tenantCode': this.confService.getForTenantCode(),
        'entityCode': this.data.entityCode
    };

    if (!this.bookingId) {
        if (this.petArray?.length > 0) {
            this.createPetEntityAndSaveData(bookingData);
        } else {
            this.populateBookingData(bookingData);
            this.createEntityAndSaveBooking(bookingData);
        }
    } else {
        this.populateBookingData(bookingData);
        this.saveBookingData(this.bookingData);
    }
  }

  createPetEntityAndSaveData(bookingData) {
    const petEntityData = {
      'countryCode': this.country[0].countryCode,
      'tenantCode': this.confService.getForTenantCode(),
      'entityCode': 'booking_pet_info'
    };

    // Create an observable for each pet info
    const petRequests = this.petArray?.map(petInfo => {
      return this.entityService.createEntities(petEntityData.entityCode, petEntityData).pipe(
        switchMap((res: entityResponse) => {
          this.petEntityIds.push(res.entityId);
          this.initializePetBookingData();
          this.populatePetBookingData(petInfo);

          const data = this.cs.mapAttributeIds(this.petBookingData.data, this.bookingPetInfo.tabs);
          this.petBookingData.data = data;

          return this.entityService.saveAttributeData(
            this.petBookingData.entityCode,
            res.entityId,
            this.petBookingData
          );
        })
      );
    });

    // Execute all pet requests in sequence
    from(petRequests).pipe(
      concatMap((request: Observable<any>) => request)
    ).subscribe({
      complete: () => {
        this.setRelationshipData(this.petEntityIds);
        bookingData.pet_accommodation = this.petEntityIds;
        this.populateBookingData(bookingData);
        this.createEntityAndSaveBooking(bookingData);
      },
      error: (error) => {
        this.messageService.add({
          key: 'tst',
          severity: 'error',
          summary: 'Error',
          detail: this.cs.getLabel(error)
        });
      }
    });
  }



  setAttributeValuesForStops(key, attributeValue) {
    if (attributeValue) {
      this.stopGeoLocations.push(attributeValue.geoLocation);
      this.stopAddresses.push(attributeValue.address);
    }
  }

  getPassengerIds() {
    return this.selectedPassengers.map(item => typeof item === 'object' ? item.booking_pass_entityId : item) || [];
  }

  saveBookingData(bookingData) {
    this.mapAttributeIds(bookingData);
    console.log(bookingData);
    this.entityService.saveAttributeData(this.entitiesData.entityCode, this.bookingId, bookingData).subscribe(res => {
      if (this.isEditView) {
        this.showSuccessMessage('bookings.booking_update');
      } else {
        this.showSuccessMessage('bookings.booking_added');
        if (!this.autoToManual) {
          this.onLater();
        }
      }


    });
  }


  onCancel() {
    this.router.navigate(['app/bookings/current-bookings']);
  }

  onPassengerList(data) {
    this.selectedPassengers = data;
  }

  private mapAttributeIds(bookingData) {
    bookingData.data = this.cs.mapAttributeIds(bookingData.data, this.bookingAttributeData.tabs);
  }


  private createEntityAndSaveBooking(bookingData) {
    this.entityService.createEntities(this.entitiesData.entityCode, this.entitiesData).pipe(
      switchMap((res: entityResponse) => {
        this.bookingId = res.entityId;
        this.mainBookingId = this.bookingId;

        if (bookingData?.luggage_vehicle_required > 0) {
          return this.createSequentialAssignments(
            bookingData.luggage_vehicle_required,
            AssignmentType.LUGGAGE
          );
        }
        return of(null);
      }),
      switchMap(() => {
        if (bookingData?.additional_passenger_vehicle > 0) {
          return this.createSequentialAssignments(
            bookingData.additional_passenger_vehicle,
            AssignmentType.ADDITIONAL
          );
        }
        return of(null);
      })
    ).subscribe({
      complete: () => {
        if (this.passengerBookingIds.length > 0) {
          this.bookingData.data.push({
            attributeCode: 'passenger_booking_ids',
            attributeValue: this.passengerBookingIds
          });          
          this.passengerBookingIds.forEach(id => {
            this.bookingData.relationshipData.push({
              entityRelationshipConfigId: this.relatedDataOfPassenger?.entityRelationshipConfigId,
              otherEntityId: id
            })
          });
        }
        if (this.luggageBookingIds.length > 0) {
          this.bookingData.data.push({
            attributeCode: 'luggage_booking_ids',
            attributeValue: this.luggageBookingIds
          });

          this.luggageBookingIds.forEach(id => {
            this.bookingData.relationshipData.push({
              entityRelationshipConfigId: this.relatedDataOfLuggage?.entityRelationshipConfigId,
              otherEntityId: id
            })
          });
        }

        this.saveBookingData(this.bookingData);
      },
      error: (error) => {
        this.messageService.add({
          key: 'tst',
          severity: 'error',
          summary: 'Error',
          detail: this.cs.getLabel(error)
        });
      }
    });
  }

  // Helper method to create sequential assignments
  private createSequentialAssignments(count: number, type: AssignmentType): Observable<any> {
    if (!count || count <= 0) return of(null);

    return from(Array(count)).pipe(
      concatMap(() =>
        this.entityService.createEntities(this.entitiesData?.entityCode, this.entitiesData).pipe(
          tap((res: entityResponse) => {
            if (res?.entityId) {
              if (type === AssignmentType.LUGGAGE) {
                this.luggageBookingIds.push(res.entityId);
              } else if (type === AssignmentType.ADDITIONAL) {
                this.passengerBookingIds.push(res.entityId);
              }
            }
          })
        )
      )
    );
  }

  private filterBookingData(item: any): boolean {
    return (
      (!_.isArray(item.attributeValue) || item.attributeValue.length > 0) &&
      item.attributeValue !== null &&
      item.attributeValue !== "" &&
      item.attributeCode !== 'stops' &&
      item.attributeCode !== this.assignVehicleControlCode &&
      item.attributeCode !== this.selectVehicleCode &&
      !/^100[0-9]/.test(item.attributeCode)
    );
  }

  private addStopAttributes() {
    this.bookingData.data.push({
      attributeCode: "booking_stops_location_geolocation",
      attributeValue: this.stopGeoLocations
    });
    this.bookingData.data.push({
      attributeCode: "booking_stops_location_address",
      attributeValue: this.stopAddresses
    });
  }

  private setGeolocationData(bookingData: any, prefix: string, key: string, count?: number) {
    const locationData = bookingData[key];
    if (locationData) {
      bookingData[`${prefix}_address`] = locationData.select_location_address;
      bookingData[`${prefix}_geolocation`] = locationData.select_location_geolocation;
      bookingData[`${prefix}_category`] = locationData.select_location_type;
      bookingData[`${prefix}_id`] = locationData.location_id;
    }
  }

  private initializeBookingData() {
    this.bookingData = {
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: this.data.entityCode,
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      data: [],
      relationshipData: []
    };
  }

  private initializePetBookingData() {
    this.petBookingData = {
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: 'booking_pet_info',
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      data: [],
      relationshipData: []
    };
  }


  private setRelationshipData(petEntityIds: string[]) {
    if (!Array.isArray(petEntityIds) || !this.bookingPetInfo?.relations) return;

    petEntityIds.forEach((id) => {
      if (!id) return;

      const { entityRelationshipConfigId } = this.bookingPetInfo.relations;
      if (entityRelationshipConfigId) {
        this.bookingData.relationshipData.push({
          entityRelationshipConfigId,
          otherEntityId: id
        });
      }
    });
  }

  private populateBookingData(bookingData: any) {
    for (const [key, value] of Object.entries(bookingData)) {
      let attributeValue = value;
      if (AppSettings.BOOKING.DATE_ATTRIBUTE_CODES.includes(key)) {
        attributeValue = new Date(value as string).getTime();
      }
      if (false) {
        // this.setAttributeValuesForStops(key, attributeValue);
      } else {
        this.bookingData.data.push({ attributeCode: key, attributeValue });
      }
    }
    this.bookingData.data = this.bookingData.data.filter(this.filterBookingData.bind(this));
  }

  private populatePetBookingData(bookingData: any) {
    for (const [key, value] of Object.entries(bookingData)) {
      let attributeValue = value;
      if (AppSettings.BOOKING.DATE_ATTRIBUTE_CODES.includes(key)) {
        attributeValue = new Date(value as string).getTime();
      }
      if (false) {
        // this.setAttributeValuesForStops(key, attributeValue);
      } else {
        this.petBookingData.data.push({ attributeCode: key, attributeValue });
      }
    }
  }

  private showErrorMessage() {
    this.messageService.add({
      key: 'tst',
      severity: 'error',
      summary: 'Error',
      detail: this.cs.getLabel('bookings.msg_please_select_passengers')
    });
  }


  private showSuccessMessage(labelKey) {
    this.messageService.add({
      key: 'tst',
      severity: 'success',
      summary: 'Successful',
      detail: this.cs.getLabel(labelKey),
    });
  }

  passengerSidebarOpen() {
    this.uiService.showSideDriverDrawer();
  }

  handleOverlayToggle() {
    this.uiService.hideSideDriverDrawer();
  }

  updateValidations() {
    this.updateValidation(this.bookingAttributeData.tabs, 'booking_pickup_location_address');
    this.updateValidation(this.bookingAttributeData.tabs, 'booking_pickup_location_geolocation');
    this.updateValidation(this.bookingAttributeData.tabs, 'booking_dropoff_location_address');
    this.updateValidation(this.bookingAttributeData.tabs, 'booking_dropoff_location_geolocation');
    this.updateValidation(this.bookingAttributeData.tabs, 'booking_passenger_section');
  }

  private validatePassengers(bookingData): boolean {
    if (!bookingData?.booking_for && this.transportationTypeView !== "Vehicle Transfer") {
      this.showErrorMessage();
      return true;
    }
    return false;
  }


  private extractPetData(bookingData: any) {
    if (bookingData.pet_count > 0) {
      const petData = [];
      for (let i = 1; i <= bookingData.pet_count; i++) {
        const petEntry = {};
        this.petFields.forEach(field => {
          const fieldKey = `${i}_${field.attributeCode}`;
          if (bookingData[fieldKey]) {
            petEntry[field.attributeCode] = bookingData[fieldKey];
            // Remove the numbered field from bookingData
            delete bookingData[fieldKey];
          }
        });
        if (Object.keys(petEntry).length > 0) {
          petData.push(petEntry);
        }
      }
      // Add consolidated pet data to bookingData
      if (petData.length > 0) {
        this.petArray = petData;
      }
    }
  }

  private setBookingForData(bookingData: any): void {
    if (!bookingData) return;

    if (this.isEdit) {
      console.log('edit selected pass:', this.selectedPassengers);
      bookingData.booking_for = this.editBookingFor;
      if (Array.isArray(this.selectedPassengers) && this.selectedPassengers.length > 0) {
        const passengerIds = this.selectedPassengers.filter(pass => pass?.isCoPass).map(passenger => passenger?.id).filter(Boolean);
        // const bookingForChangeId = this.selectedPassengers.filter(pass => pass?.isBookingFor).map(passenger => passenger?.id).filter(Boolean);
        // const coPassChangeIds = this.selectedPassengers.filter(pass => !pass?.isAdded && !pass.isBookingFor).map(passenger => passenger?.id).filter(Boolean);

        if (bookingData && Array.isArray(this.coPassengerDetails)) {
          bookingData.booking_passenger_section = this.coPassengerDetails
            .map(item => item?.id)
            .filter(Boolean);
        }

        bookingData?.booking_passenger_section?.forEach(bookingSectionId => {
          if (bookingSectionId && this.editRelationBookingPassData?.entityRelationshipConfigId) {
            this.bookingData.relationshipData.push({
              entityRelationshipConfigId: this.editRelationBookingPassData.entityRelationshipConfigId,
              otherEntityId: bookingSectionId
            });
          }
        });

        if (passengerIds.length > 0) {
          bookingData.co_passenger_ids = passengerIds;
        }
        // if (bookingForChangeId) {
        //   bookingData.booking_for = bookingForChangeId;
        // }
        // if (coPassChangeIds) {
        //   bookingData.co_passenger_ids = coPassChangeIds;
        // }
      }

      bookingData?.luggage_booking_ids?.forEach(luggageId => {
        this.bookingData.relationshipData.push({
          entityRelationshipConfigId: this.relatedDataOfLuggage?.entityRelationshipConfigId,
          otherEntityId: luggageId
        });
      });

      this.bookingData.relationshipData.push({
        entityRelationshipConfigId: this.relatedDataOfBookingFor?.entityRelationshipConfigId,
        otherEntityId: bookingData.booking_for
      });



    } else {

      if (this.selectedPassengers.length > 0) {
        const passengerIds = this.selectedPassengers.map(passenger => passenger?.id).filter(Boolean);
        const coPassengerIds = passengerIds.slice(1);
        bookingData.booking_for = passengerIds[0];
        bookingData.co_passenger_ids = coPassengerIds;
      } else {
        bookingData.booking_for = null;
        bookingData.co_passenger_ids = null;
      }

      if (this.transportationTypeView !== 'Vehicle Transfer') {
        this.bookingData.relationshipData.push({
          entityRelationshipConfigId: this.relatedDataOfBookingFor?.entityRelationshipConfigId,
          otherEntityId: bookingData.booking_for
        });
      }
    }
  }

  private setRequestedPassengerData(bookingData: any): void {
    if (this.isEdit) {
      if (this.requestedPassengers.length > 0) {
        bookingData.requested_by = this.editRequestedBy;

        this.bookingData.relationshipData.push({
          entityRelationshipConfigId: this.relatedDataOfRequestedBy?.entityRelationshipConfigId,
          otherEntityId: bookingData.requested_by
        });
      }
    
    } else {
      if (this.requestedPassengers.length > 0) {
        const requestPassengerId = this.requestedPassengers[0].id;
        bookingData.requested_by = requestPassengerId;

        if (this.transportationTypeView !== 'Vehicle Transfer') {
          this.bookingData.relationshipData.push({
            entityRelationshipConfigId: this.relatedDataOfRequestedBy?.entityRelationshipConfigId,
            otherEntityId: bookingData.requested_by
          });
        }
      } else {
        bookingData.requested_by = null;
      }
  
      
    }
  }

  ngOnDestroy() {
    this.passengerId = null;
    this.selectedPassengers = [];
    this.addedPassengerList = [];
    this.uiService.hideSideDriverDrawer();
  }

}
