import { Injectable } from '@angular/core';
import { RestApiService } from '../../services/rest-api.service';
import { BehaviorSubject } from 'rxjs';
import { ConfigService } from '../../services/config.service';

@Injectable({
  providedIn: 'root'
})
export class MITableService {

  constructor(private restApiService: RestApiService, private configService: ConfigService) { }

  bulkRowCount = new BehaviorSubject(null);

  updateUserEntityConfig(entityCode: string, data: any) {
    return this.restApiService.post('Update user entity preferences', `${this.configService.getForTenantCode()}/api/v1/entity-conf/${entityCode}/user-preferences`, data, 'page-center');
  }


  getUserEntityConfig(entityCode: string, prefType: string) {
    return this.restApiService.get('GET USER ENTITY CONFIG', `${this.configService.getForTenantCode()}/api/v1/entity-conf/${entityCode}/user-preferences/${prefType}?forTenantCode=${this.configService.getForTenantCode()}`, 'page-center');
  }

}
