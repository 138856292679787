import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MiFieldsComponent } from '../mi-fields/mi-fields.component';
import { MiErrorComponent } from '../mi-error/mi-error.component';
import { MiTooltipComponent } from '../mi-fields/mi-tooltip/mi-tooltip.component';
import { CommonBindingDataService } from '../../services/common-binding-data.service';

@Component({
  selector: 'app-two-column-structure',
  templateUrl: './two-column-structure.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MiTooltipComponent,
    MiFieldsComponent,
    MiErrorComponent
  ]
})
export class TwoColumnStructureComponent {
  @Input() field: any;
  @Input() attributeLabels: any;
  @Input() moduleName: string;
  @Input() dateFormat: string;
  @Input() multiSelectDisplay: any;
  @Input() showHrLine: boolean = false;

  constructor(public cs: CommonBindingDataService) {}
} 