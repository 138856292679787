<app-dashboard-navbar [includeManageWidgets]="false"></app-dashboard-navbar>

<div style="position: relative" appAccessProvider [accessCode]="{ menuCode: 'DASHBOARD', pageCode: 'MAP' }">
  <div class="flex">
    <div class="flex-50 flex col-12 md:col-12">
        <div class="p-fluid ml-1 mr-1 w-full">
            <div class="p-fluid p-formgrid grid">
                <div class="mi-table w-full">
                    <div class="flex mi-filter-container">
                        <div class="flex flex-wrap justify-content-center">
                            <div class="flex-1 flex justify-content-end mr-2">
                                <span class="p-input-icon-left">
                                    <i class="pi search-icon relative left-20 cursor-pointer"
                                        [ngClass]="{ 'pi-search': !searchValue, 'pi-times': searchValue }"
                                        (click)="searchValue ? clearSearch() : null"></i>
                                    <input class="width-200 search-filter" type="text" pInputText (input)="onSearch($event)"
                                        [placeholder]="'lbl_search' | translate" [(ngModel)]="searchValue"/>
                                </span>
                            </div>
                        </div>
                        <div class="flex flex-wrap justify-content-center">
                            <div class="flex-1 flex justify-content-end mr-2">
                                <p-multiSelect #multiSelect [placeholder]="'lbl_vehicle_type' | translate"
                                    [(ngModel)]="selectedVehicleType"
                                    [options]="vehicleTypeList"
                                    optionLabel="labelValue"
                                    [showToggleAll]="true"
                                    optionValue="labelValue"
                                    [filter]="true"
                                    (onChange)="onChangeVehicleType($event)"
                                    (onPanelHide)="clearSearchField(multiSelect)"
                                    (onFilter)="onFilter($event)">

                                    <ng-template pTemplate="filtericon">
                                        <i [class]="filterIcon" (click)="onFilterIconClick()"></i>
                                    </ng-template>
                                </p-multiSelect>
                            </div>
                        </div>
                        <div class="flex flex-wrap justify-content-center">
                          <div class="flex-1 flex justify-content-end mr-2">
                              <p-multiSelect #multiSelect [placeholder]="'lbl_queue_location' | translate"
                                  [(ngModel)]="selectedQueueLocation"
                                  [options]="queueLocationList"
                                  optionLabel="labelKey"
                                  [showToggleAll]="true"
                                  optionValue="labelValue"
                                  [filter]="true"
                                  (onChange)="onChangeQueueLocation($event)"
                                  (onPanelHide)="clearSearchField(multiSelect)"
                                  (onFilter)="onFilter($event)">

                                  <ng-template pTemplate="filtericon">
                                      <i [class]="filterIcon" (click)="onFilterIconClick()"></i>
                                  </ng-template>
                              </p-multiSelect>
                          </div>
                      </div>
                      <!-- @if(selectedQueueLocation.length > 0 || selectedVehicleType.length > 0){ -->
                        <!-- <button  pButton [label]="'clear_all' | translate" (click)="clearFilter()" class="mr-2 bg-white text-primary border-none cursor-pointer clear-btn"></button> -->
                      <!-- } -->
                    </div>
                </div>    
            </div>
        </div>
    </div>
  </div>
  <div class="flex flex-wrap justify-content-between">
    <p-button
      (click)="showRightDriversDialog('right')"
      icon="mi-lg mi-driver wh-30 inline-block"
      styleClass="toggle-btn border-round-left-3xl border-noround-right absolute right-0 top-80px z-1"
      id="driver-dialog" [label]="'lbl_all_drivers_upper' | translate">
    </p-button>
  </div>
  <div class="map-div" id="map"></div>
  <div id="style-selector-control" class="map-control mr-3 dark-light-toggle">
    <p-toggleButton
      id="toggle_button"
      class="p-0"
      id="style-selector"
      offIcon="mi-lg mi-night_mode wh-40"
      onIcon="mi-lg mi-day_mode wh-40"
      (onChange)="toggleDarkAndLightMap($event)"
      [(ngModel)]="checked">
    </p-toggleButton>
  </div>
  <button id="my-location-control" class="geolocation-button" (click)="getCurrentLocation()">
    <i class="mi-lg mi-locate_me_target wh-24" style="background-color: #1e90ff"></i>
  </button>
</div>

<p-sidebar [(visible)]="rightDriversSectionVisible" [position]="rightDialogPosition" [modal]="true">
  <ng-template pTemplate="header">
    <div class="flex justify-content-between items-center w-full">
      <div class="font-semibold font-18 default-text-black-color" translate>lbl_all_drivers_upper</div>
      <span class="flex justify-content-end relative right-m-35 top-4">{{refreshTime}}
        <i class="{{miIcons.REFRESH}} inline-block cursor-pointer wh-20" (click)="onRefresh()"></i>
      </span>
    </div>
  </ng-template>
  
  <ng-template pTemplate="content">
    <div class="flex flex-wrap justify-content-center">
      <div class="flex-1 flex justify-content-center pr-3">
          <span class="p-input-icon-left w-full">
              <i class="pi z-1 relative top-10 cursor-pointer"
                  [ngClass]="{ 'pi-search': !searchDriver, 'pi-times': searchDriver }"
                  (click)="searchDriver ? clearSearchDriver() : null"></i>
              <input class="w-full driver-search-filter" type="text" pInputText (input)="onSearchDriver()"
                  [placeholder]="'lbl_search' | translate" [(ngModel)]="searchDriver"/>
          </span>
      </div>
    </div>
    <hr class="mt-0 mr-3">
    @for(driver of filteredDriverList; track driver){
      <div class="driver-container">
        <div class="col-1 md:col-1 lg:col-1">
          <img class="border-round relative top-4" [src]="driver.profileImage || defaultLoaderImg" height="28px" width="28px"/>
          <span class="{{miIcons.ELLIPSE}} inline-block wh-24 relative mb-2" [ngClass]="getDriverActionStatus(driver)"></span>
        </div>
        <div class="col-6 md:col-6 lg:col-6">
          <div class="row">
            <span class="font-semibold">{{ driver.driverName }}</span>
          </div>
          <div class="row">
            <span class="font-semibold font-13">
              {{ getCallSignCode(driver) }}
              @if(getCallSignCode(driver) && getGroupName(driver)) {
                <span> | </span>
              }
              {{ getGroupName(driver) }}
            </span>
          </div>
          <div class="row">
            <span class="font-13">{{ getVehicleInfo(driver) }}</span>
          </div>
        </div>
        <div class="col-5 md:col-5 lg:col-5 flex flex-column gap-2 save-button">
          @if(driver.queueEntityId) {
            <span class="flex justify-content-between height-34px width-150px">
              <i class="{{miIcons.QUEUE_MANAGEMENT}} wh-16 mt-1px"></i>
              <span class="truncate-text">{{ getQueueLocation(driver) }}</span>
              <i class="{{miIcons.BASIC_DELETE_RED}} wh-18 required-red cursor-pointer" (click)="onRemoveQueue(driver)"></i>
            </span>
          }
          @else {
            <p-button class="flex height-34px" [label]="addToQueueBtn" (click)="addToQueue(driver)"></p-button>
          }
          <button pButton class="flex height-34px text-primary bg-white border-primary" [label]="assignTripBtn" (click)="assignTrip(driver)"></button>
        </div>
      </div>
      <hr class="hr-line mr-3">
    }
  </ng-template>
</p-sidebar>

<!-- Add To Queue Dialog -->
<p-dialog [header]="queueDialogHeader | translate" [(visible)]="isVisibleQueue" [style]="{ width: '400px' }" [draggable]="false" [resizable]="false" (onHide)="onHideQueue()">
  <div>
      <div class="form-field col-12 md:col-12 lg:col-12 pr-4 mb-2">
          <label class="flex mb-2 font-semibold" translate>lbl_select_queue</label>
          <div class="form-control">
            <p-dropdown class="uppercase" appendTo="body" class="p-component p-element w-full" [style]="{'width':'100%'}" [options]="addToQueueList" 
              [placeholder]="'lbl_please_select' | translate" optionValue="labelValue" optionLabel="labelKey" [(ngModel)]="selectedQueue"></p-dropdown>
          </div>
      </div>
  </div>
  <div class="flex justify-content-end mr-4 mb-2">
      <button pButton class="mr-2 mb-2 bg-white default-text-light-black-color border-0 hover:border-300" [label]="'cancel' | translate" (click)="onHideQueue()"></button>
      <p-button class="mb-2 flex align-items-center justify-content-center" [label]="'lbl_add' | translate" (click)="onAddToQueue()"></p-button>
  </div> 
</p-dialog>

<!-- Assign Trip Dialog -->
<p-dialog [header]="tripDialogHeader | translate" [(visible)]="isVisibleTrip" [style]="{ width: '400px' }" [draggable]="false" [resizable]="false" (onHide)="onHideTrip()">
  <div>
      <div class="form-field col-12 md:col-12 lg:col-12 pr-4 mb-2">
          <label class="flex mb-2 font-semibold" translate>lbl_add_booking_id</label>
          <div class="form-control">
            <input class="p-component p-element w-full" type="text" [(ngModel)]="bookingCode" pInputText/>
          </div>
      </div>
  </div>
  <div class="flex justify-content-end mr-4 mb-2">
      <button pButton class="mr-2 mb-2 bg-white default-text-light-black-color border-0 hover:border-300" [label]="'cancel' | translate" (click)="onHideTrip()"></button>
      <p-button class="mb-2 flex align-items-center justify-content-center" [label]="'bookings.assign' | translate" (click)="onAddToTrip()"></p-button>
  </div> 
</p-dialog>
