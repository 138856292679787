<p-breadcrumb [model]="routePath"></p-breadcrumb>

<div class="flex">
    <div class="col-fixed bg-surface hide-scrollbar">
        <div class="navigation-menu">
            <div class="flex my-2">
                <div>
                    <span>
                        <i class="{{miIcons.USER_CIRCLE}} block wh-20"></i>
                    </span>
                </div>
                <div>
                    <p class="font-semibold mx-3 pb-3" translate>settings.lbl_account_settings</p>
                </div>
                <div>
                    <a class="default-text-blue-color ml-4 cursor-pointer" (click)="doLogOut()" translate>lbl_logout</a>
                </div>
            </div>
            <div appAccessProvider [accessCode]="{ menuCode: 'ACCOUNT_SETTINGS', pageCode: 'USER_PROFILE', subPageCode: 'EDIT' }"
                class="navigation-item" [routerLink]="['/app/settings/user-profile']"
                (click)="setActiveSection(sectionHandlers.userProfile); updateBreadcrumb('User Profile')"
                [ngClass]="{'active': activeSection === sectionHandlers.userProfile}">
                <label class="navigation-item-label" translate>settings.lbl_user_profile</label>
            </div>
            <div appAccessProvider [accessCode]="{ menuCode: 'ACCOUNT_SETTINGS', pageCode: 'NOTIFICATION_SETTING', subPageCode: 'EDIT' }"
                class="navigation-item" [routerLink]="['/app/settings/notification-settings']"
                (click)="setActiveSection(sectionHandlers.notificationSettings); updateBreadcrumb('Notification Settings')"
                [ngClass]="{'active': activeSection === sectionHandlers.notificationSettings}">
                <label class="navigation-item-label" translate>settings.lbl_notification_settings</label>
            </div>
            <div appAccessProvider [accessCode]="{ menuCode: 'ACCOUNT_SETTINGS', subMenuCode: 'LOGIN_&_PASSWORD', pageCode: 'LOGIN_INFORMATION', subPageCode: 'EDIT' }"
                class="navigation-item" [routerLink]="['/app/settings/login-and-password']"
                (click)="setActiveSection(sectionHandlers.loginPassword); updateBreadcrumb('Login & Password')"
                [ngClass]="{'active': activeSection === sectionHandlers.loginPassword}">
                <label class="navigation-item-label" translate>settings.lbl_login_and_password</label>
            </div>
        </div>

        <div class="navigation-menu">
            <div class="flex my-2">
                <div>
                    <span>
                        <i class="{{miIcons.CALENDAR_SETTINGS}} block wh-20"></i>
                    </span>
                </div>
                <div>
                    <p class="font-semibold mx-3" translate>settings.lbl_administration</p>
                </div>
            </div>
            <div appAccessProvider [accessCode]="{ menuCode: 'ADMINISTRATION', pageCode: 'GENERAL_SETTING', subPageCode: 'EDIT' }"
                class="navigation-item" [routerLink]="['/app/settings/general-settings']"
                (click)="setActiveSection(sectionHandlers.generalSettings); updateBreadcrumb('General Settings')"
                [ngClass]="{'active': activeSection === sectionHandlers.generalSettings}">
                <label class="navigation-item-label" translate>settings.lbl_general_settings</label>
            </div>
            <div appAccessProvider [accessCode]="{ menuCode: 'ADMINISTRATION', pageCode: 'CONFIGURATION_SETTING', subPageCode: 'EDIT' }"
                class="navigation-item" [routerLink]="['/app/settings/configuration-settings']" (click)="setActiveSection(sectionHandlers.configurationSetting); updateBreadcrumb('Configuration Settings')"
                [ngClass]="{'active': activeSection === sectionHandlers.configurationSetting}">
                <label class="navigation-item-label" translate>settings.lbl_configuration_settings</label>
            </div>
            <div appAccessProvider [accessCode]="{ menuCode: 'ADMINISTRATION', pageCode: 'TIME_SETTING', subPageCode: 'EDIT' }"
                class="navigation-item" [routerLink]="['/app/settings/time-settings']" (click)="setActiveSection(sectionHandlers.timeSettings); updateBreadcrumb('Time Settings')"
                [ngClass]="{'active': activeSection === sectionHandlers.timeSettings}">
                <label class="navigation-item-label" translate>settings.lbl_time_settings</label>
            </div>
            <div appAccessProvider [accessCode]="{ menuCode: 'ADMINISTRATION', pageCode: 'EXPORT_ACCOUNT_DATA', subPageCode: 'EDIT' }"
                class="navigation-item" [routerLink]="['/app/settings/export-account-data']"
                (click)="setActiveSection(sectionHandlers.exportAccountData); updateBreadcrumb('Export Account Data')"
                [ngClass]="{'active': activeSection === sectionHandlers.exportAccountData}">
                <label class="navigation-item-label" translate>settings.lbl_export_account_data</label>
            </div>
            <!-- <div class="navigation-item">
                <label class="navigation-item-label" translate>settings.lbl_integrations</label>
            </div> -->
            <div appAccessProvider [accessCode]="{ menuCode: 'ADMINISTRATION', pageCode: 'SECURITY', subPageCode: 'EDIT' }"
                class="navigation-item" [routerLink]="['/app/settings/security']"
                (click)="setActiveSection(sectionHandlers.security); updateBreadcrumb('Security')"
                [ngClass]="{'active': activeSection === sectionHandlers.security}">
                <label class="navigation-item-label" translate>settings.lbl_security</label>
            </div>
        </div>


        <div class="navigation-menu">
            <div class="flex my-2">
                <div>
                    <span>
                        <i class="{{miIcons.CALENDAR_SETTINGS}} block wh-20"></i>
                    </span>
                </div>
                <div>
                    <p class="font-semibold mx-3" translate>settings.authentication.header</p>
                </div>
            </div>
            <div appAccessProvider [accessCode]="{ menuCode: 'AUTHENTICATION', pageCode: 'USER', subPageCode: 'EDIT' }"
                class="navigation-item" [routerLink]="['/app/settings/auth-user']"
                (click)="setActiveSection(sectionHandlers.authUser); updateBreadcrumb('Authentication / User')"
                [ngClass]="{'active': activeSection === sectionHandlers.authUser}">
                <label class="navigation-item-label" translate>settings.authentication.user</label>
            </div>
            <div appAccessProvider [accessCode]="{ menuCode: 'AUTHENTICATION', pageCode: 'DRIVER', subPageCode: 'EDIT' }"
                class="navigation-item" [routerLink]="['/app/settings/auth-driver']"
                (click)="setActiveSection(sectionHandlers.authDriver); updateBreadcrumb('Authentication / Driver')"
                [ngClass]="{'active': activeSection === sectionHandlers.authDriver}">
                <label class="navigation-item-label" translate>settings.authentication.driver</label>
            </div>
            <div appAccessProvider [accessCode]="{ menuCode: 'AUTHENTICATION', pageCode: 'PASSENGER', subPageCode: 'EDIT' }"
                class="navigation-item" [routerLink]="['/app/settings/auth-passenger']"
                (click)="setActiveSection(sectionHandlers.authPassenger); updateBreadcrumb('Authentication / Passenger')"
                [ngClass]="{'active': activeSection === sectionHandlers.authPassenger}">
                <label class="navigation-item-label" translate>settings.authentication.passenger</label>
            </div>
           
        </div>

        <div class="navigation-menu">
            <div class="flex my-2">
                <div>
                    <span>
                        <i class="{{miIcons.CALENDAR_SETTINGS}} block wh-20"></i>
                    </span>
                </div>
                <div>
                    <p class="font-semibold mx-3" translate>settings.lbl_system_settings</p>
                </div>
            </div>
            <div appAccessProvider [accessCode]="{ menuCode: 'SYSTEM_SETTINGS', pageCode: 'CUSTOM_FIELDS', subPageCode: 'LIST' }"
                class="navigation-item" [routerLink]="['/app/settings/custom-fields']"
                (click)="setActiveSection(sectionHandlers.customFields); updateBreadcrumb('Custom Fields')"
                [ngClass]="{'active': activeSection === sectionHandlers.customFields}">
                <label class="navigation-item-label" translate>settings.lbl_custom_fields</label>
            </div>
            <div appAccessProvider [accessCode]="{ menuCode: 'SYSTEM_SETTINGS', pageCode: 'LABELS', subPageCode: 'LIST' }"
                class="navigation-item" [routerLink]="['/app/settings/labels']"
                (click)="setActiveSection(sectionHandlers.labels); updateBreadcrumb('Labels')"
                [ngClass]="{'active': activeSection === sectionHandlers.labels}">
                <label class="navigation-item-label" translate>settings.lbl_labels</label>
            </div>

            <div class="flex my-2">
                <div>
                    <span>
                        <i class="{{miIcons.CALENDAR_SETTINGS}} block wh-20"></i>
                    </span>
                </div>
                <div>
                    <p class="font-semibold mx-3" translate>settings.lbl_bookings</p>
                </div>
            </div>
            <div appAccessProvider [accessCode]="{ menuCode: 'SETTINGS', pageCode: 'BOOKING', subPageCode: 'LIST' }"
                class="navigation-item" [routerLink]="['/app/settings/ride-preference']"
                (click)="setActiveSection(sectionHandlers.journeyPreference); updateBreadcrumb('Journey Preference')"
                [ngClass]="{'active': activeSection === sectionHandlers.journeyPreference}">
                <label class="navigation-item-label" translate>settings.lbl_journey_preference</label>
            </div>
            <!-- <div class="navigation-item" (click)="setActiveSection(sectionHandlers.bookingTransportationMode); updateBreadcrumb('Transportation Mode')"
                [ngClass]="{'active': activeSection === sectionHandlers.bookingTransportationMode}">
                <a [routerLink]="['/app/settings/booking-transportation-mode']">
                    <label class="navigation-item-label" translate>settings.lbl_transportation_mode</label>
                </a>
            </div>
            <div class="navigation-item" (click)="setActiveSection(sectionHandlers.childSeats); updateBreadcrumb('Child Seats')"
                [ngClass]="{'active': activeSection === sectionHandlers.childSeats}">
                <a [routerLink]="['/app/settings/child-seats']">
                    <label class="navigation-item-label" translate>settings.lbl_child_seats</label>
                </a>
            </div>
            <div class="navigation-item" (click)="setActiveSection(sectionHandlers.luggage); updateBreadcrumb('Luggage')"
                [ngClass]="{'active': activeSection === sectionHandlers.luggage}">
                <a [routerLink]="['/app/settings/luggage']">
                    <label class="navigation-item-label" translate>settings.lbl_luggage</label>
                </a>
            </div>
            <div class="navigation-item" (click)="setActiveSection(sectionHandlers.vehicleBodyType); updateBreadcrumb('Vehicle Body Type')"
                [ngClass]="{'active': activeSection === sectionHandlers.vehicleBodyType}">
                <a [routerLink]="['/app/settings/vehicle-body-type']">
                    <label class="navigation-item-label" translate>settings.lbl_vehicle_body_type</label>
                </a>
            </div>
            <div class="navigation-item" (click)="setActiveSection(sectionHandlers.vehicleDispatch); updateBreadcrumb('vehicleDispatch')"
                [ngClass]="{'active': activeSection === sectionHandlers.vehicleDispatch}">
                <a [routerLink]="['/app/settings/booking-vehicle-dispatch']">
                    <label class="navigation-item-label" translate>settings.lbl_vehicle_dispatch</label>
                </a>
            </div> -->

            <div class="flex my-2">
                <div>
                    <span>
                        <i class="{{miIcons.CALENDAR_SETTINGS}} block wh-20"></i>
                    </span>
                </div>
                <div>
                    <p class="font-semibold mx-3" translate>settings.lbl_events</p>
                </div>
            </div>           

            
            <div appAccessProvider [accessCode]="{ menuCode: 'SETTINGS', pageCode: 'EVENT', subPageCode: 'LIST' }"
                class="navigation-item" [routerLink]="['/app/settings/transportation-type']"
                (click)="setActiveSection(sectionHandlers.transportationType); updateBreadcrumb('Transportation Type')"
                [ngClass]="{'active': activeSection === sectionHandlers.transportationType}">
                <label class="navigation-item-label" translate>settings.lbl_transportation_type</label>
            </div>

            <div class="flex my-2">
                <div>
                    <span>
                        <i class="{{miIcons.CALENDAR_SETTINGS}} block wh-20"></i>
                    </span>
                </div>
                <div>
                    <p class="font-semibold mx-3" translate>settings.lbl_passengers</p>
                </div>
            </div>
            <div appAccessProvider [accessCode]="{ menuCode: 'SETTINGS', pageCode: 'PASSENGER', subPageCode: 'LIST' }"
                class="navigation-item" [routerLink]="['/app/settings/passenger-type']"
                (click)="setActiveSection(sectionHandlers.passengerType); updateBreadcrumb('Passenger Type')"
                [ngClass]="{'active': activeSection === sectionHandlers.passengerType}">
                <label class="navigation-item-label" translate>settings.lbl_passenger_type</label>
            </div>
            <div appAccessProvider [accessCode]="{ menuCode: 'SETTINGS', pageCode: 'PASSENGER', subPageCode: 'LIST' }"
                class="navigation-item" [routerLink]="['/app/settings/privilege-settings']"
                (click)="setActiveSection(sectionHandlers.privilegeSettings); updateBreadcrumb('Privilege Settings')"
                [ngClass]="{'active': activeSection === sectionHandlers.privilegeSettings}">
                <label class="navigation-item-label" translate>settings.lbl_privilege_settings</label>
            </div>

            <div class="flex my-2">
                <div>
                    <span>
                        <i class="{{miIcons.CALENDAR_SETTINGS}} block wh-20"></i>
                    </span>
                </div>
                <div>
                    <p class="font-semibold mx-3" translate>settings.lbl_vehicles</p>
                </div>
            </div>
            <div appAccessProvider [accessCode]="{ menuCode: 'SETTINGS', pageCode: 'VEHICLES', subPageCode: 'LIST' }"
                class="navigation-item" [routerLink]="['/app/settings/vehicle-status']"
                (click)="setActiveSection(sectionHandlers.vehicleStatus); updateBreadcrumb('Vehicle Status')"
                [ngClass]="{'active': activeSection === sectionHandlers.vehicleStatus}">
                <label class="navigation-item-label" translate>settings.lbl_vehicle_status</label>
            </div>
            <div appAccessProvider [accessCode]="{ menuCode: 'SETTINGS', pageCode: 'VEHICLES', subPageCode: 'LIST' }"
                class="navigation-item" [routerLink]="['/app/settings/vehicle-types']"
                (click)="setActiveSection(sectionHandlers.vehicleTypes); updateBreadcrumb('Vehicle Types')"
                [ngClass]="{'active': activeSection === sectionHandlers.vehicleTypes}">
                <label class="navigation-item-label" translate>settings.lbl_vehicle_types</label>
            </div>
            <div appAccessProvider [accessCode]="{ menuCode: 'SETTINGS', pageCode: 'VEHICLES', subPageCode: 'LIST' }"
                class="navigation-item" [routerLink]="['/app/settings/body-types']"
                (click)="setActiveSection(sectionHandlers.bodyTypes); updateBreadcrumb('Body Types')"
                [ngClass]="{'active': activeSection === sectionHandlers.bodyTypes}">
                <label class="navigation-item-label" translate>settings.lbl_body_types</label>
            </div>
            <div appAccessProvider [accessCode]="{ menuCode: 'SETTINGS', pageCode: 'VEHICLES', subPageCode: 'LIST' }"
                class="navigation-item" [routerLink]="['/app/settings/features']"
                (click)="setActiveSection(sectionHandlers.features); updateBreadcrumb('Features')"
                [ngClass]="{'active': activeSection === sectionHandlers.features}">
                <label class="navigation-item-label" translate>settings.lbl_features</label>
            </div>

            <div class="flex my-2">
                <div>
                    <span>
                        <i class="{{miIcons.CALENDAR_SETTINGS}} block wh-20"></i>
                    </span>
                </div>
                <div>
                    <p class="font-semibold mx-3" translate>settings.lbl_drivers</p>
                </div>
            </div>
            <div appAccessProvider [accessCode]="{ menuCode: 'SETTINGS', pageCode: 'DRIVER', subPageCode: 'LIST' }"
                class="navigation-item" [routerLink]="['/app/settings/leave-type']"
                (click)="setActiveSection(sectionHandlers.leaveType); updateBreadcrumb('Leave Type')"
                [ngClass]="{'active': activeSection === sectionHandlers.leaveType}">
                <label class="navigation-item-label" translate>settings.lbl_leave_type</label>
            </div>

            <div appAccessProvider [accessCode]="{ menuCode: 'SETTINGS', pageCode: 'DRIVER', subPageCode: 'LIST' }"
                class="navigation-item" [routerLink]="['/app/settings/reason-for-duty-status']"
                (click)="setActiveSection(sectionHandlers.reasonForDutyStatus); updateBreadcrumb('Reason For Duty Status')"
                [ngClass]="{'active': activeSection === sectionHandlers.reasonForDutyStatus}">
                <label class="navigation-item-label" translate>settings.lbl_reason_for_duty_status</label>
            </div>
       
            <div appAccessProvider [accessCode]="{ menuCode: 'SETTINGS', pageCode: 'DRIVER', subPageCode: 'LIST' }"
                class="navigation-item" [routerLink]="['/app/settings/driver-driving-record']"
                (click)="setActiveSection(sectionHandlers.drivingRecord); updateBreadcrumb('Driving Record')"
                [ngClass]="{'active': activeSection === sectionHandlers.drivingRecord}">
                <label class="navigation-item-label" translate>settings.lbl_driving_record</label>
            </div>

            <div class="flex my-2">
                <div>
                    <span>
                        <i class="{{miIcons.CALENDAR_SETTINGS}} block wh-20"></i>
                    </span>
                </div>
                <div>
                    <p class="font-semibold mx-3" translate>settings.lbl_location</p>
                </div>
            </div>
            <div appAccessProvider [accessCode]="{ menuCode: 'SETTINGS', pageCode: 'LOCATIONS', subPageCode: 'LIST' }"
                class="navigation-item" [routerLink]="['/app/settings/location-category']"
                (click)="setActiveSection(sectionHandlers.locationCategory); updateBreadcrumb('Location Category')"
                [ngClass]="{'active': activeSection === sectionHandlers.locationCategory}">
                <label class="navigation-item-label" translate>settings.lbl_location_category</label>
            </div>

            <div class="flex my-2">
                <div>
                    <span>
                        <i class="{{miIcons.CALENDAR_SETTINGS}} block wh-20"></i>
                    </span>
                </div>
                <div>
                    <p class="font-semibold mx-3" translate>settings.lbl_concerns</p>
                </div>
            </div>

            <div appAccessProvider [accessCode]="{ menuCode: 'SETTINGS', pageCode: 'INCIDENTS_&_COMPLAINTS', subPageCode: 'LIST' }"
                class="navigation-item" [routerLink]="['/app/settings/incidents-status']"
                (click)="setActiveSection(sectionHandlers.incidentComplaintStatus); updateBreadcrumb('Incidents & Complaints Status')"
                [ngClass]="{'active': activeSection === sectionHandlers.incidentComplaintStatus}">
                <label class="navigation-item-label" translate>settings.lbl_incidents_and_complaints_status</label>
            </div>

            <div class="flex my-2">
                <div>
                    <span>
                        <i class="{{miIcons.CALENDAR_SETTINGS}} block wh-20"></i>
                    </span>
                </div>
                <div>
                    <p class="font-semibold mx-3" translate>settings.lbl_shift_management</p>
                </div>
            </div>
            <div appAccessProvider [accessCode]="{ menuCode: 'SETTINGS', pageCode: 'SHIFT_MANAGEMENT', subPageCode: 'LIST' }"
                class="navigation-item" [routerLink]="['/app/settings/group-color']"
                (click)="setActiveSection(sectionHandlers.groupColor); updateBreadcrumb('Group Colors')"
                [ngClass]="{'active': activeSection === sectionHandlers.groupColor}">
                <label class="navigation-item-label" translate>settings.lbl_group_colors</label>
            </div>
            <div appAccessProvider [accessCode]="{ menuCode: 'SETTINGS', pageCode: 'SHIFT_MANAGEMENT', subPageCode: 'LIST' }"
                class="navigation-item" [routerLink]="['/app/settings/reason-for-break']"
                (click)="setActiveSection(sectionHandlers.reasonForBreak); updateBreadcrumb('Reason For Break')"
                [ngClass]="{'active': activeSection === sectionHandlers.reasonForBreak}">
                <label class="navigation-item-label" translate>settings.lbl_reason_for_break</label>
            </div>

            <div class="flex my-2">
                <div>
                    <span>
                        <i class="{{miIcons.CALENDAR_SETTINGS}} block wh-20"></i>
                    </span>
                </div>
                <div>
                    <p class="font-semibold mx-3" translate>settings.lbl_communication</p>
                </div>
            </div>
            <div appAccessProvider [accessCode]="{ menuCode: 'SETTINGS', pageCode: 'COMMUNICATION', subPageCode: 'LIST' }"
                class="navigation-item" [routerLink]="['/app/settings/message-type']"
                (click)="setActiveSection(sectionHandlers.messageType); updateBreadcrumb('Message Type')"
                [ngClass]="{'active': activeSection === sectionHandlers.messageType}">
                <label class="navigation-item-label" translate>settings.lbl_message_type</label>
            </div>
        </div>
    </div>
    <div class="col p-0 scrollable">
        <div>
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
