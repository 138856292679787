<p-breadcrumb [model]="routePath"></p-breadcrumb>
<div appAccessProvider [accessCode]="{ menuCode: 'SHIFT_MANAGEMENT', subMenuCode: 'MANAGE_SHIFT', pageCode: 'ALL', subPageCode: 'VIEW' }" class="shift-color">
<div class="grid mt-1 details-div flex align-items-center justify-content-end pl-3">
    <div class="col-9">
       <label class="shift-name capitalize"> {{ shiftDetails?.shiftName}}</label>
       <label class="driver-count ml-2">{{driverCount}} Drivers</label>
    </div>
    <div class="col-3 text-right flex align-items-center justify-content-end">
        <div appAccessProvider [accessCode]="{ menuCode: 'SHIFT_MANAGEMENT', subMenuCode: 'MANAGE_SHIFT', pageCode: 'ALL', subPageCode: 'EDIT' }">
            <button (click)="edit()" pButton class="edit-btn border-400 bg-white text-color mr-1 pl-2 pr-2 pt-1 pb-1 mr-2 hover:surface-100" [label]="editBtnLabel">
                <i class="mi-lg mr-2 edit-pen edit_pencil wh-16"></i>
            </button>
        </div>
     </div>
</div>

<div class="grid mt-2 ml-3 overview-details">
    <div class="col-6 pl-0">
        <div class="card p-fluid">
            <div class="flex justify-content-between flex-wrap">
                <div class="flex justify-content-center mb-3">
                    <label class="card-heading uppercase" translate>shift.shift_details</label>
                </div>
                <div appAccessProvider [accessCode]="{ menuCode: 'SHIFT_MANAGEMENT', subMenuCode: 'MANAGE_SHIFT', pageCode: 'ALL', subPageCode: 'EDIT' }">
                    <div (click)="edit()" class="edit flex justify-content-center mb-3">
                        <label class="card-action" translate>shift.lbl_edit</label>
                        <i class="mi-lg edit_pencil"></i>
                    </div>
                </div>
            </div>

            <div class="grid">
                <div class="col-6 pt-1">
                    <label class="detail-heading" translate>shift.name</label>
                </div>
                <div class="col-6 pb-1">
                    <label class="detail-value capitalize"> {{ shiftDetails?.shiftName}}</label>
                </div>
            </div>
            <hr class="hr-line">
            <div class="grid">
                <div class="col-6 pt-1">
                    <label class="detail-heading" translate>shift.lbl_shift_start_date</label>
                </div>
                <div class="col-6 pb-1">
                    <label class="detail-value"> {{ shiftDetails?.startDateStr | date: 'dd MMM YYYY' }}</label>
                </div>
            </div>
            <hr class="hr-line">
            <div class="grid">
                <div class="col-6 pt-1">
                    <label class="detail-heading" translate>shift.lbl_end_date</label>
                </div>
                <div class="col-6 pb-1">
                    <label class="detail-value"> {{ shiftDetails?.endDateStr | date: 'dd MMM YYYY' }}</label>
                </div>
            </div>

            <hr class="hr-line">
            <div class="grid">
                <div class="col-6 pt-1">
                    <label class="detail-heading" translate>shift.start_time</label>
                </div>
                <div class="col-6 pb-1">
                    <label class="detail-value capitalize"> {{ shiftDetails?.startTimeStr | date: hourFormat === '12' ? 'hh:mm a' : 'HH:mm'}}</label>
                </div>
            </div>

            <hr class="hr-line">
            <div class="grid">
                <div class="col-6 pt-1">
                    <label class="detail-heading" translate>shift.end_time</label>
                </div>
                <div class="col-6 pb-1">
                    <label class="detail-value capitalize"> {{ shiftDetails?.endTimeStr | date: hourFormat === '12' ? 'hh:mm a' : 'HH:mm'}}</label>
                </div>
            </div>
            <hr class="hr-line">
            <div class="grid">
                <div class="col-6 pt-1">
                    <label class="detail-heading" translate>shift.days</label>
                </div>
                <div class="col-6 pb-1">

                        @for (day of shiftDetails?.weekdaysList; track day; let d = $index) {
                            @if (d === shiftDetails?.weekdaysList.length - 1) {
                                <label class="detail-value capitalize"> {{ day }}</label>
                            } @else {
                                <label class="detail-value capitalize"> {{ day }},</label>
                            }

                        }

                </div>
            </div>

            <hr class="hr-line">
            <div class="grid">
                <div class="col-6 pt-1">
                    <label class="detail-heading" translate>shift.total_break_duration</label>
                </div>
                <div class="col-6 pb-1">
                    <label class="detail-value"> {{ shiftDetails?.breakDurationStr }}</label>
                </div>
            </div>

            <hr class="hr-line">
            <div class="grid">
                <div class="col-6 pt-1">
                    <label class="detail-heading" translate>shift.driver_type</label>
                </div>
                <div class="col-6 pb-1">
                    <label class="detail-value capitalize"> {{ shiftDetails?.assignedEntity === DRIVER_TYPE.DRIVERS ? cs.getLabel('shift.driver') : cs.getLabel('shift.group')}}</label>
                </div>
            </div>
        </div>

        <div class="card p-fluid">
            <div class="flex justify-content-between flex-wrap">
                <div class="flex justify-content-center mb-3">
                    <label class="card-heading uppercase" translate>shift.breaks</label>
                </div>
                <div appAccessProvider [accessCode]="{ menuCode: 'SHIFT_MANAGEMENT', subMenuCode: 'MANAGE_SHIFT', pageCode: 'ALL', subPageCode: 'EDIT' }">
                    <div (click)="edit()" class="edit flex justify-content-center mb-3">
                        <label class="card-action" translate>shift.lbl_edit</label>
                        <i class="mi-lg edit_pencil"></i>
                    </div>
                </div>
            </div>

            @for (break of shiftDetails?.shiftBreakList; track break; let j = $index) {
                <div class="grid">
                    <div class="col-6 pt-1">
                        <label class="detail-heading" translate>shift.lbl_start_time</label>
                    </div>
                    <div class="col-6 pb-1">
                        <label class="detail-value">{{ break?.breakStartTime }}</label>
                    </div>
                </div>
                <hr class="hr-line">
                <div class="grid">
                    <div class="col-6 pt-1">
                        <label class="detail-heading" translate>shift.lbl_end_time</label>
                    </div>
                    <div class="col-6 pb-1">
                        <label class="detail-value">{{ break?.breakEndTime }}</label>
                    </div>
                </div>
                <hr class="hr-line">
                <div class="grid">
                    <div class="col-6 pt-1">
                        <label class="detail-heading" translate>shift.reason</label>
                    </div>
                    <div class="col-6 pb-1">
                        <label class="detail-value">{{ break?.reason }}</label>
                    </div>
                </div>
            }
        </div>
    </div>

    @if (shiftDetails?.assignedEntity === DRIVER_TYPE.GROUPS) {
        <div class="col-6">
            <div class="card p-fluid">
                <div class="grid">
                    <div class="col-6 flex align-items-center justify-content-end">
                        <label class="card-heading uppercase" translate>shift.group</label>
                    </div>
                    <div class="col-6 pr-0">
                            <p-dropdown
                                #groupDropdown
                                [ngModel]="selectedGroup.groupId"
                                [options]="groups"
                                (onChange)="onGroupSelect(groupDropdown.value)"
                                optionValue="groupId"
                                optionLabel="groupName"
                                placeholder="Select a Group" >
                            </p-dropdown>
                    </div>
                </div>

                <div class="grid mt-2">
                    <div class="col-6 pt-1">
                        <label class="detail-heading" translate>shift.status</label>
                    </div>
                    <div class="col-6 pb-1">
                        <span [ngClass]="selectedGroup?.groupStatus?.toLowerCase()"></span>
                        <label class="detail-value"> {{selectedGroup?.groupStatus }}</label>
                    </div>
                </div>
                <hr class="hr-line">
                <div class="grid">
                    <div class="col-6 pt-1">
                        <label class="detail-heading" translate>shift.color</label>
                    </div>
                    <div class="col-6 pb-1 inline-flex">
                        <div class="color-box"  [style.backgroundColor]="selectedGroup?.color"></div>
                        <label class="detail-value ml-2">{{selectedGroup?.color }}</label>
                    </div>
                </div>
                <hr class="hr-line">
                <div class="grid">
                    <div class="col-6 pt-1">
                        <label class="detail-heading" translate>shift.created_on</label>
                    </div>
                    <div class="col-6 pb-1">
                        <label class="detail-value">{{selectedGroup?.createdOn | date: 'dd MMM yyyy | h:mm a' }}</label>
                    </div>
                </div>
                <hr class="hr-line">
                <div class="grid">
                    <div class="col-6 pt-1">
                        <label class="detail-heading" translate>shift.created_by</label>
                    </div>
                    <div class="col-6 pb-1">
                        <label class="detail-value">{{selectedGroup?.createdBy }}</label>
                    </div>
                </div>

                <hr class="hr-line">
                <div class="grid">
                    <div class="col-6 pt-1">
                        <label class="detail-heading" translate>shift.total_drivers</label>
                    </div>
                    <div class="col-6 pb-1">
                        <label class="detail-value">{{selectedGroup?.totalDrivers }}</label>
                    </div>
                </div>
                <hr class="hr-line">

                <div class="mt-3 col-12 pl-0">
                    <label class="driver-in-group-heading uppercase" translate>shift.drivers_in_group <span> ({{selectedGroup?.totalDrivers}})</span></label>
                </div>

                @for (driver of driverList; track driver; let j = $index) {
                    <div class="col-12 pl-0 driver-image">
                        <app-mi-image-container [miImage]="driver?.driverProfileImage" miWidth="30"></app-mi-image-container>
                        <label class="ml-1 driver-name"> {{ driver.driverName }} <span class="driver-id">({{ driver.uniqueId }})</span></label>
                    </div>
                }

            </div>
        </div>
    } @else {
        <div class="col-6">
            <div class="card p-fluid">
                <div class="grid">
                    <div class="col-6 flex">
                        <label class="card-heading uppercase" translate>shift.drivers <span>({{shiftDetails?.shiftDriverList.length}})</span></label>
                    </div>
                </div>
                @for (driver of driverList; track driver; let j = $index) {
                    <div class="col-12 pl-0">
                        <img [src]="driver.profilePhoto || defaultLoaderImg" class="vertical-align-middle border-round-sm driver-img mr-2" />
                        <label class="ml-1 driver-name"> {{ driver.driverName }} <span class="driver-id">({{ driver.uniqueId }})</span></label>
                    </div>
                }
            </div>
        </div>
    }

</div>
</div>
