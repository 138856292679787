import { JsonPipe } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import * as _ from 'lodash';
import { ButtonModule } from "primeng/button";
import { DropdownModule } from "primeng/dropdown";
import { GetAttributes, Group, Passenger } from "../../../../../bookings/models/booking.models";
import { AppIcons } from "../../../../../shared/app.icons";
import { AppSettings } from "../../../../../shared/app.settings";
import { MiFieldsComponent } from "../../../../../shared/components/mi-fields/mi-fields.component";
import { Country } from "../../../../../shared/models/country";
import { Language } from "../../../../../shared/models/language";
import { CommonBindingDataService } from "../../../../../shared/services/common-binding-data.service";
import { ConfigService } from "../../../../../shared/services/config.service";
import { EntityService } from "../../../../../shared/services/entity.service";
import { AttributeLabel } from "../../../../../vehicles/models/attribute.models";
import { EntityList } from "../../../../../vehicles/models/entity.models";
import { TooltipModule } from "primeng/tooltip";
import { Countries } from "app/modules/shared/countries";
import { MiMobileValueComponent } from "app/modules/shared/ui-sharable/mi-mobile-value/mi-mobile-value.component";
import { NoResultFoundComponent } from "app/modules/shared/components/no-result-found/no-result-found.component";
import { MiTooltipComponent } from "app/modules/shared/components/mi-fields/mi-tooltip/mi-tooltip.component";
@Component({
  selector: "app-passenger-tab",
  templateUrl: "./passenger-tab.component.html",
  styleUrls: ["./passenger-tab.component.scss"],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, TranslateModule, DropdownModule, ButtonModule, MiFieldsComponent, JsonPipe,
    TooltipModule, MiMobileValueComponent, NoResultFoundComponent, MiTooltipComponent
  ],
})
export class PassengerTabComponent implements OnInit {
  @Input() bookingDetailItem: any;
  selectedPassenger = [];
  suggestions: any[] | undefined;
  groups: Group[];
  bookingPass: GetAttributes;
  moduleName = 'booking_pass';
  DETAILS_GROUP_CODE = 'details';
  relation: string = 'oneToOne';
  excludeAttributeInLoop = ['pass_full_name', 'pass_type'];
  addedPassengerList = [];
  selectAddedPassenger: Passenger;
  passengerEntityId: string;
  attributeValues: Passenger;
  attributeLabels: AttributeLabel[];
  miIcons = AppIcons;
  country: Country;
  language: Language;
  entityData: EntityList = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
    forTenantCode: "",
    actionStatus: ''
  };
  tableData = [];
  data: GetAttributes;
  bookingAttributeData: any;
  bookingAttributeLength;
  miFormGroup: FormGroup;
  formGroupFields = [];
  currentSelectedCode: string;
  defaultUserImage = '../../../../../assets/images/mi-theme/light/basic/default_user.svg';
  countryCodeWithName = Countries.COUNTRY_CODE ;
  countryCodeForMobileNumber: any;
  passengerIcon = {
    1: AppIcons.BOOKING_PASSENGER,
    3: AppIcons.BOOKING_WATCHLIST,
    4: AppIcons.ID_CARD,
    5: AppIcons.PHONE,
    6: AppIcons.MAIL,
    7: AppIcons.BOOKING_CHART_BARCODE,
    8: AppIcons.BOOKING_GENDER,
    9: AppIcons.NOTIFICATION_OUTLINE
  }

  constructor(private entityService: EntityService,
    private configService: ConfigService,
    public cs: CommonBindingDataService,
  ) { }

  ngOnInit(): void {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.getCachedDataForSelectedBooking();
    this.getAttributes();
  }

  getCachedDataForSelectedBooking() {
    const selectedBookingDetails = this.entityService.getCachedEntityView(AppSettings.ENTITY_CODE.BOOKING, AppSettings.KEYS_TO_STORE_DATA_IN_SERVICE.BOOKING_DETAILS);
    if (selectedBookingDetails) {
      this.addedPassengerList = selectedBookingDetails.passengers;
      if (this.addedPassengerList.length > 0) {
        this.selectAddedPassenger = this.addedPassengerList[0];
        this.passengerEntityId = this.selectAddedPassenger.id;
        this.getPassengerDetails();
        this.getFlagsForMobileNumber();
      }
      return;
    }
  }

  getFlagsForMobileNumber() {
    const findFlag = (flagCode: string) =>
      this.countryCodeWithName.find(ele => ele.value === flagCode)?.code;
    const flag1 = this.selectAddedPassenger?.mobile_number ?  findFlag(this.selectAddedPassenger?.mobile_number?.split('-')[0]) : null;
    this.countryCodeForMobileNumber = flag1 ? flag1 : null;
  }


  getAttributes() {
    this.entityService.getAttributeDefinition(AppSettings.ENTITY_CODE.BOOKING, AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe((res) => {
      if (res) {
        this.setView(res);
      }
    });
  }

  setView(res) { 
    this.data = res;
    this.attributeLabels = this.cs.getAttributeLabels(this.data);
    this.bookingAttributeData = this.cs.getOrganizedAttribute(this.data);
    this.bookingAttributeLength = this.bookingAttributeData.tabs.length;
    this.bookingPass = _.find(this.data.relatedAttributes, { entityCode: 'booking_pass' });
    this.groups = this.bookingPass.tabs[0].groups;
    this.bookingPass.relations = _.find(this.data.relations, { otherEntityCode: 'booking_pass', relation: "oneToMany" });
  }


  onSelectAddedPassenger(event) {
    this.passengerEntityId = event.value?.id ? event.value?.id: event.value?.entityId;
    this.getPassengerDetails();
  }

  getPassengerDetails() {
    this.entityService.getEntity(this.passengerEntityId, AppSettings.ENTITY_CODE.BOOKING_PASS, AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe((result: any) => {
      this.selectAddedPassenger = result.attributeCodeValueDtoList;
      const passenger = {};
      _.forEach(this.selectAddedPassenger, pass => {
        passenger[pass.attributeCode] = pass.attributeValue;
      })
      this.selectAddedPassenger = <any>passenger;
      if (this.selectAddedPassenger?.passenger_profile_image_url && this.selectAddedPassenger?.passenger_profile_image_url.length > 0) {
        this.selectAddedPassenger.profileImage = this.selectAddedPassenger?.passenger_profile_image_url[0];
      }
      this.getFlagsForMobileNumber();
    });

    this.attributeValues = this.selectAddedPassenger;
  }

  getPassengerProfileImage(passenger) {
    this.entityService.getFile(passenger.passenger_profile_image[0], AppSettings.DOCUMENTS_TYPE.PROFILE).subscribe(result => {
      const file = new File([result], 'passenger');
      const reader = new FileReader();
      reader.readAsDataURL(result);
      const that = this;
      reader.onloadend = function() {
        const base64data = reader.result;
        passenger.profileImage = base64data;
      }
    })
  }

  getPassengerImageUrl(passenger) {
    if (passenger?.passenger_profile_image_url && passenger?.passenger_profile_image_url.length > 0) {
      return passenger?.passenger_profile_image_url[0]
    } else {
      return '../../../../../assets/images/mi-theme/light/basic/default_user.svg';
    }
  }

  getIcon(id) {
    return this.passengerIcon[id];
  }

}
