import { EventEmitter, Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UiService {

  private currentLanguage = 'en_US';

  private _languageChangeEvent = new EventEmitter<string>();

  private _sidebarToggledEvent = new Subject<string>();

  private _LoaderStateChangeEvent = new EventEmitter<string>();

  private _profileUpdateEvent = new Subject<string>();

  private tableScrollHeight: string = '';
  private filterSize: number;

  constructor() { }

  changeLanguage(language: string) {
    this.currentLanguage = language;
    this._languageChangeEvent.emit(language);
  }

  get languageChangeEvent(): EventEmitter<string> {
    return this._languageChangeEvent;
  }


  changeLoaderStatus(state: string) {
    this._LoaderStateChangeEvent.emit(state);
  }
  get LoaderStatusChangeEvent(): EventEmitter<string> {
    return this._LoaderStateChangeEvent;
  }

  sidebarToggled() {
    this._sidebarToggledEvent.next();
  }

  get sidebarToggledEvent(): Subject<string> {
    return this._sidebarToggledEvent;
  }

  profileUpdated() {
    this._profileUpdateEvent.next();
  }

  get profileUpdatedEvent(): Subject<string> {
    return this._profileUpdateEvent;
  }

  setCardScrollHeight(){
    if (window.innerWidth >= 768 && window.innerWidth < 1024) {
      this.tableScrollHeight = 'calc(20vh - 45px)';
    } else if (window.innerWidth >= 1024) {
      this.tableScrollHeight = 'calc(50vh - 45px)';
    } else {
      this.tableScrollHeight = 'calc(50vh - 45px)';
    }
    return this.tableScrollHeight;
  }

  updateFilterSize(filtersCount?:number): number {
    const width = window.innerWidth;
    if (width >= 1920) {
      this.filterSize = 3;
    } else if (width >= 1440) {
      this.filterSize = 3;
    } else if (width >= 1225) {
      this.filterSize = 3;
    } else if (width >= 1060) {
      this.filterSize = 2;
    } else if (width >= 994) {
      this.filterSize = 2;
    } else if (width >= 850) {
      this.filterSize = 2;
    } else {
      if(filtersCount>1){
        this.filterSize = 0;
      }else{
        this.filterSize = 1;
      }
    }
    return this.filterSize;
  }

}
