<p-breadcrumb [model]="routePath"></p-breadcrumb>
<div appAccessProvider [accessCode]="{ menuCode: 'SHIFT_MANAGEMENT', subMenuCode: 'MANAGE_SHIFT', pageCode: 'ALL', subPageCode: 'ADD' }">
<app-module-header [label]="btnLabel" [showLastSync]="false" (onBtnClick)="onAddShift()"
    [moduleHeaderSecondaryBtnMenuItems]="moduleHeaderSecondaryBtnMenuItems"
    (onSecondaryBtnClick)="onSecondaryBtnClick()" ></app-module-header>
</div>
<div appAccessProvider [accessCode]="{ menuCode: 'SHIFT_MANAGEMENT', subMenuCode: 'SHIFT_CALENDAR', pageCode: 'ALL', subPageCode: 'LIST' }">
<div class="flex mi-filter-container">
    <div class="flex">
        @if (selectedRows?.length>0) {
            <div class="ml-3">
              <p-button icon="pi pi-chevron-down" [badge]="selectedRows.length" badgeClass="badge-selected-row" class="mr-2 button-with-icon cursor-pointer" severity="secondary"
                (click)="actions.toggle($event)" [label]="'lbl_actions' | translate"></p-button>
              <p-menu #actions [model]="bulkSelectionsItems" [popup]="true" appendTo="body"></p-menu>
              <button pButton [label]="'clear_selection' | translate" (click)="clearSelected()" class="mr-2 bg-white text-primary border-none cursor-pointer clear-btn"></button>
            </div>
          } @else {
            <div class=" flex flex-wrap justify-content-center">
                <div class="flex-1 flex justify-content-end mr-3">
                    <span class="p-input-icon-left ">
                        <i class="mi-lg" [class]="searchValue?.length > 0 ? 'close_small' : 'search-small'"
                            (click)="clearSearch()"></i>
                        <input class="width-200 input-search" type="text" pInputText (input)="onSearch($event)"
                            [placeholder]="'shift.lbl_search_by_name_or_shift' | translate" [(ngModel)]="searchValue" />
                    </span>
                </div>
            </div>
          }
          @if (selectedListType === LIST_TYPE.DRIVER) {
            <div class="flex default-text-black-color ml-auto mr-2">
                <p-multiSelect #multiSelect [placeholder]="'lbl_select_group' | translate"
                  [(ngModel)]="selectedGroup"
                  [options]="groupList"
                  optionLabel="labelValue"
                  [showToggleAll]="true"
                  optionValue="id"
                  [filter]="true"
                  (onChange)="onChangeGroupList($event)"
                  [showClear]="true"
                  (onClear)="onClearGroupFilter()"
                  (onFilter)="onFilter($event)">
    
                  <ng-template pTemplate="filtericon">
                      <i [class]="filterIcon" (click)="onFilterIconClick()"></i>
                  </ng-template>
                </p-multiSelect>
              </div>
          }
          <p-selectButton [options]="weekDaySelectOptions" [(ngModel)]="selectedWeekDayFilter" (onChange)="onDayWeekFilterChange()" optionLabel="label"
           optionValue="value"></p-selectButton>
    </div>

    @if (!isBetweenScreenSize) {
        <div class="flex align-items-center prev-next-date md:12 calendar-container">
            <i (click)="previousClicked()" class="mi-lg arrow_left prev-next pointer"></i>
            <i (click)="nextClicked()" class="mi-lg arrow_right prev-next pointer"></i>
            <label class="current-month">{{selectedMonthOrDay}}</label>
            <img class="prev-next calendar-icon ml-2" src="../../../../../assets/images/mi-theme/light/basic/shift_calendar.svg">
            <label class="timezone ml-2">{{timezone}}</label>
        </div>  
    }   
</div>

@if (isBetweenScreenSize) {
    <div class="flex align-items-center justify-content-center md:12 calendar-outside-container">
        <i (click)="previousClicked()" class="mi-lg arrow_left prev-next pointer pr-8"></i>
        <label class="current-month">{{selectedMonthOrDay}}</label>
        <img class="prev-next calendar-icon ml-2" src="../../../../../assets/images/mi-theme/light/basic/shift_calendar.svg">
        <label class="timezone ml-2">{{timezone}}</label>
        <i (click)="nextClicked()" class="mi-lg arrow_right prev-next pointer pl-8"></i>
    </div>  
} 

 

@if (selectedListType === LIST_TYPE.DRIVER) {
    @if (selectedWeekDayFilter === CALENDAR_FILTER_TYPE.WEEK) {
        @if (showList) {
            <app-week-list
                [listType]="listType"
                (onListTypeChangeEvent)="onListTypeChange($event)"
                [selectedListType]="selectedListType"          
                (shiftPublishedUnpublishedList)="setShiftPublishedUnpublishedList($event)"
                [weekDaysList]="weekDaysList"
                [searchValue]="searchValue"
                [groupIds]="groupIds"
            ></app-week-list>
        }
    } @else {
        @if (showList) {
            <app-day-list
                [listType]="listType"
                (onListTypeChangeEvent)="onListTypeChange($event)"
                [selectedListType]="selectedListType"
                (shiftPublishedUnpublishedList)="setShiftPublishedUnpublishedList($event)"
                [selectedDayTimeStamp]="selectedDayTimeStamp"
                [selectedDayDate]="selectedDayDate"
                [searchValue]="searchValue"
                [groupIds]="groupIds"
            ></app-day-list>
        }
    }
} @else if (selectedListType === LIST_TYPE.GROUP) {
    @if (selectedWeekDayFilter === CALENDAR_FILTER_TYPE.WEEK) {
        @if (showList) {
            <app-group-week-list
                [listType]="listType"
                (onListTypeChangeEvent)="onListTypeChange($event)"
                [selectedListType]="selectedListType"        
                (shiftPublishedUnpublishedList)="setShiftPublishedUnpublishedList($event)"
                [weekDaysList]="weekDaysList"
                [searchValue]="searchValue"
            ></app-group-week-list>
        }
    } @else {
        @if (showList) {
        <app-group-day-list
            [listType]="listType"
            (onListTypeChangeEvent)="onListTypeChange($event)"
            [selectedListType]="selectedListType"          
            (shiftPublishedUnpublishedList)="setShiftPublishedUnpublishedList($event)"
            [selectedDayTimeStamp]="selectedDayTimeStamp"
            [selectedDayDate]="selectedDayDate"
            [searchValue]="searchValue"
        ></app-group-day-list>
    }
    }
} @else if (selectedListType === LIST_TYPE.VEHICLE) {
    @if (selectedWeekDayFilter === CALENDAR_FILTER_TYPE.WEEK) {
        @if (showList) {
        <app-vehicle-week-list
            [listType]="listType"
            (onListTypeChangeEvent)="onListTypeChange($event)"
            [selectedListType]="selectedListType"
            [weekDaysList]="weekDaysList"
        ></app-vehicle-week-list>
    }
    } @else {
        @if (showList) {
        <app-vehicle-day-list
            [listType]="listType"
            (onListTypeChangeEvent)="onListTypeChange($event)"
            [selectedListType]="selectedListType"
            [selectedDayTimeStamp]="selectedDayTimeStamp"
            [selectedDayDate]="selectedDayDate"
        ></app-vehicle-day-list>
    }
    }
}
</div>


<input hidden type="file" id="upload" (change)="onChange($event)"
    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" #uploader>

    <p-dialog [header]="shiftActionHeader" [(visible)]="showShiftDialog" [style]="{width: '30vw'}">
        <div class="flex flex-column gap-3 mt-3">
            @if (shiftList.length > 0) {
            <div>
               
                    <div  class="row-module col-12 inline-flex" (click)="selectAllShifts()">
                        <div class="col-9">
                            <label  [class]="selectAll ? 'selected-shift-name' : 'unselected-shift-name'" translate>shift.all_shifts</label> &nbsp;
                        </div>
                        <div class="col-3 text-right pr-0">
                            <p-checkbox
                                [(ngModel)]="selectAll"
                                (onChange)="selectAllShifts()"
                                [binary]="true"
                                name="shift" />
                        </div>
                    </div>
                    @for (shift of shiftList; track shift;let i = $index) {
                        <div  class="row-module col-12 inline-flex" (click)="selectShift(shift)">
                                <div class="col-9">
                                    <label class="capitalize" [class]="shift.checked ? 'selected-shift-name' : 'unselected-shift-name'" translate>{{ shift.shiftName }}</label> &nbsp;
                                    <i class="mi-lg driver-icon mr-2"></i>
                                    <label [class]="shift.checked ? 'selected-shift-driver-count' : ''">{{ shift.driverCount }}</label>
                                </div>
                                <div class="col-3 text-right pr-0">
                                    <p-checkbox
                                        [(ngModel)]="shift.checked"
                                        (onChange)="selectShift(shift)"
                                        [binary]="true"
                                        name="shift"
                                        [value]="shift.checked" />
                                </div>
                        </div>
                    }
               

            </div>
            <div class="flex justify-content-end flex-wrap mb-3 clear-shift">
                <button pButton class="cancel mr-2 bg-white border-0 default-text-light-black-color cursor-pointer"
                    [label]="'cancel' | translate" (click)="onCancel()"></button>
                <p-button  class="cursor-pointer unpublish-btn" (click)="publishUnpublishShift()"
                    [label]="isPublishShift ? ('shift.lbl_publish' | translate) : ('shift.unpublish_shift' | translate)"></p-button>
            </div>
        } @else {
            <label class="unselected-shift-name mb-6">No shifts available</label>
        }
        </div>
    </p-dialog>


    <p-dialog [header]="shiftActionHeader" [(visible)]="showClearShiftDialog" [style]="{width: '30vw'}">
        <div class="flex flex-column gap-3 mt-3">
            @if (shiftList.length > 0) {
            <div>
               
                    <div  class="row-module col-12 inline-flex" (click)="selectAllShifts()">
                        <div class="col-6">
                            <label  [class]="selectAll ? 'selected-shift-name' : 'unselected-shift-name'" translate>shift.all_shifts</label> &nbsp;
                        </div>
                        <div class="col-6 text-right">
                            <p-checkbox
                                [(ngModel)]="selectAll"
                                (onChange)="selectAllShifts()"
                                [binary]="true"
                                name="shift" />
                        </div>
                    </div>
                    @for (shift of shiftList; track shift;let i = $index) {
                        <div  class="row-module col-12 inline-flex" (click)="selectShift(shift)">
                                <div class="col-6">
                                    <label class="capitalize"  [class]="shift.checked ? 'selected-shift-name' : 'unselected-shift-name'" translate>{{ shift.shiftName }}</label> &nbsp;
                                    <i class="mi-lg driver-icon mr-2"></i>
                                    <label [class]="shift.checked ? 'selected-shift-driver-count' : ''">{{ shift.driverCount }}</label>
                                </div>
                                <div class="col-6 text-right">
                                    <p-checkbox
                                        [(ngModel)]="shift.checked"
                                        (onChange)="selectShift(shift)"
                                        [binary]="true"
                                        name="shift"
                                        [value]="shift.checked" />
                                </div>
                        </div>
                    }
               

            </div>
            <div class="flex justify-content-end flex-wrap mb-3 clear-shift">
                <button pButton class="cancel mr-2 bg-white default-text-light-black-color cursor-pointer"
                    [label]="'cancel' | translate" (click)="onCancel()"></button>
                <p-button class="flex align-items-center justify-content-center cursor-pointer clear-shift-btn" (click)="onClearShift()"
                    [label]="'shift.clear_shift' | translate"></p-button>
            </div>
        } @else {
            <label class="unselected-shift-name mb-6">No shifts available</label>
        }
        </div>
    </p-dialog>