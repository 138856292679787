import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import * as _ from 'lodash';
import { MenuItem, MessageService } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { TabViewModule } from 'primeng/tabview';
import { AppSettings } from '../../../shared/app.settings';
import { MiFormComponent } from '../../../shared/components/mi-form/mi-form.component';
import { Country } from '../../../shared/models/country';
import { Language } from '../../../shared/models/language';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { ConfigService } from '../../../shared/services/config.service';
import { EntityService } from '../../../shared/services/entity.service';
import type { AttributeData, entityResponse } from '../../../vehicles/models/attribute.models';

@Component({
  selector: 'app-add-incidents-complaints',
  templateUrl: './add-incidents-complaints.component.html',
  styleUrl: './add-incidents-complaints.component.scss',
  standalone: true,
  imports: [BreadcrumbModule, TabViewModule, MiFormComponent, DialogModule, ButtonModule, TranslateModule]
})
export class AddIncidentsComplaintsComponent implements OnInit {
  @ViewChild(MiFormComponent) miFormComponent: MiFormComponent;
  activeIndex: number = 0;
  data: AttributeData;
  incidentsComplaintAttributeData: any;
  attributeLabels = {};
  moduleName: string;
  country: Country;
  language: Language;
  incidentsComplaintAttributeLength: any;
  cols: any = [];
  btnLabel: string;
  nextBtnLabel;
  saveAndAddBtnText: string;
  incidentsComplaintDocumentId;
  incidentsComplaintId;
  attributeValues = [];
  concernId: string;
  routePath: MenuItem[] = [];
  attributeIdForJourneyId;

  constructor(private incidentsComplaintService: EntityService,
    private cs: CommonBindingDataService,
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute,
    private configService: ConfigService) { }

  ngOnInit() {
    this.initializeOtherVariables();
    this.getEntityData();
    this.setRoutePath();
  }

  initializeOtherVariables() {
    this.concernId = this.route.snapshot.paramMap.get('id');
    this.nextBtnLabel = this.concernId ? this.cs.getLabel('lbl_update') : this.cs.getLabel('incidents_complaints.lbl_save');
    this.saveAndAddBtnText = this.concernId ? this.cs.getLabel('cancel') : this.cs.getLabel('manage_group.lbl_save_and_add_another');
    this.incidentsComplaintId = this.route.snapshot.paramMap.get('id');
    this.btnLabel = this.incidentsComplaintId ? this.cs.getLabel('incidents_complaints.lbl_cancel') : this.cs.getLabel('incidents_complaints.lbl_save');
    this.moduleName = AppSettings.ENTITY_TYPE.INCIDENTS_AND_COMPLAINT;
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
  }

  getEntityData() {
    if (this.incidentsComplaintId) {
      this.incidentsComplaintService.getEntity(this.incidentsComplaintId, AppSettings.ENTITY_CODE.INCIDENTS_AND_COMPLAINT, AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe(res => {
        if (res) {
          const data = res;
          this.attributeValues = this.cs.getOrgAttributeValues(data);
          const dateTimeAttributeCode = AppSettings.DATE_ATTRIBUTE_IDS.INCIDENTS_DATE_TIME;
          if (this.attributeValues[dateTimeAttributeCode]) {
            this.attributeValues[dateTimeAttributeCode] = new Date(this.attributeValues[dateTimeAttributeCode]);
          }
          this.getAttributes();
        }
      });
    } else {
      this.getAttributes();
    }
  }

  getAttributes() {
    const entityType = AppSettings.ENTITY_TYPE.INCIDENTS_AND_COMPLAINT;
    this.incidentsComplaintService.getAttributeDefinition(entityType, AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe(res => {
      if (res) {
        this.data = res;
        this.attributeLabels = this.cs.getAttributeLabels(this.data);
        this.incidentsComplaintAttributeData = this.cs.getOrganizedAttribute(this.data);
        this.attributeIdForJourneyId = this.cs.getAttributeId('journey_id', this.incidentsComplaintAttributeData);
        this.incidentsComplaintAttributeLength = this.incidentsComplaintAttributeData.tabs.length;
        const concernTab = this.incidentsComplaintAttributeData.tabs.find(ele => ele.tabCode === 'concern');
        const groups = concernTab.groups.find(ele => ele.code === 'details');
        const incidentDateField = groups.fields.find(ele => ele.attributeCode === AppSettings.DATE_ATTRIBUTE_IDS.INCIDENTS_DATE_TIME);
        if (incidentDateField) {
          incidentDateField.validation.max = new Date();
          incidentDateField.validation.min = new Date();
        }
      }
    });
  }

  setRoutePath() {
    this.routePath = [
      {
        label: this.concernId ? this.cs.getLabel('incidents_complaints.lbl_route_path_edit') : this.cs.getLabel('incidents_complaints.lbl_route_path_add'),
        routerLink: this.concernId ? '../../list' : '../list',
        icon: 'pi pi-arrow-left',
        iconStyle: { 'font-weight': 'bold', 'margin-right': '10px' }
      },
    ];
  }

  onSaveBtnClick() {
    const btn = document.getElementById('details');
    btn.click();
  }

  onSaveIncidentsComplaints(event) {
    const incidentsComplaintsData = {
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: this.data.entityCode,
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      data: []
    };

    const formData = event;
    const dateIds = [AppSettings.DATE_ATTRIBUTE_IDS.INCIDENTS_DATE_TIME];
    for (const [key, value] of Object.entries(formData)) {
      let attributeValue = value;
      if (dateIds.includes(key)) {
        attributeValue = this.cs.convertToTimestamp(value as any);
      }

      if (!AppSettings.INCIDENTS_SPACER_IDS.includes(key)) {
        incidentsComplaintsData.data.push({
          attributeCode: key,
          attributeValue
        });
      }
    }
    incidentsComplaintsData.data = incidentsComplaintsData.data.filter(this.filterIncidentData.bind(this));

    const entitiesData = {
      countryCode: this.country[0].countryCode,
      tenantCode: this.configService.getLoggedInTenantCode(),
      entityCode: this.data.entityCode
    }

    if (!this.incidentsComplaintId) {
      this.incidentsComplaintService.createEntities(entitiesData.entityCode, entitiesData).subscribe((res: entityResponse) => {
        this.incidentsComplaintId = res.entityId;
        this.saveIncidentData(entitiesData.entityCode, incidentsComplaintsData, 'create');
      });
    } else {
      this.saveIncidentData(entitiesData.entityCode, incidentsComplaintsData, 'update');
    }
  }

  onSaveAndAddIncidentsComplaints(event) {
    const incidentsComplaintsData = {
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: this.data.entityCode,
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      data: []
    };

    const formData = event;
    const dateIds = [AppSettings.DATE_ATTRIBUTE_IDS.INCIDENTS_DATE_TIME];
    for (const [key, value] of Object.entries(formData)) {
      let attributeValue = value;
      if (dateIds.includes(key)) {
        attributeValue = this.cs.convertToTimestamp(value as any);
      }

      if (!AppSettings.INCIDENTS_SPACER_IDS.includes(key)) {
        incidentsComplaintsData.data.push({
          attributeCode: key,
          attributeValue
        });
      }
    }
    incidentsComplaintsData.data = incidentsComplaintsData.data.filter(this.filterIncidentData.bind(this));

    const entitiesData = {
      countryCode: this.country[0].countryCode,
      tenantCode: this.configService.getLoggedInTenantCode(),
      entityCode: this.data.entityCode
    }

    if (!this.incidentsComplaintId) {
      this.incidentsComplaintService.createEntities(entitiesData.entityCode, entitiesData).subscribe((res: entityResponse) => {
        this.incidentsComplaintId = res.entityId;
        this.saveAndAddIncidentData(entitiesData.entityCode, incidentsComplaintsData, 'create');
      });
    } else {
      this.saveAndAddIncidentData(entitiesData.entityCode, incidentsComplaintsData, 'update');
    }
  }

  filterIncidentData(item: any): boolean {
    return (
      (!_.isArray(item.attributeValue) || item.attributeValue.length > 0) &&
      item.attributeValue !== null &&
      item.attributeValue !== 0 &&
      item.attributeValue !== ""
    );
  }

  saveIncidentData(entityCode, incidentsComplaintsData, action) {
    incidentsComplaintsData.data = this.cs.mapAttributeIds(incidentsComplaintsData.data, this.incidentsComplaintAttributeData.tabs);
    const entityRelationshipConfigId = this.incidentsComplaintAttributeData.relations?.find(r => r.otherEntityCode === 'booking')?.entityRelationshipConfigId || null;
    const attribute = incidentsComplaintsData.data.find(item => item.attributeId === this.attributeIdForJourneyId);
    if(attribute) {
      incidentsComplaintsData.relationshipData = [
        {
          entityRelationshipConfigId: entityRelationshipConfigId,
          otherEntityId: attribute.attributeValue
        }
      ];
    }
    this.incidentsComplaintService.saveAttributeData(entityCode, this.incidentsComplaintId, incidentsComplaintsData).subscribe(res => {
      if (res) {
        if (action === 'create') {
          this.showSuccessMessage(this.cs.getLabel('incidents_complaints.message.add_incidents'));
        } else if (action === 'update') {
          this.showSuccessMessage(this.cs.getLabel('incidents_complaints.message.update_incidents'));
        }
        this.router.navigate(['app/concerns/list']);
      }
    });
  }

  saveAndAddIncidentData(entityCode, incidentsComplaintsData, action) {
    incidentsComplaintsData.data = this.cs.mapAttributeIds(incidentsComplaintsData.data, this.incidentsComplaintAttributeData.tabs);
    const entityRelationshipConfigId = this.incidentsComplaintAttributeData.relations?.find(r => r.otherEntityCode === 'booking')?.entityRelationshipConfigId || null;
    const attribute = incidentsComplaintsData.data.find(item => item.attributeId === this.attributeIdForJourneyId);
    if(attribute) {
      incidentsComplaintsData.relationshipData = [
        {
          entityRelationshipConfigId: entityRelationshipConfigId,
          otherEntityId: attribute.attributeValue
        }
      ];
    }
    this.incidentsComplaintService.saveAttributeData(entityCode, this.incidentsComplaintId, incidentsComplaintsData).subscribe(res => {
      if (res) {
        if (action === 'create') {
          this.showSuccessMessage(this.cs.getLabel('incidents_complaints.message.add_incidents'));
        } else if (action === 'update') {
          this.showSuccessMessage(this.cs.getLabel('incidents_complaints.message.update_incidents'));
        }
        this.miFormComponent.resetForm();
        this.cs.triggerClearAutoComplete();
      }
    });
  }

  private showSuccessMessage(labelKey) {
    this.messageService.add({
      key: 'tst',
      severity: 'success',
      summary: 'Successful',
      detail: this.cs.getLabel(labelKey),
    });
  }

  onPreviousClick() {
    if (this.activeIndex > 0) {
      this.activeIndex--;
    }
  }

  onNextClick() {
    if (this.activeIndex < this.incidentsComplaintAttributeLength - 1) {
      this.activeIndex++;
    }
  }

  onCancel() {
    this.miFormComponent.resetForm();
    this.router.navigate(['app/concerns/list']);
  }

}
