<!-- bookings -->
<div appAccessProvider [accessCode]="{ menuCode: 'DISPATCH', pageCode: 'BOOKING_DRAWER', subPageCode: 'LIST' }">
<p-sidebar
  [(visible)]="visible"
  [position]="leftDialogPosition"
>
  <ng-template pTemplate="header">
    @if(!showBookingDetails){
      <app-dialog-header class="flex-grow-1" [headerLabel]="bookingHeader" [moduleName]="'booking'" [view]="'BOOKING_FILTER_ON_DISPATCH_VIEW'" 
        (onValueChange)="onBookingFilterValueChange($event)" (onEmitSearchValue)="onEmitSearchValue($event)" [isHeaderForBooking]="true">
      </app-dialog-header>
    }
    @if(showBookingDetails){
      <app-booking-list-card [forDetail]="true" [bookingListItem]="bookingDetailItem" (closeBtnClicked)="closeBtnClicked()" class="flex-grow-1 bg-detail" style="margin: -14px;"></app-booking-list-card>
    }
  </ng-template>
  <ng-template pTemplate="content" >
   <app-booking-section [style]="{'display': !showBookingDetails ? 'block' : 'none'}" (showBookingDetails)="showBookingDetailsClick($event)"></app-booking-section>
    @if(showBookingDetails){<app-dispatch-booking-details [bookingDetailItem]="bookingDetailItem" [dispatchBookingDetails]="dispatchBookingDetails"></app-dispatch-booking-details>}
  </ng-template>
</p-sidebar>
</div>