import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { Status } from '../../../../shared/models/status';
import { EntityService } from '../../../../shared/services/entity.service';
import { AppSettings } from '../../../../shared/app.settings';
import { TranslateModule } from '@ngx-translate/core';
import { DatePipe, JsonPipe } from '@angular/common';
import { AppIcons } from '../../../../shared/app.icons';
import { MetaDataService } from 'app/modules/shared/services/meta-data.service';

@Component({
  selector: 'app-booking-header',
  standalone: true,
  imports: [TranslateModule, DatePipe, JsonPipe],
  templateUrl: './booking-header.component.html',
  styleUrl: './booking-header.component.scss'
})
export class BookingHeaderComponent {
  dd_MMM_yyyy_FORMAT: any = this.metaDataService.upperDateFormat;
  hh_mm_FORMAT: any = this.metaDataService.timeFormat;
  @Input() passengerCount: number;
  @Input() passenger: any;
  @Input() data: any;
  @Input() bookingId: string; 
  TRANSPORTATION_TYPE = AppSettings.TRANSPORTATION_TYPE;
  TRANSPORTATION_MODE = AppSettings.TRANSPORTATION_MODE;
  miIcons = AppIcons;
  @Input() driver: any;
  @Input() vehicle: any;
  extractedDriverData: { unique_id: string, first_name: string }[] = [];
  extractedVehicleData: { name_code: string, model: string }[] = [];

  constructor(
    private statusService: EntityService, 
    private metaDataService: MetaDataService,
    private cd: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.extractDriverDetails();
    this.extrectedVehicleDetails();
  }

  extractDriverDetails(){
    if (this.driver && Array.isArray(this.driver)) {
      this.driver.forEach((driver: any) => {
        let driverInfo: { unique_id: string, first_name: string } = {
          unique_id: driver.unique_id || '',
          first_name: driver.first_name || ''
        };

        if (driverInfo.unique_id && driverInfo.first_name) {
          this.extractedDriverData.push(driverInfo);
        }
      });
    }
  }

  extrectedVehicleDetails(){
    if (this.vehicle && Array.isArray(this.vehicle)) {
      this.vehicle.forEach((vehicle: any) => {
        let vehicleInfo: { name_code: string, model: string } = {
          name_code: vehicle.name_code || '',
          model: vehicle.model || ''
        };

        if (vehicleInfo.name_code && vehicleInfo.model) {
          this.extractedVehicleData.push(vehicleInfo);
        }
      });
    }
  }

}
