<p-breadcrumb [model]="routePath"></p-breadcrumb>
<div appAccessProvider [accessCode]="{ menuCode: 'CONTENT_MANAGEMENT', subMenuCode: 'PRIVACY_POLICY', pageCode: 'DETAILS' }">
    <div class="flex justify-content-end flex-wrap btn-with-links">
        <button pButton class="mr-2 bg-white default-text-light-black-color border-button" [label]="'cancel' | translate"
            (click)="onCancel()"></button>
        <p-button class="flex align-items-center justify-content-center event-box" [label]="addBtnLabel"
            (click)="onAdd()" [disabled]="privacyForm.invalid"></p-button>
    </div>


    <div class="main-settings">
        <p-tabView>
            <p-tabPanel>
                <ng-template pTemplate="header">
                    <i class="mi-lg {{miIcons.DETAILS}}"></i>
                    <span translate>content.lbl_privacy_policy</span>
                </ng-template>
                <form [formGroup]="privacyForm">
                    <div class="card background-not">
                        <div class="font-semibold pb-3">
                            <label class="navigation-item-label font-18" translate>content.lbl_privacy_policy <span class="required ml-1">*</span></label>
                        </div>
                        <div class="grid">
                            <div class="form-field col-12 md:col-12 lg:col-12 field">                           
                                <div class="form-control">
                                    <p-editor formControlName="privacy" [style]="{ height: 'calc(100vh - 330px)' }"
                                        appAutoFocus />
                                </div>
                                @if (privacyForm.controls.privacy.errors && privacyForm.controls.privacy.touched) {
                                    <div class="form-errors mt-1 required-field">
                                        <div [hidden]="!privacyForm.controls.privacy.errors.required" translate>
                                            content.privacy_policy_req_err
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </form>

                <div class="flex justify-content-end mr-17 save-action-div">
                    <button pButton class="mr-2 mb-2 bg-color-gray default-text-light-black-color border-button"
                        [label]="'cancel' | translate" (click)="onCancel()"></button>
                    <p-button class="mb-2 flex align-items-center justify-content-center" [label]="addBtnLabel"
                        (click)="onAdd()" [disabled]="privacyForm.invalid"></p-button>
                </div>
            </p-tabPanel>
        </p-tabView>
    </div>
</div>