import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';
@Pipe({
  name: 'dateformat',
  standalone: true
})
export class DateFormatPipe implements PipeTransform {

  transform(value: any): string {
    return dayjs(value).format('MMM DD, YYYY');
  }

}
