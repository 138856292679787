import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AddBookingComponent } from "./modules/bookings/components/add-booking/add-booking.component";
import { BookingDetailsComponent } from "./modules/bookings/components/booking-details/booking-details/booking-details.component";
import { BookingsComponent } from "./modules/bookings/components/bookings/bookings.component";
import { ActivityFeedComponent } from "./modules/dashboard/components/activity-feed/activity-feed.component";
import { DashboardComponent } from "./modules/dashboard/components/dashboard/dashboard.component";
import { HeatMapsComponent } from "./modules/dashboard/components/heat-maps/heat-maps.component";
import { MapsComponent } from "./modules/dashboard/components/maps/maps.component";
import { MiIconsComponent } from "./modules/dashboard/components/mi-icons/mi-icons.component";
import { VehiclesComponent } from "./modules/dashboard/components/vehicles/vehicles.component";
import { DispatchBookingDetailsComponent } from "./modules/dispatch/components/booking-section/booking-details/dispatch-booking-details.component";
import { DispatchComponent } from "./modules/dispatch/components/dispatch/dispatch.component";
import { AddDriverComponent } from "./modules/driver/components/add-driver/add-driver.component";
import { ApplyNewLeaveComponent } from "./modules/driver/components/apply-new-leave/apply-new-leave.component";
import { DriverDetailsComponent } from "./modules/driver/components/driver-details/driver-details.component";
import { DriverLeaveManagementComponent } from "./modules/driver/components/driver-leave-management/driver-leave-management.component";
import { DriverListComponent } from "./modules/driver/components/driver-list/driver-list.component";
import { LeaveDetailsComponent } from "./modules/driver/components/leave-details/leave-details.component";
import { TodayLeaveViewAllComponent } from "./modules/driver/components/today-leave-view-all/today-leave-view-all.component";
import { TomorrowLeaveViewAllComponent } from "./modules/driver/components/tomorrow-leave-view-all/tomorrow-leave-view-all.component";
import { AddEventComponent } from "./modules/events/add-event/add-event.component";
import { EventDetailsComponent } from "./modules/events/event-details/event-details.component";
import { EventsComponent } from "./modules/events/events.component";
import { AddIncidentsComplaintsComponent } from "./modules/incidents-complaints/components/add-incidents-complaints/add-incidents-complaints.component";
import { IncidentsComplaintsDetailsComponent } from "./modules/incidents-complaints/components/incidents-complaints-details/incidents-complaints-details.component";
import { IncidentsComplaintsListComponent } from "./modules/incidents-complaints/components/incidents-complaints-list/incidents-complaints-list.component";
import { AddGroupComponent } from "./modules/manage-groups/components/add-group/add-group.component";
import { ManageGroupListComponent } from "./modules/manage-groups/components/manage-group-list/manage-group-list.component";
import { AddLocationComponent } from "./modules/manage-locations/components/add-location/add-location.component";
import { LocationDetailsComponent } from "./modules/manage-locations/components/location-details/location-details.component";
import { ManageLocationsComponent } from "./modules/manage-locations/components/manage-locations/manage-locations.component";
import { AddSegmentComponent } from "./modules/manage-segments/components/add-segment/add-segment.component";
import { AddZoneComponent } from "./modules/manage-zones/components/add-zone/add-zone.component";
import { ManageZonesComponent } from "./modules/manage-zones/components/manage-zones/manage-zones.component";
import { ZoneDetailComponent } from "./modules/manage-zones/components/zone-detail/zone-detail.component";
import { AddOrganizationComponent } from "./modules/organization/components/add-organization/add-organization.component";
import { OrganizationDetailsComponent } from "./modules/organization/components/organization-details/organization-details.component";
import { OrganizationListComponent } from "./modules/organization/components/organization-list/organization-list.component";
import { AddPassengersComponent } from "./modules/passengers/components/add-passengers/add-passengers.component";
import { PassengerDetailsComponent } from "./modules/passengers/components/passenger-details/passenger-details.component";
import { PassengersComponent } from "./modules/passengers/components/passengers/passengers.component";
import { AddQueueComponent } from "./modules/queue-management/components/add-queue/add-queue.component";
import { QueueListComponent } from "./modules/queue-management/components/queue-list/queue-list.component";
import { SettingsComponent } from "./modules/settings/components/settings/settings.component";
import { LayoutComponent } from "./modules/shared/components/layout/layout.component";
import { AddShiftComponent } from "./modules/shift-management/components/add-shift/add-shift.component";
import { ShiftCalendarComponent } from "./modules/shift-management/components/shift-calendar/shift-calendar.component";
import { AddShiftTemplateComponent } from "./modules/shift-template/components/add-shift-template/add-shift-template.component";
import { ShiftTemplateListComponent } from "./modules/shift-template/components/shift-template-list/shift-template-list.component";
import { AddRoleComponent } from "./modules/user-management/components/add-role/add-role.component";
import { AddUserComponent } from "./modules/user-management/components/add-user/add-user.component";
import { ManageRolesComponent } from "./modules/user-management/components/manage-roles/manage-roles.component";
import { ManageUserComponent } from "./modules/user-management/components/manage-user/manage-user.component";
import { AddMultipleVehiclesComponent } from "./modules/vehicles/components/add-multiple-vehicles/add-multiple-vehicles.component";
import { AddVehicleComponent } from "./modules/vehicles/components/add-vehicle/add-vehicle.component";
import { VehicleDetailsComponent } from "./modules/vehicles/components/vehicle-details/vehicle-details.component";
import { VehicleListComponent } from "./modules/vehicles/components/vehicle-list/vehicle-list.component";
import { SettingsPrivilegeSettingsComponent } from "./modules/settings/components/settings/passenger/settings-privilege-settings/settings-privilege-settings.component";
import { ManageShiftListComponent } from "./modules/manage-shifts/components/manage-shift-list/manage-shift-list.component";
import { ShiftDetailsComponent } from "./modules/manage-shifts/components/shift-details/shift-details.component";
import { AssignDriverToShiftComponent } from "./modules/manage-shifts/components/assign-driver-to-shift/assign-driver-to-shift.component";
import { ManageGroupDetailsComponent } from "./modules/manage-groups/components/manage-group-details/manage-group-details.component";
import { CommunicationComponent } from "./modules/communication/pages/communication/communication.component";
import { BroadcastListComponent } from "./modules/broadcasting/components/broadcast-list/broadcast-list.component";
import { SegmentListComponent } from "./modules/manage-segments/components/segment-list/segment-list.component";
import { SettingsUserProfileComponent } from "./modules/settings/components/settings/settings-user-profile/settings-user-profile.component";
import { SettingsNotificationSettingsComponent } from "./modules/settings/components/settings/settings-notification-settings/settings-notification-settings.component";
import { SettingsLoginAndPasswordComponent } from "./modules/settings/components/settings/settings-login-and-password/settings-login-and-password.component";
import { SettingsGeneralSettingsComponent } from "./modules/settings/components/settings/administration/settings-general-settings/settings-general-settings.component";
import { SettingsTimeSettingsComponent } from "./modules/settings/components/settings/administration/settings-time-settings/settings-time-settings.component";
import { SettingsExportAccountDataComponent } from "./modules/settings/components/settings/administration/settings-export-account-data/settings-export-account-data.component";
import { SettingsSecurityComponent } from "./modules/settings/components/settings/administration/settings-security/settings-security.component";
import { SettingsCustomFieldsComponent } from "./modules/settings/components/settings/settings-custom-fields/settings-custom-fields.component";
import { SettingsLabelsComponent } from "./modules/settings/components/settings/settings-labels/settings-labels.component";
import { SettingsRidePreferenceComponent } from "./modules/settings/components/settings/bookings/settings-ride-preference/settings-ride-preference.component";
import { SettingsPassengerTypeComponent } from "./modules/settings/components/settings/passenger/settings-passenger-type/settings-passenger-type.component";
import { SettingsVehicleStatusComponent } from "./modules/settings/components/settings/vehicle/settings-vehicle-status/settings-vehicle-status.component";
import { SettingsVehicleTypesComponent } from "./modules/settings/components/settings/vehicle/settings-vehicle-types/settings-vehicle-types.component";
import { SettingsBodyTypesComponent } from "./modules/settings/components/settings/vehicle/settings-body-types/settings-body-types.component";
import { SettingFeaturesComponent } from "./modules/settings/components/settings/vehicle/setting-features/setting-features.component";
import { SettingsReasonForDutyStatusComponent } from "./modules/settings/components/settings/driver/settings-reason-for-duty-status/settings-reason-for-duty-status.component";
import { SettingsDrivingRecordComponent } from "./modules/settings/components/settings/driver/settings-driving-record/settings-driving-record.component";
import { SettingsLocationCategoryComponent } from "./modules/settings/components/settings/location/settings-location-category/settings-location-category.component";
import { SettingsStatusComponent } from "./modules/settings/components/settings/incident-complaint/settings-status/settings-status.component";
import { SettingsGroupColorComponent } from "./modules/settings/components/settings/shift-management/settings-group-color/settings-group-color.component";
import { SettingsMessageTypeComponent } from "./modules/settings/components/settings/communication/settings-message-type/settings-message-type.component";
import { UserDetailsComponent } from "./modules/user-management/components/user-details/user-details.component";
import { AboutCompanyComponent } from "./modules/content-management/components/about-company/about-company.component";
import { FAQsComponent } from "./modules/content-management/components/faqs/faqs.component";
import { PrivacyPolicyComponent } from "./modules/content-management/components/privacy-policy/privacy-policy.component";
import { TermsAndConditionsComponent } from "./modules/content-management/components/terms-and-conditions/terms-and-conditions.component";
import { ContactSupportComponent } from "./modules/content-management/components/contact-support/contact-support.component";
import { SettingsUserComponent } from "./modules/settings/components/settings/authentication/settings-user/settings-user.component";
import { SettingsDriverComponent } from "./modules/settings/components/settings/authentication/settings-driver/settings-driver.component";
import { SettingsPassengerComponent } from "./modules/settings/components/settings/authentication/settings-passenger/settings-passenger.component";
import { DocumentDetailsComponent } from "./modules/shared/components/document-details/document-details.component";
import { SettingsLeaveTypeComponent } from "./modules/settings/components/settings/driver/settings-leave-type/settings-leave-type.component";
import { SettingsReasonForBreakComponent } from "./modules/settings/components/settings/shift-management/settings-reason-for-break/settings-reason-for-break.component";
import { BookingsHistoryComponent } from "./modules/bookings/components/booking-history/bookings-history.component";
import { SettingsConfigurationSettingsComponent } from "./modules/settings/components/settings/administration/settings-configuration-settings/settings-configuration-settings.component";
import { SettingsTransportationTypeComponent } from "./modules/settings/components/settings/events/settings-transportation-type/settings-transportation-type.component";

export const routes: Routes = [
  { path: "", redirectTo: "auth/login", pathMatch: "full" },
  {
    path: "app",
    component: LayoutComponent,
    children: [
      {
        path: "dashboard",
        children: [
          {
            path: "",
            component: DashboardComponent,
          },
          {
            path: "vehicle",
            component: VehiclesComponent,
          },
          {
            path: "booking",
            component: DashboardComponent,
          },
          {
            path: "training",
            component: DashboardComponent,
          },
          {
            path: "communication",
            component: MiIconsComponent,
          },
          {
            path: "map",
            component: MapsComponent,
          },
          {
            path: "heat-map",
            component: HeatMapsComponent,
          },
          {
            path: "activity-feed",
            component: ActivityFeedComponent,
          },

        ],
      },
      {
        path: "bookings",
        children: [
          {
            path: "",
            component: BookingsComponent,
            pathMatch: 'full',
          },
          {
            path: "current-bookings",
            component: BookingsComponent,
          },
          {
            path: "booking-history",
            component: BookingsHistoryComponent,
          },
          {
            path: "add",
            component: AddBookingComponent,
          }, {
            path: "edit/:id",
            component: AddBookingComponent,
          },
          {
            path: ":id",
            component: BookingDetailsComponent,
            pathMatch: "full",
          },
        ],
      },
      {
        path: "dispatch",
        children: [
          {
            path: "",
            component: DispatchComponent,
            pathMatch: "full",
          },
          {
            path: ":id",
            component: DispatchComponent,
            pathMatch: "full",
          },
          {
            path: ":id/details",
            component: DispatchBookingDetailsComponent,
            pathMatch: "full",
          },
        ],
      },
      {
        path: "organization",
        children: [
          {
            path: "",
            component: OrganizationListComponent,
          },
          {
            path: "add",
            component: AddOrganizationComponent,
          },
          {
            path: "details/:id",
            component: OrganizationDetailsComponent,
          },
          {
            path: "edit/:id/:index/:view",
            component: AddOrganizationComponent,
          }
        ],
      },
      {
        path: "events",
        children: [
          {
            path: "",
            component: EventsComponent,
          },
          {
            path: "list",
            component: EventsComponent,
          },
          {
            path: "add",
            component: AddEventComponent,
          },
          {
            path: "edit/:id/:index/:view",
            component: AddEventComponent,
          },
          {
            path: "details/:id",
            component: EventDetailsComponent,
          },
        ],
      },
      {
        path: "shift-management",
        children: [
          {
            path: "",
            component: ShiftCalendarComponent,
          },
          {
            path: "/shift-calendar",
            component: ShiftCalendarComponent,
          }
        ]
      },
      {
        path: "manage-shift",
        children: [
          {
            path: "",
            component: ManageShiftListComponent,
            pathMatch: 'full'
          },
          {
            path: "add-shift",
            component: AddShiftComponent,
            pathMatch: 'full'
          },
          {
            path: "assign-driver/:id",
            component: AssignDriverToShiftComponent,
            pathMatch: 'full'
          },
          {
            path: "details/:id",
            component: ShiftDetailsComponent,
            pathMatch: 'full'
          },
          {
            path: "edit-shift/:id",
            component: AddShiftComponent,
            pathMatch: 'full'
          }
        ],
      },
      {
        path: "shift-template",
        children: [
          {
            path: "",
            component: ShiftTemplateListComponent

          },
          {
            path: "add",
            component: AddShiftTemplateComponent,
            pathMatch: 'full'
          },
          {
            path: "edit/:id",
            component: AddShiftTemplateComponent,
            pathMatch: 'full'
          },
        ]
      },
      {
        path: "manage-groups",
        children: [
          {
            path: "",
            component: ManageGroupListComponent,
          },
          {
            path: "add-group",
            component: AddGroupComponent,
            pathMatch: 'full'
          },
          {
            path: "edit/:id",
            component: AddGroupComponent,
            pathMatch: 'full'
          },
          {
            path: ":id",
            component: ManageGroupDetailsComponent,
            pathMatch: "full",
          }
        ]
      },
      {
        path: "manage-segments",
        children: [
          {
            path: "add-segment",
            component: AddSegmentComponent,
            pathMatch: 'full'
          },
          {
            path: "edit/:id",
            component: AddSegmentComponent,
            pathMatch: 'full'
          },
        ]
      },
      {
        path: "passengers",
        children: [
          {
            path: "",
            component: PassengersComponent,
          },
          {
            path: "list",
            component: PassengersComponent,
          },
          {
            path: "add",
            component: AddPassengersComponent,
            pathMatch: "full",
          },
          {
            path: "edit/:id/:index",
            component: AddPassengersComponent,
            pathMatch: "full",
          },
          {
            path: ":id",
            component: PassengerDetailsComponent,
            pathMatch: "full",
          },
        ],
      },
      {
        path: "vehicles",
        children: [
          {
            path: "",
            component: VehicleListComponent,
          },
          {
            path: "list",
            component: VehicleListComponent,
          },
          {
            path: "add",
            component: AddVehicleComponent,
            pathMatch: "full",
          },
          {
            path: "add-multiple",
            component: AddMultipleVehiclesComponent,
          },
          {
            path: ":id",
            component: VehicleDetailsComponent,
            pathMatch: "full",
          },
          {
            path: "add/:id",
            component: AddVehicleComponent,
            pathMatch: "full",
          },
          {
            path: ":id/:documentId",
            component: DocumentDetailsComponent,
            pathMatch: "full",
          },
        ],
      },
      {
        path: "drivers",
        children: [
          {
            path: "",
            component: DriverListComponent,
          },
          {
            path: "list",
            component: DriverListComponent,
          },
          {
            path: "add",
            component: AddDriverComponent,
            pathMatch: "full",
          },
          {
            path: ":id",
            component: DriverDetailsComponent,
            pathMatch: "full",
          },
          {
            path: "add/:id",
            component: AddDriverComponent,
            pathMatch: "full",
          },
          {
            path: ":id/:documentId",
            component: DocumentDetailsComponent,
            pathMatch: "full",
          },
        ],
      },
      {
        path: "driver-leave-management",
        children: [
          {
            path: "",
            component: DriverLeaveManagementComponent,
          },
          {
            path: "new-leave",
            component: ApplyNewLeaveComponent,
          },
          {
            path: "new-leave/:id",
            component: ApplyNewLeaveComponent,
          },
          {
            path: "todays-view-all",
            component: TodayLeaveViewAllComponent,
          },
          {
            path: "tomorrows-view-all",
            component: TomorrowLeaveViewAllComponent,
          },
          {
            path: "leave-details/:id",
            component: LeaveDetailsComponent,
          }
        ]
      },
      {
        path: "queue",
        children: [
          {
            path: "",
            component: QueueListComponent,
          },
          {
            path: "list",
            component: QueueListComponent,
          },
          {
            path: "add",
            component: AddQueueComponent,
          }
        ],
      },
      {
        path: "concerns",
        children: [
          { path: "", component: IncidentsComplaintsListComponent },
          { path: "list", component: IncidentsComplaintsListComponent },
          {
            path: "add",
            component: AddIncidentsComplaintsComponent,
            pathMatch: "full",
          },
          {
            path: "add/:id",
            component: AddIncidentsComplaintsComponent,
            pathMatch: "full",
          },
          {
            path: ":id",
            component: IncidentsComplaintsDetailsComponent,
            pathMatch: "full",
          },
        ],
      },
      {
        path: "communication",
        component: CommunicationComponent,
        children: [
          { path: '', redirectTo: 'messaging', pathMatch: 'full' },
          { path: 'messaging', component: CommunicationComponent },
          { path: 'broadcast', component: BroadcastListComponent },
          { path: 'segment', component: SegmentListComponent }
        ]
      },
      {
        path: "content",
        children: [
          { path: 'about-company', component: AboutCompanyComponent },
          { path: 'faqs', component: FAQsComponent },
          { path: 'terms-and-conditions', component: TermsAndConditionsComponent },
          { path: 'privacy-policy', component: PrivacyPolicyComponent },
          { path: 'contact-support', component: ContactSupportComponent }
        ]
      },
      {
        path: "user-management",
        children: [
          { path: "", component: ManageRolesComponent },
          { path: "manage-roles", component: ManageRolesComponent },
          { path: "manage-roles/add-role", component: AddRoleComponent },
          { path: "manage-roles/edit-role/:id", component: AddRoleComponent },
          { path: "manage-users", component: ManageUserComponent },
          { path: "manage-users/add-user", component: AddUserComponent },
          { path: "manage-users/edit-user/:id", component: AddUserComponent },
          { path: "manage-users/:id", component: UserDetailsComponent }
        ],
      },
      {
        path: "manage-locations",
        children: [
          {
            path: "",
            component: ManageLocationsComponent,
          },
          {
            path: "list",
            component: ManageLocationsComponent,
          },
          {
            path: "add",
            component: AddLocationComponent,
          },
          {
            path: "add/:id",
            component: AddLocationComponent,
          },
          {
            path: ":id",
            component: LocationDetailsComponent,
          },
        ],
      },
      {
        path: "manage-zones",
        children: [
          {
            path: "",
            component: ManageZonesComponent,
          },
          {
            path: "add",
            component: AddZoneComponent,
            pathMatch: "full",
          },
          {
            path: "edit/:id",
            component: AddZoneComponent,
            pathMatch: "full",
          },
          {
            path: ":id",
            component: ZoneDetailComponent,
            pathMatch: "full",
          },
        ],
      },
      {
        path: "settings",
        component: SettingsComponent,
        children: [
          { path: 'user-profile', component: SettingsUserProfileComponent },
          { path: 'notification-settings', component: SettingsNotificationSettingsComponent },
          { path: 'login-and-password', component: SettingsLoginAndPasswordComponent },
          { path: 'general-settings', component: SettingsGeneralSettingsComponent },
          { path: 'configuration-settings', component: SettingsConfigurationSettingsComponent },
          { path: 'time-settings', component: SettingsTimeSettingsComponent },
          { path: 'export-account-data', component: SettingsExportAccountDataComponent },
          { path: 'security', component: SettingsSecurityComponent },
          { path: 'auth-user', component: SettingsUserComponent },
          { path: 'auth-driver', component: SettingsDriverComponent },
          { path: 'auth-passenger', component: SettingsPassengerComponent },
          { path: 'custom-fields', component: SettingsCustomFieldsComponent },
          { path: 'labels', component: SettingsLabelsComponent },
          { path: 'ride-preference', component: SettingsRidePreferenceComponent },
          { path: 'transportation-type', component: SettingsTransportationTypeComponent },
          { path: 'passenger-type', component: SettingsPassengerTypeComponent },
          { path: 'privilege-settings', component: SettingsPrivilegeSettingsComponent },
          { path: 'vehicle-status', component: SettingsVehicleStatusComponent },
          { path: 'vehicle-types', component: SettingsVehicleTypesComponent },
          { path: 'body-types', component: SettingsBodyTypesComponent },
          { path: 'features', component: SettingFeaturesComponent },
          { path: 'reason-for-duty-status', component: SettingsReasonForDutyStatusComponent },
          { path: 'driver-driving-record', component: SettingsDrivingRecordComponent },
          { path: 'location-category', component: SettingsLocationCategoryComponent },
          { path: 'incidents-status', component: SettingsStatusComponent },
          { path: 'complaints-status', component: SettingsStatusComponent },
          { path: 'group-color', component: SettingsGroupColorComponent },
          { path: 'message-type', component: SettingsMessageTypeComponent },
          { path: 'leave-type', component: SettingsLeaveTypeComponent },
          { path: 'reason-for-break', component: SettingsReasonForBreakComponent }
        ]
      }
    ],
  },
  {
    path: "auth/login",
    loadComponent: () =>
      import("./pages/auth/login/login.component").then(
        (m) => m.LoginComponent
      ),
  },
  {
    path: "auth/admin-login",
    loadComponent: () =>
      import("./pages/auth/login/login.component").then(
        (m) => m.LoginComponent
      ),
  },
  {
    path: "auth/sys-login",
    loadComponent: () =>
      import("./pages/auth/login/login.component").then(
        (m) => m.LoginComponent
      ),
  },
  {
    path: "auth/forgot-password",
    loadComponent: () =>
      import("./pages/auth/forgot-password/forgot-password.component").then(
        (m) => m.ForgotPasswordComponent
      ),
  },
  {
    path: "auth/reset-password",
    loadComponent: () =>
      import("./pages/auth/reset-password/reset-password.component").then(
        (m) => m.ResetPasswordComponent
      ),
  },
   {
    path: "auth/tenant-settings",
    loadComponent: () =>
      import("./pages/auth/tenant-setting/tenant-setting.component").then(
        (m) => m.TenantSettingComponent
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule { }
