<p-breadcrumb [model]="routePath"></p-breadcrumb>
<div appAccessProvider [accessCode]="{ menuCode: 'VEHICLES', pageCode: 'ALL', subPageCode: 'ADD' }">
<div class="flex justify-content-end flex-wrap btn-with-links">
    <button pButton class="mr-2 bg-white default-text-light-black-color cursor-pointer border-button" [label]="'cancel' | translate" (click)="onCancel()"></button>
    <p-button class="flex align-items-center justify-content-center cursor-pointer passenger-box" [label]="saveVehicleBtnLabel"  (click)="onNextBtnClick()"></p-button>
</div>
<p-tabView [(activeIndex)]="activeIndex" (onChange)="onTabChange($event)">
    @for (tab of vehicleAttributeData?.tabs; track tab; let i = $index) {
        <p-tabPanel [disabled]="!isFirstTabSaved && i > 0">
            <ng-template pTemplate="header">
                <div class="tab-icons">
                    <i class="mi-lg" [class]="tab.tabCode"></i>
                    <span>{{ cs.getLabelValue('vehicle.tabs.'+tab.tabCode+'.label',attributeLabels,tab.tabCode) }}</span>
                </div>
            </ng-template>
            <div class="" >
                @if (tab.tabCode !=='documents' ) {
                    <div>
                        <app-mi-form [attributeLabels]="attributeLabels" [attributeValues]="attributeValues" [moduleName]="moduleName" [groups]="tab.groups"
                            (saveData)="onSaveVehicle($event)" (previousClick)="onPreviousClick()" [showImg]="true" [isMultipleFilesUpload]="false"
                            [submitBtnText]="nextBtnLabel" [previousBtnText]="previousBtnLabel" [activeIndex]="activeIndex" [saveBtnId]="prefixToAttachForButtonIdToUniquelyIdentifyButton + tab.tabCode"></app-mi-form>
                    </div>
                }
                @if (tab.tabCode =='documents' ) {
                    <div class="flex justify-content-center vehicle-document" >
                        <div class="flex-dir-col">
                            <div class="justify-content-center">                           
                                <app-vehicle-document-entity [attributeLabels]="attributeLabels" [attributeValues]="attributeValues"  moduleName="vehicle_document"
                                    [tab]="tab" [attributeData]="vehicleAttributeData" (previousClick)="onPreviousClick()" (nextClick)="onNextClick()" [vehicleId]="vehicleIdForDocument"
                                    [submitBtnText]="nextBtnLabel" [previousBtnText]="previousBtnLabel" [activeIndex]="activeIndex" [saveBtnId]="prefixToAttachForButtonIdToUniquelyIdentifyButton + tab.tabCode"></app-vehicle-document-entity>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </p-tabPanel>
    }
</p-tabView>
</div>
