<p-breadcrumb [model]="routePath"></p-breadcrumb>
<div appAccessProvider [accessCode]="{ menuCode: 'DRIVERS', subMenuCode: 'ALL_DRIVERS', pageCode: 'ALL', subPageCode: 'ADD' }">
<div class="flex justify-content-end flex-wrap btn-with-links">
    <button pButton class="mr-2 bg-white default-text-light-black-color cursor-pointer border-button" [label]="'cancel' | translate" (click)="onCancel()"></button>
    <p-button class="flex align-items-center justify-content-center cursor-pointer passenger-box" (click)="onNextBtnClick()" [label]="saveDriverBtnLabel"></p-button>
</div>

<p-tabView [(activeIndex)]="activeIndex" (onChange)="onTabChange($event)">
    @for (tab of driverAttributeData?.tabs; track tab; let i = $index) {
        <p-tabPanel [disabled]="!isFirstTabSaved && i > 0">
            <ng-template pTemplate="header">
                @if (tab.tabCode !== 'other_details' ) {
                    <div class="tab-icons">
                        <i class="mi-lg" [class]="tab.tabCode"></i>
                        <span>{{ cs.getLabelValue('driver.tabs.'+tab.tabCode+'.label',attributeLabels,tab.tabCode) }}</span>
                    </div>
                }
                @if (tab.tabCode == 'other_details' ) {
                    <div class="tab-icons">
                        <i class="mi-lg details"></i>
                        <span>{{ cs.getLabelValue('driver.tabs.'+tab.tabCode+'.label',attributeLabels,tab.tabCode) }}</span>
                    </div>
                }
            </ng-template>
            <div class="" >
                @if (tab.tabCode === 'details') {
                    <div>
                        <app-mi-form [attributeLabels]="attributeLabels" [attributeValues]="attributeValues" [moduleName]="moduleName" [groups]="tab.groups"
                            (saveData)="onSaveDetails($event)" (previousClick)="onPreviousClick()" (nextClick)="onNextClick()" [showImg]="true" [isMultipleFilesUpload]="false"
                            [submitBtnText]="nextBtnLabel" [previousBtnText]="previousBtnLabel" [activeIndex]="activeIndex" [saveBtnId]="'details'"></app-mi-form>
                    </div>
                }
                @if (tab.tabCode === 'other_details') {
                    <div>
                        <app-driver-driving-record-entity [attributeLabels]="attributeLabels" [attributeValues]="attributeValues" [moduleName]="moduleName" [dataToChild]="dataToChild"
                            (previousClick)="onPreviousClick()" (nextClick)="onNextClick()" [tab]="tab" [driverId]="driverIdForDrivingRecord" [attributeData]="driverAttributeData" 
                            [submitBtnText]="nextBtnLabel" [previousBtnText]="previousBtnLabel" [(activeIndex)]="activeIndex" [saveBtnId]="'otherDetails'"></app-driver-driving-record-entity>
                    </div>
                }
                @if (tab.tabCode === 'documents') {
                    <div class="flex justify-content-center driver-document">
                        <div class="flex-dir-col">
                            <div class="justify-content-center">
                                <app-driver-document-entity [attributeLabels]="attributeLabels" [attributeValues]="attributeValues"  moduleName="driver_document" [dataToChild]="dataToChild"
                                    [tab]="tab" [attributeData]="driverAttributeData" (previousClick)="onPreviousClick()" (nextClick)="onNextClick()" [driverId]="driverIdForDocument"
                                    [submitBtnText]="nextBtnLabel" [previousBtnText]="previousBtnLabel" [activeIndex]="activeIndex" [saveBtnId]="'driverDocument'"></app-driver-document-entity>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </p-tabPanel>
    }
</p-tabView>
</div>
