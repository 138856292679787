import { CommonModule, DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AppSettings } from 'app/modules/shared/app.settings';
import { AccessProviderDirective } from 'app/modules/shared/directives/access-provider.directive';
import { Country } from 'app/modules/shared/models/country';
import { Language } from 'app/modules/shared/models/language';
import { Status } from 'app/modules/shared/models/status';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { EntityService } from 'app/modules/shared/services/entity.service';
import { MetaDataService } from 'app/modules/shared/services/meta-data.service';
import { MiImageContainerComponent } from 'app/modules/shared/ui-sharable/mi-image-container/mi-image-container.component';
import { addTimeToCurrentDate } from 'app/modules/shared/utility-functions/date';
import { ShiftService } from 'app/modules/shift-management/services/shift.service';
import { MenuItem, MessageService } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';

@Component({
  selector: 'app-shift-details',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, TranslateModule, ButtonModule, BreadcrumbModule,
    DropdownModule, DatePipe, CommonModule, AccessProviderDirective, MiImageContainerComponent
  ],
  templateUrl: './shift-details.component.html',
  styleUrl: './shift-details.component.scss',
  providers: [DatePipe]
})
export class ShiftDetailsComponent {
  defaultLocationList = AppSettings.DEFAULT_ADDRESS_LIST_FOR_ORGANIZATION;
  tenantId: any;
  country: Country;
  language: Language;
  routePath: MenuItem[] = [];
  dataForStatus: Array<Status> = [];
  imgFileId: any;
  entityForStatus: any;
  logoImage: any;
  defaultLoaderImg = AppSettings.DEFAULT_LOADER_IMAGE;
  userDetails: any;
  tenantDetails: any;
  showStatus: boolean = false;
  moduleList: any[] = [];
  moduleListValues = [];

  activeIndex: number = 0;
  tenantCode: any;
  editBtnLabel: any;
  groups: any[] = [];
  selectedGroup: any;
  shiftId: any;
  shiftDetails: any;
  DRIVER_TYPE = AppSettings.DRIVER_TYPE;
  driverList: any[] = [];
  numberOfDays: any;
  groupId: any;
  driverCount: any;
  driverProfileImage: string | null = null;
  hourFormat = this.metaDataService.timeFormat;

  constructor(
    public cs: CommonBindingDataService,
    private entityService: EntityService,
    private route: ActivatedRoute,
    private configService: ConfigService,
    private router: Router,
    private messageService: MessageService,
    private shiftService: ShiftService,
    private metaDataService: MetaDataService,
    private cd: ChangeDetectorRef) { }

  ngOnInit() {
    this.shiftId = this.route.snapshot.paramMap.get('id');
    if (this.shiftId) {
      this.getShiftDetails();
    }
    this.setCountryAndLanguage();
    this.setRoutePath();
    this.editBtnLabel = this.cs.getLabel('shift.lbl_edit');

  }

  getShiftDetails() {
    this.shiftService.getShiftDetails(this.shiftId).subscribe(result => {
      this.shiftDetails = result;
      this.shiftDetails.startTimeStr = addTimeToCurrentDate(this.shiftDetails.startTimeStr);
      this.shiftDetails.endTimeStr = addTimeToCurrentDate(this.shiftDetails.endTimeStr);
      if (this.shiftDetails?.assignedEntity === AppSettings.DRIVER_TYPE.DRIVERS) {
        if (this.shiftDetails.shiftDriverList.length > 0) {
          this.getDriverList(this.shiftDetails.shiftDriverList);
        }
      } else {
        if (this.shiftDetails.shiftGroupList.length > 0) {
          this.getGroupList(this.shiftDetails.shiftGroupList);
        }
      }
    })
  }

  getGroupList(groupList: any[]) {
    let requestBody = {
      forTenantCode: this.configService.getForTenantCode(),
      countryCode: this.country[0].countryCode,
      viewCode: AppSettings.VIEW_CODE.DETAIL_VIEW,
      entityIds: groupList,
      limit: groupList.length,
      offset: 0
    }
    this.entityService.getEntityDetailsByEntityIds(requestBody, AppSettings.ENTITY_CODE.DRIVER_GROUP).subscribe((result: any) => {
      this.setGroupList(result);
      this.cd.detectChanges();
    });
  }

  setGroupList(result) {
    result.data.forEach((groupData, index) => {
      let group = {
        groupName: groupData.values.group_name,
        color: groupData.values.group_color,
        groupStatus: groupData.values.group_status,
        createdOn: groupData.values.created_on,
        createdBy: groupData.values.created_by,
        totalDrivers: groupData.values.assigned_drivers.length,
        driverIds: groupData.values.assigned_drivers,
        groupId: groupData.id
      }
      this.groups.push(group);
      if (index === result.data.length - 1) {
        this.getDriverList(this.groups[0].driverIds);
        this.selectedGroup = this.groups[0];
        this.driverCount = this.selectedGroup.driverIds;
      }
    });

  }

  getDriverList(driverList: any[]) {
    let requestBody = {
      forTenantCode: this.configService.getForTenantCode(),
      countryCode: this.country[0].countryCode,
      viewCode: AppSettings.VIEW_CODE.DETAIL_VIEW,
      entityIds: driverList,
      limit: driverList.length,
      offset: 0
    }
    this.entityService.getEntityDetailsByEntityIds(requestBody, AppSettings.ENTITY_CODE.DRIVER).subscribe((result: any) => {
      this.driverCount = result.count;
      this.setDriverList(result);
    });
  }

  setDriverList(result) {
    result.data.forEach(driverData => {
      let driver = {
        driverName: `${driverData.values.first_name} ${driverData.values.last_name}`,
        uniqueId: driverData.values.unique_id,
        profilePhoto: '',
        driverProfileImage: '',
      }

      const images = driverData.values.driver_profile_image_url;
        if (images && images.length > 0) {
          images.forEach(imageId => {
            driverData.driverProfileImage = driverData.values.driver_profile_image_url[0]
          });
        }
        console.log(driverData)
        console.log(driverData.driverProfileImage)
        
      this.driverList.push(driver);
    });


  }

  onGroupSelect(groupId) {
    this.selectedGroup = this.groups.find(ele => ele.groupId === groupId);
    if (this.selectedGroup) {
      this.driverList = [];
      this.getDriverList(this.selectedGroup.driverIds);
    }
  }

  getUserDetails(userId) {
    this.entityService.getUserDetails(userId, this.configService.getForTenantCode()).subscribe(result => {
      this.userDetails = result;
    })
  }

  getImage(fileId, driver) {
    this.entityService.getFile(fileId, AppSettings.DOCUMENTS_TYPE.PROFILE).subscribe(result => {
      const file = new File([result], 'driver');
      const reader = new FileReader();
      reader.readAsDataURL(result);
      const that = this;
      reader.onloadend = function() {
        const base64data = reader.result;
        driver.driverProfileImage = base64data;
      }
    })
  }

  edit() {
    this.router.navigate([`/app/manage-shift/edit-shift/${this.shiftId}`])
  }

  setRoutePath() {
    this.routePath = [
      {
        label: this.cs.getLabel('shift.shift_details'),
        routerLink: '/app/manage-shift',
        icon: 'pi pi-arrow-left',
        iconStyle: { 'font-weight': 'bold', 'margin-right': '10px' }
      },
      {
        label: this.cs.getLabel('shift.manage_shift'),
        styleClass: 'breadcrumb-child forward-slash details-text',
        style: { 'display': 'flex', 'top': '2px', 'position': 'relative' }
      },
    ];
  }

  setCountryAndLanguage() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
  }

  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
