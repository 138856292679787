import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import type { EntityCount, EntityCountsResponse, EntityList } from 'app/modules/vehicles/models/entity.models';
import * as _ from 'lodash';
import { MenuItem } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { AppIcons } from '../../app.icons';
import { AppSettings } from '../../app.settings';
import { Country } from '../../models/country';
import { Language } from '../../models/language';
import { CommonBindingDataService } from '../../services/common-binding-data.service';
import { ConfigService } from '../../services/config.service';
import { EntityService } from '../../services/entity.service';
import { MITableComponent } from '../mi-table/mi-table.component';
import { Values } from 'app/modules/vehicles/models/listResponse.models';

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrl: './document-list.component.scss',
  standalone: true,
  imports: [MITableComponent],
})
export class DocumentListComponent {
  @ViewChild(MITableComponent) tableRef: MITableComponent;
  @Input() entityCode: string;
  @Input() attributeValues: any;
  @Input() attributeLabels: any;
  @Input() vehicleDetails: any;
  @Input() driverDetails: any;
  entityName: string;
  miIcons = AppIcons;
  filterAttributeLabels = {};
  listColumns: Values;
  allColumns: any;
  totalRecords: number = 0;
  currentRow: any;
  pagination: any;
  tableData: any = [];
  data;
  isLoading = true;
  filterList;
  filterFieldsArray: any[];
  filterAvailableColumns = [];
  rowItems: MenuItem[] | undefined;
  country: Country;
  language: Language;
  entityCount: EntityCountsResponse;
  entityData: EntityList = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
    forTenantCode: this.configService.getForTenantCode(),
    actionStatus: ''
  };
  attributeIdOfDriverEntityId: any;
  attributeIdOfVehicleEntityId: any;
  attributeIdOfEntityId: any;
  addBtnLabel: string;
  @Output() onBtnClick = new EventEmitter<void>();

  constructor(private documentService: EntityService,
    private cs: CommonBindingDataService,
    private configService: ConfigService,
    private router: Router) { }

  ngOnInit() {
    this.initializeRowItems();
    this.initializeOtherVariablesAndAPI();
  }

  private initializeRowItems() {
    if (this.entityCode === AppSettings.ENTITY_CODE.VEHICLE_DOCUMENT) {
      this.rowItems = [
        {
          label: "Edit",
          icon: AppIcons.EDIT_2 + " wh-16",
          iconClass: 'bulk-update-icon',
          command: () => {
            this.router.navigate(['app/vehicles/add/' + this.vehicleDetails.entityId]);
          }
        },
        {
          label: "View Record History",
          icon: AppIcons.HISTORY + " wh-16",
          iconClass: 'bulk-update-icon',
          command: () => {

          }
        },
        { separator: true },
        {
          label: 'Delete',
          icon: AppIcons.BASIC_TRAY + " wh-16",
          iconClass: 'bulk-update-icon',
          command: (e) => {
            this.tableRef.delete(AppSettings.DELETE_TYPE.ROW);
          }
        }
      ];
    } else {
      this.rowItems = [];
    }
  }

  private initializeOtherVariablesAndAPI() {
    this.addBtnLabel = this.cs.getLabel('lbl_add_document');
    this.entityName = this.entityCode === AppSettings.ENTITY_CODE.VEHICLE_DOCUMENT ? 'vehicle_document' : 'driver_document';
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.getData();
  }

  onAddDocument() {
    this.onBtnClick.emit();
  }

  getTableView() {
    const entityDocumentCode = this.entityCode === AppSettings.ENTITY_CODE.VEHICLE_DOCUMENT ? AppSettings.ENTITY_CODE.VEHICLE_DOCUMENT : AppSettings.ENTITY_CODE.DRIVER_DOCUMENT;
    this.documentService.getAttributeDefinition(this.entityCode, AppSettings.VIEW_CODE.DEFAULT_TABLE_VIEW).subscribe(res => {
      if (res) {
        this.isLoading = false;
        this.data = res;
        this.attributeLabels = this.cs.getAttributeLabels(this.data);
        this.allColumns = this.cs.getColumns(this.data);

        this.allColumns?.forEach((key, index) => {
          this.allColumns[index] = key;
          this.allColumns[index].field = key.attributeCode;
          this.allColumns[index].header = this.cs.getLabelValue(
            entityDocumentCode + ".fields." + key.attributeCode + ".label",
            this.attributeLabels,
            key.attributeCode
          );
        });

        this.filterAvailableColumns = _.clone(this.allColumns);
        this.searchEntity();
      }
    });
  }

  getFilterView() {
    this.documentService.getAttributeDefinition(this.entityCode, AppSettings.VIEW_CODE.ADVANCED_FILTER_VIEW).subscribe(filterResponse => {
      if (filterResponse) {
        this.filterAttributeLabels = this.cs.getAttributeLabels(filterResponse);
        this.filterList = this.cs.getColumns(filterResponse)
          .filter(item => item.attributeCode !== (this.entityCode === AppSettings.ENTITY_CODE.VEHICLE_DOCUMENT ? 'vehicle_entity_id' : 'driver_entity_id'));
        this.filterList = _.sortBy(this.filterList, 'attributeIndex');
        this.attributeIdOfEntityId = filterResponse.tabs[0].groups[0].fields
          .find(element => element.attributeCode === (this.entityCode === AppSettings.ENTITY_CODE.VEHICLE_DOCUMENT ? 'vehicle_entity_id' : 'driver_entity_id'))?.attributeId;
        this.getTableView();
      }
    })
  }

  getEntityCount() {
    const country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    const entityCountPayload = <EntityCount>{
      forTenantCode: this.configService.getForTenantCode(),
      countryCode: country[0].countryCode,
      entityCode: this.entityCode,
    };

    this.documentService.getEntityCount(this.entityCode, entityCountPayload)
      .subscribe((res: EntityCountsResponse) => {
        this.entityCount = res;
      });
  }

  searchEntity() {
    this.tableData = [];
    const entityId = this.entityCode === AppSettings.ENTITY_CODE.VEHICLE_DOCUMENT ? this.vehicleDetails.entityId : this.driverDetails.entityId;

    this.entityData.filters = [{ attributeId: this.attributeIdOfEntityId, attributeValue: [entityId] }, ...this.entityData.filters];
    this.entityData.countryCode = this.country[0].countryCode;
    this.documentService.searchEntity(this.entityCode, this.entityData).subscribe((res: any) => {
      const filteredData = res?.data.filter(item => item.values !== null);
      this.listColumns = filteredData.length > 0 ? filteredData[0].values : [];
      this.pagination = res?.pagination;
      this.totalRecords = filteredData.length;
      this.tableData = this.cs.getTableData({ data: filteredData });
    });
  }

  getData() {
    return forkJoin({
      filterViewDate: this.getFilterView(),
      getEntityCount: this.getEntityCount()
    })
  }

  onFilterValueChange(event) {
    const vData = event;
    this.entityData.filters = [];
    for (const [key, value] of Object.entries(vData)) {
      const attributeValue = value;
      if (key === AppSettings.DATE_ATTRIBUTE_IDS.DOCUMENT_EXPIRY_DATE) {
        const date = new Date(value.toString());
        const timestamp = date.getTime();
        if (attributeValue) {
          this.entityData.filters.push({
            attributeId: this.cs.getAttributeIdForFilterFields(this.filterList, key),
            attributeValue: timestamp
          });
        }
      } else {
        if (attributeValue) {
          this.entityData.filters.push({
            attributeCode: key,
            attributeValue
          });
        }
      }
    }
    this.entityData.offset = event.first;
    this.entityData.filters = this.cs.mapAttributeIds(this.entityData.filters, this.filterList, 'filter');
    this.searchEntity();
  }

  onSearchValueChanges(event) {
    this.entityData.searchStr = event;
    this.searchEntity();
  }

  setCurrentRowData(event) {
    console.log(event);
    this.currentRow = event;
  }

  onRowSelect(event) {
    const entityId = this.entityCode === AppSettings.ENTITY_CODE.VEHICLE_DOCUMENT ? this.vehicleDetails.entityId : this.driverDetails.entityId;
    if (event.type === 'row') {
      if (this.entityCode === AppSettings.ENTITY_CODE.VEHICLE_DOCUMENT) {
        this.router.navigate([`app/vehicles/${entityId}/${event.data.id}`], { queryParams: { entityCode: this.entityCode } });
      } else if (this.entityCode === AppSettings.ENTITY_CODE.DRIVER_DOCUMENT) {
        this.router.navigate([`app/drivers/${entityId}/${event.data.id}`], { queryParams: { entityCode: this.entityCode } });
      }
    }
  }

  onAddVehicleDocument() {
    this.router.navigate(['app/vehicles/add',]);
  }

  onAddDriverDocument(){
    this.router.navigate(['app/drivers/add',]);
  }

}
