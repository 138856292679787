import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { TabViewModule } from 'primeng/tabview';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { EntityService } from '../../../shared/services/entity.service';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { AppSettings } from '../../../shared/app.settings';
import { SelectButtonModule } from 'primeng/selectbutton';
import { RadioButtonModule } from 'primeng/radiobutton';
import { AddEditBreakComponent } from '../add-edit-break/add-edit-break.component';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ShiftTemplateService } from '../../../shift-template/services/shift-template.service';
import { ConfigService } from '../../../shared/services/config.service';
import { Country } from '../../../shared/models/country';
import { ShiftService } from '../../services/shift.service';
import { CommonModule } from '@angular/common';
import dayjs from 'dayjs';
import * as _ from 'lodash';
import { PanelModule } from 'primeng/panel';
import { AccessProviderDirective } from 'app/modules/shared/directives/access-provider.directive';
import { TrimSpacesDirective } from 'app/modules/shared/directives/trim-spaces.directive';
import { MetaDataService } from 'app/modules/shared/services/meta-data.service';
import { DialogModule } from 'primeng/dialog';
import { addTimeToCurrentDate } from 'app/modules/shared/utility-functions/date';
import { AttributeData } from 'app/modules/vehicles/models/attribute.models';


@Component({
  selector: 'app-add-shift',
  standalone: true,
  imports: [
    ButtonModule, TranslateModule, TabViewModule, BreadcrumbModule, DropdownModule, CalendarModule, SelectButtonModule,
    RadioButtonModule, AddEditBreakComponent, InputTextModule, CheckboxModule, CommonModule, DialogModule,
    FormsModule, ReactiveFormsModule, RadioButtonModule, PanelModule, AccessProviderDirective, TrimSpacesDirective
  ],
  templateUrl: './add-shift.component.html',
  styleUrl: './add-shift.component.scss'
})
export class AddShiftComponent implements OnInit {
  routePath: MenuItem[] = [];
  individualDriverList: any = [];
  driverGroupList: any = [];

  shiftTemplates: any[] | undefined;
  dateFormat = AppSettings.DATE_FORMAT_DD_MM_YY;
  hourFormat = this.metaDataService.timeFormat;

  selectedShift: any;
  dayOptions = AppSettings.DAYS_COLLECTION;
  shiftType: any = [];
  selectedShiftType: any = AppSettings.SHIFT_TYPE.SHIFT_TEMPLATE;
  selectedAssignmentType: any = AppSettings.DRIVER_ASSIGNMENT_TYPE.INDIVIDUAL_DRIVER
  assignmentType: any = [];
  placeholder: any;
  DRIVER_ASSIGNMENT_TYPE = AppSettings.DRIVER_ASSIGNMENT_TYPE;
  addShiftForm: FormGroup;
  activeIndex = 0;
  SHIFT_TYPE = AppSettings.SHIFT_TYPE;
  selectedDrivers: any[] = [];
  selectedDriversCopy: any[] = [];
  newlyAddedDriversInEditMode: any[] = [];
  newlyAddedGroupssInEditMode: any[] = [];
  selectedGroups: any[] = [];
  selectedGroupsCopy: any[] = [];
  startDate: Date;
  endDate: Date;
  startTime: Date;
  endTime: Date;
  selectShiftPlaceholder: any;
  disableShiftTimeFields: boolean = false;
  shiftTemplateBreakList = [];
  showBreak: boolean = false;
  country: Country;
  searchText: any;
  filteredDriverList: any[] = [];
  filteredGroupList: any[] = [];
  shiftId: any;
  isEdit: boolean = false;
  showAssignee: boolean = false;
  checked: boolean = false;
  shiftDetails: any;
  defaultLoaderImg = AppSettings.DEFAULT_LOADER_IMAGE;
  selectedShiftTemplateId: any;
  saveBtnLabel: string;
  currentDate: Date;
  showDriverList: boolean = false;
  showGroupList: boolean = false;
  originalFormData: any;
  isFormInitialized: boolean = false;
  shiftStartDateInDetails: any;
  attributeIdForDriverName: any;
  attributeIdForDriverStatus: any;
  driverCount: any;
  groupCount: any;
  entityData: any = {
    limit: 2000,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted: 'ONLY_NON_DELETED',
    forTenantCode: '',
    actionStatus: ''
  };

  driverEntityData: any = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted: 'ONLY_NON_DELETED',
    forTenantCode: '',
    actionStatus: '',
    defaultSortType: 'asc'
  };

  private maxDataReached = {
    entityDataForDriver: false,
  }

  constructor(private entityService: EntityService,
    private cs: CommonBindingDataService,
    private messageService: MessageService,
    private router: Router,
    private formBuilder: FormBuilder,
    private shiftTemplateService: ShiftTemplateService,
    private configService: ConfigService,
    private cd: ChangeDetectorRef,
    private shiftService: ShiftService,
    private metaDataService: MetaDataService,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute) {
      this.currentDate = this.metaDataService.getCurrentTimeInTimezone();
    }


  ngOnInit() {
    this.getAttributeIdForDriverFilter();
    this.shiftId = this.route.snapshot.paramMap.get('id');
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.entityData.forTenantCode = this.driverEntityData.forTenantCode = this.entityData.forTenantCode = this.configService.getForTenantCode();
    this.driverEntityData.countryCode = this.entityData.countryCode = this.country[0].countryCode;
    this.initializeForm();
    this.initializeVariables();
    this.setRoutePath();
    this.getShiftTemplateList();
    this.getIndividualDriverList(true);
    this.getDriverGroupList(true);

    setTimeout(() => {
      const cb = () => {
        this.driverEntityData.offset = this.driverEntityData.offset + (this.driverEntityData.offset === 0 ? this.driverEntityData.limit : AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT);
        if (!this.maxDataReached.entityDataForDriver) {
          if (this.selectedAssignmentType === this.DRIVER_ASSIGNMENT_TYPE.INDIVIDUAL_DRIVER) {
            this.getIndividualDriverList(false);
          } else {
            this.getDriverGroupList(false);
          }

        }
      };
      this.applyInfiniteScroll(cb)
    }, 30);

  }

  applyInfiniteScroll(onEndPageCallback) {
    const contentEl = document.getElementById('assignment-scroll') as HTMLElement;
    const handleInfiniteScroll = (event) => {
      const heightDiff = contentEl.scrollHeight - contentEl.offsetHeight;
      const endOfPage = Math.round(contentEl.scrollTop) === heightDiff || Math.round(contentEl.scrollTop) === (heightDiff + 1);
      if (endOfPage) {
        onEndPageCallback()
      }
    };
    if (contentEl) {
      contentEl.addEventListener("scroll", handleInfiniteScroll)
    }
  }

  getAttributeIdForDriverFilter() {
    this.entityService
      .getAttributeDefinition(
        AppSettings.ENTITY_CODE.DRIVER,
        AppSettings.VIEW_CODE.DEFAULT_TABLE_VIEW
      )
      .subscribe((res: AttributeData) => {
        if (res) {
          this.setAttrributeIdForDriverFilter(res);
        }
      });
  }

  setAttrributeIdForDriverFilter(response) {
    this.attributeIdForDriverName = this.cs.getAttributeId('first_name', response);
    this.driverEntityData.defaultSortColumn =  this.attributeIdForDriverName;
    this.attributeIdForDriverStatus = this.cs.getAttributeId('driver_status', response);
    this.driverEntityData.filters = [];
    this.driverEntityData.filters.push({
      attributeId: this.attributeIdForDriverStatus,
      attributeValue: [AppSettings.ACTIVE_DRIVER_STATUS]
    })
  }

  getShiftDetails() {
    this.shiftService.getShiftDetails(this.shiftId).subscribe((result: any) => {

      this.originalFormData = result;
      this.isFormInitialized = true;
      this.shiftDetails = result;
      this.selectedDrivers = result.shiftDriverList;
      this.selectedDriversCopy = (result.shiftDriverList && result.shiftDriverList.length > 0) ? _.cloneDeep(result.shiftDriverList) : [];
      this.selectedGroups = result.shiftGroupList;
      this.selectedGroupsCopy = (result.shiftGroupList && result.shiftGroupList.length > 0) ? _.cloneDeep(result.shiftGroupList) : [];

      if (result.shiftTemplateId) {
        this.selectedShiftTemplateId = result.shiftTemplateId;
        this.addShiftForm.controls['shiftTemplate'].setValue(result.shiftTemplateId);
      }
      this.addShiftForm.controls['name'].setValue(result.shiftName);
      this.addShiftForm.controls['startDate'].setValue(new Date(result.startDateStr));
      this.shiftStartDateInDetails = new Date(result.startDateStr);
      this.startDate = new Date(result.startDateStr);
      this.addShiftForm.controls['endDate'].setValue(new Date(result.endDateStr));
      this.startTime = addTimeToCurrentDate(result.startTimeStr);
      this.endTime = addTimeToCurrentDate(result.endTimeStr);
      this.disableShiftTimeFields = this.selectedShiftType === this.SHIFT_TYPE.SHIFT_TEMPLATE;
      this.addShiftForm.controls['startTime'].setValue(this.startTime);
      this.addShiftForm.controls['endTime'].setValue(this.endTime);
      this.addShiftForm.controls['days'].setValue(result.weekdaysList);
      if (this.shiftDetails.shiftTemplateId) {
      this.getShiftTemplateDetails(this.shiftDetails.shiftTemplateId);
      }

      this.shiftTemplateBreakList = result.shiftBreakList;
      this.selectedShiftType = result.shiftTemplateId ? this.SHIFT_TYPE.SHIFT_TEMPLATE : this.SHIFT_TYPE.MANUALLY;
      if (result.shiftTemplateId) {
        this.addShiftForm.controls['shiftTemplate'].addValidators(Validators.required);
        this.addShiftForm.controls['shiftTemplate'].updateValueAndValidity();
      }
      else {
        this.addShiftForm.controls['shiftTemplate'].removeValidators(Validators.required);
        this.addShiftForm.controls['shiftTemplate'].updateValueAndValidity();
      }
      this.addShiftForm.controls['type'].setValue(this.selectedShiftType);
      this.addShiftForm.controls['assignmentType'].setValue(result.assignedEntity);

      this.selectedAssignmentType = result.assignedEntity;
      this.addShiftForm.controls['type'].disable();
      this.addShiftForm.controls['assignmentType'].disable();
      this.setSelectedDriverList();
      this.setSelectedGroupList();
      this.showBreak = true;
    })
  }

  setSelectedDriverList() {
    if (this.filteredDriverList && this.filteredDriverList.length > 0) {
      this.filteredDriverList.forEach((element, index) => {
        if (this.selectedDrivers?.includes(element.id)) {
          element.isSelected = true;
        }
        if (index === this.filteredDriverList.length - 1) {
          this.showDriverList = false;
          this.cd.detectChanges();
          this.showDriverList = true;
        }
      });
    }
  }

  setSelectedGroupList() {
    if (this.filteredGroupList && this.filteredGroupList.length > 0) {
      this.filteredGroupList.forEach((element, index) => {
        if (this.selectedGroups?.includes(element.id)) {
          element.isSelected = true;
        }
        if (index === this.filteredGroupList.length - 1) {
          this.showGroupList = false;
          this.cd.detectChanges();
          this.showGroupList = true;
        }
      });
    }
  }

  initializeVariables() {
    this.shiftType = AppSettings.SHIFT_TYPE_OPTIONS;
    this.assignmentType = AppSettings.ASSIGNMENT_TYPE_OPTIONS;
    this.placeholder = this.cs.getLabel('shift.search_driver');
    this.selectShiftPlaceholder = this.cs.getLabel('shift.lbl_select_shift');
    this.saveBtnLabel = this.shiftId ? this.cs.getLabel('shift.lbl_update_shift') : this.cs.getLabel('shift.lbl_save_shift');
  }

  initializeForm() {
    this.addShiftForm = this.formBuilder.group({
      type: [this.selectedShiftType],
      name: ['', [Validators.required, Validators.maxLength(50), Validators.pattern(AppSettings.NAME_SPACE_PATTERN2)]],
      shiftTemplate: [''],
      startDate: ['', [Validators.required]],
      endDate: ['', [Validators.required]],
      startTime: [],
      endTime: [],
      days: [],
      assignmentType: [this.selectedAssignmentType],
      drivers: [],
      groups: [],
      searchText: [],
      driverList: [],
      breakFields: this.formBuilder.array([]),
    });

    const currentDateInTimezone = this.metaDataService.getCurrentTimeInTimezone();
    this.addShiftForm.controls['startDate'].setValue(currentDateInTimezone);
    this.addShiftForm.controls['shiftTemplate'].addValidators(Validators.required);
    this.addShiftForm.controls['shiftTemplate'].updateValueAndValidity();
  }


  setRoutePath() {
    this.routePath = [
      {
        label: this.shiftId ? this.cs.getLabel('shift.lbl_edit_shift') : this.cs.getLabel('shift.add_new_shift'),
        routerLink: '/app/manage-shift',
        icon: 'pi pi-arrow-left',
        iconStyle: { 'font-weight': 'bold', 'margin-right': '10px' }
      }
    ];
  }

  getShiftTemplateList() {
    this.shiftTemplateService.searchShiftTemplateList(this.entityData).subscribe((res: any) => {
      this.shiftTemplates = res.data;
    });
  }

  getIndividualDriverList(loadshiftDetails: boolean) {
    this.entityService
      .searchEntity(AppSettings.ENTITY_CODE.DRIVER, this.driverEntityData)
      .subscribe((res: any) => {
        this.driverCount = res.count;
        this.individualDriverList.push(...res.data);
        this.filteredDriverList.push(...res.data);
        this.getDriverProfileImages();
        if (this.shiftId && loadshiftDetails) {
          this.getShiftDetails();
          this.isEdit = true;
        } else {
          this.setSelectedDriverList();
          this.showBreak = true;
          this.showDriverList = true;
        }
      });
  }

  getDriverProfileImages() {
    this.filteredDriverList.forEach(element => {
      if (element.values?.driver_profile_image_url && element.values?.driver_profile_image_url?.length > 0) {
        element.profilePhoto = element.values?.driver_profile_image_url[0];
      }
    });
  }

  getDriverGroupList(loadshiftDetails) {
    this.entityService
      .searchEntity(AppSettings.ENTITY_CODE.DRIVER_GROUP, this.entityData)
      .subscribe((res: any) => {
        this.driverCount = res.count;
        this.driverGroupList = this.filteredGroupList = res.data;
        if (this.shiftId && loadshiftDetails) {
          this.getShiftDetails();
          this.isEdit = true;
        } else {
          this.showBreak = true;
          this.setSelectedGroupList();
          this.showGroupList = true;
        }

      });
  }

  getShiftTemplateDetails(shiftTemplateId) {
    this.shiftTemplateService.getDetails(shiftTemplateId).subscribe((result: any) => {
      if (result) {
        this.startTime = addTimeToCurrentDate(result.startTimeStr);
        this.endTime = addTimeToCurrentDate(result.endTimeStr);
        this.addShiftForm.controls['startTime'].setValue(this.startTime);
        this.addShiftForm.controls['endTime'].setValue(this.endTime);
        this.shiftTemplateBreakList = result.shiftTemplateBreakList;
      }
    })
  }

  onShiftTemplateChange(event) {
    this.selectedShiftTemplateId = event.value;
    this.getShiftTemplateDetails(this.selectedShiftTemplateId);
    this.disableShiftTimeFields = true;
    this.showBreak = false;
    this.cd.detectChanges();
    this.showBreak = true;
  }

  shiftTypeChange(event) {
    this.selectedShiftType = event.key;
    this.disableShiftTimeFields = this.selectedShiftType === this.SHIFT_TYPE.SHIFT_TEMPLATE;
    if (this.selectedShiftType === this.SHIFT_TYPE.SHIFT_TEMPLATE) {
      this.addShiftForm.controls['shiftTemplate'].clearValidators();
      this.addShiftForm.controls['shiftTemplate'].updateValueAndValidity();
      this.addShiftForm.controls['shiftTemplate'].addValidators(Validators.required);
      this.addShiftForm.controls['shiftTemplate'].updateValueAndValidity();
    } else {
      this.addShiftForm.controls['shiftTemplate'].removeValidators(Validators.required);
      this.addShiftForm.controls['shiftTemplate'].updateValueAndValidity();
    }
    this.addShiftForm.controls['shiftTemplate'].setValue(null);
    this.addShiftForm.controls['startTime'].setValue(null);
    this.addShiftForm.controls['endTime'].setValue(null);

    this.shiftTemplateBreakList = [];
    this.showBreak = false;
    this.cd.detectChanges();
    this.showBreak = true;
  }

  searchDriverOrGroup() {
    if (this.addShiftForm.controls['assignmentType'].value === this.DRIVER_ASSIGNMENT_TYPE.INDIVIDUAL_DRIVER) {
      this.filteredDriverList = [];
      this.driverEntityData.offset = 0;
      this.maxDataReached.entityDataForDriver = false;
      this.driverEntityData.searchStr = this.addShiftForm.controls['searchText'].value?.toLowerCase();
      if (this.driverEntityData.searchStr.length >= 3 || this.driverEntityData.searchStr === '') {
        this.getIndividualDriverList(false);
      }
    } else {
      this.filteredGroupList = [];
      this.entityData.searchStr = this.addShiftForm.controls['searchText'].value?.toLowerCase();
      if (this.entityData.searchStr.length >= 3 || this.entityData.searchStr === '') {
        this.getDriverGroupList(false);
      }
    }
  }

  assignmentTypeChange(event) {
    this.driverEntityData.offset = 0;
    this.maxDataReached.entityDataForDriver = false;
    this.selectedDrivers = [];
    this.selectedGroups = [];
    this.individualDriverList = [];
    this.filteredDriverList = []
    this.getIndividualDriverList(true);
    this.getDriverGroupList(true);
    this.selectedAssignmentType = event.key;
  }

  onIndividualCheckboxChange(event, driver, options) {
    if (event.checked) {
      this.selectedDrivers.push(driver.id);
    } else {
      const index = this.selectedDrivers.findIndex(ele => ele === driver.id);
      if (index >= 0) {
        this.selectedDrivers.splice(index, 1);
      }
    }
  }

  onGroupCheckboxChange(event, driverGroup) {
    if (event.checked) {
      this.selectedGroups.push(driverGroup.id);
    } else {
      const index = this.selectedGroups.findIndex(ele => ele === driverGroup.id);
      if (index >= 0) {
        this.selectedGroups.splice(index, 1);
      }
    }
  }

  shiftNameBlur() {
    this.addShiftForm.controls['name'].setValue(this.addShiftForm.controls['name'].value?.trim());
  }

  onSaveShift() {
    if (!this.addShiftForm.valid) {
      this.addShiftForm.markAllAsTouched();
      return
    } else {
      let requestBody = {};
      if (this.addShiftForm.controls['type'].value === AppSettings.SHIFT_TYPE.SHIFT_TEMPLATE) {
        requestBody = {
          tenantCode: this.configService.getForTenantCode(),
          countryCode: this.country[0].countryCode,
          shiftTemplateId: this.selectedShiftTemplateId,
          shiftName: this.addShiftForm.controls['name'].value,
          startDateStr: dayjs(this.addShiftForm.controls['startDate'].value).format(AppSettings.DD_MM_YYYY),
          endDateStr: dayjs(this.addShiftForm.controls['endDate'].value).format(AppSettings.DD_MM_YYYY),
          startTimeStr: dayjs(this.addShiftForm.controls['startTime'].value).format(AppSettings.HOUR_MINUTE_FORMAT),
          endTimeStr: dayjs(this.addShiftForm.controls['endTime'].value).format(AppSettings.HOUR_MINUTE_FORMAT),
          breakList: this.getBreakList(),
          shiftDriverList: this.getShiftDriverList(),
          shiftGroupList: this.getShiftGroupList(),
          weekdaysList: this.addShiftForm.controls['days'].value,
          assignedEntity: this.isEdit ? this.shiftDetails.assignedEntity : this.addShiftForm.value.assignmentType,
          shiftId: this.isEdit ? this.shiftId : null
        }
      } else {
        requestBody = {
          tenantCode: this.configService.getForTenantCode(),
          countryCode: this.country[0].countryCode,
          shiftName: this.addShiftForm.controls['name'].value,
          startDateStr: dayjs(this.addShiftForm.controls['startDate'].value).format(AppSettings.DD_MM_YYYY),
          endDateStr: dayjs(this.addShiftForm.controls['endDate'].value).format(AppSettings.DD_MM_YYYY),
          startTimeStr: dayjs(this.addShiftForm.controls['startTime'].value).format(AppSettings.HOUR_MINUTE_FORMAT),
          endTimeStr: dayjs(this.addShiftForm.controls['endTime'].value).format(AppSettings.HOUR_MINUTE_FORMAT),
          breakList: this.getBreakList(),
          shiftDriverList: this.getShiftDriverList(),
          shiftGroupList: this.getShiftGroupList(),
          weekdaysList: this.addShiftForm.controls['days'].value,
          assignedEntity: this.isEdit ? this.shiftDetails.assignedEntity : this.addShiftForm.value.assignmentType,
          shiftId: this.isEdit ? this.shiftId : null
        };
      }

      console.log(requestBody);
      if (this.isEdit) {
        this.editShift(requestBody);
      } else {
        this.addShift(requestBody);
      }
    }
  }

  getShiftDriverList() {
    if (this.isEdit && this.selectedDrivers) {
      this.selectedDrivers.forEach(element => {
        if (!this.selectedDriversCopy.includes(element)) {
          this.newlyAddedDriversInEditMode.push(element);
        }
      });
      return this.newlyAddedDriversInEditMode;
    } else {
      return this.addShiftForm.value.assignmentType === this.DRIVER_ASSIGNMENT_TYPE.INDIVIDUAL_DRIVER ? this.selectedDrivers : [];
    }
  }

  getShiftGroupList() {
    if (this.isEdit && this.selectedGroups) {
      this.selectedGroups.forEach(element => {
        if (!this.selectedGroupsCopy.includes(element)) {
          this.newlyAddedGroupssInEditMode.push(element);
        }
      });
      return this.newlyAddedGroupssInEditMode;
    } else {
      return this.addShiftForm.value.assignmentType === this.DRIVER_ASSIGNMENT_TYPE.DRIVER_GROUP ? this.selectedGroups : [];
    }
  }

  addShift(requestBody) {
    this.shiftService.addShift(requestBody).subscribe({
      next: (result) => {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Successful', detail: this.cs.getLabel('shift.shift_added_successfully'), });
        this.router.navigate(['app/manage-shift']);
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
      }
    });
  }

  editShift(requestBody) {
    this.checkForRemovedDriverAndGroupIdsWhichAreAlreadyAssignedAndAddInPayload(requestBody);
    this.shiftService.updateShift(requestBody, this.shiftId).subscribe({
      next: (result) => {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Successful', detail: this.cs.getLabel('shift.shift_updated_successfully'), });
        this.router.navigate(['app/manage-shift']);
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
      }
    });

  }

  checkForRemovedDriverAndGroupIdsWhichAreAlreadyAssignedAndAddInPayload(requestBody) {
    const removedDriverIds = [];
    this.selectedDriversCopy.forEach(element => {
      if (!this.selectedDrivers.includes(element)) {
        return removedDriverIds.push(element);
      }
    });
    requestBody.shiftDriverListRemoved = removedDriverIds;

    const removedGroupIds = [];
    this.selectedGroupsCopy.forEach(element => {
      if (!this.selectedGroups.includes(element)) {
        return removedGroupIds.push(element);
      }
    });
    requestBody.shiftGroupListRemoved = removedGroupIds;
  }

  getBreakList() {
    if (this.addShiftForm.controls['type'].value === AppSettings.SHIFT_TYPE.SHIFT_TEMPLATE) {
      let shiftTemplate: any = this.shiftTemplates.filter(ele => ele.shiftTemplateId === this.selectedShiftTemplateId);
      let breaks = [];
      if (shiftTemplate[0].shiftTemplateBreakList) {
        shiftTemplate[0].shiftTemplateBreakList.forEach(element => {
          if (element.breakStartTime && element.breakEndTime) {
            breaks.push({
              reason: element?.reason,
              shiftTemplateBreakId: element.shiftTemplateBreakId,
              startTimeStr: dayjs(element.breakStartTime).format(AppSettings.HOUR_MINUTE_FORMAT),
              endTimeStr: dayjs(element.breakEndTime).format(AppSettings.HOUR_MINUTE_FORMAT)
            })
          }
        });
        return breaks;
      }
    } else {
      let breakList = this.addShiftForm.controls['breakFields'].value;
      let breaks = [];
      breakList.forEach(element => {
        if (element.breakStartTime && element.breakEndTime) {
          breaks.push({
            startTimeStr: dayjs(new Date(element.breakStartTime)).format(AppSettings.HOUR_MINUTE_FORMAT),
            endTimeStr: dayjs(new Date(element.breakEndTime)).format(AppSettings.HOUR_MINUTE_FORMAT),
            reason: element?.reasonForBreak?.value,
            shiftBreakId: element.shiftBreakId
          })
        }
      });
      return breaks;
    }
  }

  onCancel() {
    let driverArray = _.xor(this.selectedDriversCopy, this.selectedDrivers);
    let groupArray =  _.xor(this.selectedGroupsCopy, this.selectedGroups);
    if (this.isFormInitialized && (this.addShiftForm.dirty) || driverArray.length > 0 || groupArray.length > 0) {
      this.showDialog();
    } else {
      this.router.navigate(['./app/manage-shift']);
    }

  }

  showDialog() {
    this.confirmationService.confirm({
      header: this.cs.getLabel('shift.confirmation'),
      message: this.cs.getLabel('shift.discard_changes'),
      acceptIcon: 'none',
      rejectIcon: 'none',
      acceptLabel: this.cs.getLabel('shift.discard'),
      rejectLabel: this.cs.getLabel('lbl_no'),
      rejectButtonStyleClass: 'bg-white text-color',
      acceptButtonStyleClass: 'bg-red-500',
      accept: () => {
        this.driverEntityData.offset = 0;
        this.maxDataReached.entityDataForDriver = false;
        this.selectedDrivers = [];
        this.selectedGroups = [];
        this.individualDriverList = [];
        this.filteredDriverList = []
        this.getIndividualDriverList(this.shiftId ? true : false);
        this.getDriverGroupList(this.shiftId ? true : false);
        if (this.shiftId) {
          this.addShiftForm.patchValue(this.originalFormData);
          this.addShiftForm.markAsPristine();
          this.addShiftForm.markAsUntouched();
        } else {
          this.addShiftForm.reset();
          this.selectedAssignmentType = AppSettings.DRIVER_ASSIGNMENT_TYPE.INDIVIDUAL_DRIVER;
          this.addShiftForm.controls['assignmentType'].setValue(AppSettings.DRIVER_ASSIGNMENT_TYPE.INDIVIDUAL_DRIVER);
          this.addShiftForm.controls['type'].setValue(AppSettings.SHIFT_TYPE.SHIFT_TEMPLATE);
          this.showDriverList = true;
          this.showGroupList = false; 
          this.cd.detectChanges();
        }

        this.showBreak = false;
        this.cd.detectChanges();
        this.showBreak = true;
      },
      reject: () => {
      }
    });
  }

  onStartDateSelect(event) {
    this.startDate = event;
  }

  onEndDateSelect(event) {
    this.endDate = event;
  }

  onStartTimeSelect(event) {
    this.startTime = event;
  }

  get breakFields() {
    return this.addShiftForm.get('breakFields') as FormArray;
  }

}
