<div appAccessProvider [accessCode]="{ menuCode: 'DRIVERS', subMenuCode: 'ALL_DRIVERS', pageCode: 'ALL', subPageCode: 'UPDATE_DUTY_STATUS' }">
    <p-dialog [header]="offDutyHeader" [(visible)]="isOffDutyVisible" modal="true" (onHide)="onHide()">
        <div class="flex flex-column justify-content-between align-items-center pb-3">
            @if (tableDriverOffDutyData?.length > 0) {
                @for(data of dutyReasonData; track data; let i = $index) {
                    <div class="w-full flex justify-content-between align-items-center my-2">
                        <div class="align-items-center flex cursor-pointer" (click)="onDivClick(data)">
                            <i class="{{data?.icon}} inline-block wh-24 mr-2"></i>
                            <label class="ml-2 cursor-pointer">{{data?.label}}</label>
                        </div>
                        <p-radioButton [value]="data" [(ngModel)]="selectedReason" class="px-10px">
                        </p-radioButton>
                    </div>
                }
                <div>
                    <button pButton class="border-none px-10px left-80px relative" (click)="onDutyReasonChange(selectedReason)" 
	                [label]="'shift.lbl_add_reason' | translate"></button>
                </div>
            }
            @else{
                <div class="w-full pt-0 pr-2 pb-0 pl-3">
                    <app-no-result-found></app-no-result-found>
                </div>
            }  
        </div>
    </p-dialog>

    @if(isOnDutyVisible) {
        <p-confirmPopup>
            <ng-template pTemplate="content" let-message>
                <div class="flex flex-column align-items-center w-full gap-3 border-bottom-1 surface-border p-3 mb-3">
                    <i [class]="message.icon" class="text-6xl text-primary-500"></i>
                    <p>{{ message.message }}</p>
                </div>
            </ng-template>
        </p-confirmPopup>
    }

    @if(isVisibleLogOff) {
        <p-dialog [header]="logOffHeader" [(visible)]="isVisibleLogOff" modal="true" (onHide)="onHide()" [style]="{width: '40vw'}">
            <div class="flex flex-column justify-content-between align-items-start">
                @for(preset of locationTypePresetValues; track preset;) {
                    <div class="p-2">
                        <p-radioButton class="mb-2px"
                          name="locationType" 
                          [value]="preset.labelValue"
                          (onClick)="onOffDutyChange(preset)"
                          [(ngModel)]="selectedLocationType"
                          [id]="preset.labelValue">
                        </p-radioButton>
                        <label class="ml-2 font-semibold" [for]="preset.labelValue">{{ preset.labelValue }}</label>
                    </div>
                    @if(isDriverAddressShow) {
                        @if(preset.labelValue === 'Home Location') {
                            <div class="ml-28px">
                                <span>
                                    {{driverHomeAddress?.address_line_1}}
                                    {{driverHomeAddress?.address_line_2}}
                                    {{driverHomeAddress?.driver_address_landmark}}
                                    {{driverHomeAddress?.driver_geo_location}}
                                    {{driverHomeAddress?.city}}
                                    {{driverHomeAddress?.state}}
                                    {{driverHomeAddress?.postal_code}}
                                </span>
                            </div>
                        }
                        @if(preset.labelValue === 'Transport Hub Location') {
                            <div class="ml-28px">
                                <span>
                                    {{locationData[0]?.location_display_name_for_booker}}
                                </span>
                            </div>
                        }
                    }
                }
                @if(isShowSaveBtn) {
                    <div class="col-12 save-action-btn">
                        <button pButton class="settings-app-login mr-2 border-none btn-bg-color default-text-black-color height-44" (click)="onCancel()" [label]="previousBtnLabel"></button>
                        <p-button class="inline-flex height-44" (click)="submitData(selectedLocationType)" [label]="btnLabel"></p-button>
                    </div>
                }
                @if(isFormShow) {
                    <app-mi-form [attributeLabels]="nextShiftAttributeLabels" [attributeValues]="attributeValues" [moduleName]="moduleNameForNextShift"
                        (saveData)="onSaveNextShift($event)" (previousClick)="onCancel()" [groups]="nextShiftAttributeData[0].groups" 
                        [submitBtnText]="btnLabel" [previousBtnText]="previousBtnLabel">
                    </app-mi-form>
                }
            </div>
        </p-dialog>
    }
</div>
