import { ChangeDetectorRef, Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { ConfigService } from '../../../shared/services/config.service';
import { EntityService } from '../../../shared/services/entity.service';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { DatePipe } from '@angular/common';
import { SelectButtonModule } from 'primeng/selectbutton';
import { AppIcons } from '../../../shared/app.icons';
import { AppSettings } from '../../../shared/app.settings';
import { ModuleHeaderComponent } from '../../../shared/components/module-header/module-header.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MenuModule } from 'primeng/menu';
import { WeekListComponent } from '../week-list/week-list.component';
import { DayListComponent } from '../day-list/day-list.component';
import { GroupDayListComponent } from '../group-day-list/group-day-list.component';
import { GroupWeekListComponent } from '../group-week-list/group-week-list.component';
import { VehicleDayListComponent } from '../vehicle-day-list/vehicle-day-list.component';
import { VehicleWeekListComponent } from '../vehicle-week-list/vehicle-week-list.component';
import dayjs from 'dayjs';
import { CheckboxModule } from 'primeng/checkbox';
import { PaginatorModule } from 'primeng/paginator';
import { ShiftService } from '../../services/shift.service';
import * as _ from 'lodash';
import { AccessProviderDirective } from 'app/modules/shared/directives/access-provider.directive';
import { MetaDataService } from 'app/modules/shared/services/meta-data.service';
import { EntityList } from 'app/modules/vehicles/models/entity.models';
import { MultiSelect, MultiSelectModule } from 'primeng/multiselect';
import { AttributeData } from 'app/modules/vehicles/models/attribute.models';
import { SearchEntityResponse } from 'app/modules/shared/models/searchResponse';

@Component({
  selector: 'app-shift-calendar',
  standalone: true,
  imports: [BreadcrumbModule, TranslateModule, DialogModule, ButtonModule, InputTextModule,
    SelectButtonModule, ModuleHeaderComponent, RadioButtonModule, FormsModule, ReactiveFormsModule,
    MenuModule, WeekListComponent, DayListComponent, GroupDayListComponent, GroupWeekListComponent,
    CheckboxModule, VehicleDayListComponent, VehicleWeekListComponent, PaginatorModule, AccessProviderDirective,
    MultiSelectModule],
  templateUrl: './shift-calendar.component.html',
  styleUrl: './shift-calendar.component.scss',
  providers: [DatePipe]
})
export class ShiftCalendarComponent {
  @ViewChild('uploader', { static: true }) uploader: ElementRef<HTMLElement>;
  @ViewChild('multiSelect') multiSelect!: MultiSelect;
  miIcons = AppIcons;
  routePath: MenuItem[] = [];
  listType: any = [];
  searchValue: any = '';
  weekDaySelectOptions: any;
  selectedWeekDayFilter: any;
  moduleHeaderSecondaryBtnMenuItems: MenuItem[] = [];
  btnLabel: any;
  secondaryBtnLabel: any;
  timezone: any = new Date().toString().match(/([A-Z]+[\+-][0-9]+)/)[1];
  publishShiftHeader: any;
  deleteShiftHeader: any;
  showPublishShiftHeaderDialog: boolean = false;
  showDeleteShiftHeaderDialog: boolean = false;

  selectedCategory: any = null;
  bulkSelectionsItems: any;
  selectedRows: [] = [];
  isClearSelected: boolean = false;
  selectedMonthOrDay: any;
  selectedListType: any = AppSettings.LIST_TYPE.DRIVER;
  LIST_TYPE = AppSettings.LIST_TYPE;
  CALENDAR_FILTER_TYPE = AppSettings.CALENDAR_FILTER_TYPE;
  deleteShiftMsg: any;
  isPublish: boolean = true;
  notifyUnPublishShiftList: any[] = [];
  dayNumber: number = 0;
  weekNumber: number = 0;
  selectedDayTimeStamp: any = new Date().getTime();
  selectedDayDate: any = dayjs();
  weekDaysList: any = [];
  weekday = AppSettings.WEEK_DAYS;
  showShiftDialog: boolean = false;
  shiftActionHeader: any;
  isPublishShift: boolean = false;
  shiftList: any[] = [];
  pageChangeEventData: any;
  shiftPublishedList: any = [];
  shiftUnpublishedList: any = [];
  selectAll: boolean = false;
  showClearShiftDialog: boolean = false;
  showList: boolean = true;
  dateFormat = AppSettings.DD_MM_YYYY;
  groupNameAttributeId;
  allDriverData: any = [];
  allDriverDataTemp: any = [];
  groupIds: string;
  entityData: any = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    defaultSortColumn: 'updatedAt',
    defaultSortType: 'desc',
    startDateStr: null,
    endDateStr: null
  };

  public isBetweenScreenSize: boolean = false;
  selectedGroup;
  groupList;
  filterIcon = 'pi pi-search';

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isBetweenScreenSize = event.target.innerWidth >= 767 && event.target.innerWidth <= 1050;
  }

  notifyPublishShiftList: any = [
    { name: this.cs.getLabel('shift.notify_employees_with_changes'), key: 'A' },
    { name: this.cs.getLabel('shift.notify_all_employees'), key: 'M' },
    { name: this.cs.getLabel('shift.dont_notify_just_published'), key: 'P' },
    { name: this.cs.getLabel('shift.dont_notify_just_unpublished'), key: 'U' }
  ];

    entityDataForGroup: EntityList = {
      limit: AppSettings.ENTITY_DATA_PER_PAGE_LIMIT,
      offset: 0,
      searchStr: "",
      filters: [],
      countryCode: '',
      deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
      forTenantCode: this.configService.getForTenantCode(),
      actionStatus: '',
    };

  constructor(private entityService: EntityService,
    private cs: CommonBindingDataService,
    private messageService: MessageService,
    private router: Router,
    private configService: ConfigService,
    private datePipe: DatePipe,
    private shiftService: ShiftService,
    private cd: ChangeDetectorRef,
    private metaDataService: MetaDataService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.getAttributes();
    this.getGroupList();
    this.listType = AppSettings.LIST_TYPE_OPTIONS;
    this.selectedMonthOrDay = this.datePipe.transform(new Date(), 'MMMM yyyy');
    this.selectedCategory = this.notifyPublishShiftList[0];
    this.setCurrentWeek();
    this.setRoutePath();
    this.setHeadersAndLabels();
    this.weekDaySelectOptions = [{ label: 'Week', value: 'week' }, { label: 'Day', value: 'day' }];
    this.selectedWeekDayFilter = AppSettings.CALENDAR_FILTER_TYPE.WEEK;
    this.setModuleHeaderSecondaryBtnMenuItems();
    this.setBulkSelectionOptions();
    this.setShiftListForPopup();
    this.setselectedMonthOrDay();
  }

  private getAttributes() {
    const entityType = AppSettings.ENTITY_CODE.DRIVER;
    this.entityService.getAttributeDefinition(entityType, AppSettings.VIEW_CODE.ADVANCED_FILTER_VIEW)
    .subscribe((res: AttributeData) => {
      if (res) {
        this.setFilterView(res);
      }
    });
  }

  setFilterView(res) {
    const driverFilterData = this.cs.getOrganizedAttribute(res);
    this.groupNameAttributeId = this.cs.getAttributeId('driver_group_id', driverFilterData);
  }

  getGroupList() {
    const country = this.getCountry();
    this.entityDataForGroup.countryCode = country?.countryCode;
    this.entityService.searchEntity(AppSettings.ENTITY_CODE.DRIVER_GROUP, this.entityDataForGroup)
    .subscribe((res: SearchEntityResponse) => {
      this.groupList = res.data.map((group: any) => ({
        labelValue: group.values.group_name,
        id: group.id,
      }));
    });
  }

  onChangeGroupList(event) {
    console.log(event);
    if (event.value && event.value.length > 0) {
      this.groupIds = event.value.toString();
      console.log(this.groupIds);
    } else {
      this.groupIds = '';
    }
  }

  onClearGroupFilter() {
    this.groupIds = '';
  }

  clearSearchField(multiSelect: any) {
    multiSelect.filterValue = null;
  }

  onFilter(event) {
    if (event.filter) {
      this.filterIcon = 'pi pi-times'
    } else {
      this.filterIcon = 'pi pi-search';
    }
  }

  setShiftListForPopup() {
    this.shiftService.getShiftList(this.entityData).subscribe((res: any) => {
      this.shiftList = res.data;
      this.shiftList.forEach(element => {
        element.checked = false;
      });
    });
  }

  setHeadersAndLabels() {
    this.btnLabel = this.cs.getLabel('shift.add_shift');
    this.secondaryBtnLabel = this.cs.getLabel('shift.publish');
    this.deleteShiftHeader = this.cs.getLabel('shift.clear_shift');
  }

  setRoutePath() {
    this.routePath = [
      {
        label: this.cs.getLabel('shift.shifts_calendar'),
        routerLink: '/shift-management',
      }
    ];
  }

  clearSelected() {
    this.selectedRows = [];
    this.isClearSelected = true;
  }

  previousClicked() {
    if (this.selectedWeekDayFilter === this.CALENDAR_FILTER_TYPE.DAY) {
      this.dayNumber -= 1;
      this.setDates();
    } else {
      this.weekNumber -= 1;
      this.setWeek();
    }
  }

  nextClicked() {
    if (this.selectedWeekDayFilter === this.CALENDAR_FILTER_TYPE.DAY) {
      this.dayNumber += 1;
      this.setDates();
    } else {
      this.weekNumber += 1;
      this.setWeek();
    }
  }

  onPageChange(event) {
    this.pageChangeEventData = event;
  }

  setDates() {
    const day: any = dayjs().add(this.dayNumber, 'day');
    this.selectedMonthOrDay = this.datePipe.transform(day.$d, 'EEE, dd MMMM yyyy');
    this.selectedDayTimeStamp = day.$d.getTime();
    this.selectedDayDate = day.$d;
  }

  setWeek() {
    this.weekDaysList = [];
    const startOfWeek: any = dayjs().startOf('week').add((this.weekNumber * 7), 'd');
    this.selectedMonthOrDay = this.datePipe.transform(startOfWeek.$d, 'MMMM yyyy');
    for (let i = 0; i < 7; i++) {
      const day: any = dayjs(startOfWeek.d).startOf('week').add((this.weekNumber * 7) + i, 'd');
      this.weekDaysList.push({
        dayName: this.weekday[day.$d.getDay()],
        day: day.$D,
        available: '31',
        dayOff: '04',
        date: day.valueOf()
      })
    }
  }

  setCurrentWeek() {
    this.weekDaysList = [];
    const startOfWeek: any = dayjs().startOf('week');

    for (let i = 0; i < 7; i++) {
      const day: any = dayjs(startOfWeek.d).startOf('week').add(i, 'd')
      this.weekDaysList.push({
        dayName: this.weekday[day.$d.getDay()],
        day: day.$D,
        available: '31',
        dayOff: '04',
        date: day.valueOf()
      });
    }
  }

  setBulkSelectionOptions() {
    this.bulkSelectionsItems = [
      {
        label: this.cs.getLabel('shift.print'),
        icon: AppIcons.MI_PRINT + " wh-122",
        iconClass: 'bulk-update-icon',
        styleClass: 'bulk-update-list',
        command: () => {
          window.print();
        },
      },
      {
        label: this.cs.getLabel('shift.publish_shifts'),
        icon: AppIcons.LONG_BOTTOM_UP + " wh-122",
        iconClass: 'bulk-update-icon',
        styleClass: 'bulk-update-list',
        command: (e) => {
          this.publishShiftHeader = this.cs.getLabel('shift.publish_shift');
          this.showPublishShiftHeaderDialog = true;
          this.isPublish = true;
        },
      },
      {
        label: this.cs.getLabel('shift.unpublish_shifts'),
        icon: AppIcons.LONG_BOTTOM_DOWN + " wh-122",
        iconClass: 'bulk-update-icon',
        styleClass: 'bulk-update-list',
        command: (e) => {
          this.publishShiftHeader = this.cs.getLabel('shift.unpublish_shift');
          this.showPublishShiftHeaderDialog = true;
          this.isPublish = false;
        },
      },
      {
        label: this.cs.getLabel('shift.clear_shifts'),
        icon: AppIcons.BASIC_DELETE + " wh-122",
        iconClass: 'bulk-update-icon',
        styleClass: 'bulk-update-list',
        command: (e) => {
          this.showDeleteShiftHeaderDialog = true;
          this.deleteShiftMsg = this.cs.getLabel('shift.clear_shift_msg');
        },
      },
    ];
  }

  setShiftPublishedUnpublishedList(event) {
    this.shiftPublishedList = event.publishedShiftList;
    this.shiftUnpublishedList = event.unpublishedShiftList;
  }

  selectAllShifts() {
    this.shiftList.forEach(shift => {
      shift.checked = this.selectAll;
    });
  }

  selectShift(shift) {
    shift.checked = !shift.checked;
    let unCheckedShiftIndex = this.shiftList.findIndex(ele => ele.checked === false);
    this.selectAll = (unCheckedShiftIndex !== -1) ? false : true;
  }

  onSecondaryBtnClick() {

  }

  setselectedMonthOrDay() {
    if (this.selectedWeekDayFilter === AppSettings.CALENDAR_FILTER_TYPE.WEEK) {
      this.selectedMonthOrDay = this.datePipe.transform(new Date(), 'MMMM yyyy');
    } else {
      this.selectedMonthOrDay = this.datePipe.transform(this.selectedDayTimeStamp, 'EEE, dd MMMM yyyy');
    }
  }

  setModuleHeaderSecondaryBtnMenuItems() {
    this.moduleHeaderSecondaryBtnMenuItems = [
      {
        label: this.cs.getLabel('shift.lbl_publish'),
        icon: AppIcons.UPLOAD + "mi-lg long-bottom-up",
        command: () => {
          this.shiftList = [];
          this.shiftActionHeader = this.cs.getLabel('shift.publish_shift');
          this.shiftList = this.shiftUnpublishedList;
          this.showShiftDialog = true;
          this.shiftList.forEach(shift => {
            shift.checked = false;
          });
          this.selectAll = false;
          console.log(this.shiftList);
          this.isPublishShift = true;
        }
      },
      {
        label: this.cs.getLabel('shift.lbl_unpublish_new'),
        icon: AppIcons.UPLOAD + "mi-lg long-bottom-down",
        command: () => {
          this.shiftList = [];
          this.shiftActionHeader = this.cs.getLabel('shift.unpublish_shift');
          this.shiftList = this.shiftPublishedList;
          this.showShiftDialog = true;
          this.selectAll = false;
          this.shiftList.forEach(shift => {
            shift.checked = false;
          });
          this.isPublishShift = false;
        }
      },
      {
        label: this.cs.getLabel('shift.lbl_clear_shift'),
        icon: AppIcons.UPLOAD + "mi-lg mi-off_outline_close",
        command: () => {
          this.shiftList = [];
          this.shiftActionHeader = this.cs.getLabel('shift.lbl_clear_shift');
          this.shiftList = _.cloneDeep(this.shiftPublishedList);
          this.selectAll = false;
          this.shiftList.push(...this.shiftUnpublishedList);
          this.shiftList.forEach(shift => {
            shift.checked = false;
          });
          this.showClearShiftDialog = true;
        }
      },
      {
        separator: true
      },
      {
        label: this.cs.getLabel('shift.export_calendar'),
        icon: AppIcons.EXPORT + " wh-16",
      },
    ];
  }

  onSearch(event) {
    // this.cd.detectChanges();
  }

  clearSearch() {
    this.searchValue = '';
  }

  onAddShift() {
    this.router.navigate(['app/manage-shift/add-shift']);
  }

  onPublishShift() {
    const selectedFilterdata = this.shiftList.filter(ele => ele.checked = true);
    console.log(selectedFilterdata);

  }

  onUnPublishShift() {

  }

  onChange(event) {

  }

  onCancel() {
    this.showPublishShiftHeaderDialog = false;
    this.showDeleteShiftHeaderDialog = false;
    this.showShiftDialog = false;
    this.showClearShiftDialog = false;
  }

  onDayWeekFilterChange() {
    this.groupIds = '';
    this.selectedGroup = null;
    this.setselectedMonthOrDay();
    this.clearSelected();
  }

  onListTypeChange(event) {
    this.selectedListType = event;
    this.dayNumber = 0;
    this.weekNumber = 0;
    this.selectedDayDate = dayjs();
    this.setCurrentWeek();
  }

  publishUnpublishShift() {
    console.log(this.weekDaysList);
    let startDate: any;
    let endDate: any;
    startDate = dayjs(this.selectedDayDate).startOf('day');
    endDate = dayjs(this.selectedDayDate).endOf('day');


    let selectedFilterdata = this.shiftList.filter(ele => ele.checked === true);
    const startDateToFormat = this.selectedWeekDayFilter === AppSettings.CALENDAR_FILTER_TYPE.DAY
      ? startDate.$d.getTime()
      : (this.weekDaysList[0].date < dayjs(new Date()).startOf('day').valueOf() && this.weekDaysList[this.weekDaysList.length - 1].date > dayjs(new Date()).startOf('day').valueOf()) ? dayjs(new Date()).startOf('day').valueOf() : this.weekDaysList[0].date;

    const endDateToFormat = this.selectedWeekDayFilter === AppSettings.CALENDAR_FILTER_TYPE.DAY
      ? endDate.$d.getTime()
      : this.weekDaysList[this.weekDaysList.length - 1].date;

    const requestBody = {
      shiftIds: selectedFilterdata.map(ele => {
        return ele.shiftId
      }),
      startDateTimeStr: dayjs(startDateToFormat).format(this.dateFormat),
      endDateTimeStr: dayjs(endDateToFormat).format(this.dateFormat)
    };
    if (this.selectedWeekDayFilter === AppSettings.CALENDAR_FILTER_TYPE.WEEK && this.weekDaysList[0].date < dayjs(new Date()).startOf('day').valueOf() && this.weekDaysList[this.weekDaysList.length - 1].date < dayjs(new Date()).startOf('day').valueOf()) {
      this.messageService.add({ key: 'tst', severity: 'info', summary: 'Info', detail: this.cs.getLabel('shift.can_not_publish_unpublish_past_shifts') });
      this.showList = false;
      this.cd.detectChanges();
      this.showList = true;
    } else {
      this.shiftService.publishUnpublishShift(requestBody, this.isPublishShift ? AppSettings.SHIFT_PUBLISH_UNPUBLISH.PUBLISH : AppSettings.SHIFT_PUBLISH_UNPUBLISH.UNPUBLISH).subscribe({
        next: (res: any) => {
          this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: res.message });
          this.showShiftDialog = false;
          this.showList = false;
          this.cd.detectChanges();
          this.showList = true;
        },
        error: (error) => {
          this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
        },
      })
    }

  }

  onClearShift() {
    const startDate: any = dayjs(this.selectedDayDate).startOf('day');
    const endDate: any = dayjs(this.selectedDayDate).endOf('day');
    const selectedFilterdata = this.shiftList.filter(ele => ele.checked === true);
    const requestBody = {
      shiftIds: selectedFilterdata.map(ele => {
        return ele.shiftId
      }),
      startDateTimeStr: this.selectedWeekDayFilter === AppSettings.CALENDAR_FILTER_TYPE.DAY ? dayjs(startDate.$d.getTime()).format(this.dateFormat) : dayjs(this.weekDaysList[0].date).format(this.dateFormat),
      endDateTimeStr: this.selectedWeekDayFilter === AppSettings.CALENDAR_FILTER_TYPE.DAY ? dayjs(endDate.$d.getTime()).format(this.dateFormat) : dayjs(this.weekDaysList[this.weekDaysList.length - 1].date).format(this.dateFormat),
    };
    this.shiftService.clearShift(requestBody).subscribe({
      next: (res: any) => {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: res.message });
        this.showClearShiftDialog = false;
        this.showList = false;
        this.cd.detectChanges();
        this.showList = true;
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
      },
    })
  }

  private getCountry() {
    const countryData = localStorage.getItem(AppSettings.COUNTRY);
    return countryData ? JSON.parse(countryData)[0] : null;
  }

  onFilterIconClick() {
    if (this.filterIcon.includes('times')) {
      this.multiSelect.filterValue = null;
      this.filterIcon = 'pi pi-search';
    }
  }
}
