<div appAccessProvider [accessCode]="{ menuCode: 'ADMINISTRATION', pageCode: 'TIME_SETTING', subPageCode: 'EDIT' }">
<div class="flex justify-content-end flex-wrap btn-with-links">
    <button pButton class="mr-2 bg-white default-text-light-black-color cursor-pointer border-button" [label]="'cancel' | translate" (click)="onCancel()"></button>
    <p-button  class="flex align-items-center justify-content-center cursor-pointer" (click)="onNextBtnClick()" [label]="saveTimeSettingsBtnLabel"></p-button>
</div>

<p-tabView [(activeIndex)]="activeIndex">
    <p-tabPanel>
        <ng-template pTemplate="header">
            <div class="tab-icons">
              <i class="mi-lg details mr-2"></i>
              <span translate>organization.time_settings</span>
            </div>
          </ng-template>
          @if (showForm) {
            @for(tab of timeSettingsAttributeData?.tabs; track tab?.tabCode) {
                <app-mi-form
                    [attributeLabels]="attributeLabelsForTimeSettings"
                    [moduleName]="timeSettingsModuleName"
                    [attributeValues]="attributeValues"
                    [groups]="tab.groups"
                    [isMultipleFilesUpload]="false"
                    [previousBtnText]="previousBtnLabel"
                    [submitBtnText]="saveTimeSettingsBtnLabel"
                    (saveData)="saveTimeSettings($event)"
                    (previousClick)="onCancel()"
                    [saveBtnId]="'time'"
                    (onClickCancel)="onCancel()"
                    >
                </app-mi-form>
            }
          }

</p-tabPanel>
</p-tabView>
</div>
