<div class="card flex justify-content-center booking-dialog">
    <!-- <p-button (click)="showDialog()" icon="pi pi-external-link" label="Show"></p-button> -->
    <p-dialog [header]="'bookings.booking_status' | translate" [(visible)]="visible" [modal]="true" [style]="{ width: '350px' }" [draggable]="false" [resizable]="false" (onHide)="onHide()">
        <p class="m-0">

            @for (booking of statusData.bookingStatusList; track $index) {

                    <div class="booking-item cursor-pointer mt-1 flex align-items-center" [ngStyle]=" booking.bookingCode == selectedBookingStatus && {'background-color':booking.backgroundColorCode,'color': booking.textColorCode}">

                    <label for="ingredient{{$index}}" class="flex flex-1 align-items-center"><i class="{{miIcons[booking.bookingIcon.toUpperCase()]}} inline-block wh-24 mr-2">{{booking.bookingIcon.toUpperCase()}}</i> {{booking.bookingStatusDisplayStr[0].label}}
                    
                        <p-radioButton name="booking" class="flex ml-2" [value]="booking.bookingCode" [(ngModel)]="selectedBookingStatus" inputId="ingredient{{$index}}" [disabled]="booking.bookingCode === 'DRIVER_ASSIGNED'"></p-radioButton>
                    </label>
                  
                </div>
            }
        </p>
        <div class="flex justify-content-end mr-2 mt-2">
        <p-button  class="mb-2 flex align-items-center justify-content-center" [label]="'Apply' | translate" (click)="onApply()"></p-button>
    </div> 
    </p-dialog>
    
</div>


 <!--[ngClass]="{[namespace + '-mybutton']: type === 'mybutton'}" [ngClass]="{ '{{booking.class}} booking-item mt-1 flex align-items-center': booking.value === selectedBookingStatus,
'booking-item mt-1 flex align-items-center': booking.value != selectedBookingStatus,
}"  -->
