import { CommonModule, DatePipe, NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { EntityService } from '../../../../../shared/services/entity.service';
import { AppSettings } from '../../../../../shared/app.settings';
import { MetaDataService } from 'app/modules/shared/services/meta-data.service';

@Component({
  selector: 'app-driver-tab',
  templateUrl: './driver-tab.component.html',
  styleUrl: './driver-tab.component.scss',
  standalone: true,
  imports: [NgClass, TranslateModule, CommonModule, DatePipe],
})
export class DriverTabComponent {
  @Input() driverDetailItem: any;
  driverDetailsById: any[] = [];
  HOUR_MINUTE: any = this.metaDataService.timeFormat;
  driverGroupName: any;
  constructor(private entityService: EntityService, private metaDataService: MetaDataService,) { }

  ngOnInit(): void {
    this.getDriverDetailsById();
  }

  getDriverDetailsById() {
    this.entityService.getEntity(this.driverDetailItem.driver.driverId, AppSettings.ENTITY_CODE.DRIVER, AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe((result: any) => {
      const driverDetails = {};
      result.attributeCodeValueDtoList.map(data => {
        driverDetails[data.attributeCode] = data.attributeValue;
      })
      this.driverDetailsById.push(driverDetails);
      const driverGroupId = driverDetails['driver_group_id'];
      if (driverGroupId) {
        this.getGroupDetailsById(driverGroupId);
      }
      this.getDriverGroup(result);
    })
  }

  getDriverGroup(result) {
    if(result.relatedData) {
      result.relatedData.forEach(element => {
        if (element.entityCode === AppSettings.ENTITY_CODE.DRIVER_GROUP) {
          element.attributeCodeValueDtoList.forEach(group => {
            if (group.attributeCode === 'group_name') {
              this.driverGroupName = group.attributeValue;
            }
          });
        }
      });
    }
  }

  getGroupDetailsById(driverGrpId) {
    this.entityService.getEntity(driverGrpId, AppSettings.ENTITY_CODE.DRIVER_GROUP, AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe((result: any) => {
      const groupDetails = {};
      result.attributeCodeValueDtoList.map(data => {
        groupDetails[data.attributeCode] = data.attributeValue;
      })
      this.driverDetailsById[this.driverDetailsById.length - 1]['groupDetails'] = groupDetails;
    })
  }

}
