<div class="grid mt-2 ml-3 specifications-div">
    <div class="col-6 pl-0">
            <div class="card p-fluid">
                <div class="flex justify-content-between flex-wrap">
                    <div class="flex justify-content-center mb-3">
                        <label class="card-heading uppercase" translate>passengers.lbl_app_access</label>
                    </div>
                    <div appAccessProvider [accessCode]="{ menuCode: 'PASSENGERS', pageCode: 'ALL', subPageCode: 'EDIT' }" (click)="redirectToEdit()" class="pointer flex justify-content-center mb-3">
                        <label class="card-action" [ngClass]="{'hidden': isDeleted}" translate>vehicle.lbl_edit</label>
                        <i class="mi-lg edit_pencil"  [ngClass]="{'hidden': isDeleted}"></i>
                    </div>
                </div>
                @for (field of appAccess.fields; track field; let i = $index) {
                    @if (i !== 0) {
                        <hr class="hr-line">
                    }
                    @if (field.attributeCode === 'app_access_start_date') {
                        <div class="grid">
                            <div class="col-6 pt-1">
                                <label class="detail-heading"
                                    translate>{{cs.getLabelValue('passenger.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                            </div>
                            <div class="col-6 pb-1">
                                <label class="detail-value">{{ appAccessStartDate }}</label>
                            </div>
                        </div>

                    } @else if (field.attributeCode === 'app_access_end_date') {
                        <div class="grid">
                            <div class="col-6 pt-1">
                                <label class="detail-heading"
                                    translate>{{cs.getLabelValue('passenger.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                            </div>
                            <div class="col-6 pb-1">
                                <label class="detail-value">{{ appAccessEndDate }}</label>
                            </div>
                        </div>

                    } @else {
                        <div class="grid">
                            <div class="col-6 pt-1 flex">
                                <label class="detail-heading" translate>{{cs.getLabelValue('passenger.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                            </div>
                            <div class="col-6 pb-1">
                                <label class="detail-value">{{ attributeValues[field.attributeCode] ? attributeValues[field.attributeCode] : ('lbl_empty' | translate) }}</label>
                            </div>
                        </div>
                    }
            }
            </div>

            <div class="card p-fluid">
                <div class="flex justify-content-between flex-wrap">
                    <div class="flex justify-content-center mb-3">
                        <label class="card-heading uppercase" translate>passengers.lbl_classification</label>
                    </div>
                    <div appAccessProvider [accessCode]="{ menuCode: 'PASSENGERS', pageCode: 'ALL', subPageCode: 'EDIT' }" (click)="redirectToEdit()" class="pointer flex justify-content-center mb-3">
                        <label class="card-action"  [ngClass]="{'hidden': isDeleted}" translate>vehicle.lbl_edit</label>
                        <i class="mi-lg edit_pencil"  [ngClass]="{'hidden': isDeleted}"></i>
                    </div>
                </div>
                @for (field of classification.fields; track field; let i = $index) {
                    @if (i !== 0) {
                        <hr class="hr-line">
                    }
                <div class="grid">
                    <div class="col-6 pt-1  flex">
                        <label class="detail-heading" translate>{{cs.getLabelValue('passenger.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                    </div>
                    <div class="col-6 pb-1">
                        <label class="detail-value">{{ attributeValues[field.attributeCode] ? attributeValues[field.attributeCode] :  ('lbl_empty' | translate) }}</label>
                    </div>
                </div>
            }
            </div>

            <div class="card p-fluid">
                <div class="flex justify-content-between flex-wrap">
                    <div class="flex justify-content-center mb-3">
                        <label class="card-heading uppercase" translate>passengers.lbl_transportation_restrictions</label>
                    </div>
                    <div appAccessProvider [accessCode]="{ menuCode: 'PASSENGERS', pageCode: 'ALL', subPageCode: 'EDIT' }" (click)="redirectToEdit()" class="pointer flex justify-content-center mb-3">
                        <label class="card-action"  [ngClass]="{'hidden': isDeleted}" translate>vehicle.lbl_edit</label>
                        <i class="mi-lg edit_pencil"  [ngClass]="{'hidden': isDeleted}"></i>
                    </div>
                </div>
                @for (field of transportationRestrictions.fields; track field; let i = $index) {
                    @if (field.attributeCode === 'transportation_start_date') {
                        <div class="grid">
                            <div class="col-6 pt-1">
                                <label class="detail-heading"
                                    translate>{{cs.getLabelValue('passenger.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                            </div>
                            <div class="col-6 pb-1">
                                <label class="detail-value">{{ transportationStartDate }}</label>
                            </div>
                        </div>
                        <hr class="hr-line">
                    } @else if (field.attributeCode === 'transportation_end_date') {
                        <div class="grid">
                            <div class="col-6 pt-1">
                                <label class="detail-heading"
                                    translate>{{cs.getLabelValue('passenger.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                            </div>
                            <div class="col-6 pb-1">
                                <label class="detail-value">{{ transportationEndDate }}</label>
                            </div>
                        </div>
                        <hr class="hr-line">
                    } @else {
                        <div class="grid">
                            <div class="col-6 pt-1 flex">
                                <label class="detail-heading" translate>{{cs.getLabelValue('passenger.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                            </div>
                            <div class="col-6 pb-1">
                                <label class="detail-value">{{ attributeValues[field.attributeCode] ? attributeValues[field.attributeCode] : ('lbl_empty' | translate) }}</label>
                            </div>
                        </div>
                    }
                    @if (i !== 0) {
                        <hr class="hr-line">
                    }
            }
            </div>

            <div class="card p-fluid">
                <div class="flex justify-content-between flex-wrap">
                    <div class="flex justify-content-center mb-3">
                        <label class="card-heading uppercase" translate>passengers.lbl_booking_time_restriction</label>
                    </div>
                    <div appAccessProvider [accessCode]="{ menuCode: 'PASSENGERS', pageCode: 'ALL', subPageCode: 'EDIT' }" (click)="redirectToEdit()" class="pointer flex justify-content-center mb-3">
                        <label class="card-action"   [ngClass]="{'hidden': isDeleted}" translate>vehicle.lbl_edit</label>
                        <i class="mi-lg edit_pencil"  [ngClass]="{'hidden': isDeleted}"></i>
                    </div>
                </div>

            @for (bookingTimeRestriction of bookingTimeRestrictionArray; track bookingTimeRestriction; let j = $index) {
                <label class="slot" translate>lbl_slot <span>{{ j + 1 }}</span> </label>
                @for (field of bookingTimeRestriction.bookingTimeRestrictions; track field; let k = $index) {
                    <div class="grid">
                        <div class="col-6 pt-1 flex">
                            <label class="detail-heading" translate>{{cs.getLabelValue('passenger_booking_time_restriction.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                        </div>
                        <div class="col-6 pb-1">
                            @if (field.attributeCode === 'time_restriction') {
                                <label class="detail-value">{{ field.attributeValue ? (field.attributeValue | date: 'dd MMM yyyy') :  ('lbl_empty' | translate) }}</label>
                            } @else {
                                <label class="detail-value">{{ field.attributeValue ? field.attributeValue :  ('lbl_empty' | translate) }}</label>
                            }
                        </div>
                    </div>
                    <hr class="hr-line">
                }
            }
            </div>
    </div>
    <div class="col-6">
        <div class="card p-fluid">
            <div class="flex justify-content-between flex-wrap">
                <div class="flex justify-content-center mb-3">
                    <label class="card-heading uppercase" translate>passengers.lbl_assign_location</label>
                </div>
                <div appAccessProvider [accessCode]="{ menuCode: 'PASSENGERS', pageCode: 'ALL', subPageCode: 'EDIT' }" (click)="redirectToEdit()" class="pointer flex justify-content-center mb-3">
                    <label class="card-action"  [ngClass]="{'hidden': isDeleted}" translate>vehicle.lbl_edit</label>
                    <i class="mi-lg edit_pencil"  [ngClass]="{'hidden': isDeleted}"></i>
                </div>
            </div>
            @for (field of assignLocation.fields; track field; let i = $index) {
                @if (i !== 0) {
                    <hr class="hr-line">
                }
            <div class="grid">
                <div class="col-6 pt-1 flex">
                    <label class="detail-heading" translate>{{cs.getLabelValue('passenger.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                </div>
                <div class="col-6 pb-1">
                    <label class="detail-value">{{ attributeValues[field.attributeCode] ? attributeValues[field.attributeCode] :  ('lbl_empty' | translate) }}</label>
                </div>
            </div>
        }
        </div>
        <div class="card p-fluid">
            <div class="flex justify-content-between flex-wrap">
                <div class="flex justify-content-center mb-3">
                    <label class="card-heading uppercase" translate>passengers.lbl_home_stay_location_restriction</label>
                </div>
                <div appAccessProvider [accessCode]="{ menuCode: 'PASSENGERS', pageCode: 'ALL', subPageCode: 'EDIT' }" (click)="redirectToEdit()" class="pointer flex justify-content-center mb-3">
                    <label class="card-action"  [ngClass]="{'hidden': isDeleted}" translate>vehicle.lbl_edit</label>
                    <i class="mi-lg edit_pencil"  [ngClass]="{'hidden': isDeleted}"></i>
                </div>
            </div>
            @for (field of homeStayLocationRestriction.fields; track field; let i = $index) {
                @if (i !== 0) {
                    <hr class="hr-line">
                }
                @if (field.attributeCode === 'radius_in_miles') {
                    <div class="grid">
                        <div class="col-6 pt-1">
                            <label class="detail-heading" translate>{{cs.getLabelValue('passenger.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                        </div>
                        <div class="col-6 pb-1">
                            <label class="detail-value">{{ attributeValues[field.attributeCode] ? attributeValues[field.attributeCode] :  ('lbl_empty' | translate) }}</label>
                            &nbsp;<span class="detail-value" translate>lbl_mi</span>
                        </div>
                    </div>
                }
                @else {
                    <div class="grid">
                        <div class="col-6 pt-1">
                            <label class="detail-heading" translate>{{cs.getLabelValue('passenger.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                        </div>
                        <div class="col-6 pb-1">
                            <label class="detail-value">{{ attributeValues[field.attributeCode] ? attributeValues[field.attributeCode] :  ('lbl_empty' | translate) }}</label>
                        </div>
                    </div>
                }
            }
        </div>
        <div class="card p-fluid">
            <div class="flex justify-content-between flex-wrap">
                <div class="flex justify-content-center mb-3">
                    <label class="card-heading uppercase" translate>passengers.lbl_special_request</label>
                </div>
                <div appAccessProvider [accessCode]="{ menuCode: 'PASSENGERS', pageCode: 'ALL', subPageCode: 'EDIT' }" (click)="redirectToEdit()" class="pointer flex justify-content-center mb-3">
                    <label class="card-action"  [ngClass]="{'hidden': isDeleted}" translate>vehicle.lbl_edit</label>
                    <i class="mi-lg edit_pencil"  [ngClass]="{'hidden': isDeleted}"></i>
                </div>
            </div>
            @for (field of specialRequest.fields; track field; let i = $index) {
                @if (i !== 0) {
                    <hr class="hr-line">
                }
            <div class="grid">
                <div class="col-6 pt-1">
                    <label class="detail-heading" translate>{{cs.getLabelValue('passenger.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                </div>
                <div class="col-6 pb-1">
                    <label class="detail-value">{{ attributeValues[field.attributeCode] ? attributeValues[field.attributeCode] :  ('lbl_empty' | translate) }}</label>
                </div>
            </div>
        }
        </div>
        <div class="card p-fluid">
            <div class="flex justify-content-between flex-wrap">
                <div class="flex justify-content-center mb-3">
                    <label class="card-heading uppercase" translate>passengers.lbl_other_information</label>
                </div>
                <div appAccessProvider [accessCode]="{ menuCode: 'PASSENGERS', pageCode: 'ALL', subPageCode: 'EDIT' }" (click)="redirectToEdit()" class="pointer flex justify-content-center mb-3">
                    <label class="card-action"  [ngClass]="{'hidden': isDeleted}" translate>vehicle.lbl_edit</label>
                    <i class="mi-lg edit_pencil"  [ngClass]="{'hidden': isDeleted}"></i>
                </div>
            </div>
            @for (field of otherInformation.fields; track field; let i = $index) {
                @if (i !== 0) {
                    <hr class="hr-line">
                }
            <div class="grid">
                <div class="col-6 pt-1">
                    <label class="detail-heading" translate>{{cs.getLabelValue('passenger.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                </div>
                <div class="col-6 pb-1">
                    <label class="detail-value">{{ attributeValues[field.attributeCode] ? attributeValues[field.attributeCode] :  ('lbl_empty' | translate) }}</label>
                </div>
            </div>
        }
        </div>