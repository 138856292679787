@if (isVisible()) {
  <div class="notification-mask" (click)="close()"></div>
  <div class="notification-tray" #notficationTray>
    <div class="notification-header">
      <span class="header-title">Notifications</span>
      <div class="header-actions">
        <button pButton icon="pi pi-cog" 
                class="p-button-text" 
                (click)="menu.toggle($event)">
        </button>
        <button pButton icon="pi pi-times" 
                class="p-button-text" 
                (click)="close()">
        </button>
        
        <p-menu #menu [popup]="true" 
                [model]="menuItems"  
                appendTo="body"
                [baseZIndex]="1000"
                [autoZIndex]="true">
        </p-menu>
      </div>
    </div>

    <div class="notification-list">
      @for (notification of notifications; track notification) {
        <div class="notification-item" 
             [ngClass]="{'notification-read': notification.read, 'notification-unread': !notification.read}">
          <div class="notification-icon">
            <i [class]="getNotificationIcon(notification.type)"></i>
          </div>
          <div class="notification-content">
            <div class="notification-title text-ellipsis">{{notification.notificationMsg}}</div>
            <div class="notification-description text-ellipsis">{{getNotificationDescription(notification)}}</div>
          </div>
          <div class="notification-time">
            {{formatTime(notification.createdAt)}}
          </div>
        </div>
      } @empty {
        <div class="no-notifications">
          No notifications to display
        </div>
      }
    </div>

    <div class="notification-footer">
      @if (notifications.length > 0) {
        <div class="footer-actions">
          @if (notificationCount > (notificationParams?.offset + notificationParams?.limit)) {
            <button pButton label="Load More" 
                    class="p-button-text" 
                    (click)="onLoadMore()">
            </button>
          }
        </div>
      }
    </div>
  </div>
} 