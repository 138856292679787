import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { MenuItem, MessageService } from "primeng/api";
import { BreadcrumbModule } from "primeng/breadcrumb";
import { DispatchMapComponent } from "../dispatch-map/dispatch-map.component";
import { ButtonModule } from "primeng/button";
import { DialogModule } from "primeng/dialog";
import { TooltipModule } from "primeng/tooltip";
import { AssignDriverComponent } from "../assign-driver/assign-driver.component";
import { DialogHeaderComponent } from "../dialog-header/dialog-header.component";
import { DriverDetailsComponent } from "../assign-driver/driver-details/driver-details.component";
import { DriverListCardComponent } from "../assign-driver/driver-list-card/driver-list-card.component";
import { TranslateModule } from "@ngx-translate/core";
import { CommonBindingDataService } from "../../../shared/services/common-binding-data.service";
import { DatePipe } from "@angular/common";
import { AppIcons } from "../../../shared/app.icons";
import { AppSettings } from "../../../shared/app.settings";
import * as _ from 'lodash';
import { ActivatedRoute, Router } from "@angular/router";
import { EntityService } from "app/modules/shared/services/entity.service";
import { ConfigService } from "app/modules/shared/services/config.service";
import { DriverSidebarComponent } from "../driver-sidebar/driver-sidebar.component";
import { BookingSidebarComponent } from "../booking-sidebar/booking-sidebar.component";
import { AccessProviderDirective } from "app/modules/shared/directives/access-provider.directive";
import { BookingService } from "app/modules/bookings/services/booking.service";
@Component({
  selector: "app-dispatch",
  templateUrl: "./dispatch.component.html",
  styleUrls: ["./dispatch.component.scss"],
  standalone: true,
  imports: [
    BreadcrumbModule,
    DispatchMapComponent,
    DialogModule,
    ButtonModule,
    TooltipModule,
    AssignDriverComponent,
    DialogHeaderComponent,
    DriverDetailsComponent,
    DriverListCardComponent,
    TranslateModule,
    DatePipe,
    DriverSidebarComponent,
    BookingSidebarComponent,
    AccessProviderDirective
  ],
})
export class DispatchComponent implements OnInit {
  routePath: MenuItem[];
  leftBookingsSectionVisible: boolean = false;
  rightDriversSectionVisible: boolean = false;
  leftDialogPosition: string = "center";
  rightDialogPosition: string = "center";
  dummyNote: string = "Hello There";
  showBookingDetails: boolean = false;
  bookingDetailItem: any = {};
  bookingId: string;
  bookingDetailsById: any[] = [];
  bookingHeader: string;
  position: string;
  miIcons = AppIcons;
  TRANSPORTATION_TYPE = AppSettings.TRANSPORTATION_TYPE;
  TRANSPORTATION_MODE = AppSettings.TRANSPORTATION_MODE;
  geoLocationsData: any = {};
  dispatchBookingDetails;

  constructor(private cs: CommonBindingDataService, private cd: ChangeDetectorRef,
    private router: Router,
    private messageService: MessageService,
    private bookingService: BookingService,
    private entityService: EntityService, private route: ActivatedRoute, private configService: ConfigService
  ) { }

  ngOnInit() {
    this.cs.setSearchData('');
    this.cs.setSearchData('');
    this.bookingId = this.route.snapshot.paramMap.get('id');
    if (this.bookingId) {
      this.showLeftBookingsDialog('left');
      this.getBookingDetail();
    }
    this.setRoutePath();
    this.bookingHeader = this.cs.getLabel("dispatch.bookings");
    const element = document.getElementById('left-btn');
    if (element) {
      element.click();
    }
  }

  setRoutePath() {
    this.routePath = [
      {
        label: this.cs.getLabel("dispatch.dispatch"),
        routerLink: "",
        icon: "pi pi-arrow-left",
        iconStyle: { "font-weight": "bold", "margin-right": "10px" },
        command: () => {
          history.back();
        }
      },
    ];
  }

  showLeftBookingsDialog(position: string) {
    this.leftDialogPosition = position;
    this.position = position;
    this.leftBookingsSectionVisible = false;
    this.cd.detectChanges();
    this.leftBookingsSectionVisible = true;
  }

  showRightDriversDialog(position: string) {
    if (this.bookingId) {
      this.rightDialogPosition = position;
      this.position = position;
      this.rightDriversSectionVisible = false;
      this.cd.detectChanges();
      this.rightDriversSectionVisible = true;
    } else {
      this.messageService.add({
        key: 'tst',
        severity: 'info',
        summary: 'Info',
        detail: this.cs.getLabel('dispatch.lbl_please_select_booking_to_assign_driver')
      });
    }
  }

  onShowBookingDetail(event) {
    this.showBookingDetails = event.hasData;
    this.bookingDetailItem = event.eventData;
    this.bookingId = event.eventData.id;
    this.getBookingDetail();
  }



  getBookingDetail() {
    this.entityService.getEntity(this.bookingId, AppSettings.ENTITY_CODE.BOOKING, AppSettings.VIEW_CODE.BOOKING_DETAILS_ON_DISPATCH_VIEW).subscribe((result: any) => {
      const details = {};
      const bookingPassengers = [];
      result.attributeCodeValueDtoList.map(data => {
        details[data.attributeCode] = data.attributeValue;
      })
      result.relatedData.forEach(element => {
        const passenger = {};
        if (element.entityCode === AppSettings.ENTITY_CODE.BOOKING_PASS) {
          passenger['id'] = element.entityId;
            element.attributeCodeValueDtoList.map(data => {
              passenger[data.attributeCode] = data.attributeValue;
            })
            bookingPassengers.push(passenger);
        }
      })
      this.dispatchBookingDetails = {...details, passengers: bookingPassengers};  
      if (this.dispatchBookingDetails) {
        this.geoLocationsData = {
          pickup: this.dispatchBookingDetails.booking_pickup_location_geolocation.split(','),
          dropoff: this.dispatchBookingDetails.booking_dropoff_location_geolocation.split(','),
          stops: []
        }
      }
      // this.router.navigate(['app/dispatch/', this.bookingDetailItem.id]);
    })
  }

  onCloseBookingDetailView(event) {
    // this.showBookingDetails = event;
    this.bookingDetailItem.id = null;
    this.router.navigate(['app/dispatch']);
  }
}
