import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { CommonBindingDataService } from '../../../services/common-binding-data.service';
import { AppIcons } from 'app/modules/shared/app.icons';
import { MetaDataService } from 'app/modules/shared/services/meta-data.service';

@Component({
  selector: 'app-hour-minute',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, CalendarModule],
  templateUrl: './hour-minute.component.html',
  styleUrl: './hour-minute.component.scss'
})
export class HourMinuteComponent {
  @Input() field: any;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  miIcons = AppIcons;
  formName: FormGroup;
  placeholder: any;
  hourFormat = this.metaDataService.timeFormat;

  constructor(private formGroupDirective: FormGroupDirective,
    private metaDataService: MetaDataService,
    public cs: CommonBindingDataService) {
    this.formName = this.formGroupDirective.control;
  }

  ngOnInit() {
    this.formName = new FormGroup({
      [this.field?.isOneToMultiple ? this.field?.attributeCodeOneToMultiple : this.field.attributeCode]: new FormControl(this.metaDataService.getCurrentTimeInTimezone())
    });
    this.placeholder = this.cs.getLabelValue(this.moduleName + '.fields.' + this.field.attributeCode + '.placeholder', this.attributeLabels, null);
  }
}
