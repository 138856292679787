<form [formGroup]="miFormGroup" class="setting-app-login-form">
  @for (group of groups; track groups.fields;let i = $index) {
    <div class="col-12 md:col-12 tab-group">
      <div class="card p-fluid ml-2 mr-2" [id]="i">

        <div class="p-fluid p-formgrid grid">
            @if (group.code === GROUP_CODE.DRIVER_DRIVING_RECORD) {
              <label class="w-full mt-0 uppercase text-lg font-semibold">{{'lbl_driving_record' | translate}}</label>
              <app-driver-driving-record class="col-12"
                [group]="group"
                [attributeLabels]="attributeLabels"
                [moduleName]="moduleName"
                [miFormGroup]="miFormGroup"
                [driverDrivingRecordIds]="driverDrivingRecordIds"
                [edit]="edit"
                (onRemoveDriverDrivingRecord)="onRemoveDriverDrivingRecord($event)"
                [attributeValues]="attributeValues">
              </app-driver-driving-record>
            }
            @else if (group.code === GROUP_CODE.DRIVER_DOCUMENT) {
              <app-driver-document class="col-12"
                [group]="group"
                [attributeLabels]="attributeLabels"
                [moduleName]="moduleName"
                [miFormGroup]="miFormGroup"
                [driverDocumentEntityIdList]="driverDocumentEntityIdList"
                [edit]="edit"
                [uploadDocumentValueIds]="uploadDocumentValueIds"
                (driverDocumentFieldsChanges)="driverDocumentFieldsChanges($event)"
                (onRemoveDriverDocument)="onRemoveDriverDocument($event)"
                [attributeValues]="attributeValues">
              </app-driver-document>
            }
            @else if (group.code === GROUP_CODE.DOCUMENTS) {
              <app-vehicle-document class="col-12 p-0"
                [group]="group"
                [attributeLabels]="attributeLabels"
                [moduleName]="moduleName"
                [miFormGroup]="miFormGroup"
                [vehicleDocumentEntityIdList]="vehicleDocumentEntityIdList"
                [edit]="edit"
                (vehicleDocumentFieldsChanges)="vehicleDocumentFieldsChanges($event)"
                (onRemoveVehicleDocument)="onRemoveVehicleDocument($event)"
                [attributeValues]="attributeValues">
              </app-vehicle-document>
            }
            @else if (group.code === GROUP_CODE.BOOKING_TIME_RESTRICTION) {
              <label class="w-full mt-0 uppercase text-lg font-semibold">{{cs.getLabelValue(moduleName+'.groups.'+group.code+'.label',attributeLabels,group.code)}}</label>
              <booking-time-restriction class="col-12"
                [isTwoColStructure]="isTwoColStructure"
                [group]="group"
                [attributeLabels]="attributeLabels"
                [moduleName]="moduleName"
                [miFormGroup]="miFormGroup"
                [attributeValues]="attributeValues"
                [bookingTimeRestrictionIds]="bookingTimeRestrictionIds"
                [edit]="edit"
              ></booking-time-restriction>
            } @else if (isPassengerStayHomeAddress(group) || isGeneralSettingsAddress(group)) {
              <label class="w-full mt-0 uppercase text-lg font-semibold">{{cs.getLabelValue(moduleName+'.groups.'+group.code+'.label',attributeLabels,group.code)}}</label>
             <div class="md:col-12">
               <app-stay-home-address
               [group]="group"
               [attributeLabels]="attributeLabels"
               [moduleName]="moduleName"
               [miFormGroup]="miFormGroup"
               [attributeValues]="attributeValues"
               [addressEntityIdList]="addressEntityIdList"
               [defaultLocationList]="defaultLocationList"
               (formGroupEmit)="formGroupEmitEvent($event)"
               [showImg]="showImg"
               [isShowBookingMap]="isShowBookingMap"
               ></app-stay-home-address>
              </div>
            }  @else if (group.code === GROUP_CODE.TIME_SETTINGS) {
              <app-time-settings
                [group]="group"
                [attributeLabels]="attributeLabels"
                [moduleName]="moduleName"
                [miFormGroup]="miFormGroup"
                [attributeValues]="attributeValues"
              ></app-time-settings>
            } @else if (group.code === GROUP_CODE.ASSIGN_LOCATION) {
              <app-privilege-settings-assign-location
                [group]="group"
                [attributeLabels]="attributeLabels"
                [moduleName]="moduleName"
                [miFormGroup]="miFormGroup"
                [attributeValues]="attributeValues">
              </app-privilege-settings-assign-location>
            }
            @else {
              @if(shouldShowSpecialLabels(group)) {
                <app-special-labels
                  [group]="group"
                  [moduleName]="moduleName"
                  [attributeLabels]="attributeLabels"
                  [GROUP_CODE]="GROUP_CODE">
                </app-special-labels>
              }

              <div class="w-full formgrid grid pt-3">
                @for (field of group.fields; track field.attributeCode;let j = $index) {
                  @if(isNotSpecialAddressField(field)){
                    <div [id]="field.attributeCode" class="field input-field-wrapper col-12 md:col-12  lg:col-{{field.uiColumns}}">
                      @if(field.inputCode !== 'spacer'){
                        @if (!isTwoColStructure) {
                          @if (field.attributeCode === 'car_service_auto_dispatch' || field.attributeCode === 'shuttle_bookings') {
                            <app-dispatch-shuttle-field
                              [field]="field"
                              [attributeLabels]="attributeLabels"
                              [moduleName]="moduleName"
                              [dateFormat]="dateFormat"
                              [multiSelectDisplay]="multiSelectDisplay">
                            </app-dispatch-shuttle-field>
                          } @else if (field.attributeCode === 'seeded_player') {
                            <app-seeded-player-field
                              [field]="field"
                              [attributeLabels]="attributeLabels"
                              [moduleName]="moduleName"
                              [dateFormat]="dateFormat"
                              [multiSelectDisplay]="multiSelectDisplay">
                            </app-seeded-player-field>
                          }
                          @else if (excludeAttributeCodes.includes(field.attributeCode)) {
                            <div class="people-count"></div>
                          }
                          @else {
                            @if(shouldShowLabel(field, group)) {
                              <label [class]="getLabelClass(field)">
                                {{cs.getLabelValue(moduleName+'.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}
                                
                                @if (shouldShowRequiredStar(field, group)) {
                                  <span class="required">*</span>
                                }
                                
                                @if (shouldShowHelpTip(field, group)) {
                                  <app-mi-tooltip [messageData]="cs.getLabelValue(moduleName+'.fields.'+field.attributeCode+'.helpTips',attributeLabels,field.attributeCode)">
                                  </app-mi-tooltip>
                                }
                              </label>
                            }


                            @if (field.attributeCode === 'require_at_least_one' || field.attributeCode === 'select_data_to_include') {
                              <app-checkbox-multiselect 
                                [field]="field" 
                                [attributeLabels]="attributeLabels"
                                [moduleName]="moduleName">
                              </app-checkbox-multiselect>
                            } @else {
                              <app-mi-fields 
                                [field]="field" 
                                [attributeLabels]="attributeLabels"
                                [moduleName]="moduleName"
                                [multiSelectDisplay]="multiSelectDisplay"
                                [pKeyFilter]="field.attributeCode === 'barcode' ? pKeyFilterForBarcode : null"
                                [showImg]="showImg"
                                [isMultipleFilesUpload]="isMultipleFilesUpload"
                                [dateFormat]="dateFormat"
                                [class]="getFieldClass(field.attributeCode)">
                              </app-mi-fields>
                            }


                            @if (field.attributeCode === 'generate_code_for_booking'){
                              <app-mi-error class="generate" [field]="field" [attributeLabels]="attributeLabels" [moduleName]="moduleName"
                               class="generate-code-msg relative block"></app-mi-error>
                            } @else if (field.attributeCode === 'select_login_option'){
                              <div class="people-count"></div>
                            } @else{
                              <app-mi-error [field]="field" [attributeLabels]="attributeLabels"
                              [moduleName]="moduleName"></app-mi-error>
                            }
                          }
  
                        }
                         @else {
                          <app-privilege-form
                          [field]="field"
                          [attributeLabels]="attributeLabels"
                          [moduleName]="moduleName"
                          [dateFormat]="dateFormat"
                          [multiSelectDisplay]="multiSelectDisplay"
                          [shouldDisplayHrLine]="shouldDisplayHrLine"
                          [isResetPrivilege]="isResetPrivilege"
                          [passengerTypeToReset]="passengerTypeToReset">
                        </app-privilege-form>
                        }
                      }
  
                    </div>
                  }
                }
              </div>
            }
        </div>
      </div>
    </div>
  }

  @if (displaySubmitButtons) {
    <div [style.display]="hideCancelAndSubmitBtn ? 'none' : block" class="col-12 save-action-div">
      @if(groups[0]?.code !== 'group_dimensions' && (groups[0]?.code !== 'details' || moduleName !== 'incidents_and_complaint')) {
        @if(moduleName !== 'broadcast_message') {
          @if(activeIndex !== 0) {
            <button [id]="previousBtnId" pButton class="settings-app-login mr-2 border-none btn-bg-color default-text-black-color height-44" (click)="onPreviousClick()" [label]="previousBtnText"></button>
          } @else if(moduleName === 'location') {
            <button pButton class="mr-2 surface-100 border-100 hover:border-400 default-text-light-black-color height-44" (click)="onClickCancelFun()" [label]="previousBtnText"></button>
          }
        }
        @else {
          <button pButton class="mr-20 border-none cancel-hover bg-white text-color height-44" (click)="onClickCancelFun()" [label]="previousBtnText"></button>
        }
      }
      @else {
        @if(concernId) {
          <button pButton class="mr-20 border-none cancel-hover bg-transparent text-color height-44" (click)="onClickCancelFun()" [label]="previousBtnText"></button>
        }
        @else {
          <button [id]="previousBtnId" pButton class="mr-20 text-color height-44 save-add-hover" (click)="saveAndAddData($event)" [label]="previousBtnText"></button>
        }
      }
      <p-button [disabled]="saveBtnDisabled" [id]="saveBtnId" class="inline-flex height-44" (click)="submitData($event)" [label]="submitBtnText"></p-button>
    </div>
  }

</form>
