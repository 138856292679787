import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { AppIcons } from "app/modules/shared/app.icons";
import { AccessProviderDirective } from "app/modules/shared/directives/access-provider.directive";
import * as _ from 'lodash';
import { ConfirmationService, MenuItem, MessageService } from "primeng/api";
import { BreadcrumbModule } from "primeng/breadcrumb";
import { ButtonModule } from "primeng/button";
import { ColorPickerModule } from "primeng/colorpicker";
import { DropdownModule } from "primeng/dropdown";
import { InputNumberModule } from "primeng/inputnumber";
import { InputSwitchModule } from "primeng/inputswitch";
import { InputTextModule } from "primeng/inputtext";
import { InputTextareaModule } from "primeng/inputtextarea";
import { RadioButtonModule } from "primeng/radiobutton";
import { TabViewModule } from "primeng/tabview";
import { AppSettings } from "../../../shared/app.settings";
import { CommonBindingDataService } from "../../../shared/services/common-binding-data.service";
import { ConfigService } from "../../../shared/services/config.service";
import { Polygon, Zone, ZoneItem, ZoneItemPagination, ZonesData } from "../../models/zone.models";
import { ManageZonesService } from "../../services/manage-zones.service";
import { ZoneMapComponent } from "../zone-map/zone-map.component";

enum RadiusOption {
  DrawGeofence = "GEO",
}
@Component({
  selector: "app-add-zone",
  templateUrl: "./add-zone.component.html",
  styleUrls: ["./add-zone.component.scss"],
  standalone: true,
  imports: [
    ButtonModule,
    BreadcrumbModule,
    TabViewModule,
    TranslateModule,
    InputSwitchModule,
    InputTextModule,
    InputNumberModule,
    InputTextareaModule,
    RadioButtonModule,
    DropdownModule,
    ColorPickerModule,
    FormsModule,
    ReactiveFormsModule,
    ZoneMapComponent,
    AccessProviderDirective
  ],
})
export class AddZoneComponent implements OnInit {
  @ViewChild(ZoneMapComponent) zoneMapComponent: ZoneMapComponent;
  cancelLabel: string;
  resetLabel: string;
  saveLabel: string;
  routePath: MenuItem[];
  activeIndex: number = 0;
  zoneForm: FormGroup;
  selectedColor: string = AppSettings.COLOR_PICKER_DEFAULT_COLOR;
  zoneId: string;
  formSubmitted: boolean = false;
  drawGeofence: boolean = true;
  polygonCoordinates: string = "";
  zoneFormDetails;
  zonesData: any;
  zoneListData: ZoneItem[];
  paginator: ZoneItemPagination = {
    limit: 20,
    offset: 0,
    searchStr: "",
    defaultSortColumn: "updatedAt",
    defaultSortType: "desc",
  };
  centerCordinateList: any;
  miIcons = AppIcons;
  isPolygonCoordinatesSet = false;
  geoFenceCenterCordsList;
  isAddPage: boolean = false;
  isFormModified: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private cs: CommonBindingDataService,
    private fb: FormBuilder,
    private manageZoneService: ManageZonesService,
    private router: Router,
    private messageService: MessageService,
    private cdr: ChangeDetectorRef,
    private configService: ConfigService,
    private manageZonesService: ManageZonesService,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit() {
    this.zoneId = this.route.snapshot.paramMap.get("id");
    this.setRoutePath();
    this.setHeadersAndLabels();
    this.initForm();
    this.getZonesList();
   
  }



  initForm(): void {
    this.zoneForm = this.fb.group({
      active: [true],
      zoneName: ["", [Validators.required, Validators.maxLength(50), Validators.pattern(/^[a-zA-Z0-9][a-zA-Z0-9\s]*$/)]],
      aliasName: ["", [Validators.required, Validators.maxLength(50), Validators.pattern(/^[a-zA-Z0-9][a-zA-Z0-9\s]*$/)]],
      description: [""],
      zoneColor: [""],
      geoFence: [""],
    });
  }

  Space(event: any) {
    if (event.target.selectionStart === 0 && event.code === 'Space') {
      event.preventDefault();
    }
  }

  capitalizeFirstLetter(input: string): string {
    if (input && input.length > 0) {
      return input.charAt(0).toUpperCase() + input.slice(1);
    }
    return input;
  }

  onInputChange(event: any, controlName: string): void {
    const value = event.target.value;
    this.zoneForm.controls[controlName].setValue(this.capitalizeFirstLetter(value));
  }

  clearValidatorsAndUpdateValidity(formControl: AbstractControl) {
    formControl.clearValidators();
    formControl.updateValueAndValidity();
  }

  onSubmit(): void {
    this.formSubmitted = true;
    if (this.zoneForm.valid) {
      const formData = { ...this.zoneForm.value };
      formData["forTenantCode"] = this.configService.getForTenantCode();
      
      if (this.polygonCoordinates) {
        formData["geoFence"] = this.polygonCoordinates;
      } else { 
        const geoFenceCoordinates = this.zoneFormDetails.geoFence.split(",")
        .map(coord => {
          const [lng, lat] = coord.trim().split(" ");
          return `[${lng},${lat}]`;
        }).join(",");
        formData["geoFence"] = geoFenceCoordinates;
      }

      formData["zoneColor"] = this.selectedColor;
      
      const zoneFormData = formData;
      if (this.zoneId) {
        this.onEditZone(zoneFormData);
      } else {
        this.onAddZone(zoneFormData);
      }
    } else {
      console.error("Form is invalid");
    }
  }

  onGetPolygonCoordinates(event) {
    this.polygonCoordinates = event;
    this.isPolygonCoordinatesSet = !!event;
    this.cdr.detectChanges();
  }

  onAddZone(zoneFormData) {
    this.manageZoneService.addZone(zoneFormData).subscribe(
      (res: ZoneItem) => {
        this.messageService.add({
          key: "tst",
          severity: "success",
          summary: "Successful",
          detail: this.cs.getLabel(res.message),
        });

        this.router.navigate(["app/manage-zones"]);
      },
      (error) => {
        const errorMessage =
          error?.errors?.general?.[0]?.message ?? "An unknown error occurred.";
        this.messageService.add({
          key: "tst",
          severity: "error",
          summary: "Error",
          detail: errorMessage,
        });
      }
    );
  }

  onEditZone(zoneFormData) {
    this.manageZoneService.editZone(this.zoneId, zoneFormData).subscribe(
      (res: ZoneItem) => {
        this.messageService.add({
          key: "tst",
          severity: "success",
          summary: "Successful",
          detail: this.cs.getLabel('manage_zones.zone_update_msg'),
        });

        this.router.navigate(["app/manage-zones"]);
      },
      (error) => {
        const errorMessage =
          error?.errors?.general?.[0]?.message ?? "An unknown error occurred.";
        this.messageService.add({
          key: "tst",
          severity: "error",
          summary: "Error",
          detail: errorMessage,
        });
      }
    );
  }

  getZonesList(paginator: ZoneItemPagination = this.paginator) {
    this.manageZonesService.getZonesList(paginator).subscribe((zoneData) => {
      const zonesData: ZonesData = { polygons: [] };

      const tempzonesData = _.cloneDeep(zoneData);

      this.centerCordinateList = _.map(tempzonesData.data, ({ latitude, longitude }) => {
        if (latitude != null && longitude != null && latitude !== '' && longitude !== '' && latitude !== 0 && longitude !== 0) {
          return { lat: latitude, lng: longitude };
        }
        return null;
      }).filter(coordinate => coordinate !== null);

      this.geoFenceCenterCordsList = _.flatMap(tempzonesData.data, (zoneData) => {
        if (zoneData.geoFence) {
          const coordinatePairs = zoneData.geoFence.split(',');

          return _.map(coordinatePairs, pair => {
            const [lng, lat] = pair.trim().split(' ').map(Number);
            return { lat, lng };
          });
        }
        return [];
      });

      this.centerCordinateList = [...this.centerCordinateList, ...this.geoFenceCenterCordsList]

      const commonZoneProps = (zone: Zone) => ({
        strokeColor: zone.zoneColor,
        fillColor: zone.zoneColor,
        label: zone.zoneName,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillOpacity: 0.35
      });

      const transformGeoZone = (zone: Zone): Polygon => {
        const coords = zone.geoFence?.split(",").map(coord => {
          const [lng, lat] = coord.split(" ").map(parseFloat);
          return { lat, lng };
        });

        return {
          coords,
          ...commonZoneProps(zone)
        };
      };

      const filteredZoneData = _.reject(zoneData.data, ['zoneId', this.zoneId]);

      zonesData.polygons = filteredZoneData.map(transformGeoZone);

      this.zonesData = zonesData;

      if (this.zoneId) {
        this.getZoneData();
      }else{
        this.isAddPage = true;
      }
    });
  }

  getZoneData() {
    this.manageZoneService
      .getZoneDetails(this.zoneId)
      .subscribe((zoneDetails: ZoneItem) => {
        this.zoneForm.patchValue(zoneDetails);
        this.selectedColor = zoneDetails.zoneColor;
        this.zoneFormDetails = zoneDetails;
        this.cdr.detectChanges();
      });
  }
  onResetForm(): void {
    if (this.isAddPage) {
      this.zoneForm.reset({
        active: false,
        zoneName: "",
        aliasName: "",
        description: "",
      });
      this.selectedColor = "#e00d23";
  
      if (this.zoneMapComponent) {
        this.zoneMapComponent.clearPolygon();
      }
    } else {
      if (this.isFormModified) {
        this.zoneForm.patchValue({
          zoneName: this.zoneFormDetails.zoneName,
          aliasName: this.zoneFormDetails.aliasName,
          description: this.zoneFormDetails.description,
          active: this.zoneFormDetails.active,
        });
        this.selectedColor = this.zoneFormDetails.zoneColor || "#e00d23";
        this.isFormModified = false;
  
        if (this.zoneMapComponent && this.zoneMapComponent.drawnPolygon) {
          this.zoneMapComponent.clearPolygon();
        }
      } else {
        this.confirmationService.confirm({
          header: this.cs.getLabel('manage_zones.confirm_header'),
          message: this.cs.getLabel('manage_zones.confirm_discard_msg'),
          acceptIcon: 'none',
          rejectIcon: 'none',
          acceptLabel: this.cs.getLabel('manage_zones.lbl_discard'),
          rejectLabel: this.cs.getLabel('manage_zones.lbl_no'),
          rejectButtonStyleClass: 'bg-white text-color',
          acceptButtonStyleClass: 'bg-red-500',
          accept: () => {
            this.getZonesList();
          },
          reject: () => {
            
          }
        });
      }
    }
  }

  onCancelForm() {
    this.router.navigate(["app/manage-zones"]);
  }

  onColorChange(color: string) {
    this.selectedColor = color;
    if (this.zoneMapComponent && this.zoneMapComponent.drawnPolygon) {
      this.zoneMapComponent.updatePolygonColor(color);
      if (this.zoneFormDetails) {
        this.zoneFormDetails.zoneColor = color;
      }
    }
  }

  setRoutePath() {
    const manageZoneLabel = this.cs.getLabel("manage_zones.manage_zone");
    const addEditLabel = this.zoneId
      ? this.cs.getLabel("manage_zones.edit_zone")
      : this.cs.getLabel("manage_zones.add_zone");
    this.routePath = [
      {
        label: manageZoneLabel,
        routerLink: this.zoneId ? "../../" : "../",
        icon: "pi pi-arrow-left",
        iconStyle: { "font-weight": "bold", "margin-right": "10px" },
      },
      {
        label: addEditLabel,
        styleClass: "breadcrumb-child forward-slash breadcrumb-text",
        style: { display: "flex", top: "2px", position: "relative" },
      },
    ];
  }

  showRequiredErrorMessage(fieldName: string): boolean {
    const field = this.zoneForm.get(fieldName);
    return this.formSubmitted && field.invalid && field.errors?.required && !field.errors?.maxLength;
  }

  setHeadersAndLabels() {
    this.cancelLabel = this.cs.getLabel("cancel");
    this.resetLabel = this.cs.getLabel("reset");
    this.saveLabel = this.zoneId ? this.cs.getLabel("label_update") : this.cs.getLabel("label_save");
  }
}
