import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppSettings } from 'app/modules/shared/app.settings';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { EntityService } from 'app/modules/shared/services/entity.service';
import { MessageService } from 'primeng/api';
import { EventService } from '../services/event.service';
import { JsonPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MiFormComponent } from 'app/modules/shared/components/mi-form/mi-form.component';
import { AccessProviderDirective } from 'app/modules/shared/directives/access-provider.directive';
import { TrimSpacesDirective } from 'app/modules/shared/directives/trim-spaces.directive';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { Country } from 'app/modules/shared/models/country';
import { Language } from 'app/modules/shared/models/language';
import { AttributeData, entityResponse } from 'app/modules/vehicles/models/attribute.models';
import { BookingService } from 'app/modules/bookings/services/booking.service';
import { FindByViewResponse } from 'app/modules/shared/models/find-by-view-response';
import { EntityResponse } from 'app/modules/vehicles/models/entityResponse.models';
import { BookingStatus } from 'app/modules/bookings/models/booking.models';

@Component({
  selector: 'app-event-config',
  standalone: true,
  imports: [MiFormComponent, FormsModule, ReactiveFormsModule,
    ButtonModule, TranslateModule, InputTextModule, DropdownModule,
    MultiSelectModule, CalendarModule, InputNumberModule, InputTextareaModule,
    JsonPipe, AccessProviderDirective, TrimSpacesDirective],
  templateUrl: './event-config.component.html',
  styleUrl: './event-config.component.scss'
})
export class EventConfigComponent {
  @Input() eventCode: any;
  @Input() eventId: any;

  @Output() configDataSaved: EventEmitter<any> = new EventEmitter();
  eventConfigEntityId: any;
  attributeValues = [];
  showForm: boolean = false;
  attributeLabelsForEventConfig = {};
  eventConfigAttributeData: any;
  eventConfigModuleName = AppSettings.ENTITY_CODE.CONFIG_SETTING;
  activeIndex = 1;
  addEventForm: FormGroup;
  configSettingsEntityData: any;
  country: Country;
  language: Language;
  bookingStatuses: any = [];
  bookingStatuseStringList: any = [];

  constructor(private eventService: EventService,
    private entityService: EntityService,
    private cs: CommonBindingDataService,
    private messageService: MessageService,
    private configService: ConfigService,
    private cd: ChangeDetectorRef,
    private bookingService: BookingService) { }

  ngOnInit() {
    this.getBookingStatuses();
    this.setAppSettings();
    if (!this.eventCode) {
      this.getConfigurationSettingsFromMainSettings();
    } else {
      this.getConfigSettings();
    }
    this.setConfigSettingsEntityData();
  }

  setConfigSettingsEntityData() {
    this.configSettingsEntityData = {
      countryCode: this.country[0].countryCode,
      tenantCode: this.configService.getLoggedInTenantCode(),
      entityCode: AppSettings.ENTITY_CODE.CONFIG_SETTING
    }
  }

  getBookingStatuses() {
    this.bookingService.getBookingStatuses().subscribe({
      next: (result: BookingStatus[]) => {
        this.bookingStatuses = result;
        this.bookingStatuses = this.bookingStatuses.filter(ele =>
          (ele.bookingCode !== AppSettings.BOOKING_STATUS_CODES.CANCELLED) &&
          (ele.bookingCode !== AppSettings.BOOKING_STATUS_CODES.EXPIRED) &&
          (ele.bookingCode !== AppSettings.BOOKING_STATUS_CODES.COMPLETED));
          
        this.bookingStatuseStringList = this.bookingStatuses.map(ele => {
          const statusObject = JSON.parse(ele.bookingStatusDisplayStr);
          const statusString = statusObject.find(status => status.langId === this.language[0].languageId);
          return {
            labelValue: ele.bookingStatusId,
            labelKey: (statusString) ? statusString?.label : ''
          }
        })
        this.getAttributesForEventConfig();
      },
      error: (error) => { }
    });
  }

  getConfigurationSettingsFromMainSettings() {
    this.entityService.getEntityDetailsByView(AppSettings.ENTITY_CODE.CONFIG_SETTING, {}).subscribe({
      next: (response: FindByViewResponse) => {
        this.getEntityDetailsFromMainSettings(response.entityId);
      },
      error: (error) => {
        this.showForm = true;
      }
    })
  }

  getEntityDetailsFromMainSettings(entityId) {
    this.entityService.getEntity(entityId, AppSettings.ENTITY_CODE.CONFIG_SETTING, AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe({
      next: (response: EntityResponse) => {
        this.attributeValues = this.cs.getOrgAttributeValues(response);
        this.showForm = true;
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
      }
    })
  }

  setAppSettings() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
  }

  getAttributesForEventConfig() {
    this.entityService.getAttributeDefinition(AppSettings.ENTITY_CODE.CONFIG_SETTING, AppSettings.VIEW_CODE.EVENT_ADD_EDIT_VIEW)
    .subscribe((res: AttributeData) => {
      if (res) {
        this.attributeLabelsForEventConfig = this.cs.getAttributeLabels(res);
        this.eventConfigAttributeData = this.cs.getOrganizedAttribute(res);
        this.eventConfigAttributeData.tabs.forEach(tab => {
          tab.groups.forEach(groups => {
            groups.fields.forEach(field => {
              if (this.checkAttributeCodeToSetPresetValues(field)) {
                field.presetValues = [];
                field.presetValues.push(...this.bookingStatuseStringList);
              }
            });
          });
        });
      }
    });
  }

  checkAttributeCodeToSetPresetValues(field) {
    return (field.attributeCode === AppSettings.ATTRIBUTE_CODE_FOR_CONFIG_SETTINGS.PASSENGER_CANCEL_BOOKING_ALLOWED_STATUS ||
      field.attributeCode === AppSettings.ATTRIBUTE_CODE_FOR_CONFIG_SETTINGS.PASSENGER_EDIT_BOOKING_ALLOWED_STATUS);
  }

  getConfigSettings() {
    this.eventService.getEventSettingsDetailsByView(AppSettings.ENTITY_CODE.CONFIG_SETTING, this.eventCode, {})
      .subscribe({
        next: (response: FindByViewResponse) => {
          if (response) {
            this.eventConfigEntityId = response.entityId;
            this.getEntityDetails();
          }
        },
        error: (error) => {
          this.getConfigurationSettingsFromMainSettings();
        }
      })
  }

  getEntityDetails() {
    this.eventService.getEventSettingsEntity(this.eventConfigEntityId, AppSettings.ENTITY_CODE.CONFIG_SETTING, AppSettings.VIEW_CODE.EVENT_ADD_EDIT_VIEW, this.eventCode).subscribe({
      next: (response: EntityResponse) => {
        this.attributeValues = this.cs.getOrgAttributeValues(response);
        this.showForm = true;
        this.cd.detectChanges();
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
      }
    })
  }

  saveConfigSettings(timeSettingsFormData) {
    if (this.eventCode) {
      if (this.eventConfigEntityId) {
        this.saveConfigSettingsData(AppSettings.ENTITY_CODE.CONFIG_SETTING, timeSettingsFormData);
      } else {
        this.configSettingsEntityData.eventCode = this.eventCode;
        this.eventService.createEntityForTimeSettings(AppSettings.ENTITY_CODE.CONFIG_SETTING, this.configSettingsEntityData).subscribe({
          next: (res: entityResponse) => {
            this.eventConfigEntityId = res.entityId;
            this.saveConfigSettingsData(this.configSettingsEntityData.entityCode, timeSettingsFormData);
          },
          error: (error) => {
            this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
          }
        });
      }
    } else {
      this.messageService.add({ key: 'tst', severity: 'info', summary: 'Info', detail: this.cs.getLabel('events.please_add_event_to_add_time_settings') });
    }
  }

  saveConfigSettingsData(entityCode, timeSettingsFormData) {
    this.configSettingsEntityData = {
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: AppSettings.ENTITY_CODE.CONFIG_SETTING,
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      data: []
    };
    for (const [key, value] of Object.entries(timeSettingsFormData)) {
      const attributeValue = value;
      if (attributeValue) {
        this.configSettingsEntityData.data.push({
          attributeCode: key,
          attributeValue
        });
      }
    }
    this.configSettingsEntityData.data = this.cs.mapAttributeIds(this.configSettingsEntityData.data, this.eventConfigAttributeData.tabs);
    this.configSettingsEntityData.eventCode = this.eventCode;
    this.eventService.saveTimeSettingsForEvent(entityCode, this.eventConfigEntityId, this.configSettingsEntityData).subscribe({
      next: () => {
        this.configDataSaved.emit(true);
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('settings.lbl_config_settings_saved_successfully') });
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
      }
    });
  }

}
