import { CommonModule, NgClass } from "@angular/common";
import { Component, HostListener, Input, OnInit } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { TableModule } from "primeng/table";
import { HideButtonComponent } from "../hide-button/hide-button.component";
import { HideService } from "../../../services/hide-button-service";
import { CommonBindingDataService } from "../../../../shared/services/common-binding-data.service";
import { UiService } from "app/modules/shared/services/ui.service";

@Component({
  selector: "app-vehicles-card",
  templateUrl: "./vehicles-card.component.html",
  standalone: true,
  imports: [
    TableModule,
    TranslateModule,
    NgClass,
    CommonModule,
    HideButtonComponent,
  ],
  styleUrl: './vehicles-card.component.scss'
})
export class VehiclesCardComponent implements OnInit {
  @Input() totalVehiclesData: any;
  cardId: string;
  tableScrollHeight: string;

  constructor(
    public hideService: HideService,
    private cs: CommonBindingDataService,
    private uiService: UiService
  ) { }

  ngOnInit() {
    this.cardId = this.cs.getLabel("dashboard_card_ids.vehicle_table");
    this.tableScrollHeight = this.uiService.setCardScrollHeight();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.tableScrollHeight = this.uiService.setCardScrollHeight();
  }
  
}
