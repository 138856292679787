import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { EntityService } from '../../../shared/services/entity.service';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { AppIcons } from '../../../shared/app.icons';
import { AppSettings } from '../../../shared/app.settings';
import { ConfigService } from '../../../shared/services/config.service';
import { LeaveManagementService } from '../../services/leave-management.service';

@Component({
  selector: 'app-bulk-leave-update-dialog',
  standalone: true,
  imports: [DialogModule, DividerModule, FormsModule, ReactiveFormsModule, DropdownModule, ButtonModule, TableModule, TranslateModule],
  templateUrl: './bulk-leave-update-dialog.component.html',
  styleUrl: './bulk-leave-update-dialog.component.scss'
})
export class BulkLeaveUpdateDialogComponent {
  miIcons = AppIcons;
  @Input() entityName;
  @Input() attributeData;
  @Input() filterFields = [];
  @Input() visible;
  @Input() showBulkLeaveUpdateDialog;
  @Input() bulkRowCount;
  @Input() selectedRecords;
  @Input() bulkUpdateHeader;
  @Input() reviewHeader;
  @Input() btnUpdate;
  @Output() onToggleOverLay: EventEmitter<boolean> = new EventEmitter();
  @Output() onBulkLeaveDataUpdate: EventEmitter<boolean> = new EventEmitter();
  bulkUpdateMaxCount;

  bulkUpdateForm: FormGroup;
  updateFormData;
  isVisible: boolean = false;
  show: boolean = false;
  position: string = "center";
  selectedStatus: any;
  replaceWithDropdown: any[] = [
    {
      label: this.cs.getLabel('lbl_replace_with'),
      value: AppSettings.REPLACE_WITH_DROPDOWN[0].value,
      disabled: AppSettings.REPLACE_WITH_DROPDOWN[0].disabled,
      icon: this.miIcons.REPEAT,
    }
  ];

  selectedItems: string = this.replaceWithDropdown[0];
  lbl_update;
  constructor(private formBuilder: FormBuilder,
    private cd: ChangeDetectorRef,
    private cs: CommonBindingDataService,
    private bulkLeaveUpdateService: LeaveManagementService,
    private messageService: MessageService,
    private confService: ConfigService) {
  }

  ngOnInit() {
    this.lbl_update = this.cs.getLabel('btn_update_vehicles');
    this.bulkUpdateForm = this.formBuilder.group({
      filterFieldControls: this.formBuilder.array([]),
    });
    this.bulkUpdateMaxCount = this.filterFields?.length;
    this.cd.detectChanges();
    this.addMultipleColumn('eve');
  }

  addMultipleColumn(event) {
    this.setSelectedFieldOptionAndDropdownDisabled();
    if (
      (this.bulkUpdateForm.get("filterFieldControls") as FormArray)?.length <= 5
    ) {
      (this.bulkUpdateForm.get("filterFieldControls") as FormArray).push(
        this.formBuilder.group({
          fieldName: ['', [Validators.required]],
          replaceWith: [this.selectedItems],
          fieldValue: ['', [Validators.required]],
          listOfStatus: [this.filterFields],
          presetValues: []
        })
      );
    }
  }

  setSelectedFieldOptionAndDropdownDisabled() {
    if (this.filterFields?.length > 0) {
      const fieldName = this.filterFields.find(element => element.attributeCode === this.selectedStatus);
      if (fieldName) {
        fieldName.isDisable = true;

        const obj = this.bulkUpdateForm.get("filterFieldControls") as FormArray;
        obj.controls.forEach((element: any) => {
          element.controls.fieldName.disable();
        });
      }
    }
  }

  removeAllChange(event, index) {
    if (event?.value?.value === this.replaceWithDropdown[1]?.value) {
      (this.bulkUpdateForm?.get("filterFieldControls") as FormArray)?.controls[
        index
      ]
        ?.get("fieldValue")
        ?.disable();
    } else {
      (this.bulkUpdateForm?.get("filterFieldControls") as FormArray)?.controls[
        index
      ]
        ?.get("fieldValue")
        ?.enable();
    }
  }

  confirmPopupShow() {
    const obj = this.bulkUpdateForm.get("filterFieldControls") as FormArray;
    obj.controls.forEach((element: any) => {
      element.controls.fieldName.enable();
    });

    this.isVisible = true;
    this.visible = false;
    this.updateFormData = (this.bulkUpdateForm.get("filterFieldControls") as FormArray).value;
  }

  confirmPopupHide() {
    this.isVisible = false;
  }

  onHide(event) {
    this.visible = false;
    this.onToggleOverLay.emit(this.visible);
  }

  filterStatus(event, idx) {
    this.selectedStatus = event.value.attributeCode;
    const obj = this.bulkUpdateForm.get("filterFieldControls") as FormArray;
    const formGroupLength = obj.length;
    const presetValues = this.filterFields.find(element => element.attributeCode === this.selectedStatus).presetValues;
    const filteredValues = presetValues.filter(item => item.labelValue.toLowerCase() !== "pending");
    (obj.controls[formGroupLength - 1] as FormArray).controls['presetValues'].setValue(filteredValues);
    if (this.bulkUpdateForm.get("filterFieldControls")) {
      const id = this.filterFields.find(element => element.value === obj.value[idx].fieldName.value);
      if (obj.value[idx].fieldName.value) {
        this.filterGroup(obj, idx);
      }
      this.refreshDropdown();
    }
  }

  filterGroup(obj, idx) {
    if (obj.value[idx].fieldName.value !== 'group') {
      this.replaceWithDropdown[1].disabled = true;
    } else {
      this.replaceWithDropdown[1].disabled = false;
    }
  }

  refreshDropdown() {
    this.filterFields = [...this.filterFields];
  }

  onFiledValueChange(event, idx) {
    const obj = this.bulkUpdateForm.get("filterFieldControls");
    obj.value[idx].fieldValue = event.value;
  }

  updateData() {
    const CountryCode = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    const reqBody = {
      forTenantCode: this.confService.getLoggedInTenantCode(),
      countryCode: CountryCode[0].countryCode,
      approvalStatus: this.updateFormData[0].fieldValue ? this.updateFormData[0].fieldValue : '',
      leaveIds: this.selectedRecords.map(ele => ele.leaveId),
    };

    this.bulkLeaveUpdateService.updateBulkLeaveData(reqBody).subscribe(
      (res: any) => {
        this.onBulkLeaveDataUpdate.emit(true);
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: res.message });
        this.isVisible = false;
      },
      err => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: err });
      });
  }

  get formArray() {
    return this.bulkUpdateForm.controls.filterFieldControls as FormArray;
  }

}
