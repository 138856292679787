<app-mi-table
    [collectionOfColumns]="allColumns"    
    entityName="leave_management_history"
    [attributeLabels]="filterAttributeLabels"
    [data]="tableData"
    [isLoading]="isLoading"
    [filterList]="filterList"
    [filterFields]="filterFieldsArray"
    [attributeData]="attributeData"
    [reviewHeader]="reviewHeader"
    [totalRecords]="totalRecords"
    [isEntity]="isEntity"
    (onValueChange)="onFilterValueChange($event)"
    (onSearchValueChange)="onSearchValueChanges($event)"
    [isLeaveRequest]="isLeaveRequest"
    [uniqueId]="uniqueId"
    (onPageChange)="onPageChange($event)"
    (itemPerPageChange)="itemPerPageChange($event)"
    [staticEntity]="'leave_management_history'">
</app-mi-table>
