import { Component, Input, OnInit } from '@angular/core';
import { Status } from '../../../../shared/models/status';
import { MetaDataService } from 'app/modules/shared/services/meta-data.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-overview-status',
  templateUrl: './overview-status.component.html',
  styleUrl: './overview-status.component.scss',
  standalone: true,
  imports: [DatePipe],
  providers: [DatePipe]
})
export class OverviewStatusComponent implements OnInit {
  @Input() public dataForStatus: Array<Status> = [];
  @Input() public incidentsComplaintsCategory;
  dd_MMM_yyyy_FORMAT: any = this.metaDataService.upperDateFormat;

  constructor(private metaDataService: MetaDataService) { }

  ngOnInit(): void {
  }
}
