import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { AppSettings } from "app/modules/shared/app.settings";
import { MITableComponent } from "app/modules/shared/components/mi-table/mi-table.component";
import type { Country } from "app/modules/shared/models/country";
import type { Language } from "app/modules/shared/models/language";
import { CommonBindingDataService } from "app/modules/shared/services/common-binding-data.service";
import { ConfigService } from "app/modules/shared/services/config.service";
import { EntityService } from "app/modules/shared/services/entity.service";
import type { EntityCount, EntityCountsResponse } from "app/modules/vehicles/models/entity.models";
import type { ListResponse, Values } from "app/modules/vehicles/models/listResponse.models";
import * as _ from 'lodash';
import { forkJoin } from "rxjs";


@Component({
  selector: 'app-vehicle-assignment-history',
  templateUrl: './vehicle-assignment-history.component.html',
  styleUrl: './vehicle-assignment-history.component.scss',
  standalone: true,
  imports: [MITableComponent],
})

export class VehicleAssignmentHistoryComponent {
  @Input() vehicleDetails: any;
  filterList;
  attributeLabels = {};
  filterAttributeLabels = {};
  isLoading = true;
  filterAvailableColumns = [];
  listColumns: Values;
  allColumns: any;
  pagination: any;
  tableData: any = [];
  tableDataUpdated: any = [];
  country: Country;
  language: Language;
  entityCount: EntityCountsResponse;
  entityData: any = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
    forTenantCode: this.configService.getForTenantCode(),
    actionStatus: ''
  };

  filterFieldsArray: any[];
  attributeData: any;
  data: any;
  visible: boolean = false;
  totalRecords: number = 0;
  attributeIdOfVehicleEntityId: any;
  fieldForDuration: any;
  showTable: boolean = false;

  constructor(private assignmentHistoryService: EntityService,
    private cs: CommonBindingDataService, private configService: ConfigService, private router: Router) {

  }

  ngOnInit() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.getData();
  }

  getTableView() {
    this.assignmentHistoryService.getAttributeDefinition(AppSettings.ENTITY_CODE.VEHICLE_ASSIGNMENT_HISTORY, AppSettings.VIEW_CODE.DEFAULT_TABLE_VEHICLE_ASSIGNMENT_VIEW).subscribe(res => {
      if (res) {
        this.isLoading = false;
        this.data = res;
        this.attributeLabels = this.cs.getAttributeLabels(this.data);
        this.allColumns = this.cs.getColumns(this.data);
        if (this.data && this.data.tabs && this.data.tabs[0] && this.data.tabs[0].groups && this.data.tabs[0].groups[0] && this.data.tabs[0].groups[0].fields) {
          this.fieldForDuration = _.cloneDeep(this.data.tabs[0].groups[0].fields[this.data.tabs[0].groups[0].fields.length - 1]);
        }
        this.fieldForDuration.attributeCode = 'duration';
        this.allColumns.push(this.fieldForDuration);

        this.allColumns?.forEach((key, index) => {
          this.allColumns[index] = key;
          this.allColumns[index].field = key.attributeCode;
          this.allColumns[index].header = this.cs.getLabelValue(
            "vehicle_assignment_history" + ".fields." + key.attributeCode + ".label",
            this.attributeLabels,
            key.attributeCode
          );
        });
        this.filterAvailableColumns = _.clone(this.allColumns);
        this.searchEntity();
      }
    });

  }

  getFilterView() {
    this.assignmentHistoryService.getAttributeDefinition(AppSettings.ENTITY_CODE.VEHICLE_ASSIGNMENT_HISTORY, AppSettings.VIEW_CODE.ADVANCED_FILTER_VIEW).subscribe((filterResponse: any) => {
      if (filterResponse) {
        this.attributeIdOfVehicleEntityId = filterResponse.tabs[0].groups[0].fields.find(element => element.attributeCode === 'vehicle_entity_id')?.attributeId;
        this.getTableView();
      }
    })
  }

  getEntityCount() {
    const country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    const entityCountPayload = <EntityCount>{
      forTenantCode: this.configService.getForTenantCode(),
      countryCode: country[0].countryCode,
      entityCode: AppSettings.ENTITY_CODE.VEHICLE_ASSIGNMENT_HISTORY,
    };

    this.assignmentHistoryService.getEntityCount(AppSettings.ENTITY_CODE.VEHICLE_ASSIGNMENT_HISTORY, entityCountPayload)
      .subscribe((res: EntityCountsResponse) => {
        this.entityCount = res;
      });
  }

  getData() {
    return forkJoin({
      filterViewDate: this.getFilterView(),
      getEntityCount: this.getEntityCount(),
    })
  }


  searchEntity() {
    this.tableData = [];
    this.entityData.filters = [{ attributeId: this.attributeIdOfVehicleEntityId, attributeValue: [this.vehicleDetails.entityId] }];
    this.entityData.countryCode = this.country[0].countryCode;
    this.entityData.tableViewCode = AppSettings.VIEW_CODE.ADD_EDIT_VIEW;
    this.assignmentHistoryService.searchEntity(AppSettings.ENTITY_CODE.VEHICLE_ASSIGNMENT_HISTORY, this.entityData).subscribe((res: ListResponse) => {
      this.listColumns = res?.data[0]?.values;
      this.cs.setDuration(res);
      this.pagination = res?.pagination;
      this.totalRecords = res?.count;
      this.tableData = this.cs.getTableData(res);
      this.setDriverProfileImage();
    });
  }

  setDriverProfileImage() {
    this.tableData.forEach((driver, index) => {
      driver.relatedData.forEach(relatedDataObject => {
        if (relatedDataObject.entityCode === AppSettings.ENTITY_CODE.DRIVER) {
          this.tableDataUpdated.push({
            ...driver,
            ...relatedDataObject.values,
            profileImage: relatedDataObject?.values?.driver_profile_image_url?.[0]
          })
        }
      });
      if (index === this.tableData.length - 1) {
        this.showTable = true;
      }
    });
  }

  onSearchValueChanges(event) {
    this.entityData.searchStr = event;
    this.tableDataUpdated = [];
    this.searchEntity();
  }

  onPageChange(event) {
    this.entityData.offset = event?.first;
    this.entityData.limit = event?.rows;
    this.searchEntity();
  }

  itemPerPageChange(event) {
    this.entityData.limit = event;
    this.searchEntity();
  }
}