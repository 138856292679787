<p-breadcrumb [model]="routePath"></p-breadcrumb>
<div appAccessProvider [accessCode]="{ menuCode: 'USER_MANAGEMENT', subMenuCode: 'MANAGE_ROLES', pageCode: 'DETAILS', subPageCode: 'ADD' }">
<div class="role-details">
    <form [formGroup]="roleForm">
        <div class="top-bg py-2 px-3 border-bottom bg-white">
            <div class="grid flex">
                <div class="flex col-3 flex-column ">
                    <div class="frm-label form-lbl mb-1 font-semibold">
                        <label for="roleName" translate>manage_roles.lbl_role_name</label><span class="text-red-500">*</span>
                    </div>
                    <div class="frm-control">
                        <input formControlName="roleName" class="full-width w-full custom-form" type="text" [placeholder]="'manage_roles.enter_role' | translate" pInputText />
                    </div>
                    @if (roleForm.controls.roleName.errors && (roleForm.controls.roleName.dirty || roleForm.controls.roleName.touched)) {
                        <div class="form-errors mt-1">
                            <div [hidden]="!roleForm.controls.roleName.errors.required" class="text-red-500" translate>
                                manage_roles.role_name_err
                            </div>
                        </div>
                    }
                </div>
                <div class="flex col-6 flex-column ">
                    <div class="frm-label form-lbl mb-1 font-semibold">
                        <label for="description" translate>manage_roles.lbl_description</label>
                    </div>
                    <div class="frm-control">
                        <input formControlName="description" class="full-width w-full custom-form" type="text" pInputText maxlength="250" [placeholder]="'manage_roles.enter_description' | translate"/>
                    </div>
                </div>
                <div class="col-3 flex justify-content-end pr-2 p-4">
                    <button type="button" pButton [label]="'manage_users.cancel' | translate" class="mr-2 text-color-secondary bg-white border-0 border-button border-none"
                        id="cancelBtnId" (click)="onCancel()"></button>
                        
                    <button type="button" pButton id="saveBtnId" [label]="roleId ? ('manage_users.update' | translate) : ('manage_users.save' | translate)"
                        class="pelement blue-btn  flex align-items-center justify-content-center cursor-pointer event-box shadow-none" (click)="onSave()" [disabled]="roleForm.invalid"></button>
                </div>
            </div>
        </div>
        <div class="grid m-0">
            <div class="col-12 md:col-4 lg:col-2 left-section mt-0 pt-0 pl-0">
                <div class="card p-0 border-none border-noround">
                    <ul class="m-0 p-1">
                        @for (module of modules; track module) {
                            <li class="p-2 list-none font-14" [class.selected]="module === selectedModule" (click)="selectModule(module)">
                                {{module}}
                            </li>
                        }
                    </ul>
                </div>
            </div>
            <div class="col-12 md:col-8 lg:col-10 right-section" #rightSection>
                <div class="grid pt-2 pr-2">
                    <div class="col-12 right-content">
                        <app-permission-card [permissionArray]="permissionArray" [selectedModule]="selectedModule"></app-permission-card>
                    </div>
                </div>
                <div class="col-12">
                    <div class="flex save-button justify-content-end save-action-div">
                        <button type="button" pButton [label]="'manage_users.cancel' | translate" class="mr-2 bg-white btn-bg-color border-none default-text-light-black-color cursor-pointer"
                            id="cancelBtnId" (click)="onCancel()"></button>
                            
                        <button type="button" pButton id="saveBtnId" [label]="roleId ? ('manage_users.update' | translate) : ('manage_users.save' | translate)"
                            class="flex align-items-center justify-content-center cursor-pointer shadow-none" (click)="onSave()" [disabled]="roleForm.invalid"></button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
</div>

<p-dialog [(visible)]="displayDialog" [modal]="true" [closable]="true" [responsive]="true" [style]="{width: '550px'}">
    <p-header>Confirmation</p-header>
    <div>
        <p>Are you sure you want to discard the changes?</p>
    </div>
    <p-footer>
      <button type="button" pButton label="No" (click)="onCancelDialog()" class="noChange"></button>
      <button type="button" pButton label="Discard" (click)="onConfirmCancel()" class="discard"></button>
    </p-footer>
</p-dialog>
