import { Component, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { BookingService } from 'app/modules/bookings/services/booking.service';
import { AppSettings } from 'app/modules/shared/app.settings';
import { MiFormComponent } from 'app/modules/shared/components/mi-form/mi-form.component';
import { AccessProviderDirective } from 'app/modules/shared/directives/access-provider.directive';
import { BookingStatus } from 'app/modules/shared/models/booking-status';
import { Country } from 'app/modules/shared/models/country';
import { CreateEntityResponse } from 'app/modules/shared/models/create-entity-response';
import { FindByViewResponse } from 'app/modules/shared/models/find-by-view-response';
import { Language } from 'app/modules/shared/models/language';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { EntityService } from 'app/modules/shared/services/entity.service';
import { AttributeData } from 'app/modules/vehicles/models/attribute.models';
import { EntityResponse } from 'app/modules/vehicles/models/entityResponse.models';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TabViewModule } from 'primeng/tabview';

@Component({
  selector: 'app-settings-configuration-settings',
  standalone: true,
  imports: [InputTextModule, FormsModule, ReactiveFormsModule, TranslateModule, ButtonModule, MiFormComponent, TabViewModule, AccessProviderDirective],
  templateUrl: './settings-configuration-settings.component.html',
  styleUrl: './settings-configuration-settings.component.scss'
})
export class SettingsConfigurationSettingsComponent {
  @ViewChild(MiFormComponent) form: MiFormComponent;
  configurationSettingsAttributeData: any;
  attributeLabelsForConfigurationSettings = {};
  country: Country;
  language: Language;
  configurationSettingsModuleName = AppSettings.ENTITY_CODE.CONFIG_SETTING;

  configurationSettingsEntityData: any;
  configurationSettingEntityId: any;
  configurationSettingsData: any;

  previousBtnLabel: any = this.cs.getLabel('lbl_cancel');
  saveConfigurationSettingsBtnLabel: any = this.cs.getLabel('settings.save_configuration_settings');
  entityId: any;
  attributeValues: any;
  showForm: boolean = false;
  bookingStatuses: any = [];
  bookingStatuseStringList: any = [];
  excludeBookingStatusList: any[] = [AppSettings.BOOKING_STATUS_CODES.CANCELLED,
  AppSettings.BOOKING_STATUS_CODES.EXPIRED,
  AppSettings.BOOKING_STATUS_CODES.COMPLETED
  ]

  constructor(
    private configService: ConfigService,
    public cs: CommonBindingDataService,
    private messageService: MessageService,
    private bookingService: BookingService,
    private entityService: EntityService) { }

  ngOnInit() {
    this.getBookingStatuses();
    this.getConfigurationSettings();
    this.setAppSettings();
    this.setConfigurationSettingsEntityData();
  }

  getBookingStatuses() {
    this.bookingService.getBookingStatuses().subscribe({
      next: (result: BookingStatus[]) => {
        this.bookingStatuses = result;
        this.bookingStatuses = this.bookingStatuses.filter(ele =>
          (ele.bookingCode !== AppSettings.BOOKING_STATUS_CODES.CANCELLED) &&
          (ele.bookingCode !== AppSettings.BOOKING_STATUS_CODES.EXPIRED) &&
          (ele.bookingCode !== AppSettings.BOOKING_STATUS_CODES.COMPLETED));
        this.bookingStatuseStringList = this.bookingStatuses.map(ele => {
          const statusObject = JSON.parse(ele.bookingStatusDisplayStr);
          const statusString = statusObject.find(status => status.langId === this.language[0].languageId);
          return {
            labelValue: ele.bookingStatusId,
            labelKey: (statusString) ? statusString?.label : ''
          }
        })
        this.getAttributesForConfigurationSetting();
      },
      error: (error) => { }
    });
  }

  getConfigurationSettings() {
    this.entityService.getEntityDetailsByView(AppSettings.ENTITY_CODE.CONFIG_SETTING, {}).subscribe({
      next: (response: FindByViewResponse) => {
        this.entityId = response.entityId;
        this.getEntityDetails();
      },
      error: (error) => {
        this.showForm = true;
      }
    })
  }

  getEntityDetails() {
    this.entityService.getEntity(this.entityId, AppSettings.ENTITY_CODE.CONFIG_SETTING, AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe({
      next: (response: EntityResponse) => {
        this.attributeValues = this.cs.getOrgAttributeValues(response);
        this.showForm = true;
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
      }
    })
  }

  onCancel() {
    this.form.resetForm();
    this.attributeValues = null;
    if (this.entityId) {
      this.showForm = false;
      this.getEntityDetails();
    }
  }

  private setAppSettings() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
  }

  onNextBtnClick() {
    const btn = document.getElementById('config');
    btn.click();
  }


  getAttributesForConfigurationSetting() {
    this.entityService.getAttributeDefinition(AppSettings.ENTITY_CODE.CONFIG_SETTING, AppSettings.VIEW_CODE.ADD_EDIT_VIEW)
      .subscribe((res: AttributeData) => {
        if (res) {
          this.attributeLabelsForConfigurationSettings = this.cs.getAttributeLabels(res);
          this.configurationSettingsAttributeData = this.cs.getOrganizedAttribute(res);
          this.configurationSettingsAttributeData.tabs.forEach(tab => {
            tab.groups.forEach(groups => {
              groups.fields.forEach(field => {
                if (this.checkAttributeCodeToSetPresetValues(field)) {
                  field.presetValues = [];
                  field.presetValues.push(...this.bookingStatuseStringList);
                }
              });
            });
          });
        }
      });
  }

  checkAttributeCodeToSetPresetValues(field) {
    return (field.attributeCode === AppSettings.ATTRIBUTE_CODE_FOR_CONFIG_SETTINGS.ADMIN_CANCEL_BOOKING_ALLOWED_STATUS ||
      field.attributeCode === AppSettings.ATTRIBUTE_CODE_FOR_CONFIG_SETTINGS.ADMIN_EDIT_BOOKING_ALLOWED_STATUS ||
      field.attributeCode === AppSettings.ATTRIBUTE_CODE_FOR_CONFIG_SETTINGS.PASSENGER_CANCEL_BOOKING_ALLOWED_STATUS ||
      field.attributeCode === AppSettings.ATTRIBUTE_CODE_FOR_CONFIG_SETTINGS.PASSENGER_EDIT_BOOKING_ALLOWED_STATUS)
  }

  setConfigurationSettingsEntityData() {
    this.configurationSettingsEntityData = {
      countryCode: this.country[0].countryCode,
      tenantCode: this.configService.getLoggedInTenantCode(),
      entityCode: AppSettings.ENTITY_CODE.CONFIG_SETTING
    }
  }

  saveConfigurationSettings(event) {
    const timeSettingsFormData = event;
    if (this.entityId) {
      this.configurationSettingEntityId = this.entityId;
      this.saveConfigurationSettingsData(this.configurationSettingsEntityData.entityCode, timeSettingsFormData);
    } else {
      this.entityService.createEntities(this.configurationSettingsEntityData.entityCode, this.configurationSettingsEntityData).subscribe({
        next: (res: CreateEntityResponse) => {
          this.configurationSettingEntityId = res.entityId;
          this.saveConfigurationSettingsData(this.configurationSettingsEntityData.entityCode, timeSettingsFormData);
        },
        error: (error) => {
          this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
        }
      });
    }

  }

  saveConfigurationSettingsData(entityCode, configurationSettingsFormData) {
    this.configurationSettingsData = {
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: AppSettings.ENTITY_CODE.CONFIG_SETTING,
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      data: []
    };
    for (const [key, value] of Object.entries(configurationSettingsFormData)) {
      const attributeValue = value;
      if (attributeValue) {
        this.configurationSettingsData.data.push({
          attributeCode: key,
          attributeValue
        });
      }

    }
    this.configurationSettingsData.data = this.cs.mapAttributeIds(this.configurationSettingsData.data, this.configurationSettingsAttributeData.tabs);
    this.entityService.saveAttributeData(entityCode, this.configurationSettingEntityId, this.configurationSettingsData, true).subscribe({
      next: () => {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('settings.lbl_save_configuration_settings') });
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
      }
    });
  }
}
