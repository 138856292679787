import { Injectable } from '@angular/core';

declare global {
  interface Window {
    initMap: () => void;
  }
}

@Injectable({
  providedIn: 'root'
})
export class GoogleMapsService {
  private scriptLoaded = false;
  public mapId: string = '';
  public apiKey: string = '';

  loadScript(apiKey: string, mapId: string): Promise<void> {
    this.apiKey = apiKey;
    this.mapId = mapId;
    if (this.scriptLoaded) {
      return Promise.resolve();
    }
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&map_ids=${mapId}&callback=initMap&loading=async&v=weekly`;
      script.async = true;
      script.defer = true;
      
      // Define the callback function in the window object
      window.initMap = () => {
        this.scriptLoaded = true;
        resolve();
      };
      
      script.onerror = () => reject('Failed to load Google Maps script');
      document.head.appendChild(script);
    });
  }
}
