@if (field.attributeCode === 'allow_booking_start_time' || field.attributeCode === 'allow_booking_end_time') {
    <form [formGroup]="formName">
        <p-calendar [showTime]="true" [iconDisplay]="'input'" [showIcon]="true" inputId="icondisplay" [timeOnly]="true" 
            [hourFormat]="hourFormat" [formControlName]="field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode" 
            class="w-full" [placeholder]="placeholder">
            <ng-template pTemplate="inputicon" let-clickCallBack="clickCallBack">
                <i class="pi pi-clock cursor-pointer" (click)="clickCallBack($event)"></i>
            </ng-template>
        </p-calendar>
    </form>
} @else {
    <form [formGroup]="formName">
        <p-calendar [showTime]="true" [iconDisplay]="'input'" [showIcon]="true" inputId="icondisplay" [timeOnly]="true" 
            [hourFormat]="hourFormat" [formControlName]="field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode" 
            class="w-full" [placeholder]="placeholder">
            <ng-template pTemplate="inputicon" let-clickCallBack="clickCallBack">
                <i class="pi pi-clock cursor-pointer" (click)="clickCallBack($event)"></i>
            </ng-template>
        </p-calendar>
    </form>
}