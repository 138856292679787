<div class="grid ml-2">
    <div class="col">
        <div class="inline-flex align-items-center">
            <label class="category-name">{{ incidentsComplaintsCategory }}</label>
        </div>
        <div class="other-details">
            @for (data of dataForStatus; track data; let i = $index) {
                <span>
                    <label class="default-text-black-color font-12 font-semibold"> {{ data.label }}</label>
                    <label class="default-text-black-color font-12">
                        @if(data.label === 'Incident & Complaint Date:') {
                            <span>
                                {{ data.text | date: dd_MMM_yyyy_FORMAT}}
                            </span>
                        }
                        @if(data.label !== 'Incident & Complaint Date:') {
                            <span>
                                {{ data.text }}
                            </span>
                        }
                    </label>
                </span>
                @if (i < dataForStatus.length - 1) {
                    <label class="separator">|</label>
                }
            }
        </div>
    </div>
</div>
