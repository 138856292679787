import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ConfigService } from 'app/modules/shared/services/config.service';
import * as _ from 'lodash';
import { MenuItem, MessageService } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { AppSettings } from '../../../shared/app.settings';
import { BasicTableComponent } from '../../../shared/components/basic-table/basic-table.component';
import { MiFormComponent } from '../../../shared/components/mi-form/mi-form.component';
import { Country } from '../../../shared/models/country';
import { Language } from '../../../shared/models/language';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { EntityService } from '../../../shared/services/entity.service';
import type { AttributeData, entityResponse } from '../../../vehicles/models/attribute.models';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-driver-document-entity',
  standalone: true,
  imports: [BreadcrumbModule, TabViewModule, MiFormComponent, DialogModule, BasicTableComponent, ButtonModule, TranslateModule, ToastModule],
  templateUrl: './driver-document-entity.component.html',
  styleUrl: './driver-document-entity.component.scss'
})
export class DriverDocumentEntityComponent {
  @ViewChild(MiFormComponent) miFormComponent: MiFormComponent;
  @Output() previousClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() nextClick: EventEmitter<void> = new EventEmitter<void>();
  @Input() dataToChild;
  @Input() tab;
  @Input() activeIndex;
  @Input() attributeLabels = {};
  @Input() moduleName: string;
  @Input() submitBtnText: string;
  @Input() previousBtnText: string;
  @Input() attributeValues = [];
  @Input() attributeData: any;
  @Input() driverId;
  @Input() saveBtnId: string;
  routePath: MenuItem[] = [];
  data: AttributeData;
  driverAttributeData: any;
  country: Country;
  language: Language;
  cols: any = [];
  driverAttributeLength: number;
  driverDocumentId: string;
  driverDocument: any[] = [];
  driverDocumentFields: number = 6;
  start: number = 0;
  end: number = 3;
  prefixForOneToManyRelatedFields: any = '_';
  driverDocumentEntityIdList: any[] = [];
  attributeIdForDriverDocument;
  edit: boolean = false;
  driverData: any;
  driverDetails: any;
  isReady: boolean = false;
  driverDocumentPayload: any;
  entityId: string;
  imgFileId;
  driverDocumentToShow;
  documentTypeValue;
  uploadDocumentValue;
  relationshipDataForDriverDocument: any[] = [];
  removedDriverDocumentId;
  removedIndex;
  attributeIdForDriverEntityId: any;
  driverDocumentArray: any[] = [];
  createEntityObsArray: any[] = [];
  saveAttributeObsArray: any[] = [];

  constructor(private driverService: EntityService,
    private cs: CommonBindingDataService,
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute,
    public appSettings: AppSettings,
    private configService: ConfigService,
    private cd: ChangeDetectorRef) { }

  ngOnInit() {
    this.setLabels();
    this.setDriverId();
    this.setCountryAndLanguage();
    this.loadDriverData();
    this.setRoutePath();
  }

  setLabels() {
    this.submitBtnText = this.cs.getLabel('lbl_save_driver');
    this.previousBtnText = this.cs.getLabel('lbl_previous');
  }

  setDriverId() {
    this.driverId = this.route.snapshot.paramMap.get('id');
  }

  setCountryAndLanguage() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
  }

  private loadDriverData() {
    if (this.driverId) {
      this.driverService
        .getEntity(this.driverId, AppSettings.ENTITY_CODE.DRIVER, AppSettings.VIEW_CODE.ADD_EDIT_VIEW)
        .subscribe((res) => {
          if (res) {
            const data = res;
            this.driverDetails = res;
            this.attributeValues = this.cs.getOrgAttributeValues(data);
            this.parseAttributeValues();
            this.getDriverDocumentData();
          }
          this.getAttributes();
        });
    } else {
      this.getAttributes();
    }
  }

  getDriverDocumentData() {
    if (this.driverDetails.relatedData.length > 0) {
      const documentList = this.driverDetails.relatedData.filter(ele => ele.entityCode === AppSettings.ENTITY_CODE.DRIVER_DOCUMENT);
      if (documentList && documentList.length > 0) {
        documentList.forEach((data, index) => {
          this.setAttributeValuesForDriverDocumentEditMode(data, index);
        });
      }

    }
  }

  getAttributes() {
    this.attributeLabels = this.cs.getAttributeLabels(this.dataToChild);
    this.driverAttributeData = this.cs.getOrganizedAttribute(this.dataToChild);
    this.driverAttributeLength = this.driverAttributeData.tabs.length;
    this.attributeIdForDriverDocument = this.getAttributeIdForGroupCode('driver_document');
    this.isReady = true;
    this.attributeIdForDriverEntityId = this.getAttributeId('driver_entity_id');
  }

  getAttributeId(attributeCode: string): number | undefined {
    const driverDocument = _.find(this.driverAttributeData.relatedAttributes, { entityCode: 'driver_document' });
    for (const tab of driverDocument.tabs) {
      for (const group of tab.groups) {
        const attribute = group.fields.find(field => field.attributeCode === attributeCode);
        if (attribute) {
          return attribute.attributeId;
        }
      }
    }
    return undefined;
  }

  getAttributeIdForGroupCode(groupCode: string): string | undefined {
    const group = _.flatMap(this.driverAttributeData.tabs, 'groups').find(group => group.code === groupCode);
    return group ? group.relation.ownerAttributeId : undefined;
  }

  setRoutePath() {
    this.routePath = [
      {
        label: this.cs.getLabel('lbl_add_new_driver'),
        routerLink: '../list',
        icon: 'pi pi-arrow-left',
        iconStyle: { 'font-weight': 'bold', 'margin-right': '10px' }
      },
      {
        label: this.driverId ? this.cs.getLabel('lbl_edit_driver') : this.cs.getLabel('driver.add'),
        styleClass: 'breadcrumb-child forward-slash breadcrumb-text',
        style: { 'display': 'flex', 'top': '2px', 'position': 'relative' }
      }
    ];
  }

  private parseAttributeValues() {
    const dateAttributes = [
      AppSettings.DATE_ATTRIBUTE_IDS.DRIVER_DOCUMENT_ISSUED_DATE,
      AppSettings.DATE_ATTRIBUTE_IDS.DRIVER_DOCUMENT_EXPIRY_DATE
    ];
    dateAttributes.forEach(attr => {
      this.attributeValues[attr] = this.attributeValues[attr] ? new Date(this.attributeValues[attr]) : null;
    });

    const driverDocumentIdArray = this.driverDetails?.attributeCodeValueDtoList.find(ele => ele.attributeCode === AppSettings.GROUP_CODE.DOCUMENT_SECTION);
    this.driverDocumentEntityIdList = (driverDocumentIdArray && driverDocumentIdArray.attributeValue.length > 0) ? driverDocumentIdArray.attributeValue : [];
  }

  setAttributeValuesForDriverDocumentEditMode(data, index) {
     for (const labelsObj of data.attributeCodeValueDtoList) {
      this.attributeValues[index + '_' + labelsObj.attributeCode] =
      (labelsObj.attributeCode === AppSettings.DATE_ATTRIBUTE_IDS.DRIVER_DOCUMENT_ISSUED_DATE ||
        labelsObj.attributeCode === AppSettings.DATE_ATTRIBUTE_IDS.DRIVER_DOCUMENT_EXPIRY_DATE) ? new Date(labelsObj.attributeValue) : labelsObj.attributeValue;
    }
  }

  getAttributeValueByCode(attributes: any[], code: string): any {
    const attribute = attributes.find(attr => attr.attributeCode === code);
    return attribute ? attribute : null;
  }

  onRemoveDriverDocumentEntity(event) {
    this.removedIndex = event[1];
    this.removedDriverDocumentId = typeof event[0] === 'string' ? event[0] : String(event[0]);
    this.driverDocumentEntityIdList = this.driverDocumentEntityIdList.filter(id => id !== this.removedDriverDocumentId);
    this.saveDriverDocumentData();
  }

  saveDocumentsData(event) {
    const driverFormData = event;
    this.driverData = {
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: AppSettings.ENTITY_CODE.DRIVER,
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      data: [],
    };
    const removedDriverFormData = this.cs.removeDocumentByIndex(driverFormData, this.removedIndex);
    if (removedDriverFormData && typeof removedDriverFormData === 'object' && !Array.isArray(removedDriverFormData)) {
      for (const [key, value] of Object.entries(removedDriverFormData)) {
        if (!key.includes(AppSettings.FIELDS.DRIVER_DOCUMENT_URL)) {
          const attributeValue = value;
          const arr = key.split('_');
          if (parseInt(arr[0]) >= 0) {
            this.setAttributeValuesForDriverDocument(key, attributeValue);
          } else {
            this.setAttributeValuesForOtherDetails(key, attributeValue);
          }
        }
      }
    } else {
      console.log('removedDriverFormData is not a valid object or it is an array.');
    }
    this.driverDocumentArray.forEach((drivingRecord, index) => {
      this.driverDocumentArray[index] = this.cs.mapAttributeIdsForRelatedData(drivingRecord, this.driverAttributeData.tabs);
    });
    this.saveDriverDocumentData();
  }

  saveDriverDocumentData() {
    this.relationshipDataForDriverDocument = [];
    const newDriverRecordList: any[] = [];
    const entitiesData = {
      countryCode: this.country[0].countryCode,
      tenantCode: this.configService.getForTenantCode(),
      entityCode: AppSettings.ENTITY_CODE.DRIVER_DOCUMENT,
    }

    this.createEntityObsArray = [];
    this.saveAttributeObsArray = [];
    this.driverDocumentArray.forEach((record, index) => {
      const driverRecordEntity = {
        forTenantCode: this.configService.getForTenantCode(),
        entityCode: AppSettings.ENTITY_CODE.DRIVER_DOCUMENT,
        countryCode: this.country[0].countryCode,
        languageCode: this.language[0].langCode,
        data: record
      };
 

      if (this.driverDocumentEntityIdList[index]) {
        this.saveAttributeObsArray.push(this.driverService.saveAttributeData(driverRecordEntity.entityCode, this.driverDocumentEntityIdList[index], driverRecordEntity));
        this.relationshipDataForDriverDocument.push(
          {
            entityRelationshipConfigId:  this.driverAttributeData.tabs[2].groups.find(ele => ele.code === 'driver_document').relation.entityRelationshipConfigId,
            otherEntityId: this.driverDocumentEntityIdList[index]
          }
        );
      } else {
        newDriverRecordList.push(driverRecordEntity);
        this.createEntityObsArray.push(this.driverService.createEntities(entitiesData.entityCode, entitiesData));
      }
      if (index === this.driverDocumentArray.length - 1) {
        this.createAndSaveDriverDocument(entitiesData, newDriverRecordList);
      }
    });
  }

  createAndSaveDriverDocument(entitiesData, newDriverRecordList) {
    if (this.createEntityObsArray.length > 0) {
      forkJoin(this.createEntityObsArray).subscribe(result => {
        if (result && result.length > 0) {
          result.forEach((createEntityResponse: any, index) => {
            this.driverDocumentEntityIdList.push(createEntityResponse.entityId);
            this.saveAttributeObsArray.push(this.driverService.saveAttributeData(entitiesData.entityCode, createEntityResponse.entityId, newDriverRecordList[index]));
            this.relationshipDataForDriverDocument.push(
              {
                entityRelationshipConfigId:  this.driverAttributeData.tabs[2].groups.find(ele => ele.code === 'driver_document').relation.entityRelationshipConfigId,
                otherEntityId: createEntityResponse.entityId
              }
            );
          });
          this.saveDriverDocumentAttributes();
        }
 
      })
    } else {
      this.saveDriverDocumentAttributes();
    }
  }

  saveDriverDocumentAttributes() {
    forkJoin(this.saveAttributeObsArray).subscribe(data => {
      this.driverData.data.push({
        attributeId: this.attributeIdForDriverDocument,
        attributeValue: this.driverDocumentEntityIdList
      });
      this.saveDriverDocumentDataToDb();
    });
  }

  private setAttributeValuesForDriverDocument(key, attributeValue) {
    const arr = key.split('_');
    if (!Array.isArray(this.driverDocumentArray[arr[0]])) {
      this.driverDocumentArray[arr[0]] = [];
    }
    const attributeCode = key.substring(key.indexOf('_') + 1);
    if (attributeValue) {
      const isExpiryDate = attributeCode === AppSettings.DATE_ATTRIBUTE_IDS.DRIVER_DOCUMENT_EXPIRY_DATE;
      const isIssuedDate = attributeCode === AppSettings.DATE_ATTRIBUTE_IDS.DRIVER_DOCUMENT_ISSUED_DATE;
      const value = isExpiryDate || isIssuedDate ? new Date(attributeValue).getTime() : attributeValue;
      this.driverDocumentArray[arr[0]].push({ attributeCode, attributeValue: value });
    }
  }

  private setAttributeValuesForOtherDetails(key, attributeValue) {
    if (attributeValue) {
      this.driverData?.data?.push({
        'attributeCode': key,
        attributeValue
      });
    }
  }

  saveDriverDocumentDataToDb() {
    const entitiesData = {
      countryCode: this.country[0].countryCode,
      tenantCode: this.configService.getLoggedInTenantCode(),
      entityCode: AppSettings.ENTITY_CODE.DRIVER
    }
    this.driverData.relationshipData = this.relationshipDataForDriverDocument;
    if (this.driverId) {
      this.saveAttributeData(entitiesData.entityCode);
    } else {
      this.createEntityAndUpdateAttributeData(entitiesData);
    }
  }

  saveAttributeData(entityCode) {
    this.driverService.saveAttributeData(entityCode, this.driverId, this.driverData).subscribe((res: any) => {
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('driver.message.add_driver') });
      this.router.navigate(['app/drivers/list']);
    });
  }

  createEntityAndUpdateAttributeData(entitiesData) {
    this.driverService.createEntities(entitiesData.entityCode, entitiesData).subscribe((res: entityResponse) => {
      this.driverId = res.entityId;
      this.driverService.saveAttributeData(entitiesData.entityCode, this.driverId, this.driverData).subscribe((res: any) => {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('driver.message.add_driver') });
        this.router.navigate(['app/drivers/list']);
      });
    });
  }

  saveDriverData(driverData) {
    const { entityRelationshipConfigId, otherEntityId } = this.driverAttributeData?.tabs[2]?.groups.find(ele => ele.code === 'driver_document')?.relation || {};
    this.driverData.relationshipData = [];
    for (let index = 0; index < this.driverDocumentEntityIdList.length; index++) {
      this.driverData.relationshipData.push({ entityRelationshipConfigId, otherEntityId: this.driverDocumentEntityIdList[index] });
    }
    this.driverService.saveAttributeData(AppSettings.ENTITY_CODE.DRIVER, this.driverId, driverData).subscribe(() => {
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Successful', detail: this.cs.getLabel('driver.message.add_driver'), });
      this.router.navigate(['app/drivers/list']);
    });
  }

  onPreviousClick(event) {
    this.previousClick.emit();
  }

  onNextClick() {
    this.nextClick.emit();
  }

  getLastIndex(): number {
    return this.driverAttributeLength - 1;
  }

  isLastIndex(): boolean {
    return this.activeIndex === this.getLastIndex();
  }

  onCancel() {
    this.miFormComponent.resetForm();
    this.router.navigate(['app/drivers/list']);
  }
}
