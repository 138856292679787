
<div id="shift-cells" class="scroll">
    <div class="flex">
        <div class="driver-list-column pr-0">
            @if (groupList.length === 0) {
                <div class="first-column first-row first-cell pl-3 pt-2 pb-2 flex align-items-center">
                    <div class="inline-flex">
                        <p-dropdown [options]="listTypeCopy"  [(ngModel)]="selectedListType" (onChange)="onListTypeChange($event)" optionLabel="name" optionValue="value">
                            <ng-template  pTemplate="dropdownicon">
                                <i class="mi-lg caret-down-2"></i>
                            </ng-template>
                        </p-dropdown>
                        <div class="filter-az" (click)="sortDrivers()">
                            <span translate>shift.az</span>
                            <i class="mi-lg mi-filter_2"></i>
                        </div>
                    </div>
                </div>
            }
    
            @for(group of groupList; track group; let j=$index) {
                @if (j === 0) {
                    <div class="first-column first-row first-cell pl-3 pt-2 pb-2 flex align-items-center">
                        <div class="inline-flex">
                            <p-dropdown [options]="listTypeCopy" [(ngModel)]="selectedListType" (onChange)="onListTypeChange($event)" optionLabel="name" optionValue="value">
                                <ng-template  pTemplate="dropdownicon">
                                    <i class="mi-lg caret-down-2"></i>
                                </ng-template>
                            </p-dropdown>
                            <div class="filter-az" (click)="sortDrivers()">
                                @if (isAscendingData) {
                                    <span translate>shift.za</span>
                                } @else {
                                    <span translate>shift.az</span>
                                }

                                <i class="mi-lg mi-filter_2"></i>
                            </div>
                        </div>
                    </div>
                }
    
                <div class="col-12 flex td">
                    <div class="column-cells pl-2 flex align-items-center">
                        <div class="inline-flex">
                           <div class="block w-13rem ml-2">
    
                            <div class="flex justify-content-between flex-wrap">
                                <div class="flex align-items-center justify-content-center ">
                                    <span class="list-group-name align-content-center ellipse"  (mouseover)="op.toggle($event); hoveredGroup = group" (mouseout)="op.toggle($event)"  >{{group.driverGroup}}</span>
                                </div>
                            </div>
                            <div class="inline-flex align-items-center mt-1">
                                <i class="mi-lg mi-endofshift"></i>
                                <span class="total-week-hours ml-1">{{group.workingHours / 60}} hrs</span>
                            </div>
                           </div>
                        </div>
    
                    </div>
                </div>
            }
        </div>
    
        <div class=" toolbar-scroll pl-0">
            <!-- day row -->
            <div >
                <ul>
                    @if (datePipe.transform(selectedDayDate, 'dd MM yyyy') === datePipe.transform(currentDate, 'dd MM yyyy')) {
                        <span class="current-time" [style.margin-left.px]="((((currentDate | date: 'H') % 24) * 52) + 31 + (+(currentDate | date: 'm')))">
                            <span class="circle"></span>
                        </span>
                    }
    
                    @for (hourGroup of hourList; track hourGroup; let i = $index) {
                        <li class="first-row">
                                <div class="hour-row align-items-center flex pt-2 pb-2">
                                    @for (hour of hourGroup; track hour; let h = $index) {
                                            <div class="hour font-bold text-center"> {{ hour}} </div>
    
                                    }
                                </div>
                        </li>
                    }
                </ul>
            </div>
    
    
    
    
            @for(group of groupList; track group; let m=$index) {
                <ul>
                    @for (hourGroup of hourList; track hourGroup; let n = $index) {
                        <li>
                                <div class="shift-cells flex align-items-center align-items-center">
                                    @for (hour of hourGroup; track hour; let k = $index) {
                                        @for (shift of group.shift; track shift; let l = $index) {
                                            @if (hourFormat === '12') {
                                                @if (shift?.startTimeHour.toUpperCase().trim() === (hour + 'm').toUpperCase().trim()) { 
                                                    @if ((shift.startDateStr | date: 'dd MM yyyy') === (selectedDayTimeStamp | date: 'dd MM yyyy')) {
                                                        @if (shift.isLeave) {
                                                            <div class="block shift-block"  [style.width.px]="((+(52/60)) + (+ (24 * 60)) + (+13))">
                                                                <div class="day-off ml-2 align-content-center">
                                                                    <div class="ml-2 uppercase" translate>shift.dayOff </div>
                                                                    <div class="ml-2 font-semibold"> {{shift.leaveReason}}</div>
                                                                </div>
                                                            </div>
                                                        } @else if (shift.isShiftPublish) {
                                                            <div class="block shift-block">
                                                                @if (shift.endTimeStr) {
                                                                    <div class="shift-time flex align-items-center pointer"  [style.backgroundColor]="group.color" [style.margin-left.px]="((+(k * 52)) + (+(shift.startTime)) + (+13))" [style.width.px]="(shift.timeDifferenceInMinutes * (+(52/60)))" (click)="shiftOverlay.toggle($event); hoveredPublishedUnPublishedShift = shift; publishedUnpublishedShiftGroup = group">
                                                                        <div class="ml-2 uppercase"> {{ shift.shiftName }}</div>
                                                                        <div class="ml-2 font-semibold"> <span>{{shift.startTimeStr | date : hourFormat === '12' ? 'hh:mm a' : 'HH:mm'}} -  {{shift.endTimeStr | date : hourFormat === '12' ? 'hh:mm a' : 'HH:mm'}}</span> </div>
                                                                    </div>
                                                                } @else {
                                                                    <div class="shift-time ml-2 align-content-center pointer" [style.backgroundColor]="group.color" (click)="shiftOverlay.toggle($event); hoveredPublishedUnPublishedShift = shift; publishedUnpublishedShiftGroup = group" [class]="shift.dateDifference > 1 ? 'line-img' : ''" [style.width.px]="((52/60) * 24 * 60)" >
                                                                        <div class="ml-2 uppercase"> {{ shift.shiftName }}</div>
                                                                        <div class="ml-2 font-semibold"> <span>{{shift.startTimeStr | date : hourFormat === '12' ? 'hh:mm a' : 'HH:mm'}} -  {{shift.endTimeStr | date : hourFormat === '12' ? 'hh:mm a' : 'HH:mm'}}</span> <span class="dot ml-2"></span> <span class="ml-2">{{shift.startTimeStr | date : hourFormat === '12' ? 'hh:mm a' : 'HH:mm'}} -  <span translate>shift.noEndTime</span></span> </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        } @else if (!shift.isShiftPublish) {
                                                            @if (shift.endTimeStr) {
                                                                <div class="block shift-block" (click)="shiftOverlay.toggle($event); hoveredPublishedUnPublishedShift = shift; publishedUnpublishedShiftGroup = group">
                                                                    <div class="unpublish-shift flex align-items-start pointer flex-column"  [style.backgroundColor]="group.color" [style.margin-left.px]="((+(k * 52)) + (+(shift.startTime)) + (+13))" [style.width.px]="(shift.timeDifferenceInMinutes * (+(52/60)))">
                                                                        <div class="ml-2 uppercase shift-name"> {{ shift.shiftName }}</div>
                                                                        <div class="ml-2 font-semibold shift-start-time"> {{shift.startTimeStr | date : hourFormat === '12' ? 'hh:mm a' : 'HH:mm'}} -  {{shift.endTimeStr | date : hourFormat === '12' ? 'hh:mm a' : 'HH:mm'}}</div>
                                                                    </div>
                                                                </div>
                                                            } @else {
                                                                <div class="shift-time ml-2 align-content-center pointer"  [style.backgroundColor]="group.color" (click)="shiftOverlay.toggle($event); hoveredPublishedUnPublishedShift = shift; publishedUnpublishedShiftGroup = group" [class]="shift.dateDifference > 1 ? 'line-img' : ''" [style.width.px]="((52/60) * 24 * 60)" >
                                                                    <div class="ml-2 uppercase"> {{ shift.shiftName }}</div>
                                                                    <div class="ml-2 font-semibold"> <span>{{shift.startTimeStr | date : hourFormat === '12' ? 'hh:mm a' : 'HH:mm'}} -  {{shift.endTimeStr | date : hourFormat === '12' ? 'hh:mm a' : 'HH:mm'}}</span> <span class="dot ml-2"></span> <span class="ml-2">{{shift.startTime  | date : hourFormat === '12' ? 'hh:mm a' : 'HH:mm'}} -  <span translate>shift.noEndTime</span></span> </div>
                                                                </div>
                                                            }
                                                        } @else {
                                                            <div class="shift-time flex align-items-center pointer" [style.margin-left.px]="((+(k * 52)) + (+(shift.startTime)) + (+13))" [style.width.px]="(shift.timeDifferenceInMinutes * (+(52/60))) + (+9)" (click)="shiftOverlay.toggle($event); hoveredPublishedUnPublishedShift = shift; publishedUnpublishedShiftGroup = group">
                                                                <div class="ml-2 uppercase"> {{ shift.shiftName }}</div>
                                                                <div class="ml-2 font-semibold"> <span>{{shift.startTimeStr | date : hourFormat === '12' ? 'hh:mm a' : 'HH:mm'}} -  {{shift.endTimeStr | date : hourFormat === '12' ? 'hh:mm a' : 'HH:mm'}}</span> </div>
                                                            </div>
                                                        }
        
        
                                                    }
                                                }
                                            } @else {
                                                @if (shift?.startTimeHour.toString() === hour.toString()) { 
                                                    @if ((shift.startDateStr | date: 'dd MM yyyy') === (selectedDayTimeStamp | date: 'dd MM yyyy')) {
                                                        @if (shift.isLeave) {
                                                            <div class="block shift-block"  [style.width.px]="((+(52/60)) + (+ (24 * 60)) + (+13))">
                                                                <div class="day-off ml-2 align-content-center">
                                                                    <div class="ml-2 uppercase" translate>shift.dayOff </div>
                                                                    <div class="ml-2 font-semibold"> {{shift.leaveReason}}</div>
                                                                </div>
                                                            </div>
                                                        } @else if (shift.isShiftPublish) {
                                                            <div class="block shift-block">
                                                                @if (shift.endTimeStr) {
                                                                    <div class="shift-time flex align-items-center pointer"  [style.backgroundColor]="group.color" [style.margin-left.px]="((+(k * 52)) + (+(shift.startTime)) + (+13))" [style.width.px]="(shift.timeDifferenceInMinutes * (+(52/60)))" (click)="shiftOverlay.toggle($event); hoveredPublishedUnPublishedShift = shift; publishedUnpublishedShiftGroup = group">
                                                                        <div class="ml-2 uppercase"> {{ shift.shiftName }}</div>
                                                                        <div class="ml-2 font-semibold"> <span>{{shift.startTimeStr | date : hourFormat === '12' ? 'hh:mm a' : 'HH:mm'}} -  {{shift.endTimeStr | date : hourFormat === '12' ? 'hh:mm a' : 'HH:mm'}}</span> </div>
                                                                    </div>
                                                                } @else {
                                                                    <div class="shift-time ml-2 align-content-center pointer" [style.backgroundColor]="group.color" (click)="shiftOverlay.toggle($event); hoveredPublishedUnPublishedShift = shift; publishedUnpublishedShiftGroup = group" [class]="shift.dateDifference > 1 ? 'line-img' : ''" [style.width.px]="((52/60) * 24 * 60)" >
                                                                        <div class="ml-2 uppercase"> {{ shift.shiftName }}</div>
                                                                        <div class="ml-2 font-semibold"> <span>{{shift.startTimeStr | date : hourFormat === '12' ? 'hh:mm a' : 'HH:mm'}} -  {{shift.endTimeStr | date : hourFormat === '12' ? 'hh:mm a' : 'HH:mm'}}</span> <span class="dot ml-2"></span> <span class="ml-2">{{shift.startTimeStr | date : hourFormat === '12' ? 'hh:mm a' : 'HH:mm'}} -  <span translate>shift.noEndTime</span></span> </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        } @else if (!shift.isShiftPublish) {
                                                            @if (shift.endTimeStr) {
                                                                <div class="block shift-block" (click)="shiftOverlay.toggle($event); hoveredPublishedUnPublishedShift = shift; publishedUnpublishedShiftGroup = group">
                                                                    <div class="unpublish-shift flex align-items-start pointer flex-column"  [style.backgroundColor]="group.color" [style.margin-left.px]="((+(k * 52)) + (+(shift.startTime)) + (+13))" [style.width.px]="(shift.timeDifferenceInMinutes * (+(52/60)))">
                                                                        <div class="ml-2 uppercase shift-name"> {{ shift.shiftName }}</div>
                                                                        <div class="ml-2 font-semibold shift-start-time"> {{shift.startTimeStr | date : hourFormat === '12' ? 'hh:mm a' : 'HH:mm'}} -  {{shift.endTimeStr | date : hourFormat === '12' ? 'hh:mm a' : 'HH:mm'}}</div>
                                                                    </div>
                                                                </div>
                                                            } @else {
                                                                <div class="shift-time ml-2 align-content-center pointer"  [style.backgroundColor]="group.color" (click)="shiftOverlay.toggle($event); hoveredPublishedUnPublishedShift = shift; publishedUnpublishedShiftGroup = group" [class]="shift.dateDifference > 1 ? 'line-img' : ''" [style.width.px]="((52/60) * 24 * 60)" >
                                                                    <div class="ml-2 uppercase"> {{ shift.shiftName }}</div>
                                                                    <div class="ml-2 font-semibold"> <span>{{shift.startTimeStr | date : hourFormat === '12' ? 'hh:mm a' : 'HH:mm'}} -  {{shift.endTimeStr | date : hourFormat === '12' ? 'hh:mm a' : 'HH:mm'}}</span> <span class="dot ml-2"></span> <span class="ml-2">{{shift.startTime | date : hourFormat === '12' ? 'hh:mm a' : 'HH:mm'}} -  <span translate>shift.noEndTime</span></span> </div>
                                                                </div>
                                                            }
                                                        } @else {
                                                            <div class="shift-time flex align-items-center pointer" [style.margin-left.px]="((+(k * 52)) + (+(shift.startTime)) + (+13))" [style.width.px]="(shift.timeDifferenceInMinutes * (+(52/60))) + (+9)" (click)="shiftOverlay.toggle($event); hoveredPublishedUnPublishedShift = shift; publishedUnpublishedShiftGroup = group">
                                                                <div class="ml-2 uppercase"> {{ shift.shiftName }}</div>
                                                                <div class="ml-2 font-semibold"> <span>{{shift.startTimeStr | date : hourFormat === '12' ? 'hh:mm a' : 'HH:mm' }} -  {{shift.endTimeStr  | date : hourFormat === '12' ? 'hh:mm a' : 'HH:mm' }}</span> </div>
                                                            </div>
                                                        }
        
        
                                                    }
                                                }
                                            }

                                        }
                                    }
                                </div>
                        </li>
                    }
                </ul>
            }
    
        </div>
    </div>
    @if (groupList.length === 0) {
        <app-no-result-found></app-no-result-found>
    }
    <!-- calendar code end -->
</div>



<!-- Group overlay panels -->
<p-overlayPanel #op showCloseIcon="true">
    <ng-template pTemplate="content">
        <div class="grid">
            <div class="col-1">
                <span class="group-color" [style.background-color]="hoveredGroup.color"></span>
            </div>
            <div class="col-11 pl-0">
                <div class="flex">
                    <label class="driver-overlay-name mr-2">{{hoveredGroup.driverGroup}}</label> <label class="mr-2">(Group)</label>
                </div>
            </div>
        </div>
        <div class="grid">
            <div class="col-5 label-text" translate>shift.status</div>
            <div class="col-7 label-value capitalize">
                <span [class]="hoveredGroup.status.toLowerCase()"></span>
                <span [class]="hoveredGroup.status.toLowerCase"></span> {{hoveredGroup.status}}
            </div>
        </div>
        <hr class="hr">
        <div class="grid">
            <div class="col-5 label-text" translate>shift.groupMembers</div>
            <div class="col-7 label-value">{{hoveredGroup.groupMembers}}</div>
        </div>
    </ng-template>
</p-overlayPanel>


<!-- unpublish, publish shift overlay panel -->
<p-overlayPanel #shiftOverlay showCloseIcon="true">
    <ng-template pTemplate="content">
        <div class="grid">
            <div class="col-1">
                <span class="group-color" [style.background-color]="publishedUnpublishedShiftGroup.color"></span>
            </div>
            <div class="col-11 pl-0">
                <div class="flex">
                    <label class="driver-overlay-name mr-2">{{publishedUnpublishedShiftGroup.driverGroup}}</label> <label class="mr-2">(Group)</label>
                    <span class="group">
                        <i class="mi-lg mi-scheduledbreak"></i>
                        <span class="ml-1"> {{publishedUnpublishedShiftGroup?.shift[0]?.shiftName}}</span>
                    </span>
                </div>
            </div>

        </div>
        @if (hoveredPublishedUnPublishedShift.endTimeStr) {
            <div class="grid">
                <div class="col-6 label-text">
                    <i class="mi-lg calendar_calendar"></i>
                    <span class="ml-1">  {{hoveredPublishedUnPublishedShift.startDateStr  | date: 'dd MMM'}} </span>
                </div>
                <div class="col-6 label-value">
                    <i class="mi-lg mi-endofshift"></i>
                    <span class="ml-1">  {{hoveredPublishedUnPublishedShift.startTimeStr  | date : hourFormat === '12' ? 'hh:mm a' : 'HH:mm' }} -  {{hoveredPublishedUnPublishedShift.endTimeStr | date : hourFormat === '12' ? 'hh:mm a' : 'HH:mm' }}</span>

                </div>
            </div>
        } @else {
            <div class="grid">
                <div class="col-6 label-text">
                    <i class="mi-lg calendar_calendar"></i>
                    <span class="ml-1">  {{hoveredPublishedUnPublishedShift.startDateStr  | date: 'd MMM'}} -  {{hoveredPublishedUnPublishedShift.endTimeStr}} </span>
                </div>
                <div class="col-6 label-value">
                    <i class="mi-lg mi-endofshift"></i>
                    <span class="ml-1">  {{hoveredPublishedUnPublishedShift.startTimeStr | date : hourFormat === '12' ? 'hh:mm a' : 'HH:mm' }} -  <span translate>shift.noEndTime</span></span>
                </div>
            </div>
        }

        <hr class="hr">
        <div class="grid">
            <div class="col-6" translate>
                <i class="mi-lg mi-breaks"></i>
                <label class="label-text ml-2" translate>shift.breaks</label>
            </div>
            <div class="col-6 label-value">
                @for (break of hoveredPublishedUnPublishedShift.shiftBreakList; track break; let n = $index) {
                    <div>
                        <i class="mi-lg mi-endofshift"></i>
                        <span class="ml-1">  {{break.breakStartTime | date : hourFormat === '12' ? 'hh:mm a' : 'HH:mm' }} -  {{break.breakEndTime | date : hourFormat === '12' ? 'hh:mm a' : 'HH:mm' }}</span>
                    </div>
                }
            </div>
        </div>
        <hr class="hr">
        <div class="grid">
            <div class="col-6 label-text">
                <label translate>shift.driverAssigned</label>
            </div>
            <div class="col-6 label-value">
             <div>{{hoveredPublishedUnPublishedShift.assignCount}}</div>
            </div>
        </div>
    </ng-template>
</p-overlayPanel>
