import { Injectable, signal } from '@angular/core';
import { Country } from '../../shared/models/country';
import { ConfigService } from '../../shared/services/config.service';
import { RestApiService } from '../../shared/services/rest-api.service';
import { StorageService } from 'app/modules/shared/services/storage.service';
import { BehaviorSubject } from 'rxjs';
import { CreateEntityPayload } from 'app/modules/shared/models/entity.models';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  country: Country;
  private selectedEventSignal = signal<any>({});
  public eventAdded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private restApiService: RestApiService, private confService: ConfigService,
    private storageService: StorageService
  ) { }

  get getSelectedEventData() {
    return this.selectedEventSignal();
  }

  setEventAdded(value: boolean) {
    this.eventAdded$.next(value);
  }


  setSelectedEventData(selectedEvent: any): void {
    // this.selectedEventSignal.set(selectedEvent);
    this.storageService.setItem('eventCodeData', selectedEvent);
  }

  getSelectedEventcode() {
    const eventData = JSON.parse(localStorage.getItem('eventCodeData'));
    // const eventData = this.selectedEventSignal();
    return eventData ? eventData : null;
  }

  setSelectedEventId(eventId: any): void {
    this.storageService.setItem('selectedEventId', eventId);
  }

  getSelectedEventId() {
    const eventId = JSON.parse(localStorage.getItem('selectedEventId'));
    return eventId ? eventId : null;
  }

  setSelectedEventTimeZoneId(eventTimeZoneId: any): void {
    this.storageService.setItem('selectedEventTimeZoneId', eventTimeZoneId);
  }

  getSelectedEventTimeZoneId(): void {
    const timezoneId = JSON.parse(localStorage.getItem('selectedEventTimeZoneId'));
    return timezoneId ? timezoneId : null;
  }

  getEventList(requestBody) {
    return this.restApiService.post('Event LIST', `${this.confService.getLoggedInTenantCode()}/api/v1/events/search`, requestBody, 'page-center');
  }

  createEvent(requestBody) {
    return this.restApiService.post('create event', `${this.confService.getLoggedInTenantCode()}/api/v1/events`, requestBody, 'page-center');
  }

  updateEvent(requestBody, eventId) {
    return this.restApiService.put('update event', `${this.confService.getLoggedInTenantCode()}/api/v1/events/${eventId}`, requestBody, 'page-center');
  }

  getEvent(eventId) {
    return this.restApiService.get('get event', `${this.confService.getLoggedInTenantCode()}/api/v1/events/${eventId}?forTenantCode=${this.confService.getForTenantCode()}`, 'page-center');
  }

  updateEventStatus(eventId: string, data: any) {
    return this.restApiService.put('UPDATE EVENT STATUS', `${this.confService.getLoggedInTenantCode()}/api/v1/events/${eventId}/action`, data, 'page-center');
  }

  getTransportationTypes(eventId) {
    return this.restApiService.get('get transportation types', `${this.confService.getLoggedInTenantCode()}/api/v1/event-transportation-types/${eventId}?forTenantCode=${this.confService.getForTenantCode()}`, 'page-center');
  }

  getEventSettingsDetailsByView(entityCode: string, eventCode: any, data: {}) {
    return this.restApiService.post('get entity details by view', `${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${entityCode}/entities/find-by-view?forTenantCode=${this.confService.getForTenantCode()}&eventCode=${eventCode}`, data, 'page-center');
  }

  getEventSettingsEntity(entityId: string, entityCode: string, viewCode: string, eventCode: any) {
    return this.restApiService.get('GET ENTITY DETAILS BY ENTITYID', `${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${entityCode}/entities/${entityId}?forTenantCode=${this.confService.getForTenantCode()}&viewCode=${viewCode}&eventCode=${eventCode}`, 'page-center');
  }

  createEntityForTimeSettings(entityCode: string, data: CreateEntityPayload) {
    return this.restApiService.post('CREATE NEW ENTITY', `${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${entityCode}/entities`, data, 'page-center');
  }

  saveTimeSettingsForEvent(entityCode: string, entityId: string, data: {}) {
    return this.restApiService.post('SAVE ATTRIBUTE DEFINITION', `${this.confService.getLoggedInTenantCode()}/api/v1/entity-conf/${entityCode}/entities/${entityId}/attribute-data`, data, 'page-center');
  }

  saveRegionalSettings(eventId: string, data: {}) {
    return this.restApiService.put('SAVE ATTRIBUTE DEFINITION', `${this.confService.getLoggedInTenantCode()}/api/v1/events/event-details/${eventId}`, data, 'page-center');
  }

  getUserEvents(data: {}) {
    return this.restApiService.post('get user events', `${this.confService.getLoggedInTenantCode()}/api/v1/events/user-events`, data, 'page-center');
  }

}
