<p-breadcrumb [model]="routePath"></p-breadcrumb>
<div appAccessProvider [accessCode]="{ menuCode: 'CONTENT_MANAGEMENT', subMenuCode: 'ABOUT_US', pageCode: 'DETAILS' }">

    <div class="flex justify-content-end flex-wrap btn-with-links">
        <button pButton class="mr-2 bg-white default-text-light-black-color border-button" [label]="'cancel' | translate"
            (click)="onCancel()"></button>
        <p-button class="flex align-items-center justify-content-center event-box" [label]="addBtnLabel"
            (click)="onAddUserProfile()"></p-button>
    </div>


    <div class="main-settings">
        <p-tabView>
            <p-tabPanel>
                <ng-template pTemplate="header">
                    <i class="mi-lg {{miIcons.DETAILS}}"></i>
                    <span translate>content.lbl_about_us</span>
                </ng-template>

                <form [formGroup]="aboutUsForm">
                    <div class="card background-not">
                        <div class="font-semibold pb-2">
                            <label class="navigation-item-label font-18" translate>content.lbl_caps_about_us</label>
                        </div>
                        <div class="grid">
                            <div class="form-field col-6 md:col-6 lg:col-6 field">
                                <label class="flex" translate>content.lbl_company_name
                                    <span class="required ml-1">*</span>
                                </label>
                                <div class="form-control">
                                    <input #nameInput formControlName="companyName" class="p-component p-element w-full" type="text" pInputText appAutoFocus [placeholder]="'content.placeholder_company_name' | translate"/>
                                </div>
                                @if (aboutUsForm.controls.companyName.errors && (aboutUsForm.controls.companyName.dirty || aboutUsForm.controls.companyName.touched)) {
                                    <div class="form-errors mt-1 required-field">
                                        <div [hidden]="!aboutUsForm.controls.companyName.errors.required" translate>
                                            content.company_name_req_err
                                        </div>
                                    </div>
                                    @if (aboutUsForm.controls.companyName.errors.maxlength) {
                                        <div class="form-errors mt-1" translate>
                                            content.company_name_max_err
                                        </div>
                                    }
                                }
                            </div>
                            <div class="form-field col-6 md:col-6 lg:col-6 field">
                                <label class="flex" translate>content.lbl_company_website
                                    <span class="required ml-1">*</span></label>
                                <div class="form-control">
                                    <input formControlName="website" class="p-component p-element w-full" type="text" pInputText [placeholder]="'content.placeholder_company_website' | translate"/>
                                </div>
                                @if (aboutUsForm.controls.website.errors && (aboutUsForm.controls.website.dirty || aboutUsForm.controls.website.touched)) {
                                    <div class="form-errors mt-1 required-field">
                                        <div [hidden]="!aboutUsForm.controls.website.errors.required" translate>
                                            content.company_website_req_err
                                        </div>
                                    </div>
                                }
                                @if (aboutUsForm.controls.website.errors && (aboutUsForm.controls.website.dirty || aboutUsForm.controls.website.touched)) {
                                    <div class="form-errors mt-1 required-field" [hidden]="!aboutUsForm.controls.website.errors.pattern" translate>
                                        settings.website_pattern
                                    </div>
                                }
                                @if (aboutUsForm.controls.website.errors && (aboutUsForm.controls.website.dirty || aboutUsForm.controls.website.touched)) {
                                    <div class="form-errors mt-1 required-field" [hidden]="!aboutUsForm.controls.website.errors.maxlength" translate>
                                        content.company_website_max_err
                                    </div>
                                }
                            </div>
                            <div class="form-field col-6 md:col-6 lg:col-6 field">
                                <label class="flex" translate>content.lbl_address_line_1</label>
                                <div class="form-control">
                                    <input formControlName="addressLine1" class="p-component p-element w-full" type="email"
                                        pInputText [placeholder]="'content.placeholder_address_line1' | translate"/>
                                </div>
                                @if (aboutUsForm.controls.addressLine1.errors && (aboutUsForm.controls.addressLine1.dirty || aboutUsForm.controls.addressLine1.touched)) {
                                    <div class="form-errors mt-1" [hidden]="!aboutUsForm.controls.addressLine1.errors.maxlength" translate>
                                        content.address1_max_err
                                    </div>
                                }
                            </div>

                            <div class="form-field col-6 md:col-6 lg:col-6 field">
                                <label class="flex" translate>content.lbl_address_line_2</label>
                                <div class="form-control">
                                    <input formControlName="addressLine2" class="p-component p-element w-full" type="email" pInputText [placeholder]="'content.placeholder_address_line2' | translate"/>
                                </div>
                                @if (aboutUsForm.controls.addressLine2.errors && (aboutUsForm.controls.addressLine2.dirty || aboutUsForm.controls.addressLine2.touched)) {
                                    <div class="form-errors mt-1" [hidden]="!aboutUsForm.controls.addressLine2.errors.maxlength" translate>
                                        content.address2_max_err
                                    </div>
                                }
                            </div>

                            <div class="form-field col-6 md:col-6 lg:col-6 field">
                                <label class="flex" translate>content.lbl_support_number</label>
                                <div class="form-control-container">
                                    <p-dropdown #dropdown (onChange)="selectCountry($event)" [options]="countries" [filter]="true" filterBy="value" [(ngModel)]="selectedCountry" [ngModelOptions]="{standalone : true}"
                                        optionLabel="code" [showClear]="false">
                                        <ng-template pTemplate="selectedItem">
                                            @if(selectedCountry) {
                                                <div class="flex align-items-center gap-2">
                                                    <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png"
                                                        [class]="'flag flag-' + selectedCountry?.code?.toLowerCase()"
                                                        class="width-18" />
                                                    <div>{{ selectedCountry.value }}</div>
                                                </div>
                                            }
                                        </ng-template>
                                        <ng-template let-country pTemplate="item">
                                            <div class="flex align-items-center gap-2">
                                                <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png"
                                                    [class]="'flag flag-' + country.code.toLowerCase()" class="width-18" />
                                                <div>{{ country.value }}</div>
                                            </div>
                                        </ng-template>
                                    </p-dropdown>
                                    <input pInputText type="text" class="input-mobile" pKeyFilter="int" (input)="selectCountry($event)" formControlName="mobileNumber"
                                    [placeholder]="'content.placeholder_support_number' | translate"/>
                                </div>
                                @if (aboutUsForm.controls.mobileNumber.errors && (aboutUsForm.controls.mobileNumber.dirty || aboutUsForm.controls.mobileNumber.touched)) {
                                    <div class="form-errors mt-1 required-field">
                                        @if (aboutUsForm.controls.mobileNumber.errors.maxlength || aboutUsForm.controls.mobileNumber.errors.minlength) {
                                            <div class="text-red-500" translate>
                                                lbl_mobile_number_max_length
                                            </div>
                                        }
                                    </div>
                                }
                            </div>


                            <div class="form-field col-6 md:col-6 lg:col-6 field">
                                <label class="flex" translate>content.lbl_email_address
                                    <span class="required ml-1">*</span></label>
                                <div class="form-control">
                                    <input formControlName="email" class="p-component p-element w-full" type="email" pInputText [placeholder]="'content.placeholder_email' | translate"/>
                                </div>
                                @if (aboutUsForm.controls.email.errors && (aboutUsForm.controls.email.dirty || aboutUsForm.controls.email.touched)) {
                                    <div class="form-errors mt-1 required-field">
                                        <div [hidden]="!aboutUsForm.controls.email.errors.required" translate>
                                            content.email_req_err
                                        </div>
                                    </div>
                                }
                                @if (aboutUsForm.controls.email.errors && (aboutUsForm.controls.email.dirty || aboutUsForm.controls.email.touched)) {
                                    <div class="form-errors mt-1 required-field" [hidden]="!aboutUsForm.controls.email.errors.pattern" translate>
                                        settings.email_pattern
                                    </div>
                                }
                                @if (aboutUsForm.controls.email.errors && (aboutUsForm.controls.email.dirty || aboutUsForm.controls.email.touched)) {
                                    <div class="form-errors mt-1 required-field" [hidden]="!aboutUsForm.controls.email.errors.maxlength" translate>
                                        content.email_max_err
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    <div class="card background-not">
                        <div class="grid">
                            <div class="form-field mb-2 col-12 md:col-12 lg:col-12 field">
                                <label class="flex" translate>content.lbl_about_company
                                </label>
                                <div class="form-control">
                                    <p-editor formControlName="aboutCompany" [style]="{ height: '142px' }" appAutoFocus [placeholder]="'content.placeholder_about_company' | translate"/>
                                </div>
                                @if (aboutUsForm.controls.aboutCompany.errors && (aboutUsForm.controls.aboutCompany.dirty || aboutUsForm.controls.aboutCompany.touched)) {
                                    @if (aboutUsForm.controls.aboutCompany.errors.maxlength) {
                                        <div class="form-errors mt-1 required-field" translate>
                                            About company field accept only 5000 characters only
                                        </div>
                                    }
                                }
                            </div>


                            <div class="form-field col-12 md:col-12 lg:col-12 field">
                                <label class="flex" translate>content.lbl_company_location
                                </label>
                                <div class="form-control">
                                    <input formControlName="companyLocation" class="p-component p-element w-full" type="text"
                                    pInputText id="search-input"  appSearchLocation (setAddress)="getAndStoreAddress($event)"/>
                                    
                                </div>                           
                                <div class="map-div mt-3 border-radius" id="map" style="height:197px"></div>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="flex justify-content-end mr-17 save-action-div">
                    <button pButton class="mr-2 mb-2 bg-color-gray default-text-light-black-color border-button"
                        [label]="'cancel' | translate" (click)="onCancel()"></button>
                    <p-button class="mb-2 flex align-items-center justify-content-center" [label]="addBtnLabel"
                        (click)="onAddUserProfile()"></p-button>
                </div>

            </p-tabPanel>
        </p-tabView>
    </div>
</div>