import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MiFieldsComponent } from '../mi-fields/mi-fields.component';
import { PassengerTypeFieldComponent } from '../passenger-type-field/passenger-type-field.component';
import { TwoColumnStructureComponent } from '../two-column-structure/two-column-structure.component';
import { AppSettings } from '../../app.settings';
import { TranslateModule } from '@ngx-translate/core';
import { EntityService } from '../../services/entity.service';
import { ConfigService } from '../../services/config.service';
import { EventService } from 'app/modules/events/services/event.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CommonBindingDataService } from '../../services/common-binding-data.service';

@Component({
  selector: 'app-privilege-form',
  templateUrl: './privilege-form.component.html',
  styleUrl: './privilege-form.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    MiFieldsComponent,
    PassengerTypeFieldComponent,
    TwoColumnStructureComponent,
    TranslateModule
  ]
})
export class PrivilegeFormComponent {
  @Input() field: any;
  @Input() attributeLabels: any;
  @Input() moduleName: string;
  @Input() dateFormat: string;
  @Input() multiSelectDisplay: any;
  @Input() isResetPrivilege;
  @Input() passengerTypeToReset;
  fieldsToDisplayHRLine = AppSettings.FIELDS;
  resetPrivilegeHeader: string = this.cs.getLabel('lbl_reset_privilege_settings_header');
  resetPrivilegeMsg: string = this.cs.getLabel('lbl_reset_privilege_settings_message');

    constructor(public entityService: EntityService,
      private configService: ConfigService,
      private eventService: EventService,
      private cs: CommonBindingDataService,
      private messageService: MessageService,
      private confirmationService: ConfirmationService,) {
    }

  isPassengerTypePrivilegeConfig(field: any): boolean {
    return field.attributeCode === 'passenger_type' && this.moduleName === 'pass_privilege_conf';
  }

  shouldDisplayHrLine(field: any): boolean {
    const hrLineFields = [
        this.fieldsToDisplayHRLine.AIRPORT_TRANSPORT,
        this.fieldsToDisplayHRLine.TRANSPORTATION_END_DATE,
        this.fieldsToDisplayHRLine.DEPARTURE_JOURNEY,
        this.fieldsToDisplayHRLine.NUMBER_OF_BOOKING_PER_DAY,
        this.fieldsToDisplayHRLine.BOOKING_CANCELLATION_IN_STATUS,
        this.fieldsToDisplayHRLine.OTP_VERIFICATION_FOR_BOOKING_END
    ];
    return hrLineFields.includes(field.attributeCode);
  }

  resetPrivilegeSettings() {
    const payload = {
      forTenantCode: this.configService.getForTenantCode(),
      eventCode: this.eventService.getSelectedEventcode(),
      passType: this.passengerTypeToReset,
    };
    this.confirmationService.confirm({
      header: this.resetPrivilegeHeader,
      message: this.resetPrivilegeMsg,
      acceptIcon: '',
      rejectIcon: '',
      rejectButtonStyleClass: 'bg-white text-color',
      acceptButtonStyleClass: 'bg-red-500',
      acceptLabel: 'Yes',
      rejectLabel: 'No',
      accept: () => {
        this.entityService.resetPrivilegeSettings(AppSettings.ENTITY_CODE.PASSENGER, payload).subscribe(() => {
          this.messageService.add({key: 'tst', severity: 'success', summary: 'Successful', detail: this.cs.getLabel('lbl_reset_privilege_successfully'),
          });
        })
      },
      reject: () => {
      }
    });
  }
} 