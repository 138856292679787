import { Component, OnInit, OnDestroy } from "@angular/core";
import { MenuItem } from "primeng/api";
import { Subscription } from "rxjs";
import { LayoutService } from "../../../../app.layout.service";
import { NgStyle, NgClass } from "@angular/common";
import { DashboardNavbarComponent } from "../dashboard-navbar/dashboard-navbar.component";
import { TranslateModule } from "@ngx-translate/core";
import { BookingStatusComponent } from "./booking-status/booking-status.component";
import { CancelledJourneyComponent } from "./cancelled-journey/cancelled-journey.component";
import { LiveStatusComponent } from "./live-status/live-status.component";
import { JourneyStatusComponent } from "./journey-status/journey-status.component";
import { EmergencyComponent } from "./emergency/emergency.component";
import { DriversComponent } from "./drivers/drivers.component";
import { UpcomingJourneyComponent } from "./upcoming-journey/upcoming-journey.component";
import { LiveJourneyTypesComponent } from "./live-journey-types/live-journey-types.component";
import { PassengerByTypesComponent } from "./passenger-by-types/passenger-by-types.component";
import { VehiclesCardComponent } from "./vehicles-card/vehicles-card.component";
import { ParkingQueuesComponent } from "./parking-queues/parking-queues.component";
import data from "../../../../../../src/assets/json/dashboard/data.json";
import { DashboardService } from "./dashboard.service";
import { AppSettings } from "app/modules/shared/app.settings";
import { ConfigService } from "app/modules/shared/services/config.service";
import { Country } from "app/modules/shared/models/country";
import { RestApiService } from "app/modules/shared/services/rest-api.service";
import { CommonBindingDataService } from "app/modules/shared/services/common-binding-data.service";
import { AccessProviderDirective } from "app/modules/shared/directives/access-provider.directive";
import { Language } from "app/modules/shared/models/language";
import { EventService } from "app/modules/events/services/event.service";
import { SkeletonModule } from "primeng/skeleton";
import { DashboardModal } from "./dashboard.modal";

interface DashboardCards {
  label: string;
  value: string;
  activeIndex: number;
  actualIndex: number;
  height: string;
  uiColumns: number;
  isChecked: boolean;
}

interface Vehicle {
  totalVehicleCount: number;
  occupiedVehicleCount: number;
}
@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
  standalone: true,
  imports: [
    SkeletonModule,
    DashboardNavbarComponent,
    NgStyle,
    NgClass,
    TranslateModule,
    BookingStatusComponent,
    CancelledJourneyComponent,
    LiveStatusComponent,
    JourneyStatusComponent,
    EmergencyComponent,
    DriversComponent,
    UpcomingJourneyComponent,
    LiveJourneyTypesComponent,
    PassengerByTypesComponent,
    VehiclesCardComponent,
    ParkingQueuesComponent,
    AccessProviderDirective,
  ],
})
export class DashboardComponent implements OnInit, OnDestroy {
  upcomingJourneyData: any[] = [];
  bookingStatusData: any[];
  cancelledJourneyData: any[];
  liveStatusData: any[];
  driverStatusData: any[];
  transportRequestData: any[];
  playerData: any[];
  journeyStatusData: any[];
  emergencyStatusData: any[];
  totalVehicles: number;
  totalVehiclesData: any[];
  parkingQueueData: any[];
  items!: MenuItem[];
  passengerByTypes: any[] = [];
  onTabMenu: boolean = false;
  products!: [];
  chartData: any;
  chartOptions: any;
  subscription!: Subscription;
  widgetSubscription!: Subscription;
  country: Country;
  language: Language;
  reqPayload = {
    limit: 20,
    offset: 0,
    searchStr: '',
    defaultSortColumn: 'updatedAt',
    defaultSortType: 'desc',
    forTenantCode: this.configService.getForTenantCode(),
    attributeCode: AppSettings.ATTRIBUTE_CODE.BOOKING_STATUS
  };
  countPayload: {
    forTenantCode: string;
    countryCode: string;
  }
  cancelledJourneyPayload: {
    forTenantCode: string,
    countryCode: string,
    languageCode: string,
    eventCode: string,
    fromDate: number,
    toDate: number,
    apiType: string,
  };
  bookingJourneyStatusPayload: {
    forTenantCode: string,
    countryCode: string,
    languageCode: string,
    eventCode: string,
    fromDate: number,
    toDate: number,
    apiType: string,
  };
  liveStatusPayload: {
    forTenantCode: string,
    countryCode: string,
    languageCode: string,
    eventCode: string,
    fromDate: number,
    toDate: number,
    apiType: string,
  };
  liveJourneyPayload: {
    forTenantCode: string,
    countryCode: string,
    languageCode: string,
    eventCode: string,
    fromDate: number,
    toDate: number,
    apiType: string,
  };
  vehicleAssignmentData: any[];
  widgetData: DashboardCards[] = [];

  constructor(
    public layoutService: LayoutService,
    private dashboardService: DashboardService,
    private configService: ConfigService,
    private restApiService: RestApiService,
    private cs: CommonBindingDataService,
    private eventService: EventService,
  ) {
    this.subscription = this.layoutService.configUpdate$.subscribe(() => { });
  }

  ngOnInit() {
    this.setVariablesAndPayloads();
    // this.getDashboardData();
    this.items = [{ label: "Hide", icon: "pi pi-eye-slash" }];
    // this.passengerByTypes = data.passengerByTypes;
    // this.cancelledJourneyData = data.cancelledJourneyData;
    this.driverStatusData = data.vehicleStatusData;
    // this.emergencyStatusData = data.emergencyStatusData;
    this.totalVehicles = 161;
    // this.parkingQueueData = data.parkingQueueData;
    this.widgetSubscription = this.dashboardService.changeObservable$.subscribe(data => {
      this.widgetData = data;
    });
  }

  setVariablesAndPayloads() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.countPayload = {
      forTenantCode: this.configService.getForTenantCode(),
      countryCode: this.country[0].countryCode,
    };
    this.cancelledJourneyPayload = {
      forTenantCode: this.configService.getForTenantCode(),
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      eventCode: this.eventService.getSelectedEventcode(),
      fromDate: 0,
      toDate: 0,
      apiType: "userType",
    };
    this.bookingJourneyStatusPayload = {
      forTenantCode: this.configService.getForTenantCode(),
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      eventCode: this.eventService.getSelectedEventcode(),
      fromDate: 0,
      toDate: 0,
      apiType: "userType",
    };
    this.liveStatusPayload = {
      forTenantCode: this.configService.getForTenantCode(),
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      eventCode: this.eventService.getSelectedEventcode(),
      fromDate: 0,
      toDate: 0,
      apiType: "liveStatus",
    };
    this.liveJourneyPayload = {
      forTenantCode: this.configService.getForTenantCode(),
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      eventCode: this.eventService.getSelectedEventcode(),
      fromDate: 0,
      toDate: 0,
      apiType: "userType",
    };

    this.getDashboardData();
    this.items = [{ label: "Hide", icon: "pi pi-eye-slash" }];
    // this.passengerByTypes = data.passengerByTypes;
    // this.cancelledJourneyData = data.cancelledJourneyData;
    this.driverStatusData = data.vehicleStatusData;
    // this.emergencyStatusData = data.emergencyStatusData;
    // this.upcomingJourneyData = data.upcomingJourneyData;
    this.totalVehicles = 161;
    // this.totalVehiclesData = data.totalVehiclesData;
    this.parkingQueueData = data.parkingQueueData;

    this.widgetSubscription = this.dashboardService.changeObservable$.subscribe(data => {
      this.widgetData = data;
    });
  }

  isVisible(labelKey: string): boolean {
    if (!this.widgetData || this.widgetData.length === 0) {
      return true;
    }
    const item = this.widgetData.find(widget => widget.value === this.cs.getLabel(labelKey));
    return item ? item.isChecked : true;
  }

  isBookingStatusVisible(): boolean {
    return this.isVisible('dashboard_card_ids.booking_status');
  }

  isJourneyStatusVisible(): boolean {
    return this.isVisible('dashboard_card_ids.journey_status');
  }

  isLiveStatusVisible(): boolean {
    return this.isVisible('dashboard_card_ids.live_status');
  }

  isUpcomingJourneyVisible(): boolean {
    return this.isVisible('dashboard_card_ids.upcoming_journey');
  }

  isCancelledJourneyVisible(): boolean {
    return this.isVisible('dashboard_card_ids.cancelled_journey');
  }

  isPassengerByTypesVisible(): boolean {
    return this.isVisible('dashboard_card_ids.passenger_by_types');
  }

  isDriversVisible(): boolean {
    return this.isVisible('dashboard_card_ids.drivers');
  }

  isVehiclesVisible(): boolean {
    return this.isVisible('dashboard_card_ids.vehicles');
  }

  isParkingQueuesVisible(): boolean {
    return this.isVisible('dashboard_card_ids.parking_queues');
  }

  isLiveJourneyTypesVisible(): boolean {
    return this.isVisible('dashboard_card_ids.live_journey_by_types');
  }

  getDashboardData() {
    const payload = this.getMultiApiPayload();
    this.dashboardService.multipleApiData(payload).subscribe((res: DashboardModal) => {
      const liveJourneyTypeData = JSON.parse(res.liveJourneyTypeData.responseBody);
      // const criticalWatchListData = JSON.parse(res.criticalWatchListData.responseBody);
      const passengerByType = JSON.parse(res.passengerByType.responseBody);
      // const totalVehicleData = JSON.parse(res.totalVehicleData.responseBody);
      // const vehicleByBodyType = JSON.parse(res.vehicleByBodyType.responseBody);
      const vehicles = JSON.parse(res.vehiclesAvailabilities.responseBody);
      const upcomingJourneyArray = JSON.parse(res.upcomingJourney.responseBody);
      const cancelledJourney = JSON.parse(res.cancelledJourney.responseBody);
      const bookingStatus = JSON.parse(res.bookingJourneyStatus.responseBody);
      this.bookingStatusData = [
        {
          count: bookingStatus.new_request || 0,
          label: 'New Request',
          color: 'text-blue-500'
        },
        {
          count: bookingStatus.critical || 0,
          label: 'Critical',
          color: 'text-red-500'
        },
        {
          count: bookingStatus.watchlist || 0,
          label: 'Watch List',
          color: 'text-color'
        }
      ];
      const journeyStatus = JSON.parse(res.bookingJourneyStatus.responseBody);
      this.journeyStatusData = [
        {
          count: journeyStatus.completed || 0,
          label: 'Completed',
          color: 'text-blue-500'
        },
        {
          count: journeyStatus.expired || 0,
          label: 'Expired',
          color: 'text-color'
        },
      ];
      const liveStatus = JSON.parse(res.liveStatus.responseBody);
      this.liveStatusData = [
        {
          label: "Admin Confirmed",
          count: liveStatus.admin_confirmed || 0,
          labelClass: "font-13 default-text-black-color",
          countClass: "font-18 font-semibold"
        },
        {
          label: "Driver Assigned",
          count: liveStatus.driver_assigned || 0,
          labelClass: "font-13 default-text-black-color",
          countClass: "font-18 font-semibold"
        },
        {
          label: "Acknowledged",
          count: liveStatus.acknowledged || 0,
          labelClass: "font-13 default-text-black-color",
          countClass: "font-16 font-semibold default-text-black-color"
        },
        {
          label: "Reconfirmed",
          count: liveStatus.reconfirmed || 0,
          labelClass: "font-13 default-text-black-color",
          countClass: "font-16 font-semibold default-text-black-color"
        },
        {
          label: "Driver On The Way",
          count: liveStatus.driver_on_the_way || 0,
          labelClass: "font-13 default-text-black-color",
          countClass: "font-16 font-semibold default-text-black-color"
        },
        {
          label: "Driver Arrived",
          count: liveStatus.driver_arrived || 0,
          labelClass: "font-13 default-text-black-color",
          countClass: "font-16 font-semibold default-text-black-color"
        },
        {
          label: "On Trip",
          count: liveStatus.on_trip || 0,
          labelClass: "font-13 default-text-black-color",
          countClass: "font-16 font-semibold default-text-black-color"
        },
        {
          label: "Completed",
          count: liveStatus.completed || 0,
          labelClass: "font-13 default-text-black-color",
          countClass: "font-16 font-semibold default-text-black-color"
        },
        {
          label: "Expired",
          count: liveStatus.expired || 0,
          labelClass: "font-13 default-text-black-color",
          countClass: "font-16 font-semibold default-text-black-color"
        },
        {
          label: "Cancelled",
          count: liveStatus.cancelled || 0,
          labelClass: "font-13 default-text-black-color",
          countClass: "font-16 font-semibold default-text-black-color"
        },
        {
          label: "Watch List",
          count: liveStatus.watchlist || 0,
          labelClass: "font-13 default-text-black-color",
          countClass: "font-18 font-semibold"
        },
        {
          label: "New Request",
          count: liveStatus.new_request || 0,
          labelClass: "font-13 default-text-black-color",
          countClass: "font-18 font-semibold"
        }
      ];
      this.transportRequestData = Object.keys(liveJourneyTypeData).map(key => ({
        label: key,
        labelIcon: this.cs.getLabelIcon(key),
        count: liveJourneyTypeData[key],
        labelClass: "font-13 default-text-black-color",
        countClass: "font-18 font-semibold"
      }));
      this.playerData = passengerByType.data;
      const attributeData: Record<string, Vehicle> = vehicles; 
      const totalVehiclesCount = Object.values(attributeData).reduce((total, v) => total + v.totalVehicleCount, 0);
      const totalAvailable = Object.values(attributeData).reduce((total, v) => total + v.totalVehicleCount, 0);
      const totalOccupied = Object.values(attributeData).reduce((total, v) => total + v.occupiedVehicleCount, 0);

      this.totalVehiclesData = [{
        label: `All(${totalVehiclesCount})`,
        available: totalAvailable,
        occupied: totalOccupied,
        labelClass: 'default-text-black-color font-bold font-16',
        availableClass: 'text-green-color font-bold font-20',
        occupiedClass: 'text-yellow-color font-bold font-20',
      }];

      for (const [name, counts] of Object.entries(attributeData)) {
        this.totalVehiclesData.push({
          label: name,
          available: counts.totalVehicleCount,
          occupied: counts.occupiedVehicleCount
        });
      }

      const totalCancelledJourney = Object.values(cancelledJourney).reduce((sum: number, count: number) => {
        return sum + count;
      }, 0);

      this.cancelledJourneyData = Object.keys(cancelledJourney).map(key => ({
        label: key,
        count: cancelledJourney[key],
        labelClass: "font-13 default-text-black-color",
        countClass: "font-16 font-semibold default-text-black-color"
      }));

      this.cancelledJourneyData.unshift({
        label: "Total",
        count: totalCancelledJourney,
        labelClass: "font-14 font-semibold",
        countClass: "font-20 default-text-blue-color font-bold"
      });

      this.upcomingJourneyData = Object.keys(upcomingJourneyArray).map(key => ({
        date: key,
        icon: "mi-lg mi-stepper_radio wh-18 mi-stepper_color",
        journey: {
          car: upcomingJourneyArray[key].bookingCount,
          code: upcomingJourneyArray[key].childSeatCount
        }
      }));
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.widgetSubscription) {
      this.widgetSubscription.unsubscribe();
    }
  }

  private getMultiApiPayload() {
    return [
      {
        url: `${AppSettings.BASE_URL}${this.configService.getLoggedInTenantCode()}/api/v1/entity-conf/${AppSettings.ENTITY_CODE.BOOKING}/attributes/preset-values/search`,
        inputBody: this.reqPayload,
        method: 'POST',
        outputKey: AppSettings.OUTPUT_KEY.NEW_REQUEST,
        headers: this.restApiService.getHeaderForMultipleAPI()
      },
      {
        url: `${AppSettings.BASE_URL}${this.configService.getLoggedInTenantCode()}/api/v1/entity-conf/${AppSettings.ENTITY_CODE.BOOKING}/custom/canceled-booking-count-usertype`,
        inputBody: this.cancelledJourneyPayload,
        method: 'POST',
        outputKey: AppSettings.OUTPUT_KEY.CANCELLED_JOURNEY,
        headers: this.restApiService.getHeaderForMultipleAPI()
      },
      {
        url: `${AppSettings.BASE_URL}${this.configService.getLoggedInTenantCode()}/api/v1/entity-conf/${AppSettings.ENTITY_CODE.BOOKING}/custom/booking-count-booking-status`,
        inputBody: this.bookingJourneyStatusPayload,
        method: 'POST',
        outputKey: AppSettings.OUTPUT_KEY.BOOKING_JOURNEY_STATUS,
        headers: this.restApiService.getHeaderForMultipleAPI()
      },
      {
        url: `${AppSettings.BASE_URL}${this.configService.getLoggedInTenantCode()}/api/v1/entity-conf/${AppSettings.ENTITY_CODE.BOOKING}/custom/booking-count-booking-status`,
        inputBody: this.liveStatusPayload,
        method: 'POST',
        outputKey: AppSettings.OUTPUT_KEY.LIVE_STATUS,
        headers: this.restApiService.getHeaderForMultipleAPI()
      },
      {
        url: `${AppSettings.BASE_URL}${this.configService.getLoggedInTenantCode()}/api/v1/entity-conf/${AppSettings.ENTITY_CODE.BOOKING}/custom/live-journey-by-types`,
        inputBody: this.liveJourneyPayload,
        method: 'POST',
        outputKey: AppSettings.OUTPUT_KEY.LIVE_JOURNEY_TYPE_DATA,
        headers: this.restApiService.getHeaderForMultipleAPI()
      },
      {
        url: `${AppSettings.BASE_URL}${this.configService.getLoggedInTenantCode()}/api/v1/entity-conf/${AppSettings.ENTITY_CODE.BOOKING}/custom/dashboard-upcoming-journeys?forTenantCode=${this.configService.getForTenantCode()}&eventCode=${this.eventService.getSelectedEventcode()}`,
        inputBody: '',
        method: 'GET',
        outputKey: AppSettings.OUTPUT_KEY.UPCOMING_JOURNEY,
        headers: this.restApiService.getHeaderForMultipleAPI()
      },
      {
        url: `${AppSettings.BASE_URL}${this.configService.getLoggedInTenantCode()}/api/v1/entity-conf/${AppSettings.ENTITY_CODE.BOOKING}/custom/dashboard-vehicle-count?forTenantCode=${this.configService.getForTenantCode()}&eventCode=${this.eventService.getSelectedEventcode()}`,
        inputBody: '',
        method: 'GET',
        outputKey: AppSettings.OUTPUT_KEY.VEHICLE_AVAILABILITY,
        headers: this.restApiService.getHeaderForMultipleAPI()
      },
      {
        url: `${AppSettings.BASE_URL}${this.configService.getLoggedInTenantCode()}/api/v1/entity-conf/${AppSettings.ENTITY_CODE.BOOKING}/entities/counts`,
        inputBody: this.countPayload,
        method: 'POST',
        outputKey: AppSettings.OUTPUT_KEY.CRITICAL_WATCHLIST_DATA,
        headers: this.restApiService.getHeaderForMultipleAPI()
      },
      {
        url: `${AppSettings.BASE_URL}${this.configService.getLoggedInTenantCode()}/api/v1/entity-conf/${AppSettings.ENTITY_CODE.VEHICLE}/entities/counts`,
        inputBody: this.countPayload,
        method: 'POST',
        outputKey: AppSettings.OUTPUT_KEY.TOTAL_VEHICLE_DATA,
        headers: this.restApiService.getHeaderForMultipleAPI()
      },
      {
        url: `${AppSettings.BASE_URL}${this.configService.getLoggedInTenantCode()}/api/v1/entity-conf/${AppSettings.ENTITY_CODE.VEHICLE}/attributes/preset-values/search`,
        inputBody: {
          limit: 20,
          offset: 0,
          searchStr: '',
          defaultSortColumn: 'updatedAt',
          defaultSortType: 'desc',
          forTenantCode: this.configService.getForTenantCode(),
          attributeCode: AppSettings.ATTRIBUTE_CODE_FOR_SETTINGS.BODY_TYPE
        },
        method: 'POST',
        outputKey: AppSettings.OUTPUT_KEY.VEHICLE_BY_BODY_TYPE,
        headers: this.restApiService.getHeaderForMultipleAPI()
      },
      // {
      //   url: `${AppSettings.BASE_URL}${this.configService.getLoggedInTenantCode()}/api/v1/entity-conf/${AppSettings.ENTITY_CODE.BOOKING}/attributes/preset-values/search`,
      //   inputBody: {
      //     limit: 20,
      //     offset: 0,
      //     searchStr: '',
      //     defaultSortColumn: 'updatedAt',
      //     defaultSortType: 'desc',
      //     forTenantCode: this.configService.getForTenantCode(),
      //     attributeCode: AppSettings.ATTRIBUTE_CODE_FOR_SETTINGS.TRANSPORTATION_TYPE
      //   },
      //   method: 'POST',
      //   outputKey: AppSettings.OUTPUT_KEY.LIVE_JOURNEY_TYPE_DATA,
      //   headers: this.restApiService.getHeaderForMultipleAPI()
      // },
      {
        url: `${AppSettings.BASE_URL}${this.configService.getLoggedInTenantCode()}/api/v1/entity-conf/${AppSettings.ENTITY_CODE.PASSENGER}/custom/preset-labels/search`,
        inputBody: {
          forTenantCode: this.configService.getForTenantCode(),
          attributeCode: AppSettings.ATTRIBUTE_CODE_FOR_SETTINGS.PASSENGER_TYPE,
          searchStr: '',
          usageRequired: true
        },
        method: 'POST',
        outputKey: AppSettings.OUTPUT_KEY.PASSENGER_BY_TYPE,
        headers: this.restApiService.getHeaderForMultipleAPI()
      }
    ];
  }
}
