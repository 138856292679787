<p-breadcrumb [model]="routePath"></p-breadcrumb>
<div appAccessProvider [accessCode]="{ menuCode: 'CONTENT_MANAGEMENT', subMenuCode: 'TERMS_AND_CONDITIONS', pageCode: 'DETAILS' }">
    <div class="flex justify-content-end flex-wrap btn-with-links">
        <button pButton class="mr-2 bg-white default-text-light-black-color border-button" [label]="'cancel' | translate"
            (click)="onCancel()"></button>
        <p-button class="flex align-items-center justify-content-center event-box" [label]="addBtnLabel"
            (click)="onAdd()" [disabled]="termsForm.invalid"></p-button>
    </div>


    <div class="main-settings">
        <p-tabView>
            <p-tabPanel>
                <ng-template pTemplate="header">
                    <i class="mi-lg {{miIcons.DETAILS}}"></i>
                    <span translate>content.lbl_terms_and_conditions</span>
                </ng-template>
                <form [formGroup]="termsForm">
                    <div class="card background-not">
                        <div class="font-semibold pb-3">
                            <label class="navigation-item-label font-18" translate>content.lbl_terms_conditions <span class="required ml-1">*</span></label>
                        </div>
                        <div class="grid">
                            <div class="form-field col-12 md:col-12 lg:col-12 field">
                                <div class="form-control">
                                    <p-editor formControlName="termsAndConditions" [style]="{ height: 'calc(100vh - 330px)' }"
                                        appAutoFocus (onBlur)="onEditorBlur()"/>
                                </div>
                                @if (termsForm.controls.termsAndConditions.errors && (termsForm.controls.termsAndConditions.touched || termsForm.controls.termsAndConditions.dirty)) {
                                    <div class="form-errors mt-1 required-field">
                                        @if (termsForm.controls.termsAndConditions.errors.required) {
                                            <div translate>
                                                content.terms_condition_req_err
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </form>

                <div class="flex justify-content-end mr-17 save-action-div">
                    <button pButton class="mr-2 mb-2 bg-color-gray default-text-light-black-color border-button"
                        [label]="'cancel' | translate" (click)="onCancel()"></button>
                    <p-button class="mb-2 flex align-items-center justify-content-center" [label]="addBtnLabel"
                        (click)="onAdd()" [disabled]="termsForm.invalid"></p-button>
                </div>
            </p-tabPanel>
        </p-tabView>
    </div>
</div>