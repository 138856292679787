import { Component, Input } from '@angular/core';
import { MetaDataService } from '../../services/meta-data.service';

@Component({
  selector: 'app-mi-mobile-value',
  standalone: true,
  imports: [],
  templateUrl: './mi-mobile-value.component.html',
  styleUrls: ['./mi-mobile-value.component.scss', '../../../shared//components//mi-fields//mobile-input/flags.scss']
})
export class MiMobileValueComponent {
  @Input() attributeValues;
  @Input() countryCodeForMobileNumber;
  @Input() field;
  @Input() isStaticEntity: boolean = false;
  @Input() mobileNumber: any;
  @Input() moduleName:any;
  contactFormat = this.metaDataService.mobileNumber;
  formattedMobileNumber;

  constructor(private metaDataService: MetaDataService) {}

  ngOnInit() {
    this.formatMobileNumber();
  }

  formatMobileNumber() {
    let countryCodeNumber = this.mobileNumber ? this.mobileNumber :  this.attributeValues[this.field.attributeCode];
    const mobileNumberCountryCodeArray = countryCodeNumber.split('-');
    let countryCodeText;
    let number;
    if (mobileNumberCountryCodeArray.length > 1) {
      countryCodeText = mobileNumberCountryCodeArray[0];
      number = mobileNumberCountryCodeArray[1];
    } else {
      number = mobileNumberCountryCodeArray[0];
    }

    if (number) {
      let mobile = number.replace(/\D/g, '');
      if (this.contactFormat) {
        let formatParts = this.contactFormat.split('.');
        let formatted = '';
        let mobileIndex = 0;
        for (let part of formatParts) {
          formatted += mobile.substr(mobileIndex, part.length);
          mobileIndex += part.length;
          formatted += ' ';
        }

        countryCodeText = countryCodeText ? `${countryCodeText}-` : '';
        this.formattedMobileNumber = `${countryCodeText} ${formatted.slice(0, -1)}`;
      }
    }
  }

}
