import { Component, Input } from '@angular/core';
import { Values } from '../../models/driverList.models';
import { Country } from 'app/modules/shared/models/country';
import { Language } from 'app/modules/shared/models/language';
import { EntityCount, EntityCountsResponse } from 'app/modules/vehicles/models/entity.models';
import { AppSettings } from 'app/modules/shared/app.settings';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { EntityService } from 'app/modules/shared/services/entity.service';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { MITableComponent } from 'app/modules/shared/components/mi-table/mi-table.component';
import { ListResponse } from 'app/modules/vehicles/models/listResponse.models';
import { forkJoin } from 'rxjs';
import { FilterResponseModal } from 'app/modules/manage-groups/models/filterResponse.models';
import * as _ from 'lodash';
import { BookingService } from 'app/modules/bookings/services/booking.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-journey-history',
  standalone: true,
  imports: [MITableComponent],
  templateUrl: './journey-history.component.html',
  styleUrl: './journey-history.component.scss'
})
export class JourneyHistoryComponent {
  @Input() driverDetails: any;
  filterList;
  btnLabel: string;
  attributeLabels = {};
  filterAttributeLabels = {};
  isLoading = true;
  filterAvailableColumns = [];
  listColumns: Values;
  allColumns: any;
  pagination: any;
  tableData: any = [];
  country: Country;
  language: Language;
  entityCount: EntityCountsResponse;
  entityData: any = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
    forTenantCode: this.configService.getForTenantCode(),
    actionStatus: ''
  };
  routePath: MenuItem[] = [];
  totalRecords: number = 0;
  filterFieldsArray: any[];
  attributeData: any;
  data: any;
  attributeIdOfDriverEntityId: any;
  bookingStatus: any;
  bookingStatusId;
  bookingCode;

  constructor(private journeyHistoryService: EntityService,
    private cs: CommonBindingDataService, private confirmationService: ConfirmationService,
    private router: Router, private messageService: MessageService, private actRoute: ActivatedRoute, private configService: ConfigService,private bookingService: BookingService) { }

    ngOnInit() {
      this.setLabels();
      this.getData();
      this.setBookingStatus();
      this.updateBookingCodes();
    }
  
    public setLabels() {
      this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
      this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    }

    setBookingStatus() {
      this.bookingService.getBookingStatus().pipe(
        tap((res) => {
          this.bookingStatus = res;
        })).subscribe();
    }

    getAttributeData() {
      this.journeyHistoryService.getAttributeDefinition(AppSettings.ENTITY_CODE.BOOKING, AppSettings.VIEW_CODE.BULK_UPDATE_VIEW).subscribe(res => {
        if (res) {
          this.attributeData = res;
          this.isLoading = false;
          this.filterFieldsArray = res?.tabs[0]?.groups[0]?.fields;
        }
      });
    }
  
  
    getTableView() {
      this.journeyHistoryService.getAttributeDefinition(AppSettings.ENTITY_CODE.BOOKING, AppSettings.VIEW_CODE.JOURNEY_HISTORY_IN_DRIVER_VIEW).subscribe(res => {
        if (res) {
          this.setJourneyHistoryTableView(res);
        }
      });
    }
  
  setJourneyHistoryTableView(res) {      
    this.isLoading = false;
    this.data = res;
    this.attributeLabels = this.cs.getAttributeLabels(this.data);
    this.allColumns = this.cs.getColumns(this.data);
   
    this.allColumns?.forEach((key, index) => {
      this.allColumns[index] = key;
      this.allColumns[index].field = key.attributeCode;
      this.allColumns[index].header = this.cs.getLabelValue(
        "booking" + ".fields." + key.attributeCode + ".label",
        this.attributeLabels,
        key.attributeCode
      );
    });
    this.filterAvailableColumns = _.clone(this.allColumns);
    this.searchEntity();

  }

    getFilterView() {
      this.journeyHistoryService.getAttributeDefinition(AppSettings.ENTITY_CODE.BOOKING, AppSettings.VIEW_CODE.ADVANCED_FILTER_VIEW).subscribe((filterResponse: FilterResponseModal) => {
        if (filterResponse) {
          console.log(filterResponse.tabs[0].groups[0].fields)
          filterResponse.tabs.forEach(tab => {
            tab.groups.forEach(group => {
              group.fields.forEach(field => {
                if (field.attributeCode === 'driver_entity_id') {
                  this.attributeIdOfDriverEntityId = field.attributeId;
                }
              });
            });
          });
          this.getTableView();
        }
      })
    }

    onFilterValueChange(event) {
        const vData = event;
        this.entityData.filters = [];
        for (const [key, value] of Object.entries(vData)) {
          const attributeValue = value;
          if (attributeValue) {
            this.entityData.filters.push({
              attributeCode: key,
              attributeValue
            });
          }
        }
        this.entityData.filters = _.filter(_.uniq(this.entityData.filters, function(item) {
          if (item.attributeValue.length > 0) {
            return item.attributeCode;
    
          }
        }), function(element) {
          if (element.attributeValue.length > 0) {
            return true;
          }
          return false;
        });
        this.entityData.offset = event.first;
        this.entityData.filters = this.cs.mapAttributeIds(this.entityData.filters, this.filterList, 'filter');
        this.searchEntity();
    }

    getData() {
      this.getFilterView();
    }

    searchEntity() {
      this.tableData = [];
      this.entityData.filters = [{ attributeId: this.attributeIdOfDriverEntityId, attributeValue: [this.driverDetails.entityId] }];
      this.entityData.countryCode = this.country[0].countryCode;
      this.journeyHistoryService.searchEntity(AppSettings.ENTITY_CODE.BOOKING, this.entityData).subscribe((res: ListResponse) => {
        this.listColumns = res?.data[0]?.values;
        this.pagination = res?.pagination;
        this.totalRecords = res?.count;
        this.tableData = this.cs.getTableData(res);
        this.updateBookingCodes();
      })
    }

    onSearchValueChanges(event) {
      this.entityData.searchStr = event;
      this.searchEntity();
    }

    onPageChange(event) {
      this.entityData.offset = event?.first;
      this.entityData.limit = event?.rows;
      this.searchEntity();
    }

    itemPerPageChange(event) {
      this.entityData.limit = event;
      this.searchEntity();
    }

    updateBookingCodes(): void {
      this.tableData.forEach((booking,index: number) => {
        const bookingStatusId = booking.booking_status;

        const matchedStatus = this.bookingStatus.find(
          (status) => status.bookingStatusId === bookingStatusId 
        );
        if (matchedStatus) {
          const displayStrArray = JSON.parse(matchedStatus.bookingStatusDisplayStr);
    
          const language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));  
          const langId = language[0].languageId;
    
          const matchedLabel = displayStrArray.find((entry: any) => entry.langId === langId)?.label;

          if (matchedLabel) {
            this.tableData[index].bookingStatusLabel = matchedLabel;
          }
        }
        const textColorCode = matchedStatus.textColorCode;
        if (textColorCode) {
          this.tableData[index].bookingStatusTextColor = textColorCode;
        }
      });
    }

}
