<div class="grid mt pt-3 pl-2 align-items-end">
  <div class="col-auto">
    <app-mi-fields 
      [field]="field" 
      [attributeLabels]="attributeLabels"
      [dateFormat]="dateFormat"
      [moduleName]="moduleName" 
      [multiSelectDisplay]="multiSelectDisplay">
    </app-mi-fields>

    <app-mi-error 
      [field]="field" 
      [attributeLabels]="attributeLabels"
      [moduleName]="moduleName">
    </app-mi-error>
  </div>
  <div class="col-auto horizontal ml-2">
    <label class="flex">
      {{cs.getLabelValue(moduleName +'.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}
      @if (field.validation?.required) {
        <span class="required">*</span>
      }
      @if (cs.getLabelValue(moduleName+'.fields.'+field.attributeCode+'.helpTips',attributeLabels,0)) {
        <app-mi-tooltip 
          [messageData]="cs.getLabelValue(moduleName +'.fields.'+field.attributeCode+'.helpTips',attributeLabels,field.attributeCode)">
        </app-mi-tooltip>
      }
    </label>
  </div>
</div> 