<div style="position: relative">
  <div class="map-div" id="map"></div>
  <!-- <div id="style-selector-control" class="map-control mr-3 dark-light-toggle absolute">
    <p-toggleButton
      id="toggle_button"
      class="p-0"
      id="style-selector"
      offIcon="mi-lg mi-night_mode wh-40"
      onIcon="mi-lg mi-day_mode wh-40"
      (onChange)="toggleDarkAndLightMap($event)"
      [(ngModel)]="checked"
    ></p-toggleButton>
  </div> -->
  <button
    id="my-location-control"
    class="geolocation-button absolute"
    (click)="getCurrentLocation()"
  >
    <i
      class="mi-lg mi-locate_me_target wh-24"
      style="background-color: #1e90ff"
    ></i>
  </button>
</div>

@if (isAddPage || zoneFormDetails) {
  <span id="googleSearch" class="p-input-icon-left absolute left-0">
    <i class="pi pi-search relative"></i>
    <input
      id="autocompleteSearch"
      [(ngModel)]="searchText"
      type="text"
      pInputText
      class="search-field font-14 bg-white"
      placeholder="Search"
    />
    @if (searchText) {
      <span class="p-input-icon-right">
        <i class="pi pi-times relative" (click)="clearSearch()"></i>
      </span>
    }
  </span>
}
