
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NoResultFoundComponent } from 'app/modules/shared/components/no-result-found/no-result-found.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { AppIcons } from '../../../shared/app.icons';
import { AppSettings } from '../../../shared/app.settings';
import { Country } from '../../../shared/models/country';
import { Language } from '../../../shared/models/language';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { ConfigService } from '../../../shared/services/config.service';
import { EntityService } from '../../../shared/services/entity.service';
import type { EntityList } from '../../../vehicles/models/entity.models';
import type { DatumValues, DriverList } from '../../models/driverList.models';
import { AccessProviderDirective } from 'app/modules/shared/directives/access-provider.directive';
import { RadioButtonModule } from 'primeng/radiobutton';
import { MiFormComponent } from 'app/modules/shared/components/mi-form/mi-form.component';
import { AttributeData, entityResponse } from 'app/modules/vehicles/models/attribute.models';
import { Router } from '@angular/router';
import { ListResponse } from 'app/modules/vehicles/models/listResponse.models';
@Component({
  selector: 'app-off-duty-reason',
  standalone: true,
  imports: [DialogModule, TranslateModule, InputTextModule, ButtonModule, FormsModule, ConfirmPopupModule, NoResultFoundComponent, AccessProviderDirective, RadioButtonModule, MiFormComponent,],
  templateUrl: './off-duty-reason.component.html',
  styleUrl: './off-duty-reason.component.scss'
})
export class OffDutyReasonComponent {
  @ViewChild(MiFormComponent) miFormComponent: MiFormComponent;
  miIcons = AppIcons;
  @Input() isOffDutyVisible;
  @Input() isOnDutyVisible;
  @Input() rowData;
  @Input() locationAttributeData;
  @Input() tableDriverOffDutyData: any[] = [];
  @Output() onToggleOverLay: EventEmitter<boolean> = new EventEmitter();
  @Output() onSearchValueChange: EventEmitter<string> = new EventEmitter();
  @Output() onValueChange = new EventEmitter<any>();
  offDutyHeader = this.cs.getLabel('driver.lbl_off_duty_reason');
  onDutyHeader = this.cs.getLabel('driver.lbl_on_duty_reason');
  logOffHeader = this.cs.getLabel('lbl_next_shift_location_of_driver');
  moduleNameForNextShift = AppSettings.ENTITY_TYPE.DRIVER_NEXT_SHIFT;
  selectedFilterCount: number;
  driverData: any;
  country: Country;
  language: Language;
  tableData: any = [];
  locationData: any = [];
  dutyReasonData: any = [];
  selectedReason: any;
  listColumns: DatumValues;
  isVisibleLogOff: boolean = false;
  isFormShow: boolean = false;
  isDriverAddressShow: boolean = true;
  isShowSaveBtn: boolean = true;
  nextShiftData: AttributeData;
  nextShiftAttributeLabels = {};
  locationTypePresetValues: any[] = [];
  entityRelationshipConfigId: string;
  selectedLocationType;
  attributeValues = [];
  nextShiftAttributeData;
  driverHomeAddress;
  homeLocationPayload;
  hubLocationPayload;
  offDutyReason;
  btnLabel = this.cs.getLabel('label_save');
  previousBtnLabel = this.cs.getLabel('cancel');
  attributeIdForLocation;
  nextShiftId;
  entityDataForOffDuty: EntityList = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    filters: [],
    defaultSortType: "desc",
    countryCode: "",
    deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
    forTenantCode: this.configService.getForTenantCode(),
  };
  entityDataForLocation: EntityList = {
    limit: 1,
    offset: 0,
    searchStr: "",
    filters: [],
    defaultSortType: "desc",
    countryCode: "",
    deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
    forTenantCode: this.configService.getForTenantCode(),
    tableViewCode: AppSettings.VIEW_CODE.LOCATION_WITH_GEOCODE_VIEW,
  };


  constructor(private driverService: EntityService, private cs: CommonBindingDataService,
    private messageService: MessageService, private configService: ConfigService,
    private confirmationService: ConfirmationService, private router: Router,) { }

  ngOnInit() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
  }

  ngOnChanges(): void {
    this.getAttributes();
    this.driverData = this.rowData;
    this.updateDutyReasonData();
    this.getDriverHomeAddress();
    this.searchEntityForLocation();
  }

  onHide() {
    this.isOnDutyVisible = false;
    this.onToggleOverLay.emit(this.isOnDutyVisible);
  }

  onHideConfirmation() {
    this.isOffDutyVisible = false;
    this.onToggleOverLay.emit(this.isOffDutyVisible);
  }

  onHideLogOff() {
    this.isVisibleLogOff = false;
    this.isOffDutyVisible = false;
    this.onToggleOverLay.emit(this.isOffDutyVisible);
  }

  onDivClick(data: any): void {
    this.selectedReason = data;
  }

  updateDutyReasonData() {
    this.dutyReasonData = this.tableDriverOffDutyData?.map(res => {
      const className = res.labelKey.replace(/\s|\/+/g, "").toLowerCase();
      return { label: res.labelValue, icon: `pi pi-fw mi-${className} wh-24 mi-lg` };
    });
  }

  getAttributes() {
    const entityType = AppSettings.ENTITY_TYPE.DRIVER_NEXT_SHIFT;
    this.driverService.getAttributeDefinition(entityType, AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe(res => {
      if (res) {
        this.nextShiftData = res;
        this.nextShiftAttributeLabels = this.cs.getAttributeLabelsForDriver(this.nextShiftData);
        this.nextShiftAttributeData = this.cs.fetchTabsGroupsData(this.nextShiftData);
        this.locationTypePresetValues = this.getPresetValues(this.nextShiftAttributeData);
        this.entityRelationshipConfigId = this.getEntityRelationshipConfigId(this.nextShiftData);
      }
    });
  }

  getPresetValues(data) {
    if (Array.isArray(data) && data.length > 0) {
      const groups = data[0].groups;
      if (groups.length > 0) {
        const fields = groups[0].fields;
        const locationTypeField = fields.find(field => field.attributeCode === AppSettings.DRIVER_ATTRIBUTES.NEXT_SHIFT_LOCATION_TYPE);
        return locationTypeField ? locationTypeField.presetValues : [];
      }
    }
    console.error("Unexpected data structure:", data);
    return [];
  }

  getDriverHomeAddress() {
    const driverId = this.rowData?.id;
    this.driverService.getEntity(driverId, AppSettings.ENTITY_CODE.DRIVER, AppSettings.VIEW_CODE.DRIVER_ADDRESS_DETAIL_VIEW).subscribe((res) => {
      if (res) {
        const data = res;
        this.driverHomeAddress = this.getAllAttributeValues(data);
      }
    });
  }

  getEntityRelationshipConfigId(data) {
    return data?.relations[0]?.entityRelationshipConfigId
  }

  getAllAttributeValues(entityData): Record<string, string> {
    const attributeValues: Record<string, string> = {};
    entityData.attributeCodeValueDtoList.forEach(item => {
      if (!item.deleted) {
        attributeValues[item.attributeCode] = item.attributeValue;
      }
    });
    return attributeValues;
  }

  searchEntityForLocation() {
    this.locationData = [];
    this.attributeIdForLocation = this.getAttributeId('location_category', this.locationAttributeData);    
    this.entityDataForLocation.filters.push({
      attributeId: this.attributeIdForLocation,
      attributeValue: ['Hub Location']
    });
    this.entityDataForLocation.countryCode = this.country[0]?.countryCode;
    this.driverService.searchEntity(AppSettings.ENTITY_CODE.LOCATION, this.entityDataForLocation).subscribe((res: ListResponse) => {
      this.locationData = this.cs.getTableData(res);
    });
  }

  getAttributeId(attributeCode: string, locationAttributeData): number | undefined {
    for (const tab of locationAttributeData.tabs) {
      for (const group of tab.groups) {
        const attribute = group.fields.find(field => field.attributeCode === attributeCode);
        if (attribute) {
          return attribute.attributeId;
        }
      }
    }
    return undefined;
  }

  submitData(event) {
    if(event === AppSettings.DRIVER_NEXT_SHIFT_ATTRIBUTES.HOME_LOCATION) {
      this.onSaveHomeLocation(event);
    }
    if(event === AppSettings.DRIVER_NEXT_SHIFT_ATTRIBUTES.TRANSPORT_HUB_LOCATION) {
      this.onSaveHubLocation(event);
    }
  }

  onSaveHomeLocation(locationType) {
    const homeLocationPayload = this.bindHomeLocationDataToAttributes(this.nextShiftAttributeData, this.driverHomeAddress, locationType);
    this.homeLocationPayload = this.extractAttributeHomeLocationData(homeLocationPayload);
    const locationData = this.initializeLocationData();
    locationData.relationshipData.push({
      entityRelationshipConfigId: this.entityRelationshipConfigId,
      otherEntityId: this.rowData?.id,
    });
    locationData.data = this.homeLocationPayload;
    const entitiesData = {
      countryCode: this.country[0]?.countryCode,
      tenantCode: this.configService.getLoggedInTenantCode(),
      entityCode: AppSettings.ENTITY_TYPE.DRIVER_NEXT_SHIFT
    }
    this.driverService.createEntities(entitiesData.entityCode, entitiesData).subscribe((res: entityResponse) => {
      this.nextShiftId = res.entityId;
      this.saveLocationData(entitiesData.entityCode, locationData);
    });
  }

  bindHomeLocationDataToAttributes(payload: any, driverHomeAddress: any, locationType: any): any {
    payload.forEach((tab: any) => {
      tab.groups.forEach((group: any) => {
        group.fields.forEach((field: any) => {
          switch (field.attributeCode) {
            case 'next_shift_location_type':
              field.attributeValue = locationType || null;
              break;
            
            case 'next_shift_location_address':
              field.attributeValue = `${driverHomeAddress.address_line_1}, ${driverHomeAddress.address_line_2}` || null;
              break;
            
            case 'next_shift_location_geolocation':
              field.attributeValue = driverHomeAddress.driver_geo_location || null;
              break;
            
            case 'next_shift_address':
              field.attributeValue = `${driverHomeAddress.address_line_1}, ${driverHomeAddress.address_line_2}` || null;
              break;
            
            case 'next_shift_nearby_landmark':
              field.attributeValue = driverHomeAddress.driver_address_landmark || null;
              break;
            
            case 'next_shift_state':
              field.attributeValue = driverHomeAddress.state || null;
              break;
            
            case 'next_shift_city':
              field.attributeValue = driverHomeAddress.city || null;
              break;
            
            case 'next_shift_pincode':
              field.attributeValue = driverHomeAddress.postal_code || null;
              break;
            
            case 'driver_entity_id':
              field.attributeValue = this.driverData.id || null;
              break;
  
            default:
              field.attributeValue = null;
              break;
          }
        });
      });
    });
    return payload;
  }

  extractAttributeHomeLocationData(payload: any) {
    return payload.flatMap((tab: any) =>
      tab.groups.flatMap((group: any) =>
        group.fields
          .filter((field: any) => field.attributeId && field.attributeValue !== null)
          .map((field: any) => ({
            attributeId: field.attributeId,
            attributeValue: field.attributeValue
          }))
      )
    );
  }
  
  onSaveHubLocation(locationType) {
    const hubLocationPayload = this.bindHubLocationDataToAttributes(this.nextShiftAttributeData, this.locationData, locationType);
    this.hubLocationPayload = this.extractAttributeHomeLocationData(hubLocationPayload);
    const locationData = this.initializeLocationData();
    locationData.relationshipData.push({
      entityRelationshipConfigId: this.entityRelationshipConfigId,
      otherEntityId: this.rowData?.id,
    });
    locationData.data = this.hubLocationPayload;
    const entitiesData = {
      countryCode: this.country[0]?.countryCode,
      tenantCode: this.configService.getLoggedInTenantCode(),
      entityCode: AppSettings.ENTITY_TYPE.DRIVER_NEXT_SHIFT
    }
    this.driverService.createEntities(entitiesData.entityCode, entitiesData).subscribe((res: entityResponse) => {
      this.nextShiftId = res.entityId;
      this.saveLocationData(entitiesData.entityCode, locationData);
    });
  }
  
  bindHubLocationDataToAttributes(payload: any, driverHubAddress: any, locationType: any): any {
    payload.forEach((tab: any) => {
      tab.groups.forEach((group: any) => {
        group.fields.forEach((field: any) => {
          switch (field.attributeCode) {
            case 'next_shift_location_type':
              field.attributeValue = locationType || null;
              break;
            
            case 'next_shift_location_address':
              field.attributeValue = driverHubAddress.location_display_name_for_booker || null;
              break;
            
            case 'next_shift_location_geolocation':
              field.attributeValue = driverHubAddress.location_geolocation || null;
              break;
            
            case 'next_shift_address':
              field.attributeValue = driverHubAddress.location_address || null;
              break;
            
            case 'next_shift_nearby_landmark':
              field.attributeValue = null;
              break;
            
            case 'next_shift_state':
              field.attributeValue = null;
              break;
            
            case 'next_shift_city':
              field.attributeValue = null;
              break;
            
            case 'next_shift_pincode':
              field.attributeValue = null;
              break;
            
            case 'driver_entity_id':
              field.attributeValue = this.driverData.id || null;
              break;
  
            default:
              field.attributeValue = null;
              break;
          }
        });
      });
    });
    return payload;
  }

  onSaveNextShift(event) {
    const locationData = this.initializeLocationData();
    locationData.relationshipData.push({
      entityRelationshipConfigId: this.entityRelationshipConfigId,
      otherEntityId: this.rowData?.id,
    });
    this.populateDateFromEvent(locationData, event);
    const entitiesData = {
      countryCode: this.country[0]?.countryCode,
      tenantCode: this.configService.getLoggedInTenantCode(),
      entityCode: AppSettings.ENTITY_TYPE.DRIVER_NEXT_SHIFT
    }
    this.driverService.createEntities(entitiesData.entityCode, entitiesData).subscribe((res: entityResponse) => {
      this.nextShiftId = res.entityId;
      this.saveLocationData(entitiesData.entityCode, locationData);
    });
  }

  initializeLocationData() {
    return {
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: AppSettings.ENTITY_TYPE.DRIVER_NEXT_SHIFT,
      countryCode: this.country[0]?.countryCode,
      languageCode: this.language[0]?.langCode,
      data: [],
      relationshipData: [],
    };
  }

  populateDateFromEvent(locationData, event) {
    const lData = event;
    for (const [key, value] of Object.entries(lData)) {
      const attributeValue = <any>value;

      if (key === AppSettings.DRIVER_ATTRIBUTES.DRIVER_ENTITY_ID) {
        locationData.data.push({
          attributeCode: key,
          attributeValue: this.driverData.id
        })
      }

      if (key === AppSettings.DRIVER_ATTRIBUTES.NEXT_SHIFT_LOCATION_TYPE) {
        locationData.data.push({
          attributeCode: key,
          attributeValue: this.selectedLocationType
        })
      }

      if (attributeValue !== null) {
        if (key === AppSettings.LOCATION_ATTRIBUTES.LOCATION_ADDRESS) {
          const geolocation = attributeValue

          locationData.data.push({
            attributeCode: key,
            attributeValue: geolocation.address
          })
          locationData.data.push({
            attributeCode: AppSettings.LOCATION_ATTRIBUTES.LOCATION_GEOLOCATION,
            attributeValue: `${geolocation.lat},${geolocation.lng}`
          })
        } 
        else if (key === AppSettings.LOCATION_ATTRIBUTES.LOCATION_GEOLOCATION) { /* empty */ } else {
          locationData.data.push({
            attributeCode: key,
            attributeValue
          });
        }
      }
    }
  }

  saveLocationData(entityCode: string, locationData: { forTenantCode?: any; entityCode?: string; countryCode?: any; languageCode?: any; data?: any; relationshipData?: any;}) {
    locationData.data = this.cs.mapAttributeIdsForRelatedData(locationData.data, this.nextShiftAttributeData);
    this.driverService.saveAttributeData(entityCode, this.nextShiftId, locationData).subscribe(res => {
      if (res) {
        this.callOffDutyReason();
      }
    });
  }

  callOffDutyReason() {
    const requestBody = {
      forTenantCode: this.configService.getForTenantCode(),
      countryCode: this.country[0]?.countryCode,
      languageCode: this.language[0]?.langCode,
      driverEntityId: this.driverData.id,
      dutyStatus: AppSettings.DRIVER.DUTY_STATUS.OFF_DUTY,
      offDutyReason: this.offDutyReason,
    };

    this.driverService.saveDriverDutyStatusData(AppSettings.ENTITY_CODE.DRIVER, requestBody).subscribe(result => {
      this.messageService.add({key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('lbl_off_duty_reason_success')})
      this.isOffDutyVisible = false;
      this.isVisibleLogOff = false;
      this.onToggleOverLay.emit();
    })
  }

  onCancel() {
    this.isOffDutyVisible = true;
    this.isVisibleLogOff = false;
    this.miFormComponent.resetForm();
    this.router.navigate(['app/drivers/list']);
  }

  onDutyReasonChange(currentRow) {
    this.offDutyReason = currentRow.label;
    if(this.offDutyReason === this.cs.getLabel('lbl_log_off')) {
      this.isVisibleLogOff = true;
    }
    else {
      this.callOffDutyReason();
    }
  }

  onOffDutyChange(data) {
    const { labelValue } = data;
    const isOther = labelValue === AppSettings.DRIVER_NEXT_SHIFT_ATTRIBUTES.OTHER;
    const isLocation = [AppSettings.DRIVER_NEXT_SHIFT_ATTRIBUTES.HOME_LOCATION,AppSettings.DRIVER_NEXT_SHIFT_ATTRIBUTES.TRANSPORT_HUB_LOCATION].includes(labelValue);
    this.isFormShow = isOther;
    this.isDriverAddressShow = isOther || isLocation;
    this.isShowSaveBtn = !isOther || isLocation;
  }

  searchEntity() {
    const country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.tableData = [];
    this.entityDataForOffDuty.countryCode = country[0].countryId;
    this.driverService
      .searchEntity(AppSettings.ENTITY_CODE.DRIVER, this.entityDataForOffDuty)
      .subscribe((res: DriverList) => {
        this.listColumns = res?.data[0]?.values;
        this.tableData = res.data?.map(data => {
          const relatedDrivers = data?.relatedData?.map(relatedData => ({
            id: relatedData?.id,
            ...relatedData?.values
          })) || [];
          return { id: data.id, ...data.values, drivers: relatedDrivers };
        }) || [];
      });
  }

  ngOnDestroy() {
    this.tableDriverOffDutyData = [];
  }
}

