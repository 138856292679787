@if (isPassengerTypePrivilegeConfig(field)) {
  <app-passenger-type-field
    [field]="field"
    [attributeLabels]="attributeLabels"
    [moduleName]="moduleName"
    [dateFormat]="dateFormat"
    [multiSelectDisplay]="multiSelectDisplay">
  </app-passenger-type-field>
  @if (isResetPrivilege) {
    <label class="reset-label" (click)="resetPrivilegeSettings()" translate>lbl_reset</label>
  }
}
@else if (field.attributeCode === 'predefined_location_category_for_pickup_check' || field.attributeCode === 'predefined_location_category_for_drop_check') {
  <div class="grid">
    <div class="col-5 horizontal">
      <label class="hidden"></label>
    </div>
    <div class="col-5">
      <app-mi-fields 
        [field]="field" 
        [attributeLabels]="attributeLabels"  
        [dateFormat]="dateFormat"
        [moduleName]="moduleName" 
        [multiSelectDisplay]="multiSelectDisplay">
      </app-mi-fields>
    </div>
  </div>
}
@else if (field.attributeCode === 'select_location_address' && (moduleName === 'pass_privilege_conf' || moduleName === 'passenger')) {
  <div class="hidden"></div>
}
@else {
  <app-two-column-structure
    [field]="field"
    [attributeLabels]="attributeLabels"
    [moduleName]="moduleName"
    [dateFormat]="dateFormat"
    [multiSelectDisplay]="multiSelectDisplay"
    [showHrLine]="shouldDisplayHrLine(field)">
  </app-two-column-structure>
} 