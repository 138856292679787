
@if(data === 'ON' || data === 'On') {
    <span class="status-on {{data}}" translate>lbl_on</span>
}
@else if(data === 'OFF' || data === 'Off') {
    <span class="status-off {{data}}" translate>lbl_off</span>
}
@else if(data === 'OnDuty') {
    <span class="status-on-duty {{data}}" translate>lbl_on</span>
}
@else if(data === 'OffDuty') {
    <span class="status-off-duty {{data}}" translate>lbl_off</span>
}
@else if(data === 'Pending') {
    <span class="leave-status {{data}}" translate></span>
    <span class="{{data}}">{{data}}</span>
}
@else if(data === 'Approved') {
    <span class="leave-status {{data}}" translate></span>
    <span class="{{data}}">{{data}}</span>
}
@else if(data === 'Approves') {
    <span class="leave-status {{data}}" translate></span>
    <span class="{{data}}">{{data}}</span>
}
@else if(data === 'Rejected') {
    <span class="leave-status {{data}}" translate></span>
    <span class="{{data}}">{{data}}</span>
}
@else {
    <span class="dot {{data}}"></span> {{data}}
}