<p-breadcrumb [model]="routePath"></p-breadcrumb>
<div appAccessProvider [accessCode]="{ menuCode: 'PASSENGERS', pageCode: 'ALL', subPageCode: 'VIEW' }">
<div class="grid mt-1 details-div">
    <div class="col-9">
        @if (showStatus) {
            <status [dataForStatus]="dataForStatus" [entityForStatus]="entityForStatus" [userId]="userId" [imgFileId]="imgFileId" [imgFileUrl]="imgFileUrl"></status>
        }
    </div>
    @if (!isDeleted) {
        <div appAccessProvider [accessCode]="{ menuCode: 'PASSENGERS', pageCode: 'ALL', subPageCode: 'DELETE' }" class="col-3 pr-3 text-right flex align-items-center justify-content-end">
            <button icon="mi-lg basic_tray" (click)="deletePassenger()" pButton class="border-400 bg-white text-color mr-1 pl-2 pr-2 pt-1 pb-1 mr-2 hover:surface-100 delete-btn" [label]="deleteBtnLabel"></button>
        </div>
    }
</div>


<p-tabView [(activeIndex)]="activeIndex"  [scrollable]="true" >
        <p-tabPanel>
            <ng-template pTemplate="header">
                <i class="mi-lg overview"></i>
                <label class="capitalize" translate>vehicle.lbl_overview</label>
            </ng-template>
            @if (showData) {
                <passenger-overview-details
                    [attributeValues]="attributeValues"
                    [attributeLabels]="attributeLabels"
                    [vehicleAttributeData]="vehicleAttributeData"
                    [passengerDetails]="passengerDetails"
                    [isDeleted]="isDeleted"
                ></passenger-overview-details>
            }
        </p-tabPanel>
    <p-tabPanel>
        <ng-template pTemplate="header">
            <i class="mi-lg mi-settings"></i>
            <label translate>passengers.lbl_privilege_settings</label>
        </ng-template>
        @if (vehicleAttributeData) {
            <app-privilege-settings
                [attributeValues]="attributeValues"
                [attributeLabels]="attributeLabels"
                [vehicleAttributeData]="vehicleAttributeData"
                [passengerDetails]="passengerDetails"
                [isDeleted]="isDeleted"
            ></app-privilege-settings>
        }

    </p-tabPanel>

    <p-tabPanel>
        <ng-template pTemplate="header">
            <i class="mi-lg journey-history"></i>
            <label translate>vehicle.lbl_journey_history</label>
        </ng-template>
        <ng-template pTemplate="body">
            <app-passenger-journey-history [tableData]="tableData" [passengerDetails]="passengerDetails"></app-passenger-journey-history>
            @if(tableData?.length === 0) {
                <app-no-result-found></app-no-result-found> 
            }
           
        </ng-template>
    </p-tabPanel>
</p-tabView>
</div>
