<p-breadcrumb [model]="routePath"></p-breadcrumb>
<div appAccessProvider [accessCode]="{ menuCode: 'SHIFT_MANAGEMENT', subMenuCode: 'MANAGE_GROUPS', pageCode: 'ALL', subPageCode: 'VIEW' }" class="manage-group-color">
    <div class="grid mt-0 details-div">
        <div class="col-9 flex justify-content-start align-items-center">
            <div class="col-1.5 flex justify-content-start align-items-center pt-3 pr-2 pb-3 pl-2">
                <label class="header-style font-semibold text-base">
                    {{ attributeValues['group_name'] ? attributeValues['group_name'] : '--' }}
                </label>
            </div>

            <div class="col-1.5 flex justify-content-start align-items-center pt-3 pr-2 pb-3 pl-3">
                <label class="header-style">
                    {{ attributeValues['assigned_drivers'] ? attributeValues['assigned_drivers'].length + '  '+ ('lbl_drivers' | translate) : '--' }}
                </label>
            </div>

            <div class="col-1.5 flex justify-content-start align-items-center pt-3 pr-2 pb-3 pl-3">
                <app-status-cell [data]="attributeValues['group_status']">
                </app-status-cell>
            </div>
        </div>

        <div class="col-3 text-right flex align-items-center justify-content-end">
            <div appAccessProvider [accessCode]="{ menuCode: 'SHIFT_MANAGEMENT', subMenuCode: 'MANAGE_GROUPS', pageCode: 'ALL', subPageCode: 'VIEW' }">
                <button (click)="edit()" pButton class="edit-btn border-400 bg-white text-color mr-1 pl-2 pr-2 pt-1 pb-1 mr-2 hover:surface-100" [label]="editBtnLabel">
                    <i class="mi-lg mr-2 edit-pen edit_pencil wh-16"></i>
                </button>
            </div>
        </div>
    </div>

    <div class="grid mt-3 ml-3 mr-3 overview-details">
        <div class=" md:col-12 lg:col-6 pl-0">
            <div class="card p-fluid">
                <div class="flex justify-content-between flex-wrap">
                    <div class="flex justify-content-center mb-3">
                        <label class="card-heading uppercase" translate>manage_group.lbl_group_details</label>
                    </div>
                </div>
                @for (field of details?.fields; track field; let i = $index) {
                    @if (i !== 0) {
                        <hr class="hr-line">
                    } 
                    @if(field.attributeCode === 'transport_supervisor_number') {
                        <div class="grid">
                            <div class="col-6 pt-1">
                                <label class="detail-heading" translate>{{cs.getLabelValue(moduleName+'.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                            </div>
                            <div class="col-6 pb-1">
                                @if(countryCodeForTransportSupervisorNumber) {
                                    <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png" [class]="'mr-2 flag flag-' + countryCodeForTransportSupervisorNumber?.toLowerCase()" class="width-18"/>
                                }
                                <label class="detail-value font-14">{{ attributeValues[field.attributeCode] ? attributeValues[field.attributeCode] : '--' }}</label>
                            </div>
                        </div>
                    } @else if(field.attributeCode === 'emergency_number') {
                        <div class="grid">
                            <div class="col-6 pt-1">
                                <label class="detail-heading" translate>{{cs.getLabelValue(moduleName+'.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                            </div>
                            <div class="col-6 pb-1">
                                @if(emergencyNumber) {
                                    <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png" [class]="'mr-2 flag flag-' + emergencyNumber?.toLowerCase()" class="width-18"/>
                                }
                                <label class="detail-value">{{ attributeValues[field.attributeCode] ? attributeValues[field.attributeCode] : '--' }}</label>
                            </div>
                        </div>
                    } @else if(field.attributeCode === 'group_color'){
                        <div class="grid">
                            <div class="col-6 pt-1">
                                <label class="detail-heading" translate>{{cs.getLabelValue(moduleName+'.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                            </div>
                            <div class="col-6 pb-1 inline-flex">
                                <app-group-color [colorCode]="attributeValues[field.attributeCode]" 
                                    [moduleName]="'driver_group'">
                                </app-group-color> 
                                <label class="detail-value">{{ attributeValues[field.attributeCode] ? attributeValues[field.attributeCode] : '--' }}</label>
                            </div>
                        </div>
                    } @else if(field.attributeCode === 'group_status'){
                        <div class="grid">
                            <div class="col-6 pt-1">
                                <label class="detail-heading" translate>{{cs.getLabelValue(moduleName+'.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                            </div>
                            <div class="col-6 pb-1 inline-flex">
                                <app-status-cell [data]="attributeValues[field.attributeCode]">
                                </app-status-cell>
                            </div>
                        </div>
                    } @else if(field.attributeCode === 'assigned_drivers'){
                        <div class="grid">
                            <div class="col-6 pt-1">
                                <label class="detail-heading">{{'lbl_total_drivers' | translate }}</label>
                            </div>
                            <div class="col-6 pb-1">
                                <label class="detail-value">{{ attributeValues[field.attributeCode] ? attributeValues[field.attributeCode].length : '--' }}</label>
                            </div>
                        </div>
                    } @else if(field.attributeCode === 'created_on'){
                        <div class="grid">
                            <div class="col-6 pt-1">
                                <label class="detail-heading" translate>{{cs.getLabelValue(moduleName+'.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                            </div>
                            <div class="col-6 pb-1">
                                <label class="detail-value">{{ attributeValues[field.attributeCode] ? (attributeValues[field.attributeCode] | date: dd_MMM_yyyy_hh_mm_FORMAT ) : '--' }}</label>
                            </div>
                        </div>
                    } @else {
                        <div class="grid">
                            <div class="col-6 pt-1">
                                <label class="detail-heading" translate>{{cs.getLabelValue(moduleName+'.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                            </div>
                            <div class="col-6 pb-1">
                                <label class="detail-value">{{ attributeValues[field.attributeCode] ? attributeValues[field.attributeCode] : '--' }}</label>
                            </div>
                        </div>
                    }
            }
            </div>
        </div>

        <div class=" md:col-12 lg:col-6 pl-3">
            <div class="card p-fluid">
                <div class="flex justify-content-between flex-wrap">
                    <div class="flex justify-content-center mb-3">
                        <label class="card-heading uppercase">{{ 'manage_group.added_drivers' | translate }} ({{ attributeValues['assigned_drivers'] ? attributeValues['assigned_drivers'].length : '0' }})</label>
                    </div>
                </div>
            
                    @for(driver of tableData; track driver; let i = $index){
                            <div class="flex flex-wrap style-added-drivers ">
                                <div class="col-1 preview-image-details">
                                    <app-mi-image-container [miImage]="driver?.driverProfileImage || defaultLoaderImg" miWidth="30"></app-mi-image-container>
                                </div>
            
                                <div class="col-1.5 mr-2">
                                <label class="added-value font-14">{{ driver.values.first_name || '--' }}</label>
                                </div>

                                <div class="col-1.5 mr-2">
                                <label class="added-value font-14">{{ driver.values.last_name || '--' }}</label>
                                </div>
            
                                <div class="col-1.5">
                                <label class="added-value-color font-14"> ({{ driver.values.unique_id || '--' }}) </label>
                                </div>
                            </div>
                            @if(i > -1 && i < attributeValues['assigned_drivers'].length-1){
                                <hr class="hr-line">
                            }
                        
                    }
        </div>
    </div>

<!-- <div class="grid mt-2 ml-3 mr-3 overview-details">
    <div class=" md:col-12 lg:col-6  pl-0">
        <div class="card p-fluid">
            <div class="flex justify-content-between flex-wrap">
                <div class="flex justify-content-center mb-3">
                    <label class="card-heading uppercase" translate>manage_group.lbl_group_details</label>
                </div>
            </div>
            @for (field of details?.fields; track field; let i = $index) {
                @if (i !== 0) {
                    <hr class="hr-line">
                } 
                @if(field.attributeCode === 'transport_supervisor_number') {
                    <div class="grid">
                        <div class="col-6 pt-1">
                            <label class="detail-heading" translate>{{cs.getLabelValue(moduleName+'.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                        </div>
                        <div class="col-6 pb-1">
                            @if(countryCodeForTransportSupervisorNumber) {
                                <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png" [class]="'mr-2 flag flag-' + countryCodeForTransportSupervisorNumber?.toLowerCase()" class="width-18"/>
                            }
                            <label class="detail-value font-14">{{ attributeValues[field.attributeCode] ? attributeValues[field.attributeCode] : '--' }}</label>
                        </div>
                    </div>
                } @else if(field.attributeCode === 'emergency_number') {
                    <div class="grid">
                        <div class="col-6 pt-1">
                            <label class="detail-heading" translate>{{cs.getLabelValue(moduleName+'.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                        </div>
                        <div class="col-6 pb-1">
                            @if(emergencyNumber) {
                                <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png" [class]="'mr-2 flag flag-' + emergencyNumber?.toLowerCase()" class="width-18"/>
                            }
                            <label class="detail-value">{{ attributeValues[field.attributeCode] ? attributeValues[field.attributeCode] : '--' }}</label>
                        </div>
                    </div>
                } @else if(field.attributeCode === 'group_color'){
                    <div class="grid">
                        <div class="col-6 pt-1">
                            <label class="detail-heading" translate>{{cs.getLabelValue(moduleName+'.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                        </div>
                        <div class="col-6 pb-1 inline-flex">
                            <app-group-color [colorCode]="attributeValues[field.attributeCode]" 
                                [moduleName]="'driver_group'">
                            </app-group-color> 
                            <label class="detail-value">{{ attributeValues[field.attributeCode] ? attributeValues[field.attributeCode] : '--' }}</label>
                        </div>
                    </div>
                } @else if(field.attributeCode === 'group_status'){
                    <div class="grid">
                        <div class="col-6 pt-1">
                            <label class="detail-heading" translate>{{cs.getLabelValue(moduleName+'.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                        </div>
                        <div class="col-6 pb-1 inline-flex">
                            <app-status-cell [data]="attributeValues[field.attributeCode]">
                            </app-status-cell>
                        </div>
                    </div>
                } @else if(field.attributeCode === 'assigned_drivers'){
                    <div class="grid">
                        <div class="col-6 pt-1">
                            <label class="detail-heading">{{'lbl_total_drivers' | translate }}</label>
                        </div>
                        <div class="col-6 pb-1">
                            <label class="detail-value">{{ attributeValues[field.attributeCode] ? attributeValues[field.attributeCode].length : '--' }}</label>
                        </div>
                    </div>
                } @else if(field.attributeCode === 'created_on'){
                    <div class="grid">
                        <div class="col-6 pt-1">
                            <label class="detail-heading" translate>{{cs.getLabelValue(moduleName+'.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                        </div>
                        <div class="col-6 pb-1">
                            <label class="detail-value">{{ attributeValues[field.attributeCode] ? (attributeValues[field.attributeCode] | date: dd_MMM_yyyy_hh_mm_FORMAT ) : '--' }}</label>
                        </div>
                    </div>
                } @else {
                    <div class="grid">
                        <div class="col-6 pt-1">
                            <label class="detail-heading" translate>{{cs.getLabelValue(moduleName+'.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                        </div>
                        <div class="col-6 pb-1">
                            <label class="detail-value">{{ attributeValues[field.attributeCode] ? attributeValues[field.attributeCode] : '--' }}</label>
                        </div>
                    </div>
                }
        }
        </div>
    </div>

    <div class=" md:col-12 lg:col-6 pl-0">
        <div class="card p-fluid">
            <div class="flex justify-content-between flex-wrap">
                <div class="flex justify-content-center mb-3">
                    <label class="card-heading uppercase">{{ 'manage_group.added_drivers' | translate }} ({{ attributeValues['assigned_drivers'] ? attributeValues['assigned_drivers'].length : '0' }})</label>
                </div>
            </div>
        
                @for(driver of tableData; track driver; let i = $index){
                        <div class="flex flex-wrap style-added-drivers">
                            <div class="col-1 preview-image-details">
                                <app-mi-image-container [miImage]="driver?.driverProfileImage || defaultLoaderImg" miHeight="30" miWidth="30"></app-mi-image-container>
                            </div>
          
                            <div class="col-1.5 mr-2">
                              <label class="added-value font-14">{{ driver.values.first_name || '--' }}</label>
                            </div>

                            <div class="col-1.5 mr-2">
                              <label class="added-value font-14">{{ driver.values.last_name || '--' }}</label>
                            </div>
          
                            <div class="col-1.5">
                              <label class="added-value-color font-14"> ({{ driver.values.unique_id || '--' }}) </label>
                            </div>
                        </div>
                        @if(i > -1 && i < attributeValues['assigned_drivers'].length-1){
                            <hr class="hr-line">
                        }
                     
                }
    </div>
</div>

</div> -->
