import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { EventService } from 'app/modules/events/services/event.service';
import { AppSettings } from 'app/modules/shared/app.settings';
import { MenuItem } from 'primeng/api';
import { TabMenuModule } from 'primeng/tabmenu';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-transportation-type',
  standalone: true,
  imports: [TabMenuModule],
  templateUrl: './transportation-type.component.html',
  styleUrl: './transportation-type.component.scss'
})
export class TransportationTypeComponent {
  @Input() bookingStatus: string;
  @Input() isEdit: boolean;
  @Output() transportationType = new EventEmitter<string>();
  @Output() transportationTypeList = new EventEmitter<MenuItem[]>();
  items: MenuItem[] = [];
  activeItem: MenuItem | undefined;
  private previousLabel: string | undefined;

  constructor(
    private eventService: EventService, 
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    const eventId = this.eventService.getSelectedEventId();
    if (!eventId) {
      console.log('eventId not found');
      return;
    }

    this.eventService.getTransportationTypes(eventId)
      .pipe(distinctUntilChanged())
      .subscribe(result => {
        if (!Array.isArray(result)) return;

        this.items = result.map((item) => ({
          label: item.transportationType,
          icon: this.getIconClass(item.transportationType),
          command: () => this.onActiveItemChange(item),
          disabled: false,
        }));
        this.transportationTypeList.emit(this.items);

        if (!this.activeItem) {
          this.activeItem = this.items[0];
          this.emitTransportationType(this.activeItem.label);
        }
        
        this.cd.detectChanges();
      });
  }

  ngOnChanges() {
    // console.log('bookingStatus', this.bookingStatus);
    if (this.bookingStatus === AppSettings.BOOKING_STATUS_IDS.DRIVER_ON_THE_WAY || this.bookingStatus === AppSettings.BOOKING_STATUS_IDS.RECONFIRMED ||
      this.bookingStatus === AppSettings.BOOKING_STATUS_IDS.DRIVER_ASSIGNED || this.bookingStatus === AppSettings.BOOKING_STATUS_IDS.ACKNOWLEDGED && this.isEdit) {
      this.items = this.items.map(item => ({
        ...item,
        disabled: item.label !== this.activeItem.label
      }));
      this.activeItem = this.items.find(ele => !ele.disabled);
    } else {
      this.items = this.items.map(item => ({
        ...item,
        disabled: false
      }));
      this.activeItem = this.items[0];
    }
    this.cd.detectChanges();
  }

  private getIconClass(type: string): string {
    const className = type === 'Airport' ? 'airport-icon' : type.toLowerCase().replace(/\s+/g, '-');
    return `pi pi-fw mi-${className} wh-30 mi-lg`;
  }

  onActiveItemChange(event: MenuItem) {
    if (!event?.label || this.previousLabel === event.label) return;
    
    this.activeItem = event;
    this.emitTransportationType(event.label);
  }

  private emitTransportationType(label: string) {
    this.previousLabel = label;
    this.transportationType.emit(label);
  }
}
