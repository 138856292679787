import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { UserManagementService } from '../../services/user-management.service';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { DialogModule } from 'primeng/dialog';
import { AppSettings } from 'app/modules/shared/app.settings';
import { KeyFilterModule } from 'primeng/keyfilter';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { PasswordModule } from 'primeng/password';
import { AccessProviderDirective } from 'app/modules/shared/directives/access-provider.directive';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrl: './user-details.component.scss',
  standalone: true,
  imports: [BreadcrumbModule, ButtonModule, TranslateModule, FormsModule, ReactiveFormsModule, InputTextModule, DialogModule, KeyFilterModule, PasswordModule, AccessProviderDirective],
})
export class UserDetailsComponent implements OnInit {
  routePath: MenuItem[] = [];
  userId: string;
  userDetails;
  roleName: string;
  editBtnLabel: any;
  visible: boolean = false;
  addNewPassword: string;
  blockChars = AppSettings.BLOCK_CHARS;
  eventNames: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userManagementService: UserManagementService,
    private cs: CommonBindingDataService,
    private configService: ConfigService,
    private messageService: MessageService,
  ) {

  }

  ngOnInit(): void {
    this.editBtnLabel = this.cs.getLabel('lbl_edit');
    this.userId = this.route.snapshot.paramMap.get('id');
    this.getUserDetailsDetails();
    this.setRoutePath();
  }

  setRoutePath() {
    this.routePath = [
      {
        label: this.cs.getLabel('manage_users.lbl_route_users'),
        routerLink: '../../manage-users',
        icon: 'pi pi-arrow-left',
        iconStyle: { 'font-weight': '600', 'margin-right': '10px' }
      },
      {
        label: this.cs.getLabel('manage_users.lbl_route_user_details'),
        styleClass: 'breadcrumb-child forward-slash add-text',
        style: { 'display': 'flex', 'top': '2px', 'position': 'relative' }
      }
    ];
  }

  getUserDetailsDetails() {
    this.userManagementService.getUserDetails(this.userId).subscribe((userData: any) => {
      this.userDetails = userData;
      if (userData.rolelist && userData.rolelist.length > 0) {
        this.roleName = userData.rolelist[0].roleName;
      }
      if (userData.eventList && userData.eventList.length > 0) {
        this.eventNames = userData.eventList.map(event => event.eventName).join(', ');
      }
    });
  }

  onShowDialog() {
    this.visible = true;
  }

  onHide() {
    this.visible = false;
    this.addNewPassword = '';
  }

  onResetPassword() {
    const data = {
      forTenantCode: this.configService.getForTenantCode(),
      newPassword: this.addNewPassword
    }
    this.userManagementService.resetPassword(this.userId, data).subscribe({
      next: (res: any) => {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel(res.message) });
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
      }
    })
    this.visible = false;
  }

  isValidInput(): boolean {
    return this.addNewPassword ? AppSettings.RESET_PASSWORD_PATTERN.test(this.addNewPassword) : false;
  }

  edit() {
    this.router.navigate(['app/user-management/manage-users/edit-user/' + this.userId,]);
  }

}
