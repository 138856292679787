<div class="header-rule flex justify-content-end">

  <ul class="header--menu fadeInDown flex flex-row">
    <li class="flex align-items-center">
      <label class="font-semibold" id="timezone"></label>
    </li>
    <li>
      <a class="bg-white top-7" (click)="goToSettings()"><span><i class="{{miIcon.SETTINGS}} inline-block wh-22" ></i></span></a>
    </li>
    <li>
      <a class="bg-white top-7" (click)="openNotificationTray()"><span><i class="pi pi-bell text-2xl" pBadge [value]="unReadNotificationsCount" severity="danger" [badgeDisabled]="!unReadNotificationsCount"></i></span></a>
    </li>
    <li class="user-profile" (click)="onProfileClick($event)" (mouseenter)= "showMenu()">
        @if (profileData?.profileImage) {
          <img [src]="profileData.profileImage"
            class="height-40-px width-40-px border-circle cursor-pointer"/>
        }
        @if (!profileData?.profileImage) {
          <div class="img-background relative height-40-px width-40-px border-circle surface-300 cursor-pointer">
            <span class="image-name text-lg font-bold">{{createAcronym(profileData?.username || profileData?.data.email)}}</span>
          </div>
        }
        @if(showPopUp){
          <ul class="fadeInDown" (mouseenter)= "showMenu()" (mouseleave)= "hideMenu()">
            <li role="menuitem" (click)="goToSettings()"><a href="#"><span>Edit Profile</span><i class="{{miIcon.USER_CIRCLE}} inline-block wh-24"></i></a></li>
            <li role="menuitem" (click)="goToChangePassword()"><a href="#"><span>Change Password</span><i class="{{miIcon.PASSWORD}} inline-block wh-24"></i></a></li>
            <li role="menuitem" (click)="doSignOut()"><a href="#"><span>Logout</span><i class="{{miIcon.LOG_OUT}} inline-block wh-24"></i></a></li>
          </ul>
        }
    </li>
  </ul>
 
</div>

@if(notifications.length > 0) {
  <app-notification-tray 
    [notifications]="notifications"
    (loadMore)="loadMoreNotifications()"
    [notificationParams]="notificationParams"
    (markAllRead)="markAllReadNotifications()"
    [unReadNotificationsCount]="unReadNotificationsCount"
    [notificationCount]="notificationCount"
  ></app-notification-tray>
}

<p-confirmDialog 
  header="Confirmation"
  [closable]="true"
  [dismissableMask]="true"
></p-confirmDialog>
