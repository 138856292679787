<form [formGroup]="formName">
    @if(!field.multi) {
        @if (field.attributeCode === 'gender') {
            <p-dropdown 
                #miSelect
                [options]="field.presetValues"
                [placeholder]="placeholder"
                optionValue="labelValue"
                optionLabel="labelValue"
                (change)="changedValue(miSelect.value)"
                [formControlName]="field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode"
                >
                <ng-template let-value pTemplate="item">
                    <div class="flex align-items-center gap-2">
                        <i class="mi-lg" [class]="value.labelValue.toLowerCase() + '_gender'"></i>
                        <div>{{ value.labelValue }}</div>
                    </div>
                </ng-template>
            </p-dropdown>

        } @else if (field.attributeCode === 'group_color') {
            <p-dropdown #miSelect [options]="field.presetValues" [placeholder]="placeholder" optionValue="labelValue" 
                optionLabel="labelValue" (change)="changedValue(miSelect.value)" 
                [formControlName]="field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode">
                <ng-template let-value pTemplate="item">
                    <div class="flex align-items-center gap-2">
                        <i class="mi-lg" [class]="value.labelValue.toLowerCase()"></i>
                        <span class="item-content">
                            <app-group-color [colorCode]="value.color" [moduleName]="'driver_group'"></app-group-color> 
                            {{value.labelValue}}
                        </span>
                    </div>
                </ng-template>
            </p-dropdown>
        } @else if (field.attributeCode === 'color') {
            <p-dropdown #miSelect [options]="field.presetValues" [placeholder]="placeholder" optionValue="labelValue" optionLabel="labelValue" (change)="changedValue(miSelect.value)" [formControlName]="field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode">
                <ng-template let-item pTemplate="item">
                    <div class="flex align-items-center gap-2">
                      <app-group-color [colorCode]="item.labelValue"></app-group-color>
                    </div>
                  </ng-template>
            </p-dropdown>
        } @else if (field.attributeCode === 'vehicle_body_type_preference') {
            <p-dropdown #miSelect [options]="field.presetValues" [placeholder]="placeholder" optionValue="labelValue" optionLabel="labelValue" (change)="changedValue(miSelect.value)" [formControlName]="field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode"></p-dropdown>
        } @else if (field.attributeCode === 'reporter_type') {
            <p-dropdown #miSelect [options]="field.presetValues" [placeholder]="placeholder" optionValue="labelValue" optionLabel="labelValue" (onChange)="changedSelectedValue(miSelect.value)" [formControlName]="field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode"></p-dropdown>
        }
        @else {
            <p-dropdown #miSelect [options]="field.presetValues" [placeholder]="placeholder" optionValue="labelValue" optionLabel="labelValue" (change)="changedValue(miSelect.value)" [formControlName]="field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode"></p-dropdown>
        }
    }
    @if(field.multi) {
        @if (moduleName === 't_conf_sett') {
            <p-multiSelect #miSelect
            [options]="field.presetValues"
            [formControlName]="field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode"
            [placeholder]="placeholder"
            optionValue="labelValue"
            optionLabel="labelKey"
            (onChange)="changedMultiValue(miSelect.value)"
            [display]="multiSelectDisplay"
            appendTo="body"
            (onFilter)="onFilter($event)">

            <ng-template pTemplate="filtericon">
                <i [class]="filterIcon" (click)="onFilterIconClick()"></i>
            </ng-template>
        </p-multiSelect>
        } @else {
            <p-multiSelect #miSelect
            [options]="field.presetValues"
            [formControlName]="field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode"
            [placeholder]="placeholder"
            optionValue="labelValue"
            optionLabel="labelValue"
            (onChange)="changedMultiValue(miSelect.value)"
            [display]="multiSelectDisplay"
            (onFilter)="onFilter($event)">

            <ng-template pTemplate="filtericon">
                <i [class]="filterIcon" (click)="onFilterIconClick()"></i>
            </ng-template>
        </p-multiSelect>
        }
    }
</form>
