import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ToggleButtonModule } from "primeng/togglebutton";
import { AppSettings } from "../../../shared/app.settings";
import { CommonBindingDataService } from "../../../shared/services/common-binding-data.service";
import { MetaDataService } from "app/modules/shared/services/meta-data.service";
import { GoogleMapsService } from '../../../shared/services/google-map.services';

@Component({
  selector: "app-zone-map",
  templateUrl: "./zone-map.component.html",
  styleUrls: ["./zone-map.component.scss"],
  standalone: true,
  imports: [ToggleButtonModule, FormsModule],
})
export class ZoneMapComponent implements OnInit {
  @Input() zonesData: { polygons: any[]; circles: any[] };
  @Input() zoneDetailsItem: any;
  @Input() zoneFormDetails: any;
  @Input() drawGeofence: any;
  @Input() showGoogleSearchBox: boolean;
  @Input() colorPicker: string;
  @Input() centerCordinateList;
  @Input() isAddPage: boolean = false;
  @Input() isLandingPage: boolean = false;
  @Output() polygonCoordinates = new EventEmitter<string>();
  @Output() searchLatLng = new EventEmitter<any>();
  searchText: string = null;
  newGeoLocation: any;
  position: any;
  map: google.maps.Map;
  drawnPolygon: google.maps.Polygon;
  drawingManager: google.maps.drawing.DrawingManager;
  infoWindow: google.maps.InfoWindow;
  checked: boolean = false;
  clickListener: google.maps.MapsEventListener | null = null;

  points: google.maps.LatLng[] = [];
  isDrawing = false;
  searchMarker: google.maps.marker.AdvancedMarkerElement | null = null;
  isPolygonCoordinatesSet: boolean = false;
  centerCords: google.maps.LatLngLiteral = { lat: 18.5204, lng: 73.8567 };



  constructor(
    private cs: CommonBindingDataService,
    private cdr: ChangeDetectorRef,
    private metaDataService: MetaDataService,
    private googleMapsService: GoogleMapsService
  ) { }

  ngOnInit() {
    const latitude = this.metaDataService.latitude;
    const longitude = this.metaDataService.longitude;
    if (latitude !== null && longitude !== null) {
      this.centerCords = { lat: latitude, lng: longitude };
    }
    this.initMap();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['zoneFormDetails']) { 
      this.clearPolygon();
      this.setOtherZones();
    }
  }

  private calculateCenterPoint(coordinates) {
    if (coordinates.length === 0) {
      return null;
    }

    const avgLat = coordinates.reduce((sum, c) => sum + c.lat, 0) / coordinates.length;
    const avgLng = coordinates.reduce((sum, c) => sum + c.lng, 0) / coordinates.length;

    return { lat: avgLat, lng: avgLng };
  }

  clearSearch() {
    this.searchText = '';
    this.clearSearchMarker();
  }

  clearSearchMarker() {
    if (this.searchMarker) {
      this.searchMarker.map = null;
      this.searchMarker = null;
    }
  }

  async initMap(): Promise<void> {
    this.searchText = null;
    let centerCords;
    if (this.centerCordinateList > 0) {
      centerCords = this.calculateCenterPoint(this.centerCordinateList);
    } else {
      centerCords = { lat: 18.5204, lng: 73.8567 }
    }
    const { Map, InfoWindow } = (await google.maps.importLibrary(
      "maps"
    )) as google.maps.MapsLibrary;

    this.map = new Map(document.getElementById("map") as HTMLElement, {
      center: this.centerCords,
      zoom: 10,
      zoomControl: true,
      streetViewControl: false,
      fullscreenControl: true,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapTypeControl: false,
      mapId: this.googleMapsService.mapId
    });

    await new Promise((resolve) => {
      google.maps.event.addListenerOnce(this.map, 'idle', () => {
        resolve(true);
      });
      this.infoWindow = new InfoWindow();
      this.setOtherZones();
    });

    
  }
  

  setOtherZones() {    
    this.setupControls();
    if (this.isLandingPage) {
      this.setZones();
    } else if (this.isAddPage) { 
      this.setZones();
      this.initDrawingPolygon(this.colorPicker);
    } else { 
      this.setZones();
      if (this.zoneFormDetails?.geoFence || this.zoneFormDetails?.geoFence === null) {
        this.setPolygonFromCoordinates(this.zoneFormDetails?.geoFence);
        if (this.drawnPolygon && this.zoneFormDetails.zoneColor) {
          this.updatePolygonColor(this.zoneFormDetails.zoneColor);
        }
      }
      this.cdr.detectChanges();
    }

  
    this.initSearch();
    this.setCurrentPositionMarker();
  }

  setCurrentPositionMarker() {
    navigator.geolocation.getCurrentPosition(
      async (position: GeolocationPosition) => {
        const pos = {
          lat: this.centerCords.lat,
          lng:this.centerCords.lng,
        };

        // Import marker library
        const { AdvancedMarkerElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;

        // Create and set the marker
        const marker = new AdvancedMarkerElement({
          position: pos,
          map: this.map,
          title: "Current Location"
        });

        // Optionally center the map on the marker
        this.map.setCenter(pos);
      },
      () => {
        this.handleLocationError(true);
      }
    );
  }

  async initSearch() {
    const { AdvancedMarkerElement } = (await google.maps.importLibrary(
      "marker"
    )) as google.maps.MarkerLibrary;
    const element = document.getElementById("googleSearch");
    this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(element);

    const options = {
      fields: ["formatted_address", "geometry", "name"],
      strictBounds: false,
    };
    const inputHtmlElement = document.getElementById(
      "autocompleteSearch"
    ) as HTMLInputElement;
    const autocomplete = new google.maps.places.Autocomplete(
      inputHtmlElement,
      options
    );
    autocomplete.bindTo("bounds", this.map);

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      if (place.geometry.viewport) {
        this.map.fitBounds(place.geometry.viewport);
      } else {
        this.map.setCenter(place.geometry.location);
        this.map.setZoom(17);
      }

      const marker = new AdvancedMarkerElement({
        map: this.map,
        position: place.geometry.location,
        gmpDraggable: true,
      });

      this.searchMarker = marker;

      marker.addListener("dragend", (event: google.maps.MapMouseEvent) => {
        const newLatLong = {
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
        };
        this.searchLatLng.emit(newLatLong);
      });

      marker.addListener("click", () => {
        const newLatLong = {
          lat: marker.position.lat,
          lng: marker.position.lng,
        };
        this.searchLatLng.emit(newLatLong);
      });

      inputHtmlElement.addEventListener("input", () => {
        if (!inputHtmlElement.value) {
          this.clearSearchMarker();
        }
      });

      const latLong = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      this.searchLatLng.emit(latLong);
    });
  }

  setPolygonFromCoordinates(coordinates: string) {
    const {  zoneColor} = this.zoneFormDetails;
    this.createPolygonFromCoordinates(coordinates, zoneColor);
  }

  createPolygonFromCoordinates(coordinates: string, zoneColor: string) {
    const parsedCoordinates = coordinates.split(",").map(coord => {
      const [lng, lat] = coord.split(" ").map(parseFloat);
      return { lat, lng };
    });

    const polygon = new google.maps.Polygon({
      paths: parsedCoordinates,
      editable: true,
      draggable: true,
      strokeColor: zoneColor,
      fillColor: zoneColor,
    });

    this.setupPolygonListeners(polygon);    
    this.drawnPolygon = polygon;
    polygon.setMap(this.map);

    const bounds = new google.maps.LatLngBounds();
    parsedCoordinates.forEach(coord => bounds.extend(coord));
    this.map.fitBounds(bounds);
  }

  onUpdatePolygon(changes: SimpleChanges) {
    const drawGeofenceChange = changes.drawGeofence;
    const colorPickerChange = changes.colorPicker;

    if (drawGeofenceChange) {
      if (drawGeofenceChange.currentValue) {
        if (!this.drawingManager) {
          this.initDrawingPolygon(this.colorPicker);
        } else {
          this.updatePolygonColor(this.colorPicker);
        }
      } else {
        if (this.drawnPolygon) {
          this.drawnPolygon.setMap(null);
          this.drawnPolygon = null;
        }

        this.removeDrawingManager();
      }
    }
    if (colorPickerChange && !drawGeofenceChange && this.drawnPolygon) {
      this.updatePolygonColor(colorPickerChange.currentValue);
    }
  }

  updatePolygonColor(color: string): void {
    this.drawnPolygon.setOptions({
      strokeColor: color,
      fillColor: color,
    });
  }

  initDrawingPolygon(colorPicker: string): void {
    this.isDrawing = true;
    this.points = [];

    this.clearPolygon();

    this.map.addListener('click', (event: google.maps.MapMouseEvent) => {
      if (this.isDrawing) {
        this.points.push(event.latLng);

        if (this.points.length > 0) {
          if (this.drawnPolygon) {
            this.drawnPolygon.setMap(null);
          }

          const polygon = new google.maps.Polygon({
            paths: [...this.points],
            strokeColor: colorPicker,
            fillColor: colorPicker,
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillOpacity: 0.35,
            editable: true,
            draggable: true
          });
          polygon.setMap(this.map);
          this.drawnPolygon = polygon;
          this.setupPolygonListeners(polygon);
          this.logPolygonCoordinates(polygon);
        }
      }
    });
  }

  setupPolygonListeners(polygon: google.maps.Polygon) {
    google.maps.event.addListener(polygon.getPath(), "set_at", () => {
      this.logPolygonCoordinates(polygon);
    });

    google.maps.event.addListener(polygon.getPath(), "insert_at", () => {
      this.logPolygonCoordinates(polygon);
    });

    google.maps.event.addListener(polygon, "dragend", () => {
      this.logPolygonCoordinates(polygon);
    });
  }

  logPolygonCoordinates(polygon: google.maps.Polygon) {
    const paths = polygon.getPath();
    const coordinates = paths.getArray().map((latLng) => ({
      lat: latLng.lat(),
      lng: latLng.lng(),
    }));
    coordinates.push({ lat: paths.getAt(0).lat(), lng: paths.getAt(0).lng() });
    const polygonCoordinates = this.convertToDesiredFormat(coordinates);
    this.polygonCoordinates.emit(polygonCoordinates);
  }

  convertToDesiredFormat(coordinates: google.maps.LatLngLiteral[]): string {
    return coordinates.map((coord) => `[${coord.lng},${coord.lat}]`).join(",");
  }

  removeDrawingManager(): void {
    if (this.drawingManager) {
      this.drawingManager.setMap(null);
      this.drawingManager = null;
    }
  }

  clearPolygon(): void {
    this.points = [];
    this.removeDrawingManager();
    if (this.drawnPolygon) {
      this.drawnPolygon.setMap(null);
      this.drawnPolygon = null;
      this.polygonCoordinates.emit('');
    }

    if (this.clickListener) {
      google.maps.event.removeListener(this.clickListener);
      this.clickListener = null;
    }
  }

  disableDrawingMode(): void {
    this.isDrawing = false;
    if (this.clickListener) {
      google.maps.event.removeListener(this.clickListener);
      this.clickListener = null;
    }
    if (this.drawnPolygon) {
      this.drawnPolygon.setMap(null);
      this.drawnPolygon = null;
    }
  }

  setZones() {
    if (this.zonesData) {
      console.log(this.zonesData);
      this.setPolygons(this.zonesData.polygons);
    } else if (this.zoneDetailsItem) {
      this.displayPolygon(this.zoneDetailsItem);
    }
  }

  private async addLabelMarker(label: string, position: google.maps.LatLng) {
    const { AdvancedMarkerElement } = (await google.maps.importLibrary(
      "marker"
    )) as google.maps.MarkerLibrary;


    const markerWrapper = document.createElement('div');
    const iconElement = document.createElement('div');
    iconElement.style.backgroundImage = 'url("");';
    iconElement.style.backgroundSize = '30px 30px';
    iconElement.style.width = '30px';
    iconElement.style.height = '30px';

    iconElement.style.position = 'relative';
    iconElement.style.transform = 'translate(-10px, -20px)';

    const labelElement = document.createElement('span');
    labelElement.textContent = label;
    labelElement.style.color = '#FFF';
    labelElement.style.fontSize = '12px';
    labelElement.style.fontWeight = '500';
    labelElement.className = 'zones-label';

    markerWrapper.appendChild(iconElement);
    markerWrapper.appendChild(labelElement);

    markerWrapper.style.display = 'flex';
    markerWrapper.style.flexDirection = 'column';
    markerWrapper.style.alignItems = 'center';

    const marker = new google.maps.marker.AdvancedMarkerElement({
      position: position,
      map: this.map,
      content: markerWrapper,
    });


    const infoWindow = new google.maps.InfoWindow({
      content: label,
      maxWidth: 200,
    });

    marker.addListener("click", () => {
      infoWindow.open(this.map, marker);
    });
  }

  private setPolygons(polygons: any[]) {
    polygons.forEach((polygonData) => {
      const polygon = this.createPolygon(polygonData);
      const labelPosition = this.getLabelPosition(polygonData.coords);
      this.addLabelMarker(polygonData.label, labelPosition);
      polygon.setMap(this.map);
    });
  }

  private createPolygon(polygonData: any): google.maps.Polygon {
    return new google.maps.Polygon({
      paths: polygonData.coords,
      strokeColor: polygonData.strokeColor,
      strokeOpacity: polygonData.strokeOpacity,
      strokeWeight: polygonData.strokeWeight,
      fillColor: polygonData.fillColor,
      fillOpacity: polygonData.fillOpacity,
    });
  }


  private displayPolygon(polygonData: any) {
    const polygon = this.createPolygon(polygonData);
    const labelPosition = this.getLabelPosition(polygonData.coords);
    this.addLabelMarker(polygonData.label, labelPosition);
    polygon.setMap(this.map);
    const bounds = new google.maps.LatLngBounds();
    polygonData.coords.forEach(coord => {
      bounds.extend(coord);
    });
    this.map.fitBounds(bounds);
  }

  private getLabelPosition(coords: any[]): google.maps.LatLng {
    let latSum = 0;
    let lngSum = 0;
    coords.forEach((coord) => {
      latSum += coord.lat;
      lngSum += coord.lng;
    });
    const latAvg = latSum / coords.length;
    const lngAvg = lngSum / coords.length;
    return new google.maps.LatLng(latAvg, lngAvg);
  }

  setupControls() {
    const toggleControl = document.getElementById("style-selector-control");
    this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
      toggleControl
    );

    const geolocationControl = document.getElementById("my-location-control");
    this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
      geolocationControl
    );
  }

  getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          console.log('Position found:', position);
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          this.infoWindow.setPosition(pos);
          this.infoWindow.setContent('<div class="info-window-content text-lg font-normal">Location found.</div>');
          this.infoWindow.open(this.map);
          this.map.setCenter(pos);
        },
        () => {
          this.handleLocationError(true);
        }
      );
    } else {
      this.handleLocationError(false);
    }
  }

  handleLocationError(browserHasGeolocation: boolean) {
    const pos = this.map.getCenter();
    this.infoWindow.setPosition(pos);
    this.infoWindow.setContent(
      browserHasGeolocation
        ? "Error: The Geolocation service failed, Grant your browser access to your device's geolocation."
        : "Error: Your browser doesn't support geolocation."
    );
    this.infoWindow.open(this.map);
  }

  toggleDarkAndLightMap(event) {
    this.checked = event.checked;
    const mapStyles = this.checked
      ? AppSettings.LOCATION_MAP_STYLE_DARK
      : AppSettings.LOCATION_MAP_STYLE_DEFAULT;
    if (!this.checked) {
      mapStyles.push({ featureType: "poi", stylers: [{ visibility: "off" }] });
    }
    this.map.setOptions({ styles: mapStyles });
  }
}
