import { Component } from '@angular/core';
import { inject } from '@angular/core';
import { RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Message, MessageService } from 'primeng/api';
import { LoaderComponent } from './modules/shared/components/loader/loader.component';
import { ToastModule } from 'primeng/toast';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [ToastModule, RouterOutlet, RouterLink, RouterModule, LoaderComponent]
})
export class AppComponent {
  title = 'app';
  msgs: Message[] = [];
  
  private messageService = inject(MessageService);
  private translate = inject(TranslateService);

  constructor() {
    this.translate.setDefaultLang('en');
    this.translate.use('en');
  }
}
