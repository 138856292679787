<p-breadcrumb [model]="routePath"></p-breadcrumb>
<div appAccessProvider [accessCode]="{ menuCode: 'MANAGE_ZONES', pageCode: 'ALL', subPageCode: 'ADD' }">
<div
  class="flex justify-content-end align-items-center flex-wrap btn-with-links organization-box"
>
  <button
    class="flex align-items-center justify-content-center ml-3 bg-white default-text-light-black-color font-16 font-semibold border-button"
    [label]="cancelLabel"
    pButton
    (click)="onCancelForm()"
  ></button>
  <button
    class="flex align-items-center font-16 font-semibold justify-content-center ml-3 bg-white default-text-blue-color reset-button"
    [label]="resetLabel"
    pButton
    (click)="onResetForm()"
  ></button>
  <p-button
    class="flex align-items-center font-16 font-semibold justify-content-center ml-3"
    [label]="saveLabel"
    (click)="onSubmit()"
    [disabled]="(!isPolygonCoordinatesSet) && !zoneId"
  ></p-button>
</div>

<p-tabView [(activeIndex)]="activeIndex">
  <p-tabPanel>
    <ng-template pTemplate="header">
      <i class="mi-lg {{miIcons.DETAILS}}" ></i>
      <span class="mr-1" translate>manage_zones.setup_new_zone</span>
    </ng-template>
    <ng-template pTemplate="body">
      <div class="grid grid-nogutter">
        <div class="col-fixed flex flex-column justify-content-between" style="width: 25vw; height: calc(100vh - 120px); overflow: scroll; scrollbar-width: none;">
          <form [formGroup]="zoneForm" (ngSubmit)="onSubmit()" class="m-4 mt-3">
            @if (zoneId) {
              <div class="flex flex-row justify-content-end mb-3">
                <label for="active" class="font-14 font-semibold default-text-black-color mr-2 mt-1" translate>lbl_active</label>
                <p-inputSwitch id="active" formControlName="active"></p-inputSwitch>
              </div>
            }
            <div class="flex flex-column mb-3">
              <label for="zoneName" class="font-14 font-semibold default-text-black-color mb-2" translate>
                manage_zones.zone_name<span class="required-field ml-1">*</span>
              </label>
              <input id="zoneName" type="text" pInputText formControlName="zoneName" placeholder="Zone Name" (keydown)="Space($event)" (input)="onInputChange($event, 'zoneName')"/>
              @if( showRequiredErrorMessage('zoneName') ){
                <div class="font-13 mt-1 font-normal required-field" translate>
                  manage_zones.zone_name_required
                </div>
              } @else if ((zoneForm.controls.zoneName.errors && (zoneForm.controls.zoneName.dirty || zoneForm.controls.zoneName.touched && !formSubmitted))) {
                <div class="font-13 mt-1 font-normal required-field">
                    <div [hidden]="!zoneForm.controls.zoneName.errors.required" class="" translate>
                      manage_zones.zone_name_required
                    </div>
                </div>
              }
              @if( zoneForm.get('zoneName').errors?.maxlength){
                <div class="font-13 mt-1 font-normal required-field" translate>
                  manage_zones.zone_name_max
                </div>
              }
              @if( zoneForm.get('zoneName').errors?.pattern && (zoneForm.controls.zoneName.dirty || zoneForm.controls.zoneName.touched)){
                <div class="font-13 mt-1 font-normal required-field" translate>
                  manage_zones.zone_name_pattern_err
                </div>
              }
            </div>
            <div class="flex flex-column mb-3">
              <label for="aliasName" class="font-14 font-semibold default-text-black-color mb-2" translate>
                manage_zones.alias_name<span class="required-field ml-1">*</span>
              </label>
              <input id="aliasName" type="text" pInputText formControlName="aliasName" placeholder="Alias Name" (keydown)="Space($event)" (input)="onInputChange($event, 'aliasName')"/>
              @if( showRequiredErrorMessage('aliasName') ){
                <div class="font-13 mt-1 font-normal required-field" translate>
                  manage_zones.alias_name_required
                </div>
              } @else if ((zoneForm.controls.aliasName.errors && (zoneForm.controls.aliasName.dirty || zoneForm.controls.aliasName.touched && !formSubmitted))) {
                <div class="font-13 mt-1 font-normal required-field">
                    <div [hidden]="!zoneForm.controls.aliasName.errors.required" class="" translate>
                      manage_zones.alias_name_required
                    </div>
                </div>
              }
              @if( zoneForm.get('aliasName').errors?.maxlength){
                <div class="font-13 mt-1 font-normal required-field" translate>
                  manage_zones.alias_name_max
                </div>
              }
              @if( zoneForm.get('aliasName').errors?.pattern && (zoneForm.controls.aliasName.dirty || zoneForm.controls.aliasName.touched)){
                <div class="font-13 mt-1 font-normal required-field" translate>
                  manage_zones.alias_name_pattern_err
                </div>
              }
            </div>

            <div class="flex flex-column mb-3">
              <label for="description" class="font-14 font-semibold default-text-black-color mb-2" translate>
                manage_zones.description
              </label>
              <textarea id="description" pInputTextarea formControlName="description" placeholder="Description" maxlength ="300"></textarea>
            </div>
            <div class="flex flex-column mb-4">
              <label for="color" class="font-14 font-semibold default-text-black-color mb-2" translate>manage_zones.color_picker</label>
              <div class="flex flex-row">
                <p-colorPicker id="color" formControlName="zoneColor" (onChange)="onColorChange($event.value)"></p-colorPicker>
                <input type="text" class="ml-2 p-inputtext-sm uppercase" pInputText [value]="selectedColor" readonly/>
              </div>
            </div>
          </form>
        </div>
        <div class="col">
          @if(zoneFormDetails || isAddPage){
            <app-zone-map #zoneMapComponent
              [drawGeofence]="drawGeofence"
              [showGoogleSearchBox]="true"
              [colorPicker]="selectedColor"
              (polygonCoordinates)="onGetPolygonCoordinates($event)"
              (searchLatLng)="onGetSearchLatLng($event)"
              [circleRadius]="formRadius"
              [circleLat]="formLat"
              [circleLng]="formLng"
              [zoneFormDetails]="zoneFormDetails"
              [zonesData]="zonesData"
              [centerCordinateList]="centerCordinateList"
              [isAddPage]="isAddPage"
            ></app-zone-map>
          }
        </div>
      </div>
    </ng-template>
  </p-tabPanel>
</p-tabView>
</div>
