<p-breadcrumb [model]="routePath" (click)="onCancel()"></p-breadcrumb>
<div class="flex justify-content-end flex-wrap btn-with-links">
    <button pButton class="mr-2 bg-white border-0 default-text-light-black-color cursor-pointer border-button" [label]="'cancel' | translate" (click)="onCancel()"></button>
    <p-button class="flex justify-content-center cursor-pointer organization-box" [label]="nextBtnLabel" (click)="onSaveBtnClick()"></p-button>
</div>
<p-tabView [(activeIndex)]="activeIndex">
    @for(tab of incidentsComplaintAttributeData?.tabs; track tab?.tabCode) {
        <p-tabPanel >
            <ng-template pTemplate="header">
                <div class="tab-icons">
                <i class="mi-lg details" [class]="tab.tabCode"></i>
                <span>{{ cs.getLabelValue('incidents_and_complaint.tabs.'+tab.tabCode+'.label',attributeLabels,tab.tabCode) }}</span>
                </div>
            </ng-template>
            <div class="" >
                <div>
                    <app-mi-form [attributeLabels]="attributeLabels" [attributeValues]="attributeValues" [moduleName]="moduleName" [groups]="tab.groups"
                        (saveData)="onSaveIncidentsComplaints($event)" (onSaveAndAdd)="concernId ? null : onSaveAndAddIncidentsComplaints($event)" (nextClick)="onNextClick()"
                        [submitBtnText]="nextBtnLabel" [previousBtnText]="saveAndAddBtnText" [activeIndex]="activeIndex"  [saveBtnId]="'details'"
                        [isMultipleFilesUpload]="false" (onClickCancel)="concernId ? onCancel() : null" [concernId]="concernId"></app-mi-form>
                </div>
            </div>
        </p-tabPanel>
    }
</p-tabView>