<div class="grid mt-2 ml-3 speccifications booking-details">
    @if (isBookingStatusSpecialRequest(bookingStatusIdByCode, newbookingStatus, attributeValues)) {
        <div class="card w-full">
            <span class="text-xl font-semibold mt-3" translate>bookings.lbl_special_request</span>
            <div class="my-2">
                <span class="text-sm font-medium" translate>bookings.lbl_special_request_text</span>
            </div>
            <div class="card special-request">
                <label class="text-base" translate><span class="font-semibold text-900" translate>bookings.lbl_note</span>bookings.lbl_passenger_vehicle_count</label>
            </div>
            <div class="flex flex-row mt-4 justify-content-between passenger-vehicle-count">
                <div class="flex align-items-center">
                    <label class="detail-heading font-medium text-base mr-4" translate>bookings.lbl_additional_passenger_vehicle</label>
                    <div class="flex align-items-center additional-count">
                        <p-inputNumber [(ngModel)]="additionalPassengerVehicleValue" [showButtons]="true" [min]="0" [max]="bookingInfo.fields?.validation?.max"
                        buttonLayout="horizontal"  spinnerMode="horizontal" [step]="1"
                        decrementButtonClass="p-button" incrementButtonClass="p-button" incrementButtonIcon="pi pi-plus"
                        decrementButtonIcon="pi pi-minus" class="w-full"></p-inputNumber>
                    </div>
                </div>
                
                <div class="flex align-items-center">
                    <label class="divider-count"></label>
                </div>
                

                <div class="flex align-items-center">
                    <label class="detail-heading font-medium text-base mr-4" translate>bookings.lbl_luggage_vehicle_count</label>
                    <div class="flex align-items-center additional-count">
                        <p-inputNumber [(ngModel)]="luggageVehicleRequired" [showButtons]="true" [min]="0" [max]="bookingInfo.fields?.validation?.max"
                        buttonLayout="horizontal"  spinnerMode="horizontal" [step]="1"
                        decrementButtonClass="p-button" incrementButtonClass="p-button" incrementButtonIcon="pi pi-plus"
                        decrementButtonIcon="pi pi-minus" class="w-full"></p-inputNumber>
                    </div>
                </div>
                <div class="flex align-items-center justify-content-end flex-wrap mr-3">
                    <button pButton class="mr-2 bg-white default-text-light-black-color cursor-pointer border-button" label="Cancel" (click)="onCancel()"></button>
                    <button pButton class="flex align-items-center justify-content-center cursor-pointer" label="Save" (click)="onSaveModifications()"></button>
                </div>
            </div>

        </div>
    }
    <div class="col-6 pl-0">

        <div class="card p-fluid">
            <div class="flex justify-content-between flex-wrap">
                <div class="flex justify-content-center mb-3">
                    <label class="card-heading uppercase"
                        translate>bookings.lbl_booking_details</label>
                </div>

            </div>
            @for (field of bookingInfo.fields; track field; let i = $index) {


            @if (i !== 0 && i !== 24) {
            @if (!childSeatsHr.includes(field.attributeCode)){
            <hr class="hr-line">

            }
            }
            <div class="grid">
                <div class="col-6">
                    @if (childSeats.includes(field.attributeCode)){

                    @if(field.attributeCode === 'child_seat'){
                    <label class="detail-heading"
                        translate>{{cs.getLabelValue('booking.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>

                    }
                    }

                    @else if(field.attributeCode === 'luggage_booking_ids'){
                        <label class="detail-heading">Sub booking</label>    
                    } 
                    @else if(field.attributeCode === 'parent_booking_id'){
                        <label class="hidden"></label>
                    }@else if(field.attributeCode === 'booking_for'){
                        <label class="hidden"></label>
                    } @else if( field.attributeCode === 'waiting_time'){
                        <label class="hidden"></label>
                    }  @else if( field.attributeCode === 'transportation_type'){
                            <label class="detail-heading"
                            translate>{{cs.getLabelValue('booking.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>                       
                    }
                    @else{
                       
                     <label class="detail-heading"
                        translate>{{cs.getLabelValue('booking.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                    }
                </div>

                @if(field.attributeCode === 'booking_status'){
                <div class="col-6 pb-1 flex booking-status">
                    <label class="flex"><i
                            class="{{miIcons[getIcon(attributeValues[field.attributeCode])]}} inline-block wh-22 ml-1 mr-2"></i>
                    </label>

                    <label class="detail-value pb-1 booking-value-status font-bold">{{ attributeValues[field.attributeCode] ?
                        BOOKING_STATUS_LABEL[attributeValues[field.attributeCode]] : ('lbl_empty' | translate)
                        }}</label>
                </div>
                }
                @else if( field.attributeCode === 'pickup_date'){
                    <div class="col-6">
                        <label class="detail-value font-semibold">{{ attributeValues[field.attributeCode] ?
                            (attributeValues[field.attributeCode]| date: 'dd MMM yyyy' ) : ('lbl_empty' |
                            translate) }}
                        </label>
                    </div>
                } @else if(field.attributeCode === 'pickup_time'){
                    <div class="col-6">
                        <label class="detail-value font-semibold">{{ attributeValues[field.attributeCode] ?
                            (attributeValues[field.attributeCode]| date: 'hh:mm' ) : ('lbl_empty' |
                            translate) }}
                        </label>
                    </div>
                }
                @else if (childSeats.includes(field.attributeCode)) {

                <div class="col-6 pb-1">
                    <label class="detail-heading"
                        translate>{{cs.getLabelValue('booking.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                    :
                    <label class="detail-value primary font-bold">{{ attributeValues[field.attributeCode] ?
                        attributeValues[field.attributeCode]: ('lbl_empty' | translate) }}</label>
                </div>

                }
                @else if (field.attributeCode === 'luggage') {
                    <div class="col-6">
                        <label class="detail-value primary font-bold">{{ attributeValues[field.attributeCode] ?attributeValues[field.attributeCode]: ('lbl_empty' | translate) }}</label>
                    </div>
                }
                @else if(field.attributeCode === 'number_of_passenger'){
                    <div class="col-6">
                        <label class="detail-value font-bold">{{ attributeValues[field.attributeCode] ?attributeValues[field.attributeCode]: ('lbl_empty' | translate) }}</label>
                    </div>
                }
                @else if (field.attributeCode === 'wheelchair') {
                    <div class="col-6">
                        <label class="detail-value font-bold">{{ attributeValues[field.attributeCode] ?
                    attributeValues[field.attributeCode]: ('lbl_empty' | translate) }}</label>
                    </div>
                
                } 
                @else if (field.attributeCode === 'eta') {
                    <div class="col-6">
                        <label class="detail-value font-bold">{{ attributeValues[field.attributeCode] ?
                            attributeValues[field.attributeCode]: '--'}}
                            @if(attributeValues[field.attributeCode]){
                                <span class="font-bold" translate>bookings.lbl_minutes</span>
                            }
                        </label>
                    </div>
                
                }
                @else if (field.attributeCode === 'additional_vehicle_requirement') {
                    <div class="col-6">
                        <label class="detail-value font-bold">{{ attributeValues[field.attributeCode] ?
                    attributeValues[field.attributeCode]: ('lbl_empty' | translate) }}</label>
                    </div>
                }
                @else if(field.attributeCode === 'booking_pickup_location_address'){
                <div class="col-6">
                    <label class="detail-value flex justify-content-left align-items-center"> <i
                            class="{{miIcons.BOOKING_PICKUP_POINT}} inline-block relative mr-2"></i>
                            <span class=" ml-2 text-wrap">{{attributeValues[field.attributeCode] }}</span>
                    </label>
                </div>

                }
                @else if(field.attributeCode === 'booking_dropoff_location_address'){
                <div class="col-6">
                    <label class="detail-value flex justify-content-left align-items-center"> <i
                            class="{{miIcons.BOOKING_DROP_OFF_POINT}} inline-block relative mr-2"></i>
                        <span class="ml-2 text-wrap">{{attributeValues[field.attributeCode] }}</span>
                    </label>
                </div>

                }@else if(field.attributeCode === 'booking_stops_location_address' &&
                attributeValues[field.attributeCode] ){

                <div class="col-6">
                    @for (item of attributeValues[field.attributeCode]; track item;let s = $index) {


                    <div class="flex align-items-center justify-content-flex-start stops-text">
                        <i class="{{miIcons.BOOKING_STOP_POINT}} inline-block relative wh-24 mr-2 text-xs stop-booking">
                            <span class="address-number">{{s+1}}</span>
                        </i>     
                        <span class="flex flex-wrap wrap-text">{{item ? item : ('lbl_empty' | translate)}}</span>
                    </div>
                    }
                </div>

                }
                @else if(field.attributeCode ==='transportation_type'){
                <div class="col-6 pb-1">
                    <label class="detail-value ml-1 font-bold flex justify-content-start align-items-center">
                        @switch (attributeValues[field.attributeCode]) {
                        @case (TRANSPORTATION_MODE.CAR) {
                        <i class="{{miIcons['BOOKING_CAR']}} wh-24 inline-block mr-2"></i>

                        }

                        @case (TRANSPORTATION_MODE.SHUTTLE) {

                        <i class="{{miIcons.BOOKING_SHUTTLE}} wh-24 inline-block mr-2"></i>

                        }
                        @case (TRANSPORTATION_MODE.BUS) {
                        <i class="{{miIcons.BOOKING_BUS}} wh-24 inline-block mr-2"></i>
                        }
                        @case (TRANSPORTATION_MODE.AIRPORT){
                            <i class="{{miIcons.BOOKING_AIRPORT}} wh-24 inline-block mr-2"></i>
                        }
                        @case(TRANSPORTATION_MODE.VEHICLE_TRANSFER) {
                            <i class="{{miIcons.BOOKING_VEHICLE_TRANSFER}} wh-24 inline-block mr-2"></i>
                        }
                        }
                        
                            {{attributeValues[field.attributeCode] }}
                        
                    </label>
                </div>
                }

                @else if(field.attributeCode === 'number_of_passenger'){
                <label class="detail-value font-bold ml-2 justify-content-start align-items-center flex">
                    {{attributeValues[field.attributeCode] }}
                </label>

                }@else if(field.attributeCode === 'owner_pass_full_name'){
                <label class="detail-value font-bold justify-content-start align-items-center flex">
                    {{ this.passengerRelatedData[0]?.pass_full_name }} <span
                        class="sub-text"></span>({{this.passengerRelatedData[0]?.passenger_type }})
                </label>

                } 
                @else if(field.attributeCode === 'passenger_booking_ids' || field.attributeCode === 'luggage_booking_ids') {
                    <div class="col-6 pb-1">
                        @if(combinedData && combinedData.length > 0){
                            <div class="flex justify-content-center flex-column align-items-start">
                                @for(passengerDetails of combinedData; track passengerDetails; let i = $index){
                                    <label class="detail-value justify-content-start align-items-center flex primary font-semibold">
                                        {{ passengerDetails }}
                                    </label>
                                }
                            </div> 
                        } @else{
                            <label class="flex">{{'--'}}</label>
                        }
                    </div>       
                } 

                @else if(field.attributeCode === 'parent_booking_id') {
                    <label class="hidden detail-value ml-2 justify-content-start align-items-center flex">
                    </label>
                } @else if (field.attributeCode === 'booking_for'){
                    <label class="hidden detail-value ml-2 justify-content-start align-items-center flex">
                    </label>
                } @else if (field.attributeCode === 'waiting_time'){
                    <label class="hidden detail-value ml-2 justify-content-start align-items-center flex">
                    </label>
                } @else if(field.attributeCode === 'booking_time'){
                    <label class="detail-value flex align-items-center ml-2">{{ attributeValues[field.attributeCode] ?
                        (attributeValues[field.attributeCode] | date: hh_mm_dd_MMM_yyyy_FORMAT) : ('lbl_empty' | translate) }}</label>
                } @else if(field.attributeCode === 'journey_preference'){
                    <div class="col-6">
                        @if("attributeValues[field.attributeCode] && Array.isArray(attributeValues[field.attributeCode])"){
                            <ng-container>
                                @for(value of attributeValues[field.attributeCode]; track value; let i = $index){
                                    <div>{{ i + 1 }}. {{ value }}</div>
                                }
                            </ng-container>
                        } @else {
                            <div class="col-6 pb-1">
                                <label class="detail-value">{{ attributeValues[field.attributeCode] ?
                            attributeValues[field.attributeCode] : ('lbl_empty' | translate) }}</label>
                            </div>
                        }

                    </div>
                } @else if(field.attributeCode === 'pet_accommodation'){
                    @if(petInfoFormatted.length > 0){
                        <div class="col-6">
                            @for(petDetails of petInfoFormatted; track petDetails; let s = $index){
                                <div class="mt-1">
                                    <label>{{s+1 + '. '}}</label>
                                    {{ petDetails }}
                                </div>
                                
                            }
                        </div>
                        
                    } @else{
                        <div class="col-6">
                            <label class="detail-value">{{'--'}}</label>
                        </div>
                    }

                } @else if(field.attributeCode === 'created_by'){
                    @if(passFullName){
                        <div class="col-6">{{ passFullName }}</div>
                    } @else{
                        <div class="col-6">{{ '--' }}</div>
                    }
                }
                @else{
                <div class="col-6">
                    <label class="detail-value">{{ attributeValues[field.attributeCode] ?
                        attributeValues[field.attributeCode] : ('lbl_empty' | translate) }}</label>
                </div>
                }

            </div>
            }
        </div>

        @if(flightDetails){

        <div class="card p-fluid">
            <div class="flex justify-content-between flex-wrap">
                <div class="flex justify-content-center mb-3">
                    <label class="card-heading uppercase"
                        translate>{{cs.getLabelValue('booking.groups.flight_details.label',attributeLabels,'flight_details')}}</label>
                </div>
            
            </div>
            @for (field of flightDetails.fields; track field; let i = $index) {
            @if (i !== 0) {
            <hr class="hr-line">
            }
            <div class="grid">
                <div class="col-6">
                    <label class="detail-heading"
                        translate>{{cs.getLabelValue('booking.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                </div>
                @if(field.attributeCode === 'arrival_time'){
                    <div class="col-6">
                        <label class="detail-value">{{ attributeValues[field.attributeCode]?
                            (attributeValues[field.attributeCode] | date : HOUR_MINUTE_FORMAT): ('lbl_empty' | translate) }}</label>
                    </div>
                }@else {
                    <div class="col-6">
                        <label class="detail-value">{{ attributeValues[field.attributeCode]?
                            attributeValues[field.attributeCode]: ('lbl_empty' | translate) }}</label>
                    </div>
                }
                
            </div>
            }
        </div>
        }


        @if (driverInstructions) {
        <div class="card p-fluid">
            <div class="flex justify-content-between flex-wrap">
                <div class="flex justify-content-center mb-3">
                    <label class="card-heading uppercase" translate>{{cs.getLabelValue('booking.groups.instruction.label',attributeLabels,'instruction')}}</label>
                </div>
               
            </div>
            @for (field of driverInstructions?.fields; track field; let i = $index) {
            @if (i !== 0) {
            <hr class="hr-line">
            }
            <div class="grid">
                <div class="col-6">
                    <label class="detail-heading"
                        translate>{{cs.getLabelValue('booking.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                </div>
                <div class="col-6">
                    <label class="detail-value">{{ attributeValues[field.attributeCode]?
                        attributeValues[field.attributeCode] : ('lbl_empty' | translate) }}</label>
                </div>
            </div>
            }

        </div>
        }

        <div class="card p-fluid">

            <div class="flex justify-content-between flex-wrap">
                <div class="mb-3">
                    <div class="block ">
                        <label class="card-heading uppercase" translate> bookings.admin_note</label>
                    </div>
                    <div class="block ">
                        <label class="" translate>bookings.post_journey_lbl</label>
                    </div>
                </div>

            </div>

            <textarea
                rows="5"
                cols="30"
                pInputTextarea
                [(ngModel)]="adminNote" class="mb-3">
                </textarea>

            <p-button label="{{'lbl_save'| translate }}" class="width-30" (click)="onSaveAdminNote()" />
        </div>

    </div>

    <div class="col-6 passenger-information">
        @if (passengerRelatedData) {
        <div class="card p-fluid">
            <div class="flex justify-content-between flex-wrap">
                <div class="flex justify-content-center mb-3">
                    <label class="card-heading uppercase"
                        translate>{{cs.getLabelValue('booking.groups.passenger.label',attributeLabels,'passenger')}} ({{
                        passengerRelatedData?.length || 0}})</label>
                </div>
          
            </div>

            @if(this.passengerRelatedData.length > 0){
                <app-passenger-tab [bookingDetailItem]="passengerRelatedData"></app-passenger-tab>
            }
        </div>
        }


        @if(vehicleRelatedData){
        <app-booking-vehicle-info [vehicleId]="vehicleId" [vehicleRelatedData]="vehicleRelatedData" [entityId]="bookingDetails.entityId"></app-booking-vehicle-info>
        }

        @if(driverRelatedData){
            <app-booking-driver-info [driverId]="driverId" [driverRelatedData]="driverRelatedData" [entityId]="bookingDetails.entityId"></app-booking-driver-info>
        }


    </div>

</div>