<p-breadcrumb [model]="routePath"></p-breadcrumb>
<div appAccessProvider [accessCode]="{ menuCode: 'MANAGE_ZONES', pageCode: 'ALL', subPageCode: 'ADD' }">
<app-module-header
  [label]="btnLabel"
  (onBtnClick)="onAddZone()"
  [showImportExportBtn]="false"
  [showLastSync]="false"
  [isBtnDisabled]="isBtnDisabled"
></app-module-header>
</div>

<div appAccessProvider [accessCode]="{ menuCode: 'MANAGE_ZONES', pageCode: 'ALL', subPageCode: 'LIST' }">
<p-tabView [(activeIndex)]="activeIndex">
  <p-tabPanel>
    <ng-template pTemplate="header">
      <i class="{{miIcons.DASHBOARD_ACTIVITY_FEED}} inline-block wh-14 mr-2"></i>
      <span class="mr-1" translate>lbl_all</span
      ><span class="font-14 font-normal default-text-light-black-color"
        >({{ zoneListData?.length }})</span
      >
    </ng-template>
    <div class="mr-2 border-bottom-1 border-100" style="padding: 11px 0px">
      <span class="p-input-icon-left">
        <i
          class="pi relative left-20 cursor-pointer text-xs"
          [ngClass]="{ 'pi-search': !searchValue, 'pi-times': searchValue }"
          (click)="clearSearch()"
        ></i>
        <input
          style="width: 23vw"
          type="text"
          pInputText
          [placeholder]="'lbl_search' | translate"
          [(ngModel)]="searchValue"
          (input)="onSearch()"
        />
      </span>
    </div>
    <ng-template pTemplate="body">
      <div class="grid grid-nogutter">
        <div
          class="col-fixed flex flex-column justify-content-between"
          style="width: 25vw; height: calc(100vh - 176px)"
        >
          <div style="max-height: 70vh; overflow: scroll">
            @for(item of zoneListData; track item; let i = $index){
            <a
              class="flex p-3 cursor-pointer border-bottom-1 border-100"
              (mouseenter)="item.isHovered = true"
              (mouseleave)="item.isHovered = false"
              [ngClass]="{ 'bg-hover': item.isHovered }"
              (click)="navigateToZoneDetail(item.zoneId)"
            >
              <div
                class="color-bar mr-2"
                [ngClass]="{ green: item?.active, red: !item?.active }"
              ></div>
              <div class="flex flex-column justify-content-between w-full">
                <div>
                  <span
                    class="font-14 font-semibold default-text-black-color mr-1"
                    >{{ item.zoneName }}
                  </span>
                  <span
                    class="font-14 font-normal default-text-light-black-color"
                    >({{ item.aliasName }})
                  </span>
                </div>
                <div>
                  @if(item.radius > 0){<span
                    class="font-14 font-normal default-print-label-color mr-2"
                    >{{ item.radius }} M |</span
                  >}
                  <span class="font-14 font-normal default-print-label-color">{{
                    formatUpdatedAt(item.updatedAt)
                  }}</span>
                </div>
              </div>
              <a
                class="flex align-items-center mr-2 edit-icon relative"
                (mouseenter)="item.isHovered = true; hovered = true"
                (mouseleave)="item.isHovered = false; hovered = false" 
                [ngClass]="{ 'icon-bg-hover': (item.isHovered && hovered) }"
                (click)="onEditZone(item.zoneId)">
                <div appAccessProvider [accessCode]="{ menuCode: 'MANAGE_ZONES', pageCode: 'ALL', subPageCode: 'EDIT' }">
                  <i class="mi-lg inline-block vertical-align-text-bottom edit_2 cursor-pointer wh-25-20" ></i>
                </div>
              </a>
            </a>
            }
            @if (zoneListData?.length === 0) {
              <div class="w-full pt-0 pr-3 pb-0 pl-2">
                <app-no-result-found></app-no-result-found>    
              </div>         
          }
          </div>
          <div class="p-10-20 bg-f5f5f5 flex flex-row">
            <p class="mr-4 mb-0 default-text-black-color" translate>
              <span class="wh-11 green inline-block mr-2"></span>lbl_active
            </p>
            <p class="mb-0 default-text-black-color" translate>
              <span class="wh-11 red inline-block mr-2"></span>lbl_inactive
            </p>
          </div>
        </div>
        <div class="col">
          @if (zonesData) {
            <app-zone-map [isLandingPage]="true" [zonesData]="zonesData" [centerCordinateList]="centerCordinateList"></app-zone-map>
          }
        </div>
      </div>
    </ng-template>
  </p-tabPanel>
</p-tabView>
</div>
