<form [formGroup]="formName">
    <div class="flex">
        <div class="w-full">
            <input [formControlName]="field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode" class="" type="text"
                pInputText id="search-input" placeholder="Search"  appSearchLocation (setAddress)="getAndStoreAddress($event)"/>
                @if (errorMessage) {
                    <div class="text-red-500 mt-2">
                        <i class="error_outline mr-1"></i>{{ errorMessage }}
                    </div>
                }
        </div>
        <div>
            <p-button class="inline-flex ml-3" icon="mi-lg basic-location" (click)="showDialog()"></p-button>
        </div>
    </div>
    <input formControlName="location_place_id" style="display: none"/>
</form>

<p-blockUI [blocked]="blockedDocument"></p-blockUI>

<p-dialog header="Geo Location" (onHide)="close()" focusOnShow="false" [(visible)]="visible" [style]="{width: '50vw'}">
    <div class="map-div mb-3 mt-3" id="map">
    </div>

    <div class="col-12 save-div mb-3 pr-0">
        <button pButton class="cancel mr-20 border-button text-color height-44" (click)="close()" [label]="cancelBtnText"></button>
        <p-button class="inline-flex height-44" (click)="saveGeoLocation()" [label]="saveBtnText"></p-button>
    </div>
</p-dialog>
