<div class="grid pt-2">
  <div class="col-5 horizontal">
    <label class="flex font-semibold">{{cs.getLabelValue(moduleName
      +'.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}
      @if (field.validation?.required) {
        <span class="required">*</span>
      }
      @if (cs.getLabelValue(moduleName+'.fields.'+field.attributeCode+'.helpTips',attributeLabels,0)) {
        <app-mi-tooltip [messageData]="cs.getLabelValue(moduleName
          +'.fields.'+field.attributeCode+'.helpTips',attributeLabels,field.attributeCode)">
        </app-mi-tooltip>
      }
    </label>
  </div>
  <div class="col-5 privilege-radio">
    <app-mi-fields [field]="field" [attributeLabels]="attributeLabels" [dateFormat]="dateFormat"
      [moduleName]="moduleName" [multiSelectDisplay]="multiSelectDisplay"></app-mi-fields>

    <app-mi-error [field]="field" [attributeLabels]="attributeLabels"
      [moduleName]="moduleName"></app-mi-error>
  </div>
  @if (showHrLine) {
    <hr class="hr-line">
  }
</div> 