@if(items?.length > 0) {
  <app-loader></app-loader>
<div class="bg-white">
  <p-toast key="tst"></p-toast>
  @if(!isFullScreen){
  <div
    class="sidebar"
    (mouseenter)="isSidebarFull = true"
    (mouseleave)="isSidebarFull = false"
    [ngClass]="isSidebarFull ? 'full-width' : 'shrink-width'">

    <app-sidebar [items]="items" [eventResponse]="eventResponse" (isReadySidebar)="isReadySidebar($event)"></app-sidebar>
  </div>
  }

  <div
    class="main-panel"
    [ngClass]="{
      'shrink-width': isSidebarFull && !isFullScreen,
      'full-width': isSidebarFull ? false : !isFullScreen,
      'full-screen-width': isFullScreen
    }"
  >
    @if(!isFullScreen){<app-header
      class="top-bar p-grid p-col-nogutter"
    ></app-header
    >}
    <div class="main-container">
      @if (languageDetails && isSidebarReady && isMapLoaded) {
      <div class="main-content">
        <router-outlet></router-outlet>
      </div>
      }
    </div>
  </div>
</div>
}@else{
  <div class="bg-white">
      <div class="app-loading">
        <i class="{{miIcons.LOGO}} inline-block cursor-pointer flex-shrink-0 width-200"></i>
      </div>
    <!-- </div> -->
  </div>
}
