import { Component, Input, Output, EventEmitter, OnInit, inject } from '@angular/core';
import { NotificationService } from '../../services/notification.service';
import { CommonModule, JsonPipe } from '@angular/common';
import { TabViewModule } from 'primeng/tabview';
import { ButtonModule } from 'primeng/button';
import { AvatarModule } from 'primeng/avatar';
import { CardModule } from 'primeng/card';
import { NotificationIconComponent } from '../notification-icon/notification-icon.component';
import { AppSettings } from 'app/modules/shared/app.settings';
import { MenuItem } from 'primeng/api';
import { MenuModule } from 'primeng/menu';

@Component({
  selector: 'app-notification-tray',
  templateUrl: './notification-tray.component.html',
  styleUrls: ['./notification-tray.component.scss'],
  standalone: true,
  imports: [MenuModule,JsonPipe,CommonModule,TabViewModule,ButtonModule,AvatarModule,CardModule,NotificationIconComponent]
})
export class NotificationTrayComponent implements OnInit {
  @Input() notifications: Notification[] = [];
  @Input() unReadNotificationsCount: number = 0;
  @Output() markAllRead = new EventEmitter<void>();
  @Output() actionClicked = new EventEmitter<{ notificationId: string, action: string }>();
  @Output() loadMore = new EventEmitter<void>();
  @Input() notificationParams: any;
  @Input() notificationCount: number = 0;

  activeTab: 'all' | 'archived' = 'all';

  private notificationService = inject(NotificationService);
  isVisible = this.notificationService.isNotificationTrayOpen$;

  readonly notificationIcons = {
    ACCOUNT_CREATION: 'pi pi-user-plus',
    NEW_BOOKING_CREATION: 'pi pi-calendar-plus',
    ADMIN_CONFIRMED: 'pi pi-check-circle',
    DRIVER_ASSIGNED: 'pi pi-user',
    JOURNEY_ACKNOWLEDGMENT_BY_ADMIN: 'pi pi-check',
    ACKNOWLEDGED_BY_DRIVER: 'pi pi-check',
    RECONFIRMED: 'pi pi-sync',
    DRIVER_ON_THE_WAY: 'pi pi-car',
    DRIVER_ARRIVED: 'pi pi-map-marker',
    ON_TRIP: 'pi pi-directions',
    TRIP_COMPLETED: 'pi pi-check-square',
    CANCELLED_BY_ADMIN: 'pi pi-times-circle',
    BOOKING_EXPIRED: 'pi pi-calendar',
    DRIVER_ASSIGNMENT_BY_ADMIN: 'pi pi-user-edit',
    
    // Vehicle related
    VEHICLE_ASSIGNED: 'pi pi-truck',
    VEHICLE_STATUS_CHANGED: 'pi pi-info-circle',
    
    // Driver related
    DUTY_ON: 'pi pi-power-off',
    DUTY_OFF: 'pi pi-power-off',
    DRIVER_SHIFT_CREATION: 'pi pi-calendar',
    DRIVER_GROUP_CREATION: 'pi pi-users',
    
    // Shift related
    SHIFT_CREATION: 'pi pi-calendar-plus',
    PUBLISH_SHIFTS: 'pi pi-upload',
    UNPUBLISH_SHIFTS: 'pi pi-download',
    EDIT_SHIFTS: 'pi pi-pencil',
    
    // Other
    FORGOT_PASSWORD: 'pi pi-lock',
    INCIDENTS_AND_COMPLAINT_STATUS_CHANGED: 'pi pi-exclamation-triangle',
    CREATE_INCIDENT_AND_COMPLAINT: 'pi pi-exclamation-circle',
    PARKING_CHECK_IN: 'pi pi-parking',
    DUTY_STATUS_CHANGE: 'pi pi-user-edit',
    PRIVILEGE_UPDATE: 'pi pi-cog',
    SCHEDULED_REMINDER: 'pi pi-bell',
  };

  getNotificationIcon(type: string): string {
    return this.notificationIcons[type] || 'pi pi-info-circle';
  }

  onActionClick(notificationId: string, action: string): void {
    this.actionClicked.emit({ notificationId, action });
  }

  onMarkAllRead(): void {
    this.notificationService.markAllAsRead().subscribe({
      next: () => {
        this.markAllRead.emit();
      },
      error: (error) => {
        console.error('Error marking notifications as read:', error);
      }
    });
  }

  close(): void {
    this.notificationService.closeNotificationTray();
  }
  
    ngOnInit(): void {
        // Remove this as we'll use the input notifications instead
        // this.notifications = this.generateFakeNotifications();
    }

  getTimeAgo(date: string | Date): string {
    const now = new Date();
    const past = new Date(date);
    const diffInSeconds = Math.floor((now.getTime() - past.getTime()) / 1000);

    if (diffInSeconds < 60) {
      return 'just now';
    }

    const diffInMinutes = Math.floor(diffInSeconds / 60);
    if (diffInMinutes < 60) {
      return `${diffInMinutes} ${diffInMinutes === 1 ? 'minute' : 'minutes'} ago`;
    }

    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) {
      return `${diffInHours} ${diffInHours === 1 ? 'hour' : 'hours'} ago`;
    }

    const diffInDays = Math.floor(diffInHours / 24);
    if (diffInDays < 30) {
      return `${diffInDays} ${diffInDays === 1 ? 'day' : 'days'} ago`;
    }

    const diffInMonths = Math.floor(diffInDays / 30);
    if (diffInMonths < 12) {
      return `${diffInMonths} ${diffInMonths === 1 ? 'month' : 'months'} ago`;
    }

    const diffInYears = Math.floor(diffInMonths / 12);
    return `${diffInYears} ${diffInYears === 1 ? 'year' : 'years'} ago`;
  }

  onLoadMore(): void {
    this.loadMore.emit();
  }

  formatTime(date: string | Date): string {
    return new Date(date).toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    });
  }

  getNotificationDescription(notification: any): string {
    return notification.description || '';
  }

  menuItems: MenuItem[] = [
    {
      label: 'Clear all notifications',
      icon: 'pi pi-trash',
      command: () => this.clearAllNotifications()
    },
    {
      label: 'Mark all as read',
      icon: 'pi pi-check',
      command: () => this.markAllAsRead()
    }
  ];

  clearAllNotifications() {
    // Implement clear all logic
  }

  markAllAsRead() {
    // Implement mark all as read logic
  }


} 