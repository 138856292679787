import { Injectable, signal } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { RestApiService } from 'app/modules/shared/services/rest-api.service';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { EventService } from 'app/modules/events/services/event.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private isNotificationTrayOpen = signal(false);
  constructor(
    private restApiService: RestApiService,
    private configService: ConfigService,
    private eventService: EventService
  ) { }

  toggleNotificationTray(): void {
    this.isNotificationTrayOpen.update(value => !value);
  }

  searchNotifications(params: any): Observable<any> {
    return this.restApiService.post(
      'SEARCH NOTIFICATIONS', 
      `${this.configService.getLoggedInTenantCode()}/api/v1/notifications/search`, 
      params,
      'page-center'
    );
  }

  closeNotificationTray() {
    this.isNotificationTrayOpen.set(false);
  }

  get isNotificationTrayOpen$() {
    return this.isNotificationTrayOpen;
  }

  markAsRead(notificationId: string): Observable<any> {
    return this.restApiService.put(
      'MARK NOTIFICATION AS READ',
      `${this.configService.getLoggedInTenantCode()}/api/v1/notifications/${notificationId}/mark-as-read`,
      {},
      'page-center'
    );
  }

  markAllAsRead(): Observable<any> {
    const payload = {
      lastClearedAt: 0,
      lastReadAt: Date.now(),
      eventCode: this.eventService.getSelectedEventcode(),
      forTenantCode: this.configService.getLoggedInTenantCode()
    };

    return this.restApiService.put(
      'MARK ALL NOTIFICATIONS AS READ',
      `${this.configService.getLoggedInTenantCode()}/api/v1/notifications/update-status`,
      payload,
      'page-center'
    );
  }
} 