<form [formGroup]="formName">
    <div>
        <p-dropdown [formControlName]="field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode" [style]="{'width':'100%'}" [options]="dropdownList"
            optionValue="value" optionLabel="label" [placeholder]="placeholder" (onChange)="onDropdownChange($event)">
            <ng-template let-option pTemplate="selectedItem">
                <span>{{ option.label }}</span>
            </ng-template>
        </p-dropdown>
    </div>
</form>

@if(showCalendar) {
    <p-dialog [header]="'lbl_pick_date_and_time' | translate" [(visible)]="displayCalendar" [style]="{ width: '400px', height: '400px' }" [modal]="true">
        <p-calendar [(ngModel)]="selectedDate" [inline]="true" [showIcon]="false" [showTime]="true"
            [hourFormat]="hourFormat" (onSelect)="onDateSelect($event)"></p-calendar>
    </p-dialog>
}
