import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonBindingDataService } from '../../../services/common-binding-data.service';
import { CheckboxModule } from 'primeng/checkbox';
import { TranslateModule } from '@ngx-translate/core';
import { JsonPipe, NgClass } from '@angular/common';
import { EntityService } from 'app/modules/shared/services/entity.service';
import { DeviceDetectionService } from 'app/modules/shared/services/device-detection.service';

@Component({
  selector: 'app-mi-checkbox',
  templateUrl: './mi-checkbox.component.html',
  styleUrls: ['./mi-checkbox.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, CheckboxModule, TranslateModule, JsonPipe, NgClass]
})
export class MiCheckboxComponent {
  @Input() field: any;
  formName: FormGroup;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  @Output() onCheckboxChecked: EventEmitter<any> = new EventEmitter();
  selectedCategories: any;
  isTouchDevice: boolean = false;

  constructor(private formGroupDirective: FormGroupDirective,
    private entityService: EntityService,
    public cs: CommonBindingDataService,
    private deviceDetectionService: DeviceDetectionService) {
    this.formName = this.formGroupDirective.control;
  }

  ngOnInit() {
    this.isTouchDevice = this.deviceDetectionService.isTouchDevice();
    this.setSeededNumberFieldHiddenOnBasisOfSeededPlayerFieldValue();
    this.cs.passengerType$.subscribe(type => {
      const seeded_player = document.getElementById('seeded_player');
      const seed_number = document.getElementById('seed_number');
      if (type === 'Player') {
        if (seeded_player) {
          seeded_player.style.display = 'block';
        }

      } else {
        this.formName.controls['seed_number'].setValue(null);
        this.formName.controls['seeded_player'].setValue(false);
        
        if (seeded_player) {
          seeded_player.style.display = 'none';
        }
        if (seed_number) {
          seed_number.style.display = 'none';
        }

        this.formName.controls['seed_number'].removeValidators(Validators.required);
        this.formName.get('seed_number').updateValueAndValidity();
      }
    });
  }

  setSeededNumberFieldHiddenOnBasisOfSeededPlayerFieldValue() {
    if (this.field.attributeCode === 'seeded_player') {
      const element = document.getElementById('seed_number');
      if (element) {
        element.style.display = this.formName.controls['seeded_player'].value ? 'block' : 'none';
      }
    }
  }

  onCheckboxChange() {
    this.formName.controls[this.field?.isOneToMultiple ? this.field?.attributeCodeOneToMultiple : this.field.attributeCode].setValue(this.selectedCategories);
    this.formName.get(this.field?.isOneToMultiple ? this.field?.attributeCodeOneToMultiple : this.field.attributeCode).updateValueAndValidity();
  }

  onCheckBoxValueChange(event) {
    console.log(event);
    if (event.checked && this.field.attributeCode === 'default_address') {
      this.entityService.defaultAddressSelection.next(this.field);
    }

    if (!event.checked && this.field.attributeCode === 'seeded_player') {
      this.formName.controls['seed_number'].setValue(null);
      const element = document.getElementById('seed_number');
      element.style.display = 'none';
      this.formName.controls['seed_number'].removeValidators(Validators.required);
      this.formName.get('seed_number').updateValueAndValidity();
      this.cs.seededPlayerSelected.next(event.checked);
    } else if (event.checked && this.field.attributeCode === 'seeded_player') {
      const element = document.getElementById('seed_number');
      element.style.display = 'block';
      this.formName.controls['seed_number'].addValidators(Validators.required);
      this.formName.get('seed_number').updateValueAndValidity();
      this.cs.seededPlayerSelected.next(event.checked);
    }
  }
}
