<div appAccessProvider [accessCode]="{ menuCode: 'DISPATCH', pageCode: 'BOOKING_DRAWER', subPageCode: 'LIST' }">
  <p-breadcrumb [model]="routePath" (click)="onCloseBookingDetailView(false)"></p-breadcrumb>
@if (bookingId) {
 

  <div class="flex flex-row absolute top-14 left-240 z-1">
    <div class="flex flex-row light-blue-container align-items-center">
      <div class="color-bar green mr-2"></div>
      <div class="font-14 font-bold default-text-black-color mr-2">{{dispatchBookingDetails?.pickup_time | date: 'hh:mm a'}}, {{dispatchBookingDetails?.pickup_date | date: 'dd MMM YYYY'}}</div>
      <div class="font-13 font-semibold default-print-label-color mr-2">{{bookingDetailItem.booking_code}}</div>
      
      @if (dispatchBookingDetails?.transportation_type !== TRANSPORTATION_MODE.VEHICLE_TRANSFER) {
        <div class="font-12 font-semibold default-text-black-color mr-2">
          {{(dispatchBookingDetails?.number_of_passenger ? dispatchBookingDetails?.number_of_passenger : null) }}
           <span translate>dispatch.passenger</span>
        </div>
        <div class="font-12 font-semibold default-text-black-color type-of-pass mr-2">{{bookingDetailItem?.owner_pass_type}}</div>
      }


      @if (dispatchBookingDetails?.passengers[0]?.watchlist) {
        <div><i class="mi-lg mi-watchlist inline-block vertical-align-text-bottom cursor-pointer wh-16"></i></div>
      }
    </div>
    <div class="flex flex-row align-items-center ml-2">
      <div class="mr-2 flight-container">
          @switch (dispatchBookingDetails?.transportation_type) {
            @case (TRANSPORTATION_MODE.CAR) {
            <i class="{{miIcons.BOOKING_CAR_OLD}} inline-block vertical-align-text-bottom cursor-pointer wh-16 "></i>
            }
  
            @case (TRANSPORTATION_MODE.SHUTTLE) {
            <i class="{{miIcons.BOOKING_SHUTTLE_OLD}} inline-block vertical-align-text-bottom cursor-pointer wh-16 "></i>
            }
  
            @case (TRANSPORTATION_MODE.BUS) {
            <i class="{{miIcons.BOOKING_BUS}} inline-block vertical-align-text-bottom cursor-pointer wh-16 "></i>
            }

            @case (TRANSPORTATION_MODE.AIRPORT) {
              <i class="{{miIcons.BOOKING_AIRPORT_OLD}} inline-block vertical-align-text-bottom cursor-pointer wh-16 mr-2"></i>
            }

            @case (TRANSPORTATION_MODE.VEHICLE_TRANSFER) {
              <i class="{{miIcons.VEHICLE_TRANSFER}} inline-block vertical-align-text-bottom cursor-pointer wh-20 "></i>
              }
          }
      </div>
      @if (dispatchBookingDetails?.child_seat > 0) {
        <div class="mr-2">
          <span class="font-14 font-bold text-dark-green">{{dispatchBookingDetails.child_seat}}</span>
          <span class="font-13 font-semibold text-dark-green" translate>dispatch.child</span>
          <span class="default-separator-color ml-2 font-15">|</span>
        </div>
      }
      @if (dispatchBookingDetails?.wheelchair > 0) {
        <div class="mr-2">
          <span class="font-14 font-bold text-dark-green">{{dispatchBookingDetails.wheelchair}}</span>
          <span class="font-13 font-semibold text-dark-green" translate>dispatch.wheelChair</span>
          <span class="default-separator-color ml-2 font-15">|</span>
        </div>
      }
      @if (dispatchBookingDetails?.luggage > 0) {
        <div class="mr-2">
          <span class="font-14 font-bold text-dark-green">{{dispatchBookingDetails.luggage}}</span>
          <span class="font-13 font-semibold text-dark-green ml-1" translate>dispatch.bags</span>
          <span class="default-separator-color ml-2 font-15">|</span>
        </div>
      }
      @if (dispatchBookingDetails?.additional_passenger_vehicle) {
        <div>
          <i class="mi-lg mi-stepper_color mi-additional_vehicle inline-block vertical-align-text-bottom cursor-pointer wh-16"></i>
          <span class="font-14 font-semibold default-text-black-color ml-1">{{dispatchBookingDetails.additional_passenger_vehicle}}</span
          ><span class="default-separator-color ml-2 font-15">|</span>
        </div>
      }
      @if (dispatchBookingDetails?.booking_note) {
        <div>
          <span #mydiv class="relative white-space-nowrap pl-1">
            <i class="mi-lg bg-orange mi-instructions mt-2 inline-block cursor-pointer wh-16" [appendTo]="mydiv"
              [pTooltip]="dispatchBookingDetails.booking_note" tooltipPosition="right" tooltipStyleClass="tooltip-container"
            ></i>
          </span>
        </div>
      }
    </div>
  </div>
}

<div class="flex flex-wrap gap-2 justify-content-between">
  <p-button
    (click)="showLeftBookingsDialog('left')"
    icon="mi-lg chevron-duo-right wh-30 mi-stepper_color inline-block"
    styleClass="toggle-btn border-radius-right bg-white absolute top-59 left-90 z-1"
    inputId="left-btn"
  ></p-button>
  <p-button
    (click)="showRightDriversDialog('right')"
    icon="mi-lg chevron-duo-left wh-30 mi-stepper_color inline-block"
    styleClass="toggle-btn border-radius-left bg-white absolute right-0 top-59 z-1"
    id="driver-dialog"
  ></p-button>
</div>
<app-booking-sidebar  [(visible)]="leftBookingsSectionVisible"  [leftDialogPosition]="leftDialogPosition" [position]="position" [showBookingDetails]="showBookingDetails" (closeBtnClickedChanges)='onCloseBookingDetailView($event)' (showBookingBtnClickedChanges)='onShowBookingDetail($event)'></app-booking-sidebar>
<app-driver-sidebar  [(visible)]="rightDriversSectionVisible"  [rightDialogPosition]="rightDialogPosition" [position]="position" [bookingId]="bookingId" ></app-driver-sidebar>
<app-dispatch-map [geoLocationsData]="geoLocationsData" [bookingId]="bookingId"></app-dispatch-map>
</div>