import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CommonBindingDataService } from '../../services/common-binding-data.service';


enum GroupCode {
    DEFAULT_VALUES = 'default_values',
    DOWNLOAD_ACCOUNT_DATA = 'download_account_data',
    PASSWORD_REQUIREMENTS = 'password_requirements',
    SESSION_TIMEOUT = 'session_timeout'
}

@Component({
    selector: 'app-special-labels',
    templateUrl: './special-labels.component.html',
    styleUrl: './special-labels.component.scss',
    standalone: true,
    imports: [CommonModule, TranslateModule]
})
export class SpecialLabelsComponent {
    @Input() group;
    @Input() moduleName: string;
    @Input() attributeLabels: Record<string, string>;
    @Input() GROUP_CODE: typeof GroupCode;

    constructor(public cs: CommonBindingDataService) { }

    isSelectedPeople(): boolean {
        return this.cs.getLabelValue(
            this.moduleName + '.groups.' + this.group.code + '.label',
            this.attributeLabels,
            this.group.code
        ) === 'Selected People';
    }

    isDefaultValues(): boolean {
        return this.group.code === this.GROUP_CODE.DEFAULT_VALUES &&
            this.moduleName === 'pass_privilege_conf';
    }

    isDownloadAccountData(): boolean {
        return this.group.code === this.GROUP_CODE.DOWNLOAD_ACCOUNT_DATA;
    }

    isPasswordRequirements(): boolean {
        return this.group.code === this.GROUP_CODE.PASSWORD_REQUIREMENTS;
    }

    isSessionTimeout(): boolean {
        return this.group.code === this.GROUP_CODE.SESSION_TIMEOUT;
    }

    isStandardGroup(group): boolean {
        const excludedGroups = ['send_notification', 'default', 'setting'];
        return !excludedGroups.includes(group.code);
    }

    shouldShowSpecialLabels(): boolean {
        return this.isStandardGroup(this.group) ||
            this.isDownloadAccountData() ||
            this.isPasswordRequirements() ||
            this.isSessionTimeout();
    }
}
    