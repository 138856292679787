import { DatePipe, NgClass } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import * as _ from 'lodash';
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { AppSettings } from "app/modules/shared/app.settings";
import { Country } from "app/modules/shared/models/country";
import { Language } from "app/modules/shared/models/language";
import { CommonBindingDataService } from "app/modules/shared/services/common-binding-data.service";
import { ConfigService } from "app/modules/shared/services/config.service";
import { EntityService } from "app/modules/shared/services/entity.service";
import { ConfirmationService, MessageService } from "primeng/api";
import { ButtonModule } from "primeng/button";
import { OverlayPanel, OverlayPanelModule } from "primeng/overlaypanel";
import { EntityList } from "app/modules/vehicles/models/entity.models";
import dayjs from "dayjs";
import { BookingService } from "app/modules/bookings/services/booking.service";
import { MiImageContainerComponent } from "app/modules/shared/ui-sharable/mi-image-container/mi-image-container.component";
import { BookingTabComponent } from "../../booking-section/booking-details/booking-tab/booking-tab.component";

@Component({
  selector: "app-driver-list-card",
  templateUrl: "./driver-list-card.component.html",
  styleUrls: ["./driver-list-card.component.scss"],
  standalone: true,
  imports: [ButtonModule, NgClass, OverlayPanelModule, TranslateModule, DatePipe, MiImageContainerComponent],
})
export class DriverListCardComponent implements OnInit {
  @ViewChild(BookingTabComponent,) bookingTabComponent: BookingTabComponent;

  isHovered: boolean = false;
  @ViewChild("op") op: OverlayPanel;
  @Input() driverDetail: any;
  @Input() forDetail: boolean = false;
  @Input() bookingId;
  @Input() isBookingScreen: boolean = false;
  @Input() advanceFilterAttributesForBooking;
  @Input() bookingStatuses;
  @Input() bookingDetails;
  @Input() fromDialogHeader: boolean = false;
  @Output() closeBtnClicked: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() driverCardClicked: EventEmitter<any> = new EventEmitter<any>();
  country: Country;
  language: Language;
  groupName: any;
  nameCode: any;
  manufacturer: any;
  variant: any;
  vehicleType: any;
  passengerCapacity: any;
  driverGroupDetails: any;
  driverVehicleDetails: any;
  driverInfo: any;
  bookingStatusAttributeId: any;
  driverIdAttributeId: any;
  pickUpDateAttributeId: any;
  driverBookings: any[] = [];
  currentBooking: any;
  upcomingBookings: any[] = [];
  currentStatusIdList: any[] = [];
  currentBookingStatus: any;
  driverOnShiftStatusLabel = this.cs.getLabel('dispatch.onshift');
  driverOffShiftStatusLabel = this.cs.getLabel('dispatch.offShift');
  requestBodyToFetchDriverStatusDetails: EntityList = {
    limit: 10,
    offset: 0,
    searchStr: '',
    forTenantCode: this.configService.getForTenantCode(),
  };
  math = Math
  currentBookingStatuses = [AppSettings.BOOKING_STATUS_CODES.RECONFIRMED,
  AppSettings.BOOKING_STATUS_CODES.DRIVER_ON_THE_WAY,
  AppSettings.BOOKING_STATUS_CODES.DRIVER_ARRIVED,
  AppSettings.BOOKING_STATUS_CODES.ON_TRIP];
  isWheelchair: boolean = false;
  isChildSeat: boolean = false;
  isPassengerCapacityExceeds: boolean = false;
  isDriverOnShiftOrAvailableForBookingOrNotOnLeave: boolean = false;
  confirmationMessage: any;
  forceAssign: boolean = false;
  isDriverAssignedToBooking: boolean = false;

  constructor(private enitityService: EntityService,
    private configService: ConfigService,
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private entityService: EntityService,
    private cs: CommonBindingDataService,
    private confirmationService: ConfirmationService,
    private bookingService: BookingService) { }



  ngOnInit(): void {
    console.log(this.bookingDetails);
    console.log(this.bookingStatuses);
    this.checkDriverIsAssignedToBooking();
    this.checkBookingCanAssignToDriver();
    this.checkPassengerCapacityOfAssignedVehicleAndNumberOfPassengersInBooking();
    if (!this.fromDialogHeader) {
      this.initializeData();
      this.getCurrentStatusIdList();
      this.driverDetail.eta = parseInt(this.driverDetail.eta);
      console.log(this.driverDetail.eta)
      this.driverDetail.etaDistance = parseFloat(this.driverDetail.etaDistance).toFixed(2);
      console.log(this.driverDetail)

    }
    if (this.driverDetail.relatedData && this.driverDetail.relatedData.length > 0) {
      this.driverGroupDetails = this.driverDetail.relatedData.find(ele => ele.entityCode === 'driver_group');
      this.driverVehicleDetails = this.driverDetail.relatedData.find(ele => ele.entityCode === 'vehicle');
      this.driverInfo = this.driverDetail.relatedData.find(ele => ele.entityCode === 'driver');
    }
    if (this.driverDetail?.features && this.driverDetail?.features.includes('Wheelchair Support')) {
      this.isWheelchair = true;
    }
    if (this.driverDetail?.features && this.driverDetail?.features.includes('Child Seat')) {
      this.isChildSeat = true;
    }
  }

  checkDriverIsAssignedToBooking() {
    this.bookingStatuses.forEach(element => {
      if (element.bookingStatusId === this.bookingDetails.booking_status) {
        if (!AppSettings.BOOKING_STATUS_CODES_WHICH_DENOTES_DRIVER_IS_NOT_ASSIGNED_TO_BOOKING.includes(element.bookingCode)) {
          this.isDriverAssignedToBooking = true;
        }
      }
    });
  }

  checkBookingCanAssignToDriver() {
    if (this.driverDetail && this.driverDetail.driverAvailableForBooking && this.driverDetail.onShift && !this.driverDetail.onLeave) {
      this.isDriverOnShiftOrAvailableForBookingOrNotOnLeave = true;
    }
  }

  checkPassengerCapacityOfAssignedVehicleAndNumberOfPassengersInBooking() {
    if (this.driverDetail && this.driverDetail.vehicleId) {
      const assignedVehicle = this.driverDetail.relatedData.find(ele => (ele.entityCode === AppSettings.ENTITY_CODE.VEHICLE && ele.id === this.driverDetail.vehicleId));
      if (assignedVehicle) {
        if (this.bookingDetails?.number_of_passenger >  (assignedVehicle?.values?.passenger_capacity + 1)) {
          this.isPassengerCapacityExceeds = true;
        }
      }

    } else if (this.driverDetail && this.driverDetail?.defaultVehicleId) {
      const defaultVehicle = this.driverDetail.relatedData.find(ele => (ele.entityCode === AppSettings.ENTITY_CODE.VEHICLE && ele.id === this.driverDetail.defaultVehicleId));
      if (defaultVehicle) {
        if (this.bookingDetails?.number_of_passenger >  (defaultVehicle?.values?.passenger_capacity + 1)) {
          this.isPassengerCapacityExceeds = true;
        }
      }
    }
  }

  getCurrentStatusIdList() {
    this.bookingStatuses.forEach(status => {
      if (this.currentBookingStatuses.includes(status.bookingCode)) {
        this.currentStatusIdList.push(status.bookingStatusId);
      }
    });
  }

  initializeData() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.requestBodyToFetchDriverStatusDetails.advanceFilters = [];
    this.requestBodyToFetchDriverStatusDetails.filters = [];
    this.extractAttributeIdsForFilterAttributes();
  }

  extractAttributeIdsForFilterAttributes() {
    let fields = this.advanceFilterAttributesForBooking?.tabs[0]?.groups[0]?.fields;
    this.bookingStatusAttributeId = fields?.find(ele => ele.attributeCode === AppSettings.DISPATCH_DRIVER_LIST_FILTER_ATTRIBUTES.BOOKING_STATUS)?.attributeId;
    this.driverIdAttributeId = fields?.find(ele => ele.attributeCode === AppSettings.DISPATCH_DRIVER_LIST_FILTER_ATTRIBUTES.DRIVER_ENTITY_ID)?.attributeId;
    this.pickUpDateAttributeId = fields?.find(ele => ele.attributeCode === AppSettings.DISPATCH_DRIVER_LIST_FILTER_ATTRIBUTES.PICK_UP_DATE)?.attributeId;
    this.setFiltersToFetchDriverBooking();
  }

  setFiltersToFetchDriverBooking() {
    this.requestBodyToFetchDriverStatusDetails.countryCode = this.country[0].countryCode;
    this.requestBodyToFetchDriverStatusDetails.tableViewCode = AppSettings.VIEW_CODE.DRIVER_NEXT_BOOKING_ON_DISPATCH_VIEW;
    this.requestBodyToFetchDriverStatusDetails.deleted = AppSettings.DELETED_TYPE.ONLY_NON_DELETED;
    this.requestBodyToFetchDriverStatusDetails.advanceFilters.push({
      attributeId: this.pickUpDateAttributeId,
      fromValue: dayjs().startOf('day').valueOf(),
      toValue: dayjs().endOf('day').valueOf(),
      comparisonOperator: 'BETWEEN'
    });

    this.requestBodyToFetchDriverStatusDetails.filters.push({
      attributeId: this.bookingStatusAttributeId,
      attributeValue: this.getStatusIds()
    });

    this.requestBodyToFetchDriverStatusDetails.filters.push({
      attributeId: this.driverIdAttributeId,
      attributeValue: this.driverDetail.driverId
    });
    this.getDriverBookings();
  }

  getStatusIds(): string[] {
    let statusIds = [];
    if (this.bookingStatuses && this.bookingStatuses.length > 0) {
      this.bookingStatuses.forEach(ele => {
        if (ele.bookingCode === AppSettings.BOOKING_STATUS_CODES.DRIVER_ARRIVED || ele.bookingCode === AppSettings.BOOKING_STATUS_CODES.ON_TRIP ||
          ele.bookingCode === AppSettings.BOOKING_STATUS_CODES.DRIVER_ON_THE_WAY || ele.bookingCode === AppSettings.BOOKING_STATUS_CODES.RECONFIRMED ||
          ele.bookingCode === AppSettings.BOOKING_STATUS_CODES.ADMIN_CONFIRMED || ele.bookingCode === AppSettings.BOOKING_STATUS_CODES.NEW_REQUEST ||
          ele.bookingCode === AppSettings.BOOKING_STATUS_CODES.ACKNOWLEDGED || ele.bookingCode === AppSettings.BOOKING_STATUS_CODES.DRIVER_ASSIGNED) {
          statusIds.push(ele.bookingStatusId);
        }
      });
    }
    return statusIds;
  }

  onCardClick(item) {
    const driverDetails = {
      driver: item,
      bookingId: this.bookingId
    }
    this.driverCardClicked.emit(driverDetails);
  }

  onCloseDetailView() {
    this.closeBtnClicked.emit(false);
  }

  onMouseEnter(event: MouseEvent) {
    this.isHovered = true;
    this.op.show(event);
  }

  getDriverBookings() {
    this.entityService.searchEntity(AppSettings.ENTITY_CODE.BOOKING, this.requestBodyToFetchDriverStatusDetails).subscribe({
      next: (result: any) => {
        this.driverBookings = result.data;
        this.setCurrentAndUpComingBookings()
      },
      error: (error: any) => { }
    });
  }

  setCurrentAndUpComingBookings() {
    this.driverBookings?.forEach(booking => {
      if (this.currentStatusIdList.includes(booking.values.booking_status)) {
        this.currentBooking = booking;
        let statusString = this.bookingStatuses.find(ele => ele.bookingStatusId === this.currentBooking?.values?.booking_status)?.bookingStatusDisplayStr;
        this.currentBookingStatus = JSON.parse(statusString);
        console.log(this.currentBookingStatus);
      } else {
        this.upcomingBookings.push(booking);
      }
    });
  }

  onMouseLeave(event: MouseEvent) {
    this.isHovered = false;
    this.op.hide();
  }

  checkDriverAvailability() {
    if (this.driverDetail.driverAvailableForBooking && this.driverDetail.onShift && this.driverDetail) {

    }
  }

  onAssignClick(driverDetail, event: Event) {
    console.log(this.driverDetail);
    event.stopPropagation();
    if (this.bookingId) {
      if (!this.isDriverAssignedToBooking) {
        this.checkOtherConditionsForBooking(driverDetail)
      } else {
        this.showConfirmationToReassignDriverToBooking(driverDetail);
      }
    } else {
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel('dispatch.booking_assign_first') });
    }
  }

  checkOtherConditionsForBooking(driverDetail) {
    if (this.isDriverOnShiftOrAvailableForBookingOrNotOnLeave && !this.isPassengerCapacityExceeds) {
      this.assignDriverApiCall(driverDetail);
    }  else {
      if (!this.isDriverOnShiftOrAvailableForBookingOrNotOnLeave) {
        this.confirmationMessage = this.cs.getLabel('dispatch.assign_driver_confirmation_msg');
      } else if (this.isPassengerCapacityExceeds) {
        this.confirmationMessage = this.cs.getLabel('dispatch.passenger_capacity_exceeds');
      }
      this.showConfirmationMessageToAssignDriver(driverDetail);
    }
  }

  showConfirmationMessageToAssignDriver(driverDetail) {
    this.confirmationService.confirm({
      header: this.cs.getLabel('dispatch.confirmation'),
      message: this.confirmationMessage,
      acceptIcon: 'none',
      rejectIcon: 'none',
      acceptLabel: this.cs.getLabel('events.lbl_yes'),
      rejectLabel: this.cs.getLabel('events.lbl_no'),
      rejectButtonStyleClass: 'bg-white text-color',
      acceptButtonStyleClass: 'bg-red-500',
      accept: () => {
        this.forceAssign = true;
        this.assignDriverApiCall(driverDetail);
      },
      reject: () => {
      }
    });
  }

  showConfirmationToReassignDriverToBooking(driverDetail) {
    this.confirmationService.confirm({
      header: this.cs.getLabel('dispatch.confirmation'),
      message:this.cs.getLabel('dispatch.driver_is_already_assigned_to_booking_do_you_want_to_assign_new_driver'),
      acceptIcon: 'none',
      rejectIcon: 'none',
      acceptLabel: this.cs.getLabel('events.lbl_yes'),
      rejectLabel: this.cs.getLabel('events.lbl_no'),
      rejectButtonStyleClass: 'bg-white text-color',
      acceptButtonStyleClass: 'bg-red-500',
      accept: () => {
        this.assignDriverApiCall(driverDetail);
      },
      reject: () => {
      }
    });
  }

  assignDriverApiCall(driverDetail) {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    const data = {
      forTenantCode: this.configService.getForTenantCode(),
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      bookingEntityId: this.bookingId,
      driverEntityId: driverDetail.driverId
    }
    if (this.forceAssign) {
      data['forceAssign']=this.forceAssign;
    }

    this.enitityService.assignDriver(data, AppSettings.ENTITY_CODE.BOOKING).subscribe(() => {
      this.forceAssign = false;
      this.bookingService.driverAssigned.next(true);

      if (this.isBookingScreen) {
        // this.bookingService.bookingDriverDetail.next(driverDetail);
        this.bookingService.setDriverDetail(driverDetail);
      }
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('dispatch.driver_assign_msg') });
    })
  }
}
