
<div class="col-12 p-0">
    <div class="initial-details">
        <div class="flex">
            <div class=" flex ">
                <label translate="" class="font-semibold font-20"> bookings.lb_journey </label>

                <label class="font-semibold font-20">&nbsp; {{data?.booking_code}}</label> 
                <div class="separator-div "></div>
                <label class="font-semibold font-20"> {{ data.pickup_time | date: hh_mm_FORMAT}}</label>
                <label class="font-medium font-20"> {{ data.pickup_date | date:dd_MMM_yyyy_FORMAT}}</label> 
            </div>
            <div class="separator-div "></div>
            <div class="flex ml-2">
                @if(data.transportation_type === TRANSPORTATION_TYPE.AIRPORT) {
                <div class="icon-div flex align-item-center mr-2">
                    <i class="{{miIcons.BOOKING_AIRPORT}} inline-block wh-24 relative cursor-pointer"></i>
                </div>
                }@else {
                <div class="icon-div flex align-item-center mr-2">
                    @switch (data.transportation_type) {
                        @case (TRANSPORTATION_MODE.CAR) {
                        <i class="{{miIcons.BOOKING_CAR}} inline-block wh-24 relative "></i>
                        }

                        @case (TRANSPORTATION_MODE.SHUTTLE) {
                        <i class="{{miIcons.BOOKING_SHUTTLE}} inline-block wh-24 relative "></i>
                        }

                        @case (TRANSPORTATION_MODE.BUS) {
                        <i class="{{miIcons.BOOKING_BUS}} inline-block wh-24 relative "></i>
                        }
                        
                        @case (TRANSPORTATION_MODE.VEHICLE_TRANSFER){
                        <i class="pi pi-fw mi-vehicle-transfer mi-lg inline-block wh-24 relative"></i>
                        }

                    }
                </div>
                }

                @if(data.additional_vehicle_requirement){
                <div class="icon-div flex align-item-center mr-2">
                    <i class="{{miIcons.BOOKING_ADDITIONAL_VEHICLE}} inline-block wh-24 relative "></i>
                </div>
                }

                @if(passenger?.watchlist === "Yes") {
                <div class="icon-div flex align-item-center mr-2">
                    <i class="{{miIcons.BOOKING_WATCHLIST}} inline-block wh-24 relative "></i>
                </div>
                }


            </div>
        </div>
        <div class="flex font-normal text-sm booking-view-details">
            @if(data?.owner_pass_type){
                <label>{{ data.owner_pass_type }}</label>
                <label class="separator">|</label>
            }
            <div class="mr-2">{{data?.number_of_passenger}} <label translate> bookings.pass</label>
            </div>
            @if(extractedDriverData?.length > 0){
                <label class="separator">|</label>
                <div class="mr-2">
                    @for(driver of extractedDriverData; track driver; let i = $index){
                        <label>{{ driver.first_name }} - {{ driver.unique_id }}</label>
                    }
                </div>
            }
            @if(extractedVehicleData?.length > 0){
                <label class="separator">|</label>
                <div class="mr-2">
                    @for(vehicle of extractedVehicleData; track vehicle; let i = $index){
                        <label>{{ vehicle.name_code }} - {{  vehicle.model }}</label>
                    }
                </div>
            }
        </div>

    </div>

</div>

