import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { AppSettings } from '../../app.settings';
import type { Status } from '../../models/status';
import { EntityService } from '../../services/entity.service';
import { MiImageContainerComponent } from '../../ui-sharable/mi-image-container/mi-image-container.component';

@Component({
  selector: 'status',
  standalone: true,
  imports: [OverlayPanelModule, MiImageContainerComponent],
  templateUrl: './status.component.html',
  styleUrl: './status.component.scss'
})
export class StatusComponent {
  @Input() public dataForStatus: Array<Status> = [];
  @Input() public dataForVehicleStatus: Array<Status> = [];
  @Input() public userId: Array<Status> = [];
  @Input() public imgFileId: string;
  @Input() public imgFileUrl: string;
  @Input() public entityForStatus: any;
  imageBase64: any;
  defaultLoaderImg = AppSettings.DEFAULT_LOADER_IMAGE;

  constructor(
    private statusService: EntityService,
    private cd: ChangeDetectorRef) {
  }

  ngOnInit() {
    if (this.imgFileId) {
      this.getImage();
    } else {
      this.imageBase64 = AppSettings.DEFAULT_LOADER_IMAGE;
    }
  }

  getImage() {
    this.statusService.getFile(this.imgFileId, AppSettings.DOCUMENTS_TYPE.PROFILE).subscribe(result => {
      const file = new File([result], 'image');
      const reader = new FileReader();
      reader.readAsDataURL(result);
      const that = this;
      reader.onloadend = function() {
        const base64data = reader.result;
        that.imageBase64 = base64data;
        that.cd.detectChanges();
      }
    })
  }
}
