<div class="event-details">
    <form  [formGroup]="addEventForm" class="details-group">
        <div id="event-configuration">
            @if (showForm) {
                @for(tab of eventConfigAttributeData?.tabs; track tab?.tabCode) {
                    <app-mi-form
                        [attributeLabels]="attributeLabelsForEventConfig"
                        [moduleName]="eventConfigModuleName"
                        [attributeValues]="attributeValues"
                        [groups]="tab.groups"
                        [isMultipleFilesUpload]="false"
                        (saveData)="saveConfigSettings($event)"
                        [saveBtnId]="'eventConfig'"
                        [activeIndex]=activeIndex
                        [hideCancelAndSubmitBtn]="true"
                        >
                    </app-mi-form>
                }
            }
        </div>
    </form>
  </div>