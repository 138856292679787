import { Component, Input } from '@angular/core';
import { TooltipModule } from 'primeng/tooltip';
import { AppIcons } from '../../../app.icons';

@Component({
  selector: 'app-mi-tooltip',
  standalone: true,
  imports: [TooltipModule],
  templateUrl: './mi-tooltip.component.html',
  styleUrl: './mi-tooltip.component.scss'
})
export class MiTooltipComponent {
  miIcons = AppIcons;
  @Input() messageData;

}
