<p-breadcrumb [model]="routePath"></p-breadcrumb>
<div class="btn-alignment">
  <app-module-header [label]="btnLabel" [showImportExportBtn]="showImportExportBtn" (onBtnClick)="onAddQueue()"></app-module-header>
</div>
<p-tabView [(activeIndex)]="activeIndex" (onChange)="tabViewChange($event)">
  <p-tabPanel>
    <ng-template pTemplate="header">
        <i class="pi pi-align-left mr-2"></i>
        <span translate>lbl_all</span>&nbsp;<span class="iconNumber text-color-secondary">({{totalRecords}})</span>
      </ng-template>
    <ng-template pTemplate="body">
      @if(data){
        <app-mi-table
          [collectionOfColumns]="allColumns"
          [attributeLabels]="filterAttributeLabels"
          [data]="tableData"
          entityName="queue"
          [isLoading]="isLoading"
          (onPageChange)="onPageChange($event)"
          (itemPerPageChange)="itemPerPageChange($event)"
          [totalRecords]="totalRecords"
          [message]="deleteQueueMsg"
          [header]="deleteQueueHeaderMsg"
          (onDelete)="onQueueDelete($event)"
          (onBulkDataDeleteEvent)="onBulkDataDeleteEvent($event)"
          [filterList]="filterList"
          (onValueChange)="onFilterValueChange($event)"
          [bulkSelectionsItems] = "bulkSelectionsItems"
          [bulkDeleteLabel]="removeLabel"
          [filterFields]="filterFieldsArray"
          [label]="btnLabel"
          (onBtnClick)="onAddQueue()"
          [attributeData]="attributeData"
          (onSearchValueChange)="onSearchValueChanges($event)"
          (onUpdate)="onUpdateQueue($event)"
          [rowItems]="rowItems"
          [visible]="visible">
        </app-mi-table>
      }
    </ng-template>
  </p-tabPanel>
</p-tabView>
